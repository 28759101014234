<!--

日期期間選擇器

<DateRangePicker v-on="{setDateRange}"></DateRangePicker>

import DateRangePicker from 'modules/fund/components/DateRangePicker'

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      current: {
        dateStart: '',
        dateEnd: '',
      }
    }
  },
  methods: {
    setDateRange({ dateStart, dateEnd }) {
      Object.assign(this.current, {
        dateStart,
        dateEnd,
      })
    },
  }
}

-->

<template>
  <div class="daterange ui form noprint noselect">
    <div class="observation-box">
      <DropdownMonthPeriod
        v-bind="{
          baseDate,
          earliestDate,
          periodKey,
        }"
        ref="observation"
        class="daterange__input--big"
        freePeriods
        size="medium"
        fluid
        v-on="{ setDateRange }"
      />
    </div>
    <div v-if="!isFixedPeriod" class="daterange__group">
      <span class="daterange__text daterange__separator">或</span>
      <Calendar
        ref="startCalendar"
        v-model="selection.dateStart"
        type="month"
        :minDate="earliestDate"
        :maxDate="previousMonthOf(baseDate)"
        :endCalendar="calendar.end"
      />
      <span class="daterange__text">到</span>
      <Calendar
        ref="endCalendar"
        v-model="selection.dateEnd"
        type="month"
        :startCalendar="calendar.start"
        :minDate="nextMonthOf(earliestDate)"
        :maxDate="baseDate"
        @input="finishDateRange"
      />
    </div>
    <div v-if="current.currency" class="currency-box">
      <DropdownCurrency v-model="current.currency" class="fluid" v-bind="{ localCurrency }" />
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */

import DropdownMonthPeriod from 'modules/components/fund/DropdownMonthPeriod'
import DropdownCurrency from 'modules/components/fund/DropdownCurrency'
import Calendar from 'modules/ui/form/calendar'

const $ = jQuery

export default {
  components: {
    DropdownMonthPeriod,
    DropdownCurrency,
    Calendar,
  },
  props: {
    /**
     * 當使用日期區間模式時，用基準日期來計算提示的日期起訖
     * 需配合固定日期區間模式
     */
    baseDate: {
      /**
       * @name 基準日
       */
      type: [String, Object],
      default: moment().format('YYYY-MM-DD'),
      validator: value => {
        return (typeof value !== 'object' || moment.isMoment(value))
      }
    },
    earliestDate: {
      type: [String, Object, Date],
      default: () => '2000-01-01',
      validator: value => {
        return (typeof value !== 'object' || moment.isMoment(value))
      }
    },
    dateStart: {
      // 預設區間為近一年，但結束點為上個月底。例如： "2020-06"-30 ~ "2021-06"-30
      // 先往後半個月(約 0.041年)<，使起始點往後一個。變成 "2020-07"-01 ~ "2021-06"-30
      type: [String, Object],
      default: moment().add(-0.95, 'year').format(moment.HTML5_FMT.DATE),
    },
    dateEnd: {
      type: [String, Object],
      default: moment().format(moment.HTML5_FMT.DATE),
    },
    periodKey: String,
    /**
     * 使用固定日期區間模式
     * 只能選擇 近1週 ~ 近3年
     */
    isFixedPeriod: {
      type: Boolean,
    },
    currency: String,
    localCurrency: String,
  },
  data() {
    return {
      config: {
        dateFormat: moment.HTML5_FMT.DATE,
      },
      current: {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        periodKey: this.periodKey,
        currency: this.currency,
        activeId: null,
      },
      selection: {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        periodKey: this.periodKey,
      },
      calendar: {
        start: null,
        end: null
      }
    }
  },
  computed: {
    maxAvailableDate() {
      return moment().subtract(1, 'day').toDate() // 昨天
    },
    earliestDateText() {
      return this.earliestDate.format(this.config.dateFormat)
    },
  },
  watch: {
    'currency'() {
      this.current.currency = this.currency
    },
    'current.currency'() {
      this.$emit('update:currency', this.current.currency)
    },
    dateStart(value) {
      this.current.dateStart = value
      this.selection.dateStart = value
    },
    dateEnd(value) {
      this.current.dateEnd = value
      this.selection.dateEnd = value
    },
    periodKey(value) {
      this.current.periodKey = value
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.calendar.start = $(this.$refs.startCalendar?.$el)
      this.calendar.end = $(this.$refs.endCalendar?.$el)
    })
  },
  methods: {
    resetPeriod() {
      this.$refs.observation?.reset()
    },
    setDateRange({ dateStart = this.selection.dateStart, dateEnd = this.selection.dateEnd, periodKey }) {
      const curFromDate = moment(dateStart).isValid() ? moment(dateStart).endOf('month').format(moment.HTML5_FMT.DATE) : null
      const curToDate = moment(dateEnd).isValid() ? moment(dateEnd).endOf('month').format(moment.HTML5_FMT.DATE) : moment().format(moment.HTML5_FMT.DATE)

      this.$set(this.selection, 'dateStart', curFromDate)
      this.$set(this.selection, 'dateEnd', curToDate)
      this.$set(this.current, 'dateStart', curFromDate)
      this.$set(this.current, 'dateEnd', curToDate)
      this.$set(this.current, 'periodKey', periodKey)

      this.$emit('setDateRange', this.selection)
      this.$emit('update:dateStart', this.selection.dateStart)
      this.$emit('update:dateEnd', this.selection.dateEnd)
      this.$emit('update:periodKey', periodKey)
    },
    finishDateRange() {
      this.resetPeriod()
      this.setDateRange(this.selection)
    },
    previousMonthOf(date) {
      return moment(date).add(-1, 'month').format(moment.HTML5_FMT.DATE)
    },
    nextMonthOf(date) {
      return moment(date).add(1, 'month').format(moment.HTML5_FMT.DATE)
    },
  }
}
</script>

<style lang="stylus" scoped>
@import '~modules/ui/common'

.currency-box
.observation-box
  position relative
  flex 0 0 300px
  @media $mobile
    flex-basis 100%
    flex-grow 1

.currency-box
  margin-left auto

.daterange
  display flex
  align-items center
  flex-wrap wrap
  max-width initial
  input
    color #666
    font-weight bold
  > *
    display inline-block
    margin-top .5em
    margin-bottom .5em
  &__input
    &--small
      flex 0 0 150px
      width: 150px
      @media $mobile
        flex-grow 1
  &__text
    flex-shrink 0 !important
    flex-grow 0 !important
    min-width 2rem
    text-align center
  &__group
    flex-shrink 0
    flex-grow 1
    display inline-flex
    align-items center
    white-space nowrap
    max-width initial
    @media $mobile
      flex-shrink 1
  .is-title
    margin-right .5em
    @media $mobile
      text-align left
      width 100%
    &:after
      content '：'

</style>

<style lang="stylus">
@import '~modules/ui/common'

.pika-single
  margin-top 1rem
  border-color #ededed
  border-radius 5px
  .pika-table abbr
    cursor default
    text-decoration none
  +under(480px)
    left 1rem !important
    right 1rem !important
    .pika-lendar
      float none !important
      width auto !important

.ui.popup.calendar
  table
    table-layout fixed
</style>
