<template>
  <div class="member-panel-layout">
    <div class="panel-wrap full-height">
      <div class="panel-illustration pen">
        <img src="~images/img-illustration-16.png" alt="">
      </div>
      <div class="panel">
        <div class="panel-head">
          <div class="head-title">
            變更密碼
          </div>
        </div>
        <div class="panel-body">
          <div class="row-wrap">
            <UiInput v-model="form.former_password" :error="fieldErrorOf('former_password')" class="fluid" type="password" placeholder="輸入目前的密碼" />
          </div>
          <div class="row-wrap">
            <UiInput v-model="form.password" :error="fieldErrorOf('password')" class="fluid" type="password" placeholder="設定新密碼 (至少6個字)" />
          </div>
          <div class="row-wrap">
            <UiInput v-model="form.password_confirmation" :error="fieldErrorOf('password_confirmation')" class="fluid" type="password" placeholder="請再輸入一次新密碼" />
          </div>
        </div>
        <div class="panel-actions">
          <router-link :to="{name: 'Partner'}" class="ui button fluid minor">
            取消
          </router-link>
          <div class="ui button fluid major" @click="changePwd">
            確認變更
          </div>
        </div>
      </div>
      <div class="panel-illustration person">
        <img src="~images/img-illustration-15.png" alt="">
      </div>
    </div>

    <ModalSuccessChange :visible="showModal" />
  </div>
</template>

<script>
import API from 'api'
import UiInput from 'modules/ui/form/input'
import ModalSuccessChange from 'pages/member/component/ModalChangePwd'
import { createValidator } from 'modules/ui/form/validation.js'
import { MixinNotifiable } from 'modules/ui/notification.vue'

export default {
  components: {
    UiInput,
    ModalSuccessChange,
  },
  mixins: [
    MixinNotifiable,
    createValidator([
      {
        field: 'former_password',
        message: '請填入註冊使用的密碼'
      },
      {
        field: 'password',
        validator: pwd => pwd.length > 5,
        message: '新密碼過短，最少6個字'
      },
      {
        field: 'password',
        message: '請輸入新密碼'
      },
      {
        field: 'password',
        validator: (pwd, vm) => pwd === vm.form.password_confirmation,
        message: '兩次新密碼輸入不一致'
      },
      {
        field: 'password_confirmation',
        validator: (pwd2, vm) => pwd2 === vm.form.password,
        message: '兩次新密碼輸入不一致'
      },
      {
        field: 'password_confirmation',
        message: '請輸入新密碼'
      },
    ])
  ],
  data() {
    return {
      form: {
        former_password: '',
        password: '',
        password_confirmation: '',
      },
      showModal: false,
    }
  },
  watch: {
    'form': {
      deep: true,
      handler() {
        this.clearValidation()
      }
    },
  },
  methods: {
    async changePwd() {
      if (this.validateForm(this.form)) {
        const params = {
          ...this.form,
          username: this.$member.email,
        }
        this.wait(API.member.setPasswd(params), { message: '請稍後...' })
          .then((res) => {
            this.showModal = true
          }).catch(error => {
            this.pushFieldError('former_password', error.response.data.message)
          })
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~pages/member/memberPanel.styl'
</style>
