<template>
  <div class="page-list">
    <div class="list-head">
      <div class="ui two columns grid stackable">
        <div class="column">
          <div class="ui compact labeled icon menu">
            <router-link :to="{name: 'AdminFundReportCreate'}" class="item">
              <i class="file outline icon" />
              新增報告
            </router-link>
            <router-link :to="{name: 'AdminFundList'}" class="item">
              <i class="list ul icon" />
              返回基金
            </router-link>
          </div>
        </div>
      </div>

      <div class="ui divider" />
    </div>
    <div class="list-body">
      <Loader class="loader" :loading="!loadingStateReady">
        <template v-if="entries.length > 0">
          <table class="ui celled table">
            <thead>
              <tr>
                <th v-for="{name, style} in columns" :key="name" v-bind="{style}" v-text="name" />
              </tr>
            </thead>
            <Draggable v-model="entries" tag="tbody" handle=".sorter" @change="sort">
              <tr v-for="model in entries" :key="model.id">
                <template v-for="{name, style, template, column, props} in columns" v-bind="{style}" v-text="name">
                  <td :key="`${model.id}-${name}`" v-bind="{style}">
                    <component :is="template" v-bind="{model, column, ...props}" @toggle="toggle" v-on="{remove}" />
                  </td>
                </template>
              </tr>
            </Draggable>
          </table>
          <Pagination
            v-if="pagination"
            :page.sync="current.page" :total="pagination.total_pages"
          />
        </template>
        <EmptyPlaceholder v-else />
      </Loader>
    </div>
  </div>
</template>

<script>

import MixinAdminCommonPage from 'modules/admin/common/page'
import columns from './columns.js'

export default {
  mixins: [
    MixinAdminCommonPage,
  ],
  data() {
    return {
      current: {
        page: 1,
        fund: null,
      },
      entries: [],
      categories: [],
      pagination: null,
    }
  },
  computed: {
    columns: () => columns,
    listParams() {
      return {
        id: this.$route.params.fundId,
        page: this.current.page,
        format: 'full'
      }
    },
    statusTabs() {
      return Object.values(this.status)
    },
  },
  watch: {
    'listParams': {
      immediate: true,
      handler() {
        this.loadList()
      }
    },
  },
  methods: {
    reset() {
      this.entries = []
      this.pagination = null
    },
    async loadList() {
      const loader = this.createLoadingState()
      this.reset()
      // 加上 format: full 可取得完整資料，但需登入管理員的驗證
      const { entries, pagination } = await this.$api.fund.reports(this.listParams).then(res => res.data)
      this.entries = entries
      this.pagination = pagination
      loader.finish()
    },
    findById(targetId) {
      return this.entries.find(({ id }) => id === targetId)
    },
    async toggle(id, prop, val) {
      this.update(id, { [prop]: val }).then(() => {
        this.findById(id)[prop] = val
      })
    },
    async update(id, attributes) {
      await this.$api.fund.report.update({ id, attributes, format: 'full' })
    },
    async remove(entry) {
      const sure = await confirm({
        title: `刪除${entry.type}`,
        message: `確定要刪除「${entry.date} ${entry.type}」嗎？此操作不可復原`
      })
      if (sure) {
        this.wait(this.$api.fund.report.destroy({ id: entry.id })).then(() => {
          this.loadList()
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.list-body, .loader
  min-height calc(100vh - 300px) !important
.ui.table
  border-radius 0 !important
</style>
