<template>
  <div class="chart">
    <DateRangePicker
      v-bind="{ ...current, baseDate, earliestDate }"
      v-on="{ setDateRange, ...$listeners }"
    />

    <ChartLegend class="chart-legend">
      <div
        v-for="cursor in chartData" :key="cursor.name" class="legend-item"
        :class="{active: isActive(cursor.id)}" :style="{backgroundColor: hexa(cursor.color, 20)}"
        @mouseenter="current.activeId = cursor.id"
        @mouseleave="current.activeId = null"
      >
        <span class="color-ball" :style="{ background: cursor.color }" />
        <span>{{ cursor.name }}</span>
      </div>
    </ChartLegend>

    <UiLoader :loading="!loadingStateReady">
      <InteractionWrapper ref="chart" class="chart-wrap">
        <ViewportCondition value="> md">
          <div class="chart-elements" :style="styleMargin">
            <div v-if="closestCursorPoint" class="cusror-points">
              <span v-for="point in sortedCursorPoints" :key="point.name" :style="getPointStyle(point)" />
            </div>
            <div :style="cursorStyle" class="cursor-scanline">
              <!-- 游標Hover的Popup -->
              <div v-if="closestCursorPoint" ref="cursorPopup" :class="{ flipped: cursorPopupFlipped }" class="cursor-popup">
                <div class="h5">
                  <span>{{ closestCursorPoint.actual_date }}</span>
                </div>
                <div v-for="cursor in sortedCursorPoints" :key="cursor.name" class="item">
                  <span :style="{ background: cursor.color }" class="color-ball" />
                  <span class="cursor-label">{{ cursor.name }}</span>
                  <span class="cursor-value">
                    <template v-if="cursor.name === '資產規模'">
                      {{ cursor.point.value | currency(1) }}
                    </template>
                    <template v-else>
                      {{ cursor.point.value | currency(4) }}
                    </template>
                  </span>
                  <span class="cursor-remark">{{ cursor.unit }}</span>
                  <span v-if="cursor.point.estimated" class="cursor-remark">(預估值)</span>
                </div>
              </div>
            </div>
          </div>
        </ViewportCondition>
        <div>
          <svg v-once ref="svg" height="250" style="width: 100%" width="800" />
        </div>
      </InteractionWrapper>
    </UiLoader>
  </div>
</template>

<script>
import D3Core from 'modules/chart/d3/core.js'
import MixinColors, { ChartColors } from 'modules/chart/colors.js'

import UiLoader from 'modules/ui/loader'
import InteractionWrapper from 'modules/misc/InteractionWrapper.vue'
import ViewportCondition from 'modules/misc/ViewportCondition.vue'
import ChartLegend from 'modules/chart/components/Legend.vue'
import DateRangePicker from 'modules/components/fund/DateRangePicker'

import API from 'api'

function getTimeOfYear(t) {
  return (t = moment(t).startOf('year').toDate().setHours(0, 0, 0, 0))
}

function getTimeOfDay(t) {
  if (typeof t == 'string' || t instanceof Date) {
    return new Date(t).setHours(0, 0, 0, 0)
  }
  if (t instanceof Date) {
    return t.setHours(0, 0, 0, 0)
  }
  if (t && t.toDate) {
    return t.toDate().setHours(0, 0, 0, 0)
  }
}

export default {
  components: {
    UiLoader,
    InteractionWrapper,
    ViewportCondition,
    ChartLegend,
    DateRangePicker,
  },
  mixins: [
    //
    D3Core,
    MixinColors,
  ],
  props: {
    fundId: {
      type: [Number, String],
      default: null
    },
    baseDate: {
      type: [String, Date],
      default: () => moment(),
    },
    earliestDate: {
      type: [String, Object, Date],
      default: () => '2000-01-01',
      validator: value => {
        return (typeof value !== 'object' || moment.isMoment(value))
      }
    },
    dateStart: {
      type: [String],
      default: moment().add(-1, 'year').format(moment.HTML5_FMT.DATE)
    },
    dateEnd: {
      type: [String],
      default: moment().format(moment.HTML5_FMT.DATE)
    },
    periodKey: String,
  },
  data() {
    return {
      rawData: [],
      current: {
        closestPoint: null, // 目前最靠近游標的點
        activeId: null,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        periodKey: this.periodKey,
      },
      config: {
        lines: [
          // 要顯示的線
          { name: '淨值', key: 'price', color: ChartColors[0] },
          { name: '資產規模', key: 'aum_mn', color: ChartColors[1] },
        ],
        unit: {
          // 三軸的資料單位
          x: '日期',
          y: '美元',
          y2: '百萬美元',
        },
        margin: { top: 20, right: 30, bottom: 30, left: 30 },
        format: {
          // 定義資料點顯示方式
          tick: {
            x: d3.timeFormat('%Y-%m'),
            y: val => (+val).toFixed(0).toString().replace(/\B(?=(\d{3})+\b)/g, ','),
            y2: val => (+val).toFixed(0).toString().replace(/\B(?=(\d{3})+\b)/g, ','),
          }
        },
        valueOf: {
          // 定義資料點取值方式
          x: (v) => {
            if (this.mode === 'history') {
              return getTimeOfYear(v.date)
            } else {
              return getTimeOfDay(v.date)
            }
          },
          y: ({ value }) => value,
          y2: ({ value }) => value,
        },
      },
    }
  },
  computed: {
    // Ｘ 軸可以顯示幾個刻度
    tickXCount() {
      const tickSize = 80 // 每個刻度的文字寬度
      const maxCount = this.xValues.map(this.config.format.tick.x).filter((el, i, a) => i === a.indexOf(el)).length
      return Math.min(maxCount, Math.floor(this.canvas.width / tickSize))
    },
    chartData() {
      return this.rawData.map(({ data, ...line }) => ({
        ...line,
        data: data.filter(this.dataFilter)
      }))
    },
    idName() {
      const arr = this.rawData.map(data => {
        return [data.id, data.name]
      })
      return Object.fromEntries(arr)
    },
    dataPoints() {
      return this.chartData?.reduce((a, b) => a.concat(b.data), []) || []
    },
    // 取出 X 軸所有日期
    xValues() {
      return this.dataPoints
        .map((d) => this.config.valueOf.x(d))
        .filter((el, i, a) => i === a.indexOf(el))
    },
    maxY() {
      return Math.max(...this.dataPoints.filter(({ axis }) => axis === 1).map((d) => this.config.valueOf.y(d)))
    },
    minY() {
      return Math.min(0, ...this.dataPoints.filter(({ axis }) => axis === 1).map((d) => this.config.valueOf.y(d)))
    },
    maxY2() {
      return Math.max(...this.dataPoints.filter(({ axis }) => axis === 2).map((d) => this.config.valueOf.y2(d)))
    },
    minY2() {
      return Math.min(...this.dataPoints.filter(({ axis }) => axis === 2).map((d) => this.config.valueOf.y2(d)))
    },
    maxX() {
      return Math.max(...this.dataPoints.map((d) => this.config.valueOf.x(d)))
    },
    minX() {
      return Math.min(...this.dataPoints.map((d) => this.config.valueOf.x(d)))
    },
    // 最靠近游標垂直線上的所有點
    closestCursorPoint() {
      if (this.current.closestPoint) {
        return {
          ...this.current.closestPoint,
          x: this.chart.scaled.x(this.config.valueOf.x(this.current.closestPoint)),
          y: this.chart.scaled.y(this.config.valueOf.y(this.current.closestPoint)),
        }
      }
      return null
    },
    cursorPoints() {
      // 過濾掉這個日期下沒有值的
      if (this.closestCursorPoint) {
        return this.chartData.map(line => {
          const point = line.data.find(d => this.config.valueOf.x(d) === this.config.valueOf.x(this.closestCursorPoint))
          return point
            ? {
                name: line.name,
                color: line.color,
                point,
                x: this.chart.scaled.x(this.config.valueOf.x(point)),
                y: line.axisDomain(this.config.valueOf.y(point)),
                unit: line.unit,
                date: this.config.valueOf.y(point)
              }
            : null
        }).filter(point => point)
      }
      return null
    },
    sortedCursorPoints() {
      return this.cursorPoints ? this.cursorPoints.slice().sort((a, b) => b.point.value - a.point.value) : []
    },
    cursorStyle() {
      if (this.closestCursorPoint) {
        return `transform: translateX(${Math.round(this.closestCursorPoint.x)}px)`
      }
      return null
    },
    cursorPopupFlipped() {
      if (this.closestCursorPoint && this.$refs.cursorPopup) {
        if (this.closestCursorPoint.x > this.$refs.cursorPopup.clientWidth + 20) { // + 允許右邊超出的寬度
          return this.closestCursorPoint.x + this.$refs.cursorPopup.clientWidth > this.$refs.chart.$el.offsetWidth / 2
        }
      }
      return null
    },
    // 資料過濾器
    dataFilter() {
      const start = moment(this.dateStart).startOf('month').toDate()
      const end = moment(this.dateEnd).endOf('month').toDate()
      return ({ date }) => {
        const time = new Date(date)
        return time >= start && time <= end
      }
    }
  },
  watch: {
    tickXCount() {
      this.config.ticks.x = this.tickXCount
      this.render()
      this.attachEvents()
    },
    'current.activeId'(id) {
      if (id) {
        this.$refs.chart.$el.querySelectorAll('.lines path').forEach(line => {
          line.style.opacity = '0.2'
          line.style.strokeWidth = '1px'
        })
        this.$refs.chart.$el.querySelectorAll(`.lines path[id="${this.idName[id]}"]`).forEach(line => {
          line.style.opacity = ''
          line.style.strokeWidth = ''
        })
      } else {
        this.$refs.chart.$el.querySelectorAll('.lines path').forEach(line => {
          line.style.opacity = ''
          line.style.strokeWidth = ''
        })
      }
    },
    dateStart(value) {
      this.current.dateStart = value
    },
    dateEnd(value) {
      this.current.dateEnd = value
    },
    periodKey(value) {
      this.current.periodKey = value
    },
    // 當資料過濾器更新，就重劃線條
    dataFilter() {
      this.$nextTick(() => {
        this.prepare()
        this.render()
      })
    },
  },
  async mounted() {
    this.loadAndDraw()
    window.addEventListener('resize', this.render)
  },
  methods: {
    async loadAndDraw() {
      const loader = this.createLoadingState()
      this.rawData = await API.fund.history({ id: this.fundId }).then(res => {
        const history = res.data.history

        return this.config.lines.map(({ name, key, color }) => {
          const axis = (key === 'aum_mn' ? 2 : 1)
          const unit = (key === 'aum_mn' ? this.config.unit.y2 : this.config.unit.y1)
          const instance = this
          return {
            id: name,
            name,
            label: name,
            color: color,
            axis,
            unit,
            get axisDomain () {
              return (axis === 1 ? instance.chart.scaled.y : axis === 2 ? instance.chart.scaled.y2 : () => null)
            },
            data: history.filter(point => point[key] !== undefined).map(({ estimate, ...point }) => ({
            // 讓日期貼齊一號
              date: moment(point.date).set('date', 1).format('YYYY-MM-DD'),
              actual_date: point.date,
              value: point[key],
              estimate,
              axis,
            }))
          }
        })
      })

      loader.finish()
      this.$nextTick(() => {
        this.prepare()
        this.init()
        this.render()
        this.attachEvents()
      })
    },
    // 設定比例尺
    prepare() {
      this.chart.x = d3.scaleTime()
      this.chart.x.domain(d3.extent(this.xValues)) // 以X軸的日期範圍來設定
      this.chart.y = d3.scaleLinear()
      this.chart.y.domain([this.minY * 1.2, this.maxY * 1.2]) // 設定Y軸範圍
      this.chart.y2 = d3.scaleLinear()
      this.chart.y2.domain([
        this.minY2 * (this.minY2 < 0 ? 1.2 : (1 / 1.2)),
        this.maxY2 * 1.2
      ]) // 設定Y軸範圍 #2
    },
    setScale() {
      this.chart.scaled.x = this.chart.x.copy().rangeRound([0, this.canvas.width])
      this.chart.scaled.y = this.chart.y.copy().range([this.canvas.height, 0])
      this.chart.scaled.y2 = this.chart.y2.copy().range([this.canvas.height, 0])
    },
    drawTicks() {
      // Horizontal Grid lines
      this.context.graph.append('g').attr('class', 'grid-horizontal').call(d3.axisLeft(this.chart.scaled.y).ticks(this.config.ticks.y).tickFormat('').tickSize(-this.canvas.width))

      // Zero
      this.context.graph.select('.grid-horizontal')
        .append('line')
        .attrs({
          'x1': 0,
          'y1': Math.round(this.chart.scaled.y(0)),
          'x2': this.canvas.width,
          'y2': Math.round(this.chart.scaled.y(0)),
          'shape-rendering': 'crispEdges',
          'stroke': '#999',
          'stroke-width': 1,
        })

      // 左邊 Y軸刻度 #1
      this.context.graph
        .append('g')
        .attr('class', 'axis-y')
        .call(
          d3.axisLeft(this.chart.scaled.y).tickFormat(this.config.format.tick.y).ticks(this.config.ticks.y)
        )
        .append('text')
        .attr('class', 'label')
        .attr('text-anchor', 'end')
        .attr('x', 0)
        .attr('y', -6)
        .text(this.config.unit.y)
      // 右邊 Y軸刻度 #2
      this.context.graph
        .append('g')
        .attr('class', 'axis-y2')
        .attr('transform', `translate(${this.canvas.width}, 0)`)
        .call(
          d3.axisRight(this.chart.scaled.y2).tickFormat(this.config.format.tick.y).ticks(this.config.ticks.y)
        )
        .append('text')
        .attr('class', 'label')
        .attr('text-anchor', 'end')
        .attr('x', 25)
        .attr('y', -6)
        .text(this.config.unit.y2)
      // 下面 X軸刻度
      const xTickSize = Math.min(this.canvas.width / 70, this.xValues.length)
      this.context.graph
        .append('g')
        .attr('class', 'axis-x')
        .attr('transform', 'translate(0,' + this.canvas.height + ')')
        .call(
          d3.axisBottom(this.chart.scaled.x).tickFormat(this.config.format.tick.x).ticks(this.config.ticks.x)
            .tickValues(this.xValues.filter((d, i) => !(i % Math.round(this.xValues.length / xTickSize))))
        )
    },
    // 產生線的資料
    generateLinePath(data, axisY = this.chart.scaled.y) {
      return d3
        .line() // 照縮放成畫布大小的比例尺來渲染
        .x((d) => this.chart.scaled.x(this.config.valueOf.x(d)))
        .y((d) => axisY(this.config.valueOf.y(d)))(data)
    },
    drawContext() {
      this.context.chart = this.context.graph.append('g').attr('class', 'chart')

      // 提前一點開始算為預估值線條的起始
      function filterEstimatePoints({ estimate }, index, array) {
        return estimate || array[index + 1]?.estimate
      }

      // 畫每條線
      const lines = this.context.chart.append('g').attr('class', 'lines')
      this.chartData.forEach((line, index) => {
        // 實線部分 (歷史圖表才需要)
        // prettier-ignore
        lines
          .append('path')
          .attr('class', 'line')
          .attr('name', line.name)
          .attr('id', line.name)
          .attr('d', this.generateLinePath(line.data.filter(({ estimate }) => !estimate), line.axisDomain))
          .style('stroke', line.color) // 照順序設定顏色
        // 虛線部分
        // prettier-ignore
        lines
          .append('path')
          .attr('class', 'line')
          .attr('name', line.name)
          .attr('id', line.name)
          .attr('d', this.generateLinePath(line.data.filter(filterEstimatePoints), line.axisDomain))
          .style('stroke', line.color) // 照順序設定顏色
          .style('stroke-dasharray', ('3, 3'))
      })
    },
    attachEvents() {
      // http://bl.ocks.org/mikehadlow/93b471e569e31af07cd3
      this.context.graph.select('.boundary').on('mousemove touchmove', (d, i, nodes) => {
        const [mouseX] = d3.mouse(nodes[0])
        const closestPoint = this.findClosestPointByX(mouseX, this.dataPoints)
        this.current.closestPoint = {
          ...closestPoint,
          year: moment(this.config.valueOf.x(closestPoint)).year(),
        }
      })
    },
    getPointStyle(cursorPoint) {
      const y = cursorPoint.y
      const x = cursorPoint.x
      return {
        transform: `translate(${x}px,${y}px)`,
        backgroundColor: cursorPoint.color,
        boxShadow: `0 0 0px 2px white, 0 0 0px 6px ${this.hexa(cursorPoint.color, 20)}`,
      }
    },
    isActive(lineId) {
      return this.current.activeId === lineId
    },
    setDateRange({ dateStart, dateEnd }) {
      this.$emit('update:dateStart', dateStart)
      this.$emit('update:dateEnd', dateEnd)
    }
  },
}
</script>

<style lang="stylus" src="modules/chart/d3/core.styl" scoped></style>
<style lang="stylus" scoped src="modules/chart/performance/core-extend.styl"></style>
<style lang="stylus" scoped>
@import '~modules/ui/common'
+under(md)
  /deep/ .chart-legend
    padding-left 1.5rem

.chart
  padding: 1.5rem
.chart-legend
  margin-top 1.5em 0 0 0
.chart-elements
  position absolute
.cursor-remark
  color $gray2
</style>
