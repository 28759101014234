<!--
  Author: Lackneets Chang
  inspired by https://codepen.io/herteleo/pen/LraqoZ
-->
<template>
  <div class="datetimepicker">
    <input type="hidden" v-bind="{name, value, required}">
    <input v-model="date" type="date">
    <i class="icon-calendar" />
    <span />
    <input v-model="time" type="time">
    <i class="icon-time" />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: () => null,
    },
    value: {
      type: [Object, Date, String, Number],
      default: () => null
    },
    format: {
      type: String,
      default: () => 'YYYY-MM-DD HH:mm'
    },
    required: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    date: {
      get() {
        return moment(this.value).format('YYYY-MM-DD')
      },
      set(date) {
        this.$emit('input', moment(`${date} ${this.time}`, 'YYYY-MM-DD HH:mm').format(this.format))
      }
    },
    time: {
      get() {
        return moment(this.value).format('HH:mm')
      },
      set(time) {
        this.$emit('input', moment(`${this.date} ${time}`, 'YYYY-MM-DD HH:mm').format(this.format))
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.datetimepicker
  display inline-flex
  align-items baseline
  border-color #ddd
  background #fbfbfb
  border 1px solid rgba(34, 36, 38, .15)
  border-radius .28571429rem
  height 38px
  &:hover, &:focus-within
    border-color $gray2
  input
    font inherit
    color inherit
    appearance none
    outline none
    border 0
    background-color transparent
    &[type=date]
      width 9rem
      padding .25rem 0 .25rem .5rem
      border-right-width 0
    &[type=time]
      width 7.5rem
      padding .25rem .5rem .25rem 0
      border-left-width 0
  span
    height 1rem
    margin-right .25rem
    margin-left .25rem
    border-right 1px solid #ddd
  input[type='date']::-webkit-calendar-picker-indicator,
  input[type='time']::-webkit-calendar-picker-indicator
    color rgba(0, 0, 0, 0)
    opacity 1
    display block
    background transparent
    width 20px
    height 20px
    margin-left 0
    border-width thin
    cursor pointer
    &:focus
      outline none
  input
    border none !important
    background none !important
  input + [class^='icon']
    margin-left -2rem
    margin .5rem .5rem .5rem -2rem
    pointer-events none
</style>
