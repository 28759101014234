const Colors = {
  BF: '#DFBCE6',
  EF: '#88C4ED',
  MMF: '#80C9C2',
  BLF: '#DBE575',
  GlobalIndex: '#006EA5', // 全球理財指數
  Benchmark: '#95EBE8', // benchmark 顏色 (基金單頁) / 該投組顏色 (投組單頁)
  Primary: '#FF895A', // 主色
  Major: '#FF895A',
  Disabled: '#EDEDED'
}

const ChartColors = [
  '#FF895A',
  '#82CA82',
  '#76A3FC',
  '#F89FC1',
  '#91A5AE',
  '#F2D206',
  '#46B4B4',
  '#B3A6EB',
  '#E77B7B',
  '#CAA0A0',
  '#B5A981',
  '#6D8E5B',
  '#86604C',
  '#D0D0D0',
]

const MixinColorRotation = {
  data() {
    return {
      $_colorRotation: {
        index: 0,
        map: {},
      },
    }
  },
  created () {
    // 設定全球理財指數固定為藍色 (50, index)
    this.assignColor('bench-50', Colors.GlobalIndex)
    this.assignColor('index', Colors.GlobalIndex)
  },
  computed: {
    $_nextColor() {
      // 依照順序獲得下一個色碼，每一輪都會降 60% 透明度
      const alphaLevel = 1 + Math.floor(this.$data.$_colorRotation.index / ChartColors.length)
      const color =
        shadeColor(ChartColors[this.$data.$_colorRotation.index % ChartColors.length], 1 - Math.pow(0.6, alphaLevel))
      return color
    },
    $_unusedColor() {
      // 依照順序取出第一個未使用的色碼
      const used = Object.values(this.$data.$_colorRotation.map)
      const color = ChartColors.find(color => !used.includes(color))
      return color
    },
    colorMap() {
      return this.$data.$_colorRotation.map
    },
  },
  methods: {
    assignColor(id, color) {
      return this.$set(this.$data.$_colorRotation.map, id, color)
    },
    unassignColor(id) {
      return this.$set(this.$data.$_colorRotation.map, id, undefined)
    },
    getColor(id) {
      let color = this.$data.$_colorRotation.map[id]
      if (color) {
        return color
      } else if (this.$_unusedColor) {
        // 如果還有可用的顏色，優先使用
        return this.$set(this.$data.$_colorRotation.map, id, this.$_unusedColor)
      } else {
        // 否則開始輪替模式
        color = this.$set(this.$data.$_colorRotation.map, id, this.$_nextColor)
        this.$data.$_colorRotation.index++
        return color
      }
    },
    generateColorMap(ids) {
      ids.forEach(id => this.getColor(id))
      return { ...this.$data.$_colorRotation.map }
    },
  },
}

export { MixinColorRotation }
export { Colors, ChartColors }
export {
  shadeColor,
  rgbToHex,
  hexa,
  blendRGBColors,
  hexBland
}
export default {
  data: function() {
    return {
      Colors,
      ChartColors,
    }
  },
  methods: {
    shadeColor,
    rgbToHex,
    hexa,
    blendRGBColors,
    hexBland,
  },
}

/* eslint-disable */
function shadeColor(color, percent) {
  color = color.match(/rgb/) ? this.rgbToHex(color) : color
  var f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff
  return (
    '#' +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  )
}
function rgbToHex(rgb) {
  return (
    '#' +
    rgb
      .substr(4, rgb.indexOf(')') - 4)
      .split(',')
      .map(color => String('0' + parseInt(color).toString(16)).slice(-2))
      .join('')
  )
}
function hexa(hex, opacity) {
  hex = hex.replace('#', '')
  opacity = opacity <= 1 ? opacity * 100 : opacity
  let r = parseInt(hex.substring(0, 2), 16)
  let g = parseInt(hex.substring(2, 4), 16)
  let b = parseInt(hex.substring(4, 6), 16)
  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')'
}
function blendRGBColors(c0, c1, p) {
  c0 = c0.match(/^#\w{6}/) ? this.hexa(c0, 1) : c0;
  c1 = c1.match(/^#\w{6}/) ? this.hexa(c1, 1) : c1;
  var f=c0.split(","),t=c1.split(","),R=parseInt(f[0].replace(/^rgba?\(/,'')),G=parseInt(f[1]),B=parseInt(f[2]),A=parseFloat(f[3]||1);
  return "rgba("+(Math.round((parseInt(t[0].replace(/^rgba?\(/,''))-R)*p)+R)+","+(Math.round((parseInt(t[1])-G)*p)+G)+","+(Math.round((parseInt(t[2])-B)*p)+B)+","+(((parseFloat(t[3]||1)-A)*p)+A)+")";
}
function hexBland(c0, c1, p) {
  var f = parseInt(c0.slice(1), 16),
    t = parseInt(c1.slice(1), 16),
    R1 = f >> 16,
    G1 = (f >> 8) & 0x00ff,
    B1 = f & 0x0000ff,
    R2 = t >> 16,
    G2 = (t >> 8) & 0x00ff,
    B2 = t & 0x0000ff
  return (
    '#' +
    (
      0x1000000 +
      (Math.round((R2 - R1) * p) + R1) * 0x10000 +
      (Math.round((G2 - G1) * p) + G1) * 0x100 +
      (Math.round((B2 - B1) * p) + B1)
    )
      .toString(16)
      .slice(1)
  )
}
/* eslint-enable */
