<template>
  <div v-if="rawEntries && rawEntries.length > 0" class="marquee">
    <span class="icon-style">
      <i class="icon-speaker" />
    </span>
    <Slick :options="slickSetting" class="slick-wrap" @init="slickInit">
      <div v-for="item in rawEntries" :key="item.id" class="item">
        <a :href="item.path" class="headlines">{{ item.title }}</a>
      </div>
    </Slick>
  </div>
</template>

<script>
import API from 'api'
import Slick from 'modules/ui/slick'

const $ = jQuery

export default {
  components: {
    Slick,
  },
  data() {
    return {
      rawEntries: null,
      slickSetting: {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        vertical: true,
        verticalSwiping: true,
        arrows: false,
      },
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      this.rawEntries = await API.content.marquee().then(res => res.data.entries).catch()
    },
    slickInit({ target }) {
      // 強迫重新渲染文字 防止 ellipsis 失效
      $(target).find('.slick-slide').css({
        'text-shadow': '0 0 transparent'
      })
    }
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.marquee
  display flex
  .icon-style
    font-size 1.2rem
    color #ff895a
    margin-right 1rem
.slick-wrap
  font-size 1.2rem
  overflow hidden
  .headlines
    display -webkit-box
    -webkit-box-orient vertical
    text-overflow ellipsis
    -webkit-line-clamp 1
    overflow hidden
    +under(md)
      -webkit-line-clamp 2
    &:hover
      text-decoration underline
      font-weight bold

.item
  height 20px
  line-height 20px
  margin-bottom 1rem
  margin-right 1rem
  +under(md)
    height 40px
    overflow hidden
</style>
