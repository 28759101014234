/* eslint-disable eqeqeq */

const $ = jQuery

class ChartPerformancePointData {
  constructor(data = {}) {
    this._data = data
    this.offset = 0
  }

  bindOffset(cb) {
    this._offset = cb
    return this
  }

  set offset(v) {
    this._offset = v
  }

  get offset() {
    if (typeof this._offset == 'function') {
      return this._offset()
    } else {
      return this._offset
    }
  }

  get value() {
    if (this._data.value === null) {
      return null
    }
    return (this._value != undefined) ? this._value : (((this._data.value + 1) / (this.offset + 1)) - 1)
  }

  get rawValue() {
    return this._data.value
  }

  get date() {
    return this._data.date
  }

  get _date() {
    if (this._data._date) {
      return this._data._date
    } else {
      return (this._data._date = new Date(this._data.date).setHours(0, 0, 0, 0))
    }
  }

  get amount() {
    return this._data.amount
  }

  get currency() {
    return this._data.currency
  }
}

class ChartPerformanceData {
  static get defaultOptions() {
    return {
      // 使用績效模式 (起始點為 0)
      performanceMode: true
    }
  }

  constructor(data = [], options = ChartPerformanceData.defaultOptions) {
    this._rawdata = data
    this._colors = {}

    this.options = {
      ...ChartPerformanceData.defaultOptions,
      ...options,
    }

    this._left = 0
    this._right = 0

    if (this.options.performanceMode) {
      this._lineOffsets = this._rawdata.map(set => set.data[0] ? set.data[0].value : 0) // 以第一點數值當作 offset, 校準為0
    } else {
      this._lineOffsets = this._rawdata.map(line => 0)
    }

    // Parse Dates
    this._rawdata = this._rawdata.filter(set => set.data.length > 0) // Reject invalid lines
    this._rawdata.forEach((set, i) =>
      (set.data = set.data.map((point, pi) => {
        if ((point instanceof ChartPerformancePointData) === false) {
          return this.createPointData(point).bindOffset(() => {
            return this._lineOffsets[i]
          })
        } else {
          return point
        }
      }))
    )

    // Trim
    // this.trim();

    this.x = d3.scaleTime()
    this.y = d3.scaleLinear()
    this.z = d3.scaleOrdinal(ChartPerformanceData.Colors)

    this.x.domain(d3.extent(this.getAllDates().map(ChartPerformanceData.time)))
    this.z.domain(data.map(function(c) { return c.id || c.label }))

    this.domainDates()
    this.domainScale()
    this.setScale()
  }

  createPointData(data) {
    return new ChartPerformancePointData(data)
  }

  domainDates(startDate = moment('2000-01-01').toDate(), endDate = moment().toDate()) {
    this.startDate = moment(startDate).toDate()
    this.endDate = moment(endDate).toDate()
    this.x.domain(d3.extent(this.getAllDates().map(d => moment(d).toDate()).filter(t => {
      return t >= this.startDate && t <= this.endDate
    })))

    this.setAnchor(this.x(this.startDate))

    this.vMax = this.max
    this.vMin = this.min

    return this
  }

  domainScale() {
    // Min -5% ~ 5%
    // Max: -100% ~ Infinity
    if (this.options.performanceMode) {
      this.y.domain([
        Math.min(-0.05, (Math.abs(this.vMin) * -1 - this.vMax) * 1.15),
        Math.max(0.05, (this.vMin * -1 + this.vMax) * 1.15)
      ])
    } else {
      this.y.domain([
        Math.min(-0.05, this.vMin * 1.15),
        Math.max(0.05, this.vMax * 1.15)
      ])
    }

    return this
  }

  // Y 軸對稱
  domainMirror(scale = 1.15) {
    // Min -5% ~ 5% Max: -100% ~ Infinity
    this.y.domain([
      (this.vMin * -1 + this.vMax) * scale * -1,
      (this.vMin * -1 + this.vMax) * scale * 1
    ])
    return this
  }

  // NOTE: q_fund_price 未更新可能造成錯誤
  trim() {
    const maxDate = this.maxDate
    this._rawdata.forEach(set => {
      const dates = set.data.map(d => d.date) // 只保留 <= 最大日期的資料
      set.data.splice(dates.indexOf(maxDate) + 1)
    })
    return this
  }

  balances() {
    const balancesData = this.dataset.filter(ds => ds.data[0].amount != null)

    this.yBalance = this.y.copy().range([this.height, 0])
    this.yBalance.domain([
      0,
      Math.max(...balancesData.map(ds => Math.max(...ds.data.map(d => d.amount)))) * 4
    ])

    return balancesData.map(ds =>
      Object.assign({}, ds, {
        color: this.getColor(ds),
        area: d3.area()
          .x(d => { return this.xScaled(d._data._date) })
          .y0(this.height)
          .y1(d => { return this.yBalance(Math.max(0, d.amount)) })(ds.data.filter(e => e._date >= this.startDate && e._date <= this.endDate).filter((e, i, a) => i % (a.length / this.sampleSize) < 1))
      })
    )
  }

  lines() {
    return this.dataset.map(set =>
      Object.assign({}, set, {
        color: this.getColor(set),
        line: d3.line()
          .x(d => this.xScaled(d._date))
          .y(d => this.yScaled(d.value))(set.data.filter(e => e._date >= this.startDate && e._date <= this.endDate).filter((e, i, a) => i % (a.length / this.sampleSize) < 1)) // 取樣點
      })
    )
  }

  getScaleTicks() {
    return this.getAllDates().map(d => this.x(ChartPerformanceData.time(d)))
  }

  getAllDates() {
    if (!this._rawdata._allDates) {
      this._rawdata._allDates = this._rawdata
        .map(d => d.data.map(d => d.date))
        .reduce((a, b) => a.concat(b), [])
        .filter((v, i, a) => a.indexOf(v) === i)
    }
    return this._rawdata._allDates
  }

  getAvailableDates() {
    if (!this._rawdata._availableDates) {
      this._rawdata._availableDates = this._rawdata
        .map(d => d.data.map(e => e.date))
        .reduce((a, b) => a.filter(n => b.indexOf(n) !== -1))
    }
    return this._rawdata._availableDates
  }

  getValueAt(dateOrScale) {
    if (typeof dateOrScale == 'number' && dateOrScale >= 0 && dateOrScale <= 1) {
      dateOrScale = this.x.invert(dateOrScale)
    }
    const near = new Date(dateOrScale).setHours(0, 0, 0, 0)
    return this.dataset.map(set => {
      const data = set.data.reduce((prev, curr) =>
        (Math.abs(curr._date - near) < Math.abs(prev._date - near) ? curr : prev)
      )
      return Object.assign(data, {
        id: set.id,
        label: set.label,
        color: this.getColor(set),
        diff: near - data._date,
      })
    })
  }

  getRawValueAt(date) {
    const near = new Date(date)
    return this._rawdata.map(set => {
      const data = set.data.reduce((prev, curr) =>
        (Math.abs(curr._date - near) < Math.abs(prev._date - near) ? curr : prev)
      )
      return Object.assign(data, {
        id: set.id,
        label: set.label,
        color: this.getColor(set),
      })
    })
  }

  setScale(width, height) {
    this.width = width
    this.height = height
    this.xScaled = this.x.copy().range([0, this.width])
    this.yScaled = this.y.copy().range([this.height, 0])
    this.sampleSize = Math.ceil(this.width / 2)
  }

  getColor(data) {
    return data.color || this._colors[data.id] || this.z(data.id)
  }

  setColors(colors) {
    this._colors = Object.assign(this._colors, colors)
  }

  setAnchor(offset) {
    this._cacheDataset = null
    this._left = offset

    if (this.options.performanceMode) {
      // 設定錨點數值當作 offset, 校準為零
      const offsetY = this.getRawValueAt(this.x.invert(this._left))
      this._lineOffsets = this._rawdata.map((set, i) => offsetY[i].rawValue)
    }
  }

  setData(data) {
    throw new Error('ChartPerformanceData.setData has deprecated')
  }

  get dataset() {
    return this._rawdata
  }

  // NOTE: 當掉很大機率都是這裡
  get maxDate() { // 共同最短的終點
    const dates = this._rawdata.filter(set => set.data.length > 0).map(set => set.data.slice(-1).pop().date)
    return dates.length
      ? dates.reduce((a, b) =>
        ChartPerformanceData.time(a) < ChartPerformanceData.time(b) ? a : b)
      : null
  }

  get max() {
    return Math.max(...this._rawdata.map(s => Math.max(...s.data.filter(d => d._date >= this.startDate).map(d => d.value))))
  }

  get min() {
    return Math.min(...this._rawdata.map(s => Math.min(...s.data.filter(d => d._date >= this.startDate).map(d => d.value))))
  }

  static get Colors() {
    return [
      '#FF895A',
      '#82CA82',
      '#76A3FC',
      '#F89FC1',
      '#91A5AE',
      '#F2D206',
      '#46B4B4',
      '#B3A6EB',
      '#E77B7B',
      '#CAA0A0',
      '#B5A981',
      '#6D8E5B',
      '#86604C',
      '#D0D0D0',
    ]
  }

  static time(t) {
    if (typeof t == 'string' || t instanceof Date) {
      return new Date(t).setHours(0, 0, 0, 0)
    }
    if (t instanceof Date) {
      return t.setHours(0, 0, 0, 0)
    }
    if (t && t.toDate) {
      return t.toDate().setHours(0, 0, 0, 0)
    }
    return t
  }

  static from(data) {
    return new ChartPerformanceData(data.map(d => ({
      id: d.id,
      label: d.fund.name,
      fund: d.fund,
      data: d.performance,
    })))
  }

  static fromFlows(data) {
    return new ChartPerformanceDataAbsolute(data.map(d => ({
      id: d.id,
      label: d.name,
      sector: {},
      data: d.sum
    })))
  }
}

// 非績效百分比的版本
class ChartPerformancePointDataAbsolute extends ChartPerformancePointData {
  get value() {
    return (this._value != undefined) ? this._value : this._data.value - this.offset
  }
}
class ChartPerformanceDataAbsolute extends ChartPerformanceData {
  createPointData(data) {
    return new ChartPerformancePointDataAbsolute(data)
  }
}

class ChartPerformance {
  constructor(el, dataset, options) {
    this.$el = $(el)
    this.dataset = dataset
    this.options = Object.assign({
      format: d3.format('.0%'),
      formatValue: d3.format('.02%'),
      hiddenIds: [],
    }, options)

    this.svg = d3.select(this.$el.find('svg')[0])
    this.margin = { top: 20, right: 0, bottom: 30, left: 50 }
    this.$$graph = this.svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')

    this.init()
    this.render()
  }

  setSizes() {
    this.$sliderRect = this.$slider[0].getBoundingClientRect()
    this.svgWidth = parseFloat(window.getComputedStyle(this.svg.node()).width) || this.svg.attr('width')
    this.svgHeight = parseFloat(window.getComputedStyle(this.svg.node()).height) || this.svg.attr('height')
    this.width = this.svgWidth - this.margin.left - this.margin.right
    this.height = this.svgHeight - this.margin.top - this.margin.bottom
    this.svg.attr('preserveAspectRatio', 'none')
    this.svg.attr('viewBox', `0 0 ${this.svgWidth}  ${this.svgHeight}`)
    this.dataset.setScale(this.width, this.height)
    return this
  }

  defer(timer, timeout, callback) {
    clearTimeout(this['delayer_' + timer])
    this['delayer_' + timer] = setTimeout(callback, timeout)
  }

  init() {
    this.cursorOffset = 0
    this.cursorValues = []
    this.cursorPoints = []
    this.anchorOffset = [0, 1]
    this.anchorValues = [[], []]

    const $slider = this.$el.find('.slider')
    const $range = this.$el.find('.slider .range')
    const $handleStart = this.$el.find('.slider .range').find('.handle--start')
    const $handleEnd = this.$el.find('.slider .range').find('.handle--end')
    let scaleTicks = this.dataset.getScaleTicks()
    const dragging = {
      start: false,
      end: false,
    }

    this.$slider = $slider
    this.$handleStart = $handleStart
    this.$handleEnd = $handleEnd

    this.resizeHandler = () => {
      this.svg.attr('opacity', '0.1')
      this.defer('resize-render', 300, () => {
        this.render()
      })
    }

    $(window).on('resize', this.resizeHandler)

    this.$el.on('mouseenter', () => {
      scaleTicks = this.dataset.getScaleTicks()
    })
    $handleStart.on('mousedown touchstart', () => {
      dragging.start = true
    })
    $handleEnd.on('mousedown touchstart', () => {
      dragging.end = true
    })

    this.$el.on('mouseup mouseleave touchend', e => {
      dragging.start = false
      dragging.end = false
    })

    this.$el.on('mousemove touchmove', e => {
      let closestOffset
      try {
        const position = this.$sliderRect
        const x = (e.pageX || e.originalEvent.touches[0].pageX) - position.left
        const offset = Math.min(1, Math.max(0, x / position.width))
        closestOffset = scaleTicks.reduce(function(prev, curr) {
          return (Math.abs(curr - offset) < Math.abs(prev - offset) ? curr : prev)
        })
      } catch (e) {
        return
      }

      if (dragging.start) {
        this.setAnchorStart(closestOffset)
        this.setCursor(closestOffset)
        this.cursorReversed() || this.drawLines()
      } else if (dragging.end) {
        this.setAnchorEnd(closestOffset)
        this.setCursor(closestOffset)
        this.cursorReversed() && this.drawLines()
      } else {
        this.setCursor(closestOffset)
      }

      this.drawBounds()

      $range.css('left', `${this.anchorOffset[0] * 100}%`)
      $range.css('width', `${(Math.abs(this.anchorOffset[0] - this.anchorOffset[1])) * 100}%`)
      $range.toggleClass('reverse', this.cursorReversed())
    })
  }

  setCursor(offset) {
    let limitedOffset = offset

    // 限制在 Range 當中
    if (this.cursorReversed()) {
      limitedOffset = Math.min(this.anchorOffset[0], Math.max(this.anchorOffset[1], offset))
    } else {
      limitedOffset = Math.min(this.anchorOffset[1], Math.max(this.anchorOffset[0], offset))
    }

    const values = this.dataset.getValueAt(limitedOffset)
    this.cursorPoints = values
      .map(d => Object.assign(d, {
        x: this.dataset.xScaled(d._date),
        y: this.dataset.yScaled(d.value),
      }))
    return this
  }

  setAnchor(offset) {
    return this.setAnchorStart(offset)
  }

  setAnchorStart(offset) {
    // 使 Vue 可以存取 chart.anchorOffset 來偵測變動
    this.anchorOffset = [offset, this.anchorOffset[1]]
    this.anchorValues = [this.dataset.getValueAt(offset), this.anchorValues[1]]
    if (!this.cursorReversed()) {
      this.dataset.setAnchor(offset)
    }
    this.dataset.domainScale()
    this.dataset.setScale(this.width, this.height)
    this.updateAnchorControl()
    return this
  }

  setAnchorEnd(offset) {
    // 使 Vue 可以存取 chart.anchorOffset 來偵測變動
    this.anchorOffset = [this.anchorOffset[0], offset]
    this.anchorValues = [this.anchorValues[0], this.dataset.getValueAt(offset)]
    if (this.cursorReversed()) {
      this.dataset.setAnchor(offset)
    }
    this.updateAnchorControl()
    return this
  }

  setData(dataset) {
    this.dataset = dataset
    this.dataset.setAnchor(this.anchorOffset[0])
    this.setSizes()
    this.setAnchorStart(this.anchorOffset[0])
    this.setAnchorEnd(this.anchorOffset[1])
    this.setCursor(this.cursorOffset)
    return this
  }

  getFinalValues() {
    return this.cursorReversed() ? this.anchorValues[0] : this.anchorValues[1]
  }

  getBounds() {
    return [this.anchorOffset[0], this.anchorOffset[1]].sort()
  }

  cursorReversed() {
    return this.anchorOffset[1] < this.anchorOffset[0]
  }

  render() {
    if (!this.svg) {
      return
    }

    this.setSizes()
    this.setAnchorStart(this.anchorOffset[0])
    this.setAnchorEnd(this.anchorOffset[1])
    this.setCursor(1)

    this.$$graph.selectAll('*').remove()

    this.$$graph.append('g')
      .attr('class', 'axis-x')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(d3.axisBottom(this.dataset.xScaled)
        .ticks(Math.min(10, moment(this.dataset.xScaled.domain()[1]).diff(this.dataset.xScaled.domain()[0], 'days') + 1))
        .tickFormat((date, n) => {
        // date = d3.timeDay(date);
        // https://stackoverflow.com/a/40175517/2252696
          if (d3.timeMonth(date) < date) {
            return d3.timeFormat('%m/%d')(date)
          } else if (d3.timeYear(date) < date) {
            return d3.timeFormat('%b')(date)
          } else {
            return d3.timeFormat('%Y')(date)
          }
        })
      )

    this.$$context = this.$$graph.append('g').attr('class', 'context')

    this.drawContext()
    this.svg.attr('opacity', '1')
  }

  drawLines() {
    const generateLinePath = (data) => {
      const sampledData = data.filter((e, i, a) => i % (a.length / this.dataset.sampleSize) < 1) // 取樣
      return d3
        .line() // 照縮放成畫布大小的比例尺來渲染
        .x((d) => this.dataset.xScaled(d._date))
        .y((d) => this.dataset.yScaled(d.value))(sampledData)
    }

    this.$$context.selectAll('*').remove()

    // Horizontal Grid lines
    this.$$context.append('g')
      .attr('class', 'grid-horizontal')
      .call(d3.axisLeft(this.dataset.yScaled).ticks(5).tickFormat('').tickSize(-this.width))

    // Zero
    this.$$context.append('g')
      .attr('class', 'grid-horizontal-zero')
      .call(d3.axisLeft(this.dataset.yScaled).ticks(1).tickFormat('').tickSize(-this.width))

    const $$lines = this.$$context.append('g').attr('class', 'lines')
    this.dataset.lines().forEach(data => {
      const { data: points } = data
      const normal = points.filter(p => p._data.value != null)
      const dashed = points.filter((p, index, arr) => p._data.value === null || arr[index - 1]?._data?.value === null)
      if (this.options.hiddenIds.find(id => data.id.match(new RegExp(`-?${id}$`)))) {
        return
      }
      $$lines
        .append('path')
        .attr('class', 'line')
        .attr('name', data.label)
        .attr('id', data.id)
        .attr('d', generateLinePath(normal))
        .style('stroke', data.color)
      // 基金發行前
      if (dashed) {
        $$lines
          .append('path')
          .attr('class', 'line')
          .attr('name', data.label)
          .attr('id', data.id)
          .attr('d', generateLinePath(dashed))
          .style('stroke', '#aaa')
          .style('stroke-dasharray', ('2, 2'))
      }
    })

    // Draw balance
    const $$balances = this.$$context.append('g').attr('class', 'balances')
    this.dataset.balances().forEach(data => {
      $$balances.append('path')
        .attr('class', 'balance area')
        .attr('name', data.label)
        .attr('id', data.id)
        .attr('d', data.area)
        .attr('fill', ChartPerformance.hexToRGB(data.color, 0.25))

      // 起始線 (至少要有兩點)
      if (data.data.length >= 2 && data.data[0].amount == 0 && data.data[1].value == 0) {
        let first
        const firstIndex = data.data.indexOf(data.data.find(d => d.amount != 0))

        if (firstIndex > 1) {
          // 往前一天，BUG: 第一天沒有餘額
          first = data.data[firstIndex - 1]
        } else {
          return
        }

        first && this.$$context.append('line')
          .attr('class', 'align-line')
          .attr('x1', this.dataset.xScaled(first._data._date))
          .attr('y1', 0)
          .attr('x2', this.dataset.xScaled(first._data._date))
          .attr('y2', this.height)
          .style('stroke-width', 1)
          .style('stroke-dasharray', [2, 2])
          .style('stroke', data.color)
          .style('fill', 'none')
      }
    })
  }

  turncateString(str, width = 400, font = '10px arial') {
    function getTextWidth(text) {
      // re-use canvas object for better performance
      const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
      const context = canvas.getContext('2d')
      context.font = font
      const metrics = context.measureText(text)
      return metrics.width
    }

    let newStr = str.split('')
      .reduce((str, char) => {
        if (getTextWidth(str + char) < width) {
          return str + char + ''
        } else {
          return str
        }
      }, '')

    if (newStr.length < str.length) {
      newStr += '...'
    }
    return newStr
  }

  updateAnchorControl() {
    const $range = this.$el.find('.slider .range')
    const $vFrom = this.$el.find('.slider .range').find('.date.from')
    const $vTo = this.$el.find('.slider .range').find('.date.to')

    // 更精確地抓到最尾端的日期
    const minDate = this.anchorValues[0].slice().sort(({ x: offsetA }, { x: offsetB }) => offsetA - offsetB).map(({ _date }) => _date)[0]
    const maxDate = this.anchorValues[1].slice().sort(({ x: offsetA }, { x: offsetB }) => offsetB - offsetA).map(({ _date }) => _date)[0]

    this.anchorValues[0][0] && $vFrom.text(d3.timeFormat('%Y-%m-%d')(minDate))
    this.anchorValues[1][0] && $vTo.text(d3.timeFormat('%Y-%m-%d')(maxDate))

    if (this.dataset._left > 0.5) {
      $vFrom.css('margin-left', Math.min(0, $range.width() - 20 - $vFrom.width() - $vTo.width()))
    } else {
      $vTo.css('margin-right', Math.min(0, $range.width() - 20 - $vFrom.width() - $vTo.width()))
    }

    $range.css({
      left: `${this.anchorOffset[0] * 100}%`,
      width: `${(this.anchorOffset[1] - this.anchorOffset[0]) * 100}%`,
    })
  }

  drawBounds() {
    this.updateAnchorControl()

    // Fade
    const bounds = this.getBounds()

    this.$$context.selectAll('rect.fade').remove()
    this.$$context.append('rect')
      .attr('class', 'fade')
      .style('fill', 'rgba(255,255,255,.9)')
      .attr('width', bounds[0] * this.width)
      .attr('height', this.height)

    this.$$context.append('rect')
      .attr('class', 'fade')
      .style('fill', 'rgba(255,255,255,.9)')
      .attr('width', (1 - bounds[1]) * this.width)
      .attr('height', this.height)
      .attr('x', this.width * (bounds[1]))

    this.$$graph.selectAll('.axis-y').remove()
    this.$$graph.append('g')
      .attr('class', 'axis-y')
      .call(d3.axisLeft(this.dataset.yScaled).tickFormat(this.options.format))
  }

  drawContext() {
    this.drawLines()
    this.drawBounds()
  }

  destroy() {
    this.$$graph.selectAll('*').remove()
    $(window).off('resize', this.resizeHandler)
  }

  static formatCurrency(value, currency) {
    const precision = ({
      twd: 0,
      usd: 2
    }[currency] || 0)
    if (value === null) { return '-' }
    return (+value).toFixed(precision).toString().replace(/\B(?=(\d{3})+\b)/g, ',')
  }

  static hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')'
    }
  }

  static rgbToHex(rgb) {
    return '#' + rgb.substr(4, rgb.indexOf(')') - 4).split(',').map((color) => String('0' + parseInt(color).toString(16)).slice(-2)).join('')
  }

  static shadeColor(color, percent) {
    color = color.match(/rgb/) ? this.rgbToHex(color) : color
    const f = parseInt(color.slice(1), 16); const t = percent < 0 ? 0 : 255; const p = percent < 0 ? percent * -1 : percent; const R = f >> 16; const G = f >> 8 & 0x00FF; const B = f & 0x0000FF
    return '#' + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1)
  }
}

export {
  ChartPerformance,
  ChartPerformanceData,
  ChartPerformanceDataAbsolute
}
