
export default {
  install(Vue, options) {
    const reactive = Vue.observable
    class VmLoadingState {
      constructor(key = 'defaultLoader', state = false) {
        Object.assign(this, {
          key,
          state: state,
        })
        return reactive(this)
      }

      finish() {
        this.state = true
        return this
      }

      done() {
        return this.finish()
      }
    }

    Vue.mixin({
      data: () => ({
        instanceLoadingStates: [],
      }),
      computed: {
        loadingStateReady() {
          return Object.values(this.instanceLoadingStates).every(({ state }) => state === true)
        }
      },
      watch: {
        loadingStateReady(state) {
          state && this.$emit('loading-state-ready')
        }
      },
      methods: {
        registerLoadingState() {
          const stateObj = new VmLoadingState('loader', false)
          this.instanceLoadingStates.push(reactive(stateObj))
          return stateObj
        },
        createLoadingState() {
          return this.registerLoadingState()
        },
        async whenLoadingStateReady(callback = new Function()) {
          return new Promise(resolve => {
            if (this.loadingStateReady) {
              resolve()
              callback()
            } else {
              this.$once('loading-state-ready', () => {
                callback()
                resolve()
              })
            }
          })
        }
      }
    })
  }
}
