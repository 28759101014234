import { render, staticRenderFns } from "./StrategyList.vue?vue&type=template&id=08f12a36&scoped=true&"
import script from "./StrategyList.vue?vue&type=script&lang=js&"
export * from "./StrategyList.vue?vue&type=script&lang=js&"
import style0 from "./StrategyList.vue?vue&type=style&index=0&id=08f12a36&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08f12a36",
  null
  
)

export default component.exports