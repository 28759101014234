import Vue from 'lib/vue/essential'
import Vuex from 'vuex'

// Plugins
import createPersistedState from 'vuex-persistedstate'
import { createMemberAuthPlugin } from 'api'
import createCrossTabPlugin from 'lib/vuex-crosstab'
import Cookies from 'js-cookie'

// 子模組
import member from './member.js'
import fund from './fund.js'

function createRestoreKeepAuthPlugin() {
  // 還原記住的帳號
  return store => {
    if (store.state.member.keepAuth) {
      store.state.member.auth = JSON.parse(JSON.stringify(store.state.member.keepAuth))
    }
  }
}

// 使用 Cookie 判斷：若瀏覽器重新開啟，則為新的 session，清掉 crosstab 的 localStorage 資料
(async() => {
  if (String(Cookies.get('member-auth-session')) !== String(window.localStorage.getItem('member-auth-session'))) {
    const session = new Date().getTime()
    window.localStorage.removeItem('member-auth-sync')
    window.localStorage.removeItem('member-auth-sync-state')
    window.localStorage.setItem('member-auth-session', session)
    Cookies.set('member-auth-session', session)
  }
})()

const store = new Vuex.Store({
  plugins: [
    createCrossTabPlugin({
      key: 'member-auth-sync',
      recover: true, // 開啟新的頁面時還原目前狀態
      // 將這些異動同步到其他 tab:
      filter: (type) => type.match(/^member\/(signin|setProfile|signout)/)
    }),
    // 保存永久登入的資訊
    createPersistedState({
      storage: window.localStorage,
      paths: [
        'member.keepAuth',
      ]
    }),
    // 還原久登入的資訊
    createRestoreKeepAuthPlugin(),
    createMemberAuthPlugin(),
  ],
  modules: {
    member,
    fund,
  }
})

// 使全域都可以存取到已登入會員
Vue.mixin({
  computed: {
    $member() {
      return store.state.member.profile
    }
  }
})

const currentMember = store.state.member.profile

export { currentMember }
export default store
