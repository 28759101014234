<template>
  <div>
    <!-- 標題部分 -->
    <p v-if="value">
      {{ value }}
    </p>
    <!-- 按鈕部分 -->
    <div class="rule-buttons-group tighter _float-right">
      <router-link v-if="toEdit" :to="toEdit">
        <span class="ui button small major">編輯</span>
      </router-link>
      <a v-if="toPreview" :href="toPreview" class="ui button teal small" target="_blank">預覽</a>
      <span v-if="toDelete" class="ui button danger small" @click="$emit('remove', model)">刪除</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    linkEdit: [Function, Object, String],
    linkPreview: [Function, Object, String],
    deletable: [Function, Boolean],
  },
  computed: {
    toEdit() {
      return (typeof this.linkEdit === 'function') ? this.linkEdit(this.model) : this.linkEdit
    },
    toPreview() {
      return (typeof this.linkPreview === 'function') ? this.linkPreview(this.model) : this.linkPreview
    },
    toDelete() {
      return (typeof this.deletable === 'function') ? this.deletable(this.model) : this.deletable
    },
  }
}
</script>
