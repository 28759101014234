import getScrollParent from 'lib/utils/getScrollParent.js'
import './core.less'

const $ = jQuery

const Tab = {
  template: '<div><slot></slot></div>',
}
const CachedTab = Tab

export default {
  components: {
    Tab,
    CachedTab,
  },
  props: {
    tabs: {
      type: Array,
    },
    steps: {
      type: Array,
    },
    only: Array,
    navigation: {
      type: Boolean,
      default: true,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
    },
    tab: {
      type: [String, Number],
    },
    cache: {
      type: Boolean,
      default: true,
    },
    // 不快取的 tabs，逗號分隔
    omit: {
      type: String,
      default: () => '報酬機率',
    },
    // 攤平 tabs
    flatten: Boolean,
  },
  model: {
    prop: 'tabs',
  },
  data() {
    return {
      step: this.value || 1,
      direction: 1,
      is: {
        animating: false,
      }
    }
  },
  mounted() {
    if (this.$options.model.prop === 'tabs' && this.steps) {
      console.warn('[Deprecation] Use <UiTabs :tab="..."/> instead of :steps for better semantic meaning.', this)
    }
    $(this.$el).on('click', '[data-go-tab]', e => {
      this.go($(e.target).data('go-tab'))
      e.stopPropagation()
    })
    // prettier-ignore
    $(this.$el).on('click', '[data-skip]', e => this.go(this.propTabs.length) && $(this.$el).find('[data-next]').trigger('click') ? e.stopPropagation() : true)
    $(this.$el).on('click', '[data-next]', e =>
      this.next(+$(e.target).attr('data-next')) ? e.stopPropagation() : true
    )
    $(this.$el).on('click', '[data-prev]', e =>
      this.prev(+$(e.target).attr('data-prev')) ? e.stopPropagation() : true
    )
  },
  watch: {
    only() {
      this.reset()
    },
    propTab: {
      immediate: true,
      handler() {
        this.go(this.propTab)
      },
    },
    step() {
      this.$emit('update:step', this.step)
      this.$emit('update:value', this.step)
    },
    currentTab: {
      immediate: true,
      handler() {
        this.$emit('update:tab', this.currentTab)
      },
    },
  },
  computed: {
    propTabs() {
      return this.tabs || this.steps || []
    },
    propTab() {
      return this.tab || this.value || 1
    },
    currentTab() {
      const tab = this.step || this.value || this.tab
      return typeof tab == 'string' ? tab : this.propTabs[tab - 1]
    },
    currentIndex() {
      return this.step - 1
    },
    onlyTabs() {
      return (
        this.only &&
        this.only
          .sort()
          .filter((v, i, a) => a.indexOf(v) === i)
          .map(only => {
            // 轉成索引數值
            const idx = this.propTabs.indexOf(only)
            return idx === -1 ? only : idx
          })
      )
    },
    $_navTabs() {
      return this.propTabs.filter((tab, i) => !(this.onlyTabs && this.onlyTabs.length) || this.onlyTabs.indexOf(i) >= 0)
    },
    $_firstPage() {
      if (this.onlyTabs && this.onlyTabs.length) {
        return this.onlyTabs[0] + 1
      } else {
        return 1
      }
    },
    $_slotAttrs() {
      return {
        currentTab: this.currentTab,
        step: this.step,
        isAnimating: this.is.animating,
      }
    },
  },
  methods: {
    go(step) {
      if (typeof step == 'string') {
        step = this.propTabs.indexOf(step) + 1
      }
      if (step > 0) {
        this.direction = step > this.step ? 1 : -1
        this.step = step
      }
    },
    reset() {
      this.step = this.$_firstPage
    },
    findNext(skip) {
      // prettier-ignore
      const next = (this.onlyTabs && this.onlyTabs.length) ? this.onlyTabs.map(i => i + 1).filter(i => i >= this.step + (skip || 1))[0] : this.step + (skip || 1)
      return next <= this.propTabs.length ? next : false
    },
    findPrev(skip) {
      // prettier-ignore
      const prev = (this.onlyTabs && this.onlyTabs.length) ? this.onlyTabs.map(i => i + 1).filter(i => i <= this.step - (skip || 1)).pop() : this.step - (skip || 1)
      return prev >= 1 ? prev : false
    },
    next(skip) {
      if (this.findNext(skip)) {
        this.direction = 1
        return (this.step = this.findNext(skip))
      } else {
        return false
      }
    },
    prev(skip) {
      if (this.findPrev(skip)) {
        this.direction = -1
        return (this.step = this.findPrev(skip))
      } else {
        return false
      }
    },
    focus() {
      if (this.autofocus) {
        const scrollParent = getScrollParent(this.$el)
        $(scrollParent).stop().animate({
          scrollTop: $(this.$el).offset().top
        }, 300)
      }
    },
    $_bindTransitionEvents({ index, tabName }) {
      return {
        'before-enter': () => this.$_tab_beforeEnter({ index, tabName }),
        'before-leave': () => this.$_tab_beforeLeave({ index, tabName }),
        'after-enter': () => this.$_tab_afterEnter({ index, tabName }),
        'after-leave': () => this.$_tab_afterLeave({ index, tabName }),
      }
    },
    $_tab_beforeEnter({ index, tabName }) {
      this.is.animating = true
    },
    $_tab_afterEnter({ index, tabName }) {
      this.is.animating = false
      this.$emit('tabChange', { index, tabName })
      this.$_helper_enableFloatThead()
    },
    $_tab_beforeLeave({ index, tabName }) {
      this.$_helper_disableFloatThead()
    },
    $_tab_afterLeave({ index, tabName }) {

    },
    $_helper_enableFloatThead() {
      (function traverse(children) {
        children.forEach(c => {
          if (typeof c.enableFloatThead == 'function') {
            c.enableFloatThead()
          }
          traverse(c.$children)
        })
      })(this.$children)
    },
    $_helper_disableFloatThead() {
      (function traverse(children) {
        children.forEach(c => {
          if (typeof c.disableFloatThead == 'function') {
            c.disableFloatThead()
          }
          traverse(c.$children)
        })
      })(this.$children)
    },
  },
}
