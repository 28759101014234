<!--
  模組已停用
  issue: https://app.asana.com/0/1199580963712713/1200433467285900/f
-->
<template>
  <div class="basic__container">
    <h2 class="basic__topic">
      市場觀點
    </h2>
    <UiLoader v-if="current.articles.length > 0" :loading="!loadingStateReady">
      <div class="tool-bar">
        <div class="result">
          {{ totalArticles }} 個符合的文章
        </div>
        <div class="dropdown-wrap">
          <DropdownFilter v-model="current.category" :entries="filterOptions" topicPrefix="文章分類：" />
        </div>
      </div>
      <div class="list">
        <div v-for="article in current.articles" :key="article.id" class="list-item">
          <InsightCard v-bind="{article}"
                       @resetCategory="resetCategory"
          />
        </div>
      </div>
      <div v-if="current.articles.length < totalArticles" class="btn-wrap">
        <div class="ui button major large" @click="loadMore">
          <i class="icon-more" style="margin-right: .75rem;" />查看更多內容
        </div>
      </div>
    </UiLoader>
  </div>
</template>

<script>
import API from 'api'
import MixinArticlesList from 'pages/articles/MixinArticlesList'
import InsightCard from 'modules/components/SingleInsightsCard.vue'

export default {
  components: {
    InsightCard,
  },
  mixins: [
    MixinArticlesList,
  ],
  data() {
    return {
      kind: 'insight',
    }
  },
  async mounted() {
    const categoryId = this.$route.params.category_id
    await API.articles.categories({ kind: this.kind }).then(res => {
      this.categories = res.data.entries
      if (categoryId) {
        this.currentCategoryId = categoryId
      } else {
        this.current.category = 'all'
      }
    })
  },
  methods: {
    async loadList() {
      // 全部文章
      return await API.articles.list({ kind: this.kind, page: this.accumulatedPage }).then(res => res.data.entries)
    },
    async loadCategoryList(categoryId) {
      // 目錄下的文章
      return await API.articles.categories(
        {
          kind: this.kind,
          id: categoryId || this.currentCategoryId,
          page: this.accumulatedPage
        }
      ).then(res => res.data.entries)
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'

.basic__container
  position relative
  padding-bottom 14rem
  +under(md)
    padding-bottom 17rem
.tool-bar
  margin 2rem 0
  +over(md)
    display flex
    align-items center
    justify-content space-between
  .result
    font-size 1.2rem
    font-weight bold
    +under(md)
      margin-bottom 1rem
.list
  +over(md)
    display flex
    flex-wrap wrap
  .list-item
    +over(md)
      width calc((100% - 4rem)/ 3)
      margin-bottom 5rem
      margin-right 2rem
      &:nth-child(3n)
        margin-right 0
    +under(md)
      width 100%
      margin-bottom 2rem
.btn-wrap
  text-align center
  +under(md)
    margin-top 2.5rem
</style>
