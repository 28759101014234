<template>
  <div ref="dropdown" class="ui dropdown">
    <slot />
  </div>
</template>

<script>
import MixinDropdownGhost from './dropdown.ghost.js'

const $ = jQuery

export default {
  mixins: [
    MixinDropdownGhost,
  ],
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    value: [String, Number, Boolean],
  },
  data: () => ({
    instance: null,
  }),
  watch: {
    value(value) {
      this.$nextTick(() => {
        if (this.instance.dropdown('get item', String(value))) {
          this.instance.dropdown('set selected', String(value))
        } else {
          this.instance.dropdown('clear')
          this.instance.dropdown('set value', String(value))
        }
        this.instance.dropdown('save defaults')
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.instance = $(this.$refs.dropdown).dropdown(
        Object.assign(
          {
            value: this.value,
            onChange: (value, text, $choice) => {
              if ($choice && String(this.value) !== String(value)) {
                this.$emit('input', value)
                this.$emit('update:value', value)
                this.$emit('update:text', text)
                this.options.onChange && this.options.onChange(value, text, $choice)
              }
            },
            onShow: () => {
              this.$emit('show')
              this.onShow()
              this.options.onShow && this.options.onShow()
            },
            onHide: () => {
              this.$emit('hide')
              this.onHide()
              this.options.onHide && this.options.onHide()
            }
          },
          {
            forceSelection: false,
            fullTextSearch: true,
            message: {
              noResults: '沒有可選的選項',
            },
            ...this.options,
          }
        ) || {}
      )
      this.instance.dropdown('set selected', String(this.value))
      this.instance.dropdown('save defaults')
    })
  },
  beforeDestroy() {
    this.instance && this.instance.dropdown('destroy')
  },
  methods: {
    onShow() {
      $(this.$el).closest('.vue-recycle-scroller__item-view').css('zIndex', 1)
    },
    onHide() {
      $(this.$el).closest('.vue-recycle-scroller__item-view').css('zIndex', '')
    },
  },
}
</script>
