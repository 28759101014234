<!--

  會員註冊條款

  Usage:

  <MemberTerm v-if="showTerm" @agree="next" @disagree="hideTerm"></MemberTerm>

-->

<template>
  <div v-if="current.show" class="fs-info">
    <div v-if="current.loading" class="ui active inverted dimmer">
      <div class="ui indeterminate text loader">
        載入中
      </div>
    </div>
    <iframe ref="iframe" :src="fileUrl" frameborder="0" @load="termsOnload" />
    <div class="control align-right">
      <div class="ui button minor" @click="disagree">
        取消並返回
      </div>
      <div :class="{loading: current.loading, disabled: current.loading}" class="ui button major" @click="agree">
        我同意並繼續註冊
      </div>
    </div>
  </div>
</template>

<script>
const $ = jQuery

export default {
  data() {
    return {
      current: {
        loading: true,
        show: false,
      }
    }
  },
  computed: {
    fileUrl() {
      return `https://docs.google.com/gview?embedded=true&url=https://tarobo.s3.amazonaws.com/terms/member.pdf&t=${new Date().getTime()}`
    }
  },
  watch: {
    'current.show'(v) {
      this.current.loading = true
      $('html,body').css('overflow', v ? 'hidden' : '')
      v ? this.iframeReloaderStart() : this.iframeReloaderStop()
    },
  },
  mounted() {
    this.current.show = true
  },
  beforeDestroy() {
    $('html,body').css('overflow', '')
    this.current.show = false
  },
  methods: {
    termsOnload() {
      this.current.loading = false
    },
    iframeReloaderStart() {
      this._reloader = setInterval(() => {
        if (this.current.loading) {
          try {
            const iframeDoc = this.$refs.iframe.contentDocument
            if (iframeDoc.readyState === 'complete' && iframeDoc.location.href === 'about:blank') {
              this.$refs.iframe.src = String(this.$refs.iframe.src)
            }
          } catch (e) {}
        }
      }, 3000)
    },
    iframeReloaderStop() {
      clearInterval(this._reloader)
    },
    agree() {
      this.$emit('agree')
    },
    disagree() {
      this.$emit('disagree')
    },
  }
}
</script>

<style lang="stylus" scoped>
iframe
  background-color #D1D1D1

.fs-info
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  z-index 200
  display flex
  background white
  flex-direction column
  iframe
    flex-grow 1

.fs-info object
.fs-info iframe
.fs-info embed
  width 100%
  height 100%
  flex-grow 0
  flex-shrink 1

.fs-info .control
  flex-shrink 0
  padding 1em
  background white
  border-top 1px solid #CCC
  box-shadow 0 -1px 1px 0 rgba(0, 0, 0, .2)
  display flex
  justify-content space-between
  align-items center
</style>
