<!--
  基金比較 - 策略組合的手機表格
 -->
<template>
  <div ref="list" class="list">
    <div class="list-head" :class="{'status-fixed': fixedHead}">
      <div class="fixed-area">
        <Scroll ref="head" @ps-scroll-x.native="updateScroll">
          <div class="row head-row">
            <div v-for="topicName in titles" :key="topicName" class="grid" :class="gridClass">
              {{ topicName }}
            </div>
          </div>
        </Scroll>
      </div>
    </div>
    <div class="list-body">
      <template v-for="(fundId, index) in fundIds">
        <div :key="`body-row-group-${index}`" class="body-row-group">
          <div :key="fundId" class="row body-row">
            <div class="row-name">
              <div class="sector-content">
                <span class="color-line" :style="{backgroundColor: fundColors[fundId]}" />
                {{ raw.features[fundId] && raw.features[fundId].name }}
              </div>
              <div class="actions">
                <div class="icon-button third" @click="$emit('remove', fundId)">
                  <i class="icon-delete" aria-hidden="true" />
                </div>
              </div>
            </div>
            <Scroll ref="info" @ps-scroll-x.native="updateScroll">
              <div class="other-info">
                <div v-for="topicName in titles" :key="topicName" 
                  class="grid" :class="gridClass">
                  <!-- 三種策略 -->
                  <template v-if="dataType === 'strategies'">
                    <div>{{ dataProvider.getFieldValue({fundId, topicName}) }}%</div>
                    <div class="price-ccy">
                      <i v-if="dataProvider.getPercentChanges({ fundId, topicName }) > 0" class="icon-up-dir color-positive" />
                      <i v-else-if="dataProvider.getPercentChanges({ fundId, topicName }) < 0" class="icon-down-dir color-negative" />
                      <span :class="dataProvider.getPercentChanges({ fundId, topicName }) > 0 ? 'color-positive' : 'color-negative'">
                        {{ Math.abs(dataProvider.getPercentChanges({ fundId, topicName })) | percentage(1) }}
                      </span>
                    </div>
                  </template>
                  <!-- 策略特性 -->
                  <template v-else-if="dataType === 'features'">
                    {{ dataProvider.getFeatureValue({fundId, topicName}) }}
                  </template>
                </div>
              </div>
            </Scroll>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import MixinScrollList from 'modules/components/fund/MixinScrollList'

export default {
  mixins: [
    MixinScrollList,
  ],
  props: {
    titles: Array,
    fundIds: Array,
    fundColors: Object,
    percentChanges: Object,
    raw: Object,
    dataProvider: Object,
    dataType: String,
  },
  computed:{
    gridClass() {
      const map = {
        strategies: 'strategy',
        features: 'feature'
      }
      return map[this.dataType]
    }
  },
}
</script>

<style lang="stylus" scoped>
@import '~modules/components/fund/scrollList.styl'
.list
  margin-bottom 0
  .grid
    width 160px
    &.strategy
      width 120px
+under(md)
  .body-row
    .row-name, .sector-content
      display flex
      align-items center
      flex-grow 1
    .actions
      margin-left auto

.price-ccy
  &:before
    content "("
  &:after
    content ")"
// 正負
.color-positive
  color #ED4758
.color-negative
  color #00BF9C
</style>
