<!--
  * 選擇種類
  FlashCardArticle 關鍵圖卡
  SaleskitChartsContent 銷售觀點-重點圖卡
-->
<template>
  <div class="card-group">
    <router-link class="photo-cont" :to="routeLink">
      <div class="photo" :style="{'background-image': `url(${card['cover_url']})`}" />
    </router-link>
    <div class="sub-title">
      <div v-if="card.category" class="ui button round small minor" @click="$emit('clickTag')">
        {{ card.category.name }}
      </div>
      <div class="date font-bold">
        {{ card.publish_at | date }}
      </div>
    </div>
    <router-link class="main" :to="routeLink">
      <div class="title">
        {{ card.title }}
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    card: Object,
    linkRoute: {
      type: String,
      default: 'FlashCardArticle',
    },
  },
  computed: {
    routeLink() {
      let params
      if (this.linkRoute === 'FlashCardArticle') params = { id: this.card.slug }
      if (this.linkRoute === 'SaleskitChartsContent') params = { category_id: this.card.category.id, id: this.card.slug }
      return { name: this.linkRoute, params }
    },
  },
}
</script>

<style lang="stylus" scoped>
.card-group
  width 100%
  .photo-cont
    display block
    width 100%
    border-radius 21px
    box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
    overflow hidden
    cursor pointer
    &:hover .photo
      transform scale(1.1)
  .photo
    padding-bottom 82.3%
    background-repeat no-repeat
    background-position center center
    background-size cover
    transition .5s
  .sub-title
    display flex
    justify-content space-between
    margin 1rem 0 .75rem
    color $gray2
  .main
    display block
    color #505050
    &:hover
      text-decoration underline
    .title
      font-size 1.2rem
      font-weight bold
      display -webkit-box
      -webkit-line-clamp 2
      -webkit-box-orient vertical
      text-overflow ellipsis
      overflow hidden

</style>
