<template>
  <div class="element fund-name">
    <span class="color-line" :style="{backgroundColor: color}" />
    <router-link 
      :to="{name: 'FundShow', params: {id: fundId}}" 
      class="element-link"
      target="_blank"
    >
      {{ fundName }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    color: String,
    fundId: [String, Number],
    fundName: String,
  },
}
</script>

<style lang="stylus" scoped>
.element
  display flex
  align-items center
.element-link
  &:hover
    font-weight bold
.color-line
  width 4px
  flex-shrink 0
  height 28px
  border-radius 5px
  display inline-block
  background rgb(149, 235, 232)
  margin-right 1rem
</style>