<!--

  可以選擇各種時間範圍的輸入框

  定義：
  https://fomantic-ui.com/modules/calendar.html

  使用範例：
  <Field required :error="fieldErrorOf('date')">
    <label>月份</label>
    <Calendar v-model="form.date" type="month" />
  </Field>

-->
<template>
  <div id="date_calendar" class="ui calendar">
    <div class="ui input right icon">
      <i class="icon icon-calendar" />
      <input type="text" v-bind="{placeholder, ...$attrs}" readonly>
    </div>
  </div>
</template>

<script>
const $ = jQuery
export default {
  model: {
    prop: 'date'
  },
  props: {
    type: {
      type: String,
      default: 'date'
    },
    startCalendar: {
      type: Object,
      default: null
    },
    endCalendar: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    date: {
      type: [String, Date, moment],
      default: null,
    },
    minDate: {
      type: [String, Date, moment],
      default: null,
    },
    maxDate: {
      type: [String, Date, moment],
      default: null,
    }
  },
  computed: {
    options() {
      return {
        on: 'click',
        type: this.type,
        startCalendar: this.startCalendar && $(this.startCalendar),
        endCalendar: this.endCalendar && $(this.endCalendar),
        monthFirst: false,
        text: {
          days: '日一二三四五六'.split(''),
          months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
          monthsShort: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
          today: '今天',
          now: '現在',
          am: '上午',
          pm: '下午'
        },
        formatInput: false,
        formatter: {
          date: (date, settings) => {
            if (settings.type === 'month') {
              return moment(date).endOf('month').format('YYYY-MM')
            } else if (settings.type === 'year') {
              return moment(date).endOf('year').format('YYYY')
            } else if (settings.type === 'date') {
              return moment(date).format('YYYY-MM-DD')
            } else {
              return moment(date).format('YYYY-MM-DD HH:mm')
            }
          },
          datetime: (date, settings) => {
            if (settings.type === 'month') {
              return moment(date).endOf('month').format('YYYY-MM')
            } else if (settings.type === 'year') {
              return moment(date).endOf('year').format('YYYY')
            } else if (settings.type === 'date') {
              return moment(date).format('YYYY-MM-DD')
            } else {
              return moment(date).format('YYYY-MM-DD HH:mm')
            }
          },
        },
        onChange: (date, formated) => {
          if (this.type === 'month') {
            this.$emit('input', formated)
          } else {
            // Respect the input format
            if (this.date instanceof Date) {
              this.$emit('input', moment(date).toDate())
            } else if (this.date instanceof moment) {
              this.$emit('input', moment(date))
            } else {
              this.$emit('input', moment(date).format(moment.defaultFormat))
            }
          }
        }
      }
    }
  },
  watch: {
    options: {
      immediate: true,
      handler() {
        this.$nextTick(this.init)
      }
    },
    date: {
      immediate: true,
      handler(date) {
        if (date) {
          this.$nextTick(() => {
            $(this.$el).calendar('set date', new Date(date), true, false)
            $(this.$el).calendar('refresh')
          })
        }
      }
    },
    minDate() {
      $(this.$el).calendar('set minDate', moment(this.minDate).toDate())
    },
    maxDate() {
      $(this.$el).calendar('set maxDate', moment(this.maxDate).toDate())
    },
  },
  deactivated() {
    $(this.$el).calendar('destroy')
  },
  activated() {
    this.$nextTick(this.init)
  },
  methods: {
    init() {
      $(this.$el).calendar({
        ...this.options,
        minDate: this.minDate && moment(this.minDate).toDate(),
        maxDate: this.maxDate && moment(this.maxDate).toDate(),
      })
      if (this.date) {
        $(this.$el).calendar('set date', new Date(this.date), true, false)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.ui.popup.calendar
  table
    table-layout fixed
</style>
