<template>
  <span class="v-pikaday">
    <slot>
      <div class="ui icon input" style="min-width: 5em;">
        <input type="text" autocomplete="off" :value="value" readonly v-bind="$attrs">
        <i class="icon icon-calendar" />
      </div>
    </slot>
  </span>
</template>

<script>

Pikaday.prototype.config({
  i18n: {
    previousMonth: '上個月',
    nextMonth: '下個月',
    months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    monthsShort: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    weekdays: ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
    weekdaysShort: ['日', '一', '二', '三', '四', '五', '六']
  }
})

const $ = jQuery

export default {
  props: {
    value: {
      type: [Object, Date, String, Number],
      default: () => null,
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      instance: null,
    }
  },
  computed: {
    pikadayOptions() {
      return Object.assign({
        // field: this.$refs.input || $(this.$el).find('input, .input').get(0),
        bound: true,
        yearRange: 30,
        defaultDate: moment(this.value, ['YYYY/MM/DD', 'YYYY-MM-DD']).toDate(),
        setDefaultDate: moment(this.value, ['YYYY/MM/DD', 'YYYY-MM-DD']).toDate(),
        field: $(this.$el).find('input, .input').get(0) || this.$el,
        format: 'YYYY-MM-DD',
        onSelect: () => {
          if (this.instance.toString() !== this.value) {
            this.$emit('input', this.instance.toString())
          }
        },
        onClose: () => {
          this.$emit('close')
        }
      }, this.options || {})
    }
  },
  watch: {
    value(v) {
      // can optionally pass a boolean as the second parameter to prevent triggering of the onSelect callback (true), allowing the date to be set silently.
      this.instance.setDate(v, true)
    },
    options: {
      deep: true,
      handler() {
        this.instance.config(this.pikadayOptions)
      }
    }
  },
  mounted: function() {
    this.init()
  },
  beforeDestroy() {
    this.instance && this.instance.destroy()
  },
  methods: {
    init() {
      this.instance && this.instance.destroy()
      this.instance = new Pikaday(this.pikadayOptions)
      this.instance.setDate(this.instance.getDate())
    }
  }
}
</script>
