const ready = window.onReady = function(d) { document.readyState == 'complete' ? d() : document.addEventListener ? window.addEventListener('load', d, !1) : window.attachEvent('onload', d) }
window.$ = function(callback) {
  if (typeof callback == 'function') {
    window.ready(callback)
  }
  if (typeof window.jQuery == 'function') {
    window.jQuery.apply(this, arguments)
  }
}

window.ready = ready

export default ready
