<template>
  <div :is="$deviceView.mobile ? 'Scroll' : 'div'" class="chart-legend">
    <slot />
  </div>
</template>

<script>
import Scroll from 'modules/ui/scroll'
export default {
  components: {
    Scroll,
  },
}
</script>

<style lang="stylus">
// 共用樣式
.color-ball
  display inline-block
  width 1em
  height 1em
  margin-right .75em
  border-radius 5px
  vertical-align middle
  :not([class*="bg-"])
    background #FF895B
</style>

<style lang="stylus" scoped>
.chart-legend /deep/
  margin-top 1.5em
  text-align center
  user-select none
  .legend-item
    display inline-block
    vertical-align middle
    margin-right 1.5em
    margin-bottom .5em
    line-height 1
    padding 5px
    border-radius 5px
    > span
      display inline-block
      vertical-align middle

.chart-legend.ps /deep/
  display flex
  .legend-item
    flex-shrink 0
</style>
