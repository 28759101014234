import Api from 'api'
import parseJwt from 'lib/utils/parseJwt.js'

import AccountLockedError from 'exceptions/member/AccountLockedError'
import WrongPasswordError from 'exceptions/member/WrongPasswordError'

export default {
  namespaced: true,
  state: {
    keepAuth: null,
    auth: {
      token: null,
      username: null,
      expire: null,
      validation: null,
    },
    profile: null,
  },
  actions: {
    // 使用已登入的權杖，取得會員檔案。可能驗證失敗
    async loadProfile({ commit }) {
      const member = await Api.member.loadProfile()
        .then((res) => {
          // 已知的謎：回應可能被竄改
          if (typeof res.data !== 'object' && res.headers['content-length'] === '1007') {
            console.error(`警告：連線被劫持\n${res.data}`)
            return null
          }
          return res.data
        })
        .catch(err => {
          err.notificationHandled = true
          if ([400, 401, 404].includes(err.response.status)) {
            alert({
              message: '您的登入階段已過期，請重新登入'
            })
            commit('validation', false)
            return
          }
          throw err
        })
      commit('validation', true)
      commit('setProfile', member)
    },
    // 測試登入 $vm.$store.dispatch('member/testSignin')
    async testSignin({ dispatch }) {
      dispatch('signin', {
        username: 'lackneets.tarobo@gmail.com',
        password: 'taroboadmin',
      })
    },
    signin({ commit }, { username, password, remember }) {
      return new Promise((resolve, reject) => {
        Api.member.signin({
          username,
          password,
          remember,
        })
          .then(res => res.data)
          .then(profile => {
            const { member, token } = profile
            commit('signin', { member, token, remember })
            resolve(profile)
          })
          .catch(err => {
            err.notificationHandled = true
            if (err.response.status === 400 || err.response.status === 401) {
              reject(new WrongPasswordError())
              return
            }
            if (err.response.status === 403) {
              reject(new AccountLockedError())
              return
            }
            reject(new Error('發生了一些錯誤，目前無法登入'))
            throw err
          })
      })
    },
    async signout({ commit }) {
      // TODO 向伺服器取消登入階段
      commit('signout')
    }
  },
  mutations: {
    // 登入後儲存驗證資料
    signin(state, { member, token, remember }) {
      const { sub, exp } = parseJwt(token)
      state.auth.token = token
      state.auth.expire = exp * 1000
      state.auth.username = sub
      state.profile = new MemberProfile(member)
      // 保存一份記住的登入資訊
      if (remember) {
        state.keepAuth = JSON.parse(JSON.stringify(state.auth))
      }
    },
    signout(state) {
      state.auth.token = null
      state.auth.expire = null
      state.keepAuth = null
      state.profile = null
    },
    // 設定會員檔案
    setProfile(state, member) {
      state.profile = member && new MemberProfile(member)
    },
    // 設定驗證狀態
    validation(state, success) {
      if (!success) {
        state.auth.token = null
        state.auth.expire = null
        state.keepAuth = null
      }
      state.auth.validation = success
    },
    activated(state) {
      if (state.profile) {
        state.profile.activated = true
      } else {
        throw new Error('請先登入')
      }
    }
  },
  getters: {
    authToken: state => state?.auth?.token,
    profile: state => state?.profile,
    isAdmin: (state, getters) => !!getters.profile?.member_groups?.find(({ code }) => code === 'admin'),
    requireConfirmation: (state, getters) => getters.profile && !getters.profile?.activated
  },
}

class MemberProfile {
  constructor(attrs = {}) {
    Object.assign(this, attrs)
  }

  get isAdmin() {
    return !!this.member_groups?.find(({ code }) => code === 'admin')
  }
}
