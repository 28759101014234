<template>
  <div v-if="rawData" class="scroll-wrap">
    <component :is="$deviceView.tablet ? 'UiScroll' : 'div'">
      <div class="list-wrap">
        <div v-for="(item, i) in rawData" :key="i" class="list-item">
          <template v-if="item.type === '月報'">
            <div class="card-group">
              <div class="photo-wrap">
                <a class="photo-cont" :href="item.file_url" target="_blank">
                  <div class="photo" :style="{'background-image': `url(${item['fund']['cover_url']})`}" />
                </a>
              </div>
              <div class="sub-title">
                <router-link class="ui button round small minor" :to="{name: 'SaleskitReports'}">
                  月報
                </router-link>
              </div>
              <a class="main" :href="item.file_url" target="_blank">
                <div class="title">
                  {{ reportTitle(item) }}
                </div>
              </a>
            </div>
          </template>
          <template v-else>
            <div class="card-group">
              <div class="photo-wrap">
                <router-link class="photo-cont" :to="cardLink(item)">
                  <div class="photo" :style="{'background-image': `url(${item['cover_url']})`}" />
                </router-link>
              </div>
              <div class="sub-title">
                <router-link v-if="item.category" class="ui button round small minor" :to="{ name: 'SaleskitCharts', params: { category_id: item.category.id } }">
                  {{ item.category.name }}
                </router-link>
                <div class="date font-bold">
                  {{ item.publish_at | date }}
                </div>
              </div>
              <router-link class="main" :to="cardLink(item)">
                <div class="title">
                  {{ item.title }}
                </div>
              </router-link>
            </div>
          </template>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import API from 'api'
import FlashCard from 'modules/components/SingleFlashCard'
import UiScroll from 'modules/ui/scroll'

export default {
  components: {
    FlashCard,
    UiScroll,
  },
  data() {
    return {
      rawData: null,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      await API.content.saleskits().then(res => {
        this.rawData = res.data.entries
      })
    },
    cardLink(item) {
      return { name: 'SaleskitChartsContent', params: { category_id: item.category.id, id: item.slug } }
    },
    reportTitle(item) {
      return `${item.date}${item.type}開放下載啦`
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.scroll-wrap
  margin-top 2rem
  .v-perfect-scrollbar
    position relative
.list-wrap
  display flex
  +under(lg)
    width 1076px
    margin-bottom 1rem
  .list-item
    width calc((100% - 6rem)/ 4)
    margin-right 2rem
    &:nth-child(4n)
      margin-right 0
.card-group
  width 100%
  .photo-wrap
    width 100%
    border-radius 21px
    box-shadow 0px 1px 10px rgba(170, 170, 170, 0.3)
  .photo-cont
    display block
    width 100%
    border-radius 21px
    overflow hidden
    cursor pointer
    &:hover .photo
      transform scale(1.1)
  .photo
    padding-bottom 82.3%
    background-repeat no-repeat
    background-position center center
    background-size cover
    transition .5s
  .sub-title
    display flex
    justify-content space-between
    margin 1rem 0 .75rem
    color $gray2
  .main
    display block
    color $gray1
    &:hover
      text-decoration underline
    .title
      font-size 1.2rem
      font-weight bold
      display -webkit-box
      -webkit-line-clamp 2
      -webkit-box-orient vertical
      text-overflow ellipsis
      overflow hidden
</style>
