<!--

  使用 CSS 的 media query 控制 DOM 是否需要渲染，以節省資源

  用例：
  <div v-viewport-condition="'> md'">
    電腦版才會顯示的內容
  </div>

  NOTE: 存取以下屬性很吃資源
  document.documentElement.clientWidth
  window.innerWidth

-->

<script>
/* eslint-disable prefer-regex-literals */

// > 768
// > md
// < md

const breakpoints = {
  sm: '568px', // 35.5em, 568
  md: '768px', // 48em, 768
  lg: '1024px', // 64em, 1024
  xl: '1280px' // 80em, 1280
}

const makeMediaQuery = (queryString) => {
  const query = Object.keys(breakpoints).reduce((query, point) => query.replace(point, breakpoints[point]), queryString)
  const pattern = new RegExp('([<>])\\s*([\\d.]+)(px|em)')
  const matches = query.match(pattern)
  if (matches) {
    if (matches[1] === '>') {
      return `all and (min-width: ${matches[2]}${matches[3]})`
    }
    if (matches[1] === '<') {
      if (matches[3] === 'em') {
        matches[2] -= 0.0625
      }
      if (matches[3] === 'px') {
        matches[2] -= 1
      }
      return `all and (max-width: ${matches[2]}${matches[3]})`
    }
    return pattern
  } else {
    throw new Error('Invalid media query pattern: ' + queryString)
  }
}

export default {
  props: {
    value: {
      type: String,
      default: '> 1px'
    }
  },
  data: () => ({
    viewport: {
      width: 0,
      height: 0
    },
    breakpoints,
    visible: false,
    queryList: null
  }),
  computed: {
    query() {
      return makeMediaQuery(this.value)
    }
  },
  mounted() {
    this.queryList = window.matchMedia(this.query)
    this.queryList.addListener(this.toggleVisible)
    this.toggleVisible(this.queryList)
  },
  beforeDestroy() {
    this.queryList.removeListener(this.toggleVisible)
  },
  methods: {
    toggleVisible(event) {
      this.$set(this, 'visible', event.matches)
    }
  }
}
</script>

<template>
  <div v-if="visible" class="viewport-condition">
    <slot />
  </div>
</template>

<style lang="stylus" scoped>
.viewport-condition
  display inline
  max-width 100%
</style>
