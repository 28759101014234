<template>
  <div v-if="displayEntries">
    <slot name='header' :entry="displayEntries"></slot>
    <div class="announcement">
      <div class="list">
        <div v-for="entry in displayEntries" :key="entry.id" class="list-item">
          <div class="title">
            <router-link :to="{name: 'BulletinContent', params: {id: entry.slug}}">
              {{ entry.title }}
            </router-link>
          </div>
          <div class="date">
            {{ entry.publish_at | date }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from 'api'

export default {
  props: {
    specifyList: Array,
  },
  data() {
    return {
      entries: [],
      kind: 'bulletin',
    }
  },
  computed: {
    displayEntries() {
      return this.specifyList || this.entries.slice(0, 4)
    },
  },
  async mounted() {
    if (!this.specifyList) this.entries = await API.content.promoted({ kind: this.kind }).then(res => res.data.entries)
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.list
  .list-item
    position relative
    display flex
    justify-content space-between
    align-items center
    border-bottom 1px solid #ededed
    padding 9px 0 9px 18px
    &:before
      content ''
      position absolute
      left 0
      top 50%
      transform translateY(-50%)
      width 4px
      height 28px
      background-color $ugBlue2
      border-radius 5px
  .title
    flex-grow 1
    display -webkit-box
    -webkit-box-orient vertical
    text-overflow ellipsis
    -webkit-line-clamp 1
    overflow hidden
    +under(md)
      -webkit-line-clamp 2
    a:hover
      font-weight bold
  .date
    flex-shrink 0
    width 88px
    color $gray2
    font-weight bold
    margin-left 2rem
</style>
