<template>
  <div class="page-list">
    <div class="ui dividing huge header">
      內容管理 - {{ kindName }}
      <span v-if="$route.name === 'AdminContentCategories'">
        > 分類管理
      </span>
      <span v-if="$route.name === 'AdminContentCategoryEdit'">
        > 分類管理 > 編輯
      </span>
      <span v-if="$route.name === 'AdminContentCategoryCreate'">
        > 分類管理 > 新增
      </span>
    </div>
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view :key="kind" />
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive" :key="kind" />
  </div>
</template>

<script>
import MixinShared from './shared'
export default {
  mixins: [
    MixinShared,
  ]
}
</script>
