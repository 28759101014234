<template>
  <div class="reports-list">
    <div v-for="fund in fundsHasReport" :key="fund.id" class="card-group">
      <a :href="fund.report[0].file_url" target="_blank" class="photo-cont">
        <div class="photo" :style="{'background-image': `url(${fund['cover_url']})`}" />
        <div class="overlay">{{ fund.report[0].date }} 最新月報</div>
      </a>
      <div class="main">
        <a :href="fund.report[0].file_url" target="_blank" class="title">
          {{ fund.name }}
        </a>
      </div>
      <div class="tools">
        <div class="dropdown-list">
          <DropdownSimple v-model="selectedMonth[fund.id]" :entries="reportsMonth[fund.id]" />
        </div>
        <div class="tool-icon">
          <a :href="getPdfUrl(fund)" data-tooltip-title="下載月報" class="icon download" />
        </div>
        <ShareIcon direction="ani-down"
                   :setUrl="{link: shareUrl[fund.id], fb: shareUrl[fund.id], line: shareUrl[fund.id]}" style="margin-left: 1rem;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import API from 'api'
import DropdownSimple from 'modules/components/DropdownSimple'
import ShareIcon from 'modules/components/SocialShare'

export default {
  components: {
    DropdownSimple,
    ShareIcon,
  },
  data() {
    return {
      funds: [],
      selectedMonth: {},
    }
  },
  computed: {
    fundsHasReport() {
      return this.funds.filter(({ report }) => report?.length > 0)
    },
    reportsMonth() {
      const obj = {}
      this.fundsHasReport.forEach(fund => {
        const month = fund.report.map(f => {
          return {
            text: f.date,
            value: f.date,
          }
        })
        obj[fund.id] = month
      })
      return obj
    },
    shareUrl() {
      const obj = {}
      this.fundsHasReport.forEach(fund => {
        const target = fund.report.find(f => f.date === this.selectedMonth[fund.id])
        obj[fund.id] = target?.file_url
      })
      return obj
    },
  },
  async mounted() {
    const fundData = await API.fund.list().then(res => res.data.entries.map(data => {
      return {
        id: data.id,
        name: data.name,
        cover_url: data.cover_url,
      }
    }))
    this.funds = await Promise.all(fundData.map(async fund => {
      const report = await this.loadMonthlyReport(fund.id)
      report.sort((a, b) => new Date(a.date) < new Date(b.date) ? 1 : -1)
      return {
        ...fund,
        report,
      }
    }))
    this.fundsHasReport.forEach(fund => {
      this.$set(this.selectedMonth, fund.id, fund.report[0].date)
    })
  },
  methods: {
    async loadMonthlyReport(id) {
      return await API.fund.reports({ id }).then(res => res.data.entries.filter(d => d.type === '月報'))
    },
    getPdfUrl(target) {
      return this.fundsHasReport.find(fund => fund.id === target.id).report
        .find(r => r.date === this.selectedMonth[target.id]).file_download_url
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.reports-list
  position relative
  &:after
    content ''
    width 200px
    height 180px
    position absolute
    background-image url('~images/animation/img-0.0.1.svg')
    background-repeat no-repeat
    background-position center center
    background-size contain
    +over(md)
      left 10px
      top 90%
    +under(md)
      width 170px
      height 153px
      right 0
      top calc(100% + 10px)
  +over(md)
    display flex
.card-group
  +over(md)
    width calc((100% - 4rem)/3)
    margin-bottom 5rem
    + .card-group
      margin-left 2rem
  +under(md)
    + .card-group
      margin-top 2rem
  .photo-cont
    position relative
    display block
    width 100%
    border-radius 21px
    box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
    margin-bottom 1rem
    overflow hidden
    cursor pointer
    &:hover
      .photo
        transform scale(1.1)
  .overlay
    position absolute
    bottom 0
    left 0
    width 100%
    padding .5rem 0 .5rem 35px
    background-color rgba(255,255,255, .8)
    color $ugBlue1
    font-size 12px
    font-weight bold
  .photo
    padding-bottom 82.3%
    background-repeat no-repeat
    background-position center center
    background-size cover
    transition .5s
  .main
    display block
    color #505050
    .title
      display inline-block
      font-size 1.2rem
      font-weight bold
      &:hover
        text-decoration underline
  .tools
    display flex
    align-items center
    margin-top .75rem
  .dropdown-list
    width 100px
    /deep/ .ui.dropdown.fluid
      min-width 100%

.tool-icon
  width 36px
  margin-left 1rem
  cursor pointer
  .icon
    display block
    width 100%
    padding-bottom 100%
    background-repeat no-repeat
    background-position center center
    background-size contain
    &.download
      background-image url('~images/buttons/btn-download.png')
      &:hover
        background-image url('~images/buttons/btn-download-alt.png')

</style>
