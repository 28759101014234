<template>
  <UiModal ref="modal" :visible.sync="visible" size="medium" :modalClass="'confirm-modal'">
    <template #modal="modal">
      <div class="content">
        <div class="msg">
          <div class="main-text">
            歡迎成為我們的銷售夥伴！
          </div>
          <div>現在就去看看有哪些資源可以幫助銷售</div>
        </div>
      </div>
      <div class="actions">
        <router-link :to="{name: 'Partner'}" class="ui button major full">
          開始使用
        </router-link>
      </div>
    </template>
  </UiModal>
</template>

<script>
import { ModalImplement } from 'modules/ui/modal'

export default {
  mixins: [
    ModalImplement
  ],
  props: {
    visible: Boolean,
  },
}
</script>

<style lang="stylus" scoped>
.confirm-modal
  .msg
    text-align center
    .main-text
      font-size 1.44rem
      font-weight bold
      margin-bottom .5rem
</style>
