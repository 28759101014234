<template>
  <div v-if="entry">
    <form id="new_article_insight" enctype="multipart/form-data" action="/admin/article_insights" accept-charset="UTF-8" method="post" class="ui form app">
      <input name="utf8" type="hidden" value="✓">
      <input type="hidden" name="authenticity_token" value="QVCr/FoaExUrftFPajWldKdeeUwkXTR6HQFM3jW2uO3TQHQDS/vOsyMG1dW2gprAlEOvkprh4K+8dIS/UGd2mg==">

      <div>
        <Field>
          <label>顯示</label>
          <Checkbox v-model="entry.active" toggleSwitch />
        </Field>
        <div class="ui two fields">
          <Field>
            <label>封面縮圖*</label>
            <Attachment v-model="entry.cover" accept=".png,.jpg" />
          </Field>
        </div>
      </div>

      <h3 class="ui dividing header">
        基金名稱
      </h3>
      <div>
        <Field required>
          <label>基金中文名稱</label>
          <Input v-model="entry.name" class="fluid" placeholder="請輸入基金中文名稱" />
        </Field>
        <Field required>
          <label>基金代號</label>
          <div class="ui labeled input">
            <Input v-model="entry.code" class="fluid" prefix="https://funds.taroboadvisors.com/funds/" placeholder="請輸入內部代號" />
          </div>
        </Field>
        <Field required>
          <label>Bloomberg Code</label>
          <div class="ui labeled input">
            <Input v-model="entry.blg_code" class="fluid" placeholder="請輸入 BLG code" />
          </div>
        </Field>
        <Field>
          <label>策略標籤</label>
          <div class="ui labeled input">
            <Input v-model="entry.tags" class="fluid" placeholder="請輸入策略標籤，以逗點隔開，如：市場中立, 股票多空" />
          </div>
        </Field>
      </div>

      <h3 class="ui dividing header">
        策略特性
      </h3>
      <div>
        <Field v-for="field in config.metaFields" :key="field" required>
          <label>{{ field }}</label>
          <div class="ui labeled input">
            <Input v-model="makeBinding('strategies', field).binding" class="fluid" />
          </div>
        </Field>
        <Field v-for="field in otherStrategiesFields" :key="field">
          <label>{{ field }}
          </label>
          <div class="ui labeled input">
            <Input v-model="makeBinding('strategies', field).binding" class="fluid">
              <template #action>
                <div class="ui button minor" @click="deleteMetaField('strategies', field)">
                  刪除
                </div>
              </template>
            </Input>
          </div>
        </Field>
        <Field>
          <div class="ui button outline" @click="addMetaField('strategies')">
            <i class="icon-plus" />
            新增欄位
          </div>
        </Field>
        <Field required>
          <label>資料日期</label>
          <div class="ui labeled input">
            <Calendar v-model="entry.date" type="date" />
          </div>
        </Field>
      </div>

      <h3 class="ui dividing header">
        基本資料
      </h3>
      <div>
        <Field required>
          <label>基金成立日</label>
          <div class="ui labeled input">
            <Calendar v-model="entry.launch_date" type="date" />
          </div>
        </Field>
        <Field required>
          <label>計價幣別</label>
          <div class="ui labeled input">
            <Input v-model="entry.price_ccy" class="fluid" />
          </div>
        </Field>
        <Field required>
          <label>保管銀行</label>
          <div class="ui labeled input">
            <Input v-model="entry.custodian_bank" class="fluid" />
          </div>
        </Field>
        <Field required>
          <label>審計師</label>
          <div class="ui labeled input">
            <Input v-model="entry.auditor" class="fluid" />
          </div>
        </Field>
      </div>

      <h3 class="ui dividing header">
        交易資訊
      </h3>
      <div>
        <Field v-for="field in getFields('trading')" :key="field.id" required>
          <label>{{ field.name }}</label>
          <div class="ui labeled input">
            <Input v-model="entry[field.id]" class="fluid" />
          </div>
        </Field>
      </div>

      <h3 class="ui dividing header">
        投資目標及策略
      </h3>

      <Field>
        <textarea v-model="entry.strategy_desc" placeholder="(選填)" />
      </Field>

      <div class="ui divider" />

      <div class="rule-buttons-group">
        <div class="ui button minor" @click="abort">
          取消
        </div>
        <div class="ui button outline" @click="submit">
          儲存
        </div>
        <div class="ui button major" @click="submit({goBack: true})">
          儲存並返回
        </div>
        <div class="ui button info" @click="preview">
          即時預覽
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Api from 'api'
import MixinAdminCommonPage from 'modules/admin/common/page'
import validate from './validation.js'

export default {
  mixins: [
    MixinAdminCommonPage,
  ],
  data() {
    return {
      entry: null,
      config: {
        metaFields: [
          '重點投資區域',
          '整體槓桿',
          '淨風險部位',
        ],
        generalFields: [
          { group: 'trading', id: 'initial_amount', name: '最低投資金額' },
          { group: 'trading', id: 'manage_fee', name: '管理費' },
          { group: 'trading', id: 'handling_fee', name: '手續費' },
          { group: 'trading', id: 'custodian_fee', name: '保管費' },
          { group: 'trading', id: 'sales_fee', name: '銷售費' },
          { group: 'trading', id: 'performance_fee', name: '績效費' },
          { group: 'trading', id: 'trade_day', name: '交易日' },
          { group: 'trading', id: 'redemption_period', name: '贖回付款期間' },
        ]
      }
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    submitTo() {
      // 存檔或新增
      if (this.id) {
        return Api.fund.update
      } else {
        return Api.fund.create
      }
    },
    otherStrategiesFields() {
      return this.entry.metadata?.strategies?.map(({ name }) => name).filter(name => !this.config.metaFields.includes(name))
    }
  },
  watch: {
    id() {
      this.load()
    }
  },
  mounted() {
    this.load()
  },
  beforeDestroy() {
    this.entry = null
  },
  methods: {
    abort() {
      this.backToList()
    },
    backToList() {
      // 回到列表
      this.$router.push({
        name: 'AdminFundList',
        params: this.$route.params,
      })
    },
    submit({ goBack }) {
      if (this.validateForm()) {
        this.wait(this.submitTo({
          format: 'full',
          id: this.id,
          kind: this.kind,
          attributes: this.entry,
        })).then((res) => {
          this.success('已儲存').then(() => {
            if (goBack) {
              this.backToList()
            } else if (res.data?.entry?.id) {
            // 跳到新文章的頁面
              this.$router.push({
                name: 'AdminFundEdit',
                params: {
                  ...this.$route.params,
                  id: res.data.entry.id,
                }
              })
            }
          })
        })
      }
    },
    getFields(group) {
      return this.config.generalFields.filter((field) => field.group === group) || []
    },
    makeBinding(key, name) {
      // 確保有 metadata
      this.entry.metadata = this.entry.metadata || {}
      this.entry.metadata[key] = this.entry.metadata[key] || []
      // 抓取欄位
      const meta = this.entry.metadata[key]
      let attr = meta.find(field => field.name === name)
      // 資料不存在就建立一個
      if (typeof attr !== 'object') {
        attr = { name, value: null }
        this.entry.metadata[key].push(attr)
      }
      // 回傳 getter, setter
      return {
        get binding() {
          return attr.value
        },
        set binding(val) {
          attr.value = val
        }
      }
    },
    async deleteMetaField(key, name) {
      if (await confirm({
        message: `確定要刪除 ${name} 嗎？`
      })) {
        this.entry.metadata[key] = this.entry.metadata[key].filter(field => field.name !== name)
      }
    },
    async addMetaField(key) {
      const meta = this.entry.metadata[key]
      const existing = meta.map(({ name }) => name)
      const name = await prompt({
        message: '編輯新增的欄位名稱'
      })

      if (existing.includes(name)) {
        return await alert({
          type: 'danger',
          title: '無法新增',
          message: '此名稱已經存在',
        })
      }
      if (name) {
        meta.push({ name, value: null })
      }
    },
    preview() {},
    async load() {
      await this.loadEntry()
    },
    async loadEntry() {
      if (this.id) {
        this.entry = await Api.fund({ id: this.id, format: 'full' }).then(res => res.data.entry)
      } else {
        this.entry = {
          status: 'Draft',
        }
      }
    },
    validateForm() {
      try {
        return validate(this.entry)
      } catch (message) {
        this.error(message)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.remark
  margin-top 2rem
  color $gray2
</style>
