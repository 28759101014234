<!--
  浮動面板
  親戚模組： modules/portfolio/CompareTool.vue
-->
<template>
  <div class="float-panel">
    <div class="bg-layout" :class="{ active: is.opened }" />
    <div
      class="float-panel__container"
      :class="{
        opened: is.opened,
        reveal: reveal && !is.opened,
      }"
    >
      <div class="page-cont">
        <div
          class="header"
          :class="{ action: reveal && is.opened }"
          @click="$_handleClose"
        >
          <div class="ui container">
            <div class="flex">
              <div class="left-side flex">
                <slot name="left" v-bind="this" />
                <span v-if="$scopedSlots.title" class="title">
                  <slot name="title" v-bind="this" />
                </span>
              </div>
              <div class="right-side">
                <slot name="right" v-bind="this" />
              </div>
            </div>
          </div>
        </div>
        <div class="body">
          <slot name="body" v-bind="this" />
          <div v-if="$scopedSlots.container" class="ui container">
            <slot name="container" v-bind="this" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'lib/vue/essential'
const $ = jQuery
const FloatPanel = {
  props: {
    reveal: Boolean,
    opened: Boolean,
    mobileCompatible: Boolean,
  },
  data() {
    return {
      is: {
        opened: false,
      },
    }
  },
  computed: {
    // prettier-ignore
    scrollbarWidth() {
      const a = document.createElement('div'); a.style.visibility = 'hidden', a.style.width = '100px', a.style.msOverflowStyle = 'scrollbar', document.body.appendChild(a); const b = a.offsetWidth; a.style.overflow = 'scroll'; const c = document.createElement('div'); c.style.width = '100%', a.appendChild(c); const d = c.offsetWidth; return a.parentNode.removeChild(a), b - d
    }
  },
  watch: {
    'is.opened'(opened) {
      if (opened) {
        this.$_stick()
        this.$emit('open')
      } else {
        this.$_unstick()
        this.$emit('close')
      }
    },
    opened: {
      immediate: true,
      handler() {
        this.is.opened = this.opened
      },
    },
    '$deviceView.mobile'(isMobile) {
      // 手機不顯示
      if (isMobile && !this.mobileCompatible) {
        this.is.opened = false
      }
    },
  },
  mounted() {
    // console.log('asdasd')
    // document.body.appendChild(this.$el)
  },
  beforeDestroy() {
    this.$_unstick()
  },
  methods: {
    open() {
      if (!this.$deviceView.mobile || this.mobileCompatible) {
        // 手機不顯示
        this.is.opened = true
      }
    },
    close() {
      this.is.opened = false
    },
    $_stick() {
      $('html').css('overflow', 'hidden')
      $('html').css('paddingRight', `${this.scrollbarWidth}px`)
      Modernizr.touch && $('body > .header').css('zIndex', 'auto')
    },
    $_unstick() {
      $('html').css('overflow', '')
      $('html').css('paddingRight', '')
      $('body > .header').css('zIndex', '')
    },
    $_handleClose() {
      if (this.is.opened && this.reveal) {
        this.close()
      }
    },
  },
}

const PanelImplement = {
  components: {
    FloatPanel,
  },
  methods: {
    getPanel() {
      if (this.$refs.panel) {
        return this.$refs.panel
      } else {
        Vue.config.silent || console.error('Bad Implement. Please put ref="panel" in <FloatPanel>')
        return {
          open: () => {},
          close: () => {},
        }
      }
    },
    show() {
      this.getPanel().open()
    },
    hide() {
      this.getPanel().close()
    },
    open() {
      this.getPanel().open()
    },
    close() {
      this.getPanel().close()
    },
    load() {

    }
  }
}

export default FloatPanel
export { PanelImplement }
</script>

<style lang="stylus" scoped>
.float-panel
  position fixed
  // NOTE 200 會蓋到 tooltip
  z-index 210
  top 100%
  width 100%
  height 100%
  left 0
  .bg-layout
    position fixed
    top 0
    background rgba(0, 0, 0, .35)
    width 100%
    height 100%
    opacity 0
    transition .5s opacity
    will-change opacity
    pointer-events none
    &.active
      opacity 1
  &__container
    position fixed
    width 100%
    height 100%
    -webkit-overflow-scrolling touch
    left 0
    top 100%
    z-index 200
    opacity 0
    // 使用 transform 會比較順暢
    transform translateY(calc(0vh - 0px))
    will-change opacity, transform
    transition-property opacity, transform
    transition-duration .6s, 1s // 關閉時
    transition-delay .2s, 0s
    transition-timing-function cubic-bezier(.19, 1, .22, 1), cubic-bezier(.19, 1, .22, 1)
    &.reveal
      opacity 1
      transform translateY(-101px)
      transition-duration .3s, .8s // 選擇、縮小時
      transition-delay 0s, 0s
    &.opened
      opacity 1
      transform translateY(-100%)
      transition-duration .3s, .8s // 開啟時
      transition-delay 0s, 0s
      overflow-y auto

.page-cont
  position relative
  top 45px
  height calc(100% - 45px)
  background-color #fff
  display flex
  flex-direction column
  // @media $mobile
  //   height 100%
  //   top 0
  .header
    height 56px
    line-height 56px
    flex-grow 0
    flex-shrink 0
    box-shadow 0px -2px 6px 0 rgba(170, 170, 170, .3)
    border-bottom 1px solid $gray3
  .body
    height 100%
    background $gray4
    overflow-y auto
    .container
      padding-top 1rem
      padding-bottom 1rem
    .container:first-child:last-child
      // 唯一的 container 滿高
      height 100%

.flex
  display flex
  align-items center
  .left-side
    margin-right 1rem
    flex-grow 1
    align-items left
    .title
      width 100%
      font-size 1.44rem
      font-weight bold
  .right-side
    margin-left auto
    display flex
    align-items center
    height 100%

.outside-head
  font-size 1.2em
  font-weight bold
  margin-right 1em
  .selected-num
    font-size 1.5em
    margin .25em
</style>
