<template>
  <div v-if="subject" class="basic__container">
    <h2 class="basic__topic">
      {{ subject.title }}
    </h2>
    <div class="document-content">
      <iframe :src="`https://docs.google.com/gview?embedded=true&url=${subject.url}`" frameborder="0" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      terms: {
        'member': {
          title: '會員條款',
          url: 'https://tarobo.s3.ap-northeast-1.amazonaws.com/fund_service/terms/member.pdf'
        },
        'disclaimer': {
          title: '重要聲明與網站資訊聲明',
          url: 'https://tarobo.s3.ap-northeast-1.amazonaws.com/fund_service/terms/disclaimer.pdf'
        },
        'privacy': {
          title: '隱私權保護政策與個人資料保護法告知事項',
          url: 'https://tarobo.s3.ap-northeast-1.amazonaws.com/fund_service/terms/privacy.pdf'
        },
        'anti-laundering': {
          title: '洗錢防制',
          url: 'https://tarobo.s3.ap-northeast-1.amazonaws.com/terms/anti-laundering.pdf'
        }
      }
    }
  },
  computed: {
    subject() {
      return this.terms[this.$route.params.id]
    }
  },
  watch: {
    subject: {
      immediate: true,
      handler(subject) {
        if (!subject) {
          this.$router.replace({ name: 'NotFound' })
        } else {
          this.$setTitle(subject.title)
        }
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
.basic__topic
  margin-bottom 2rem

.document-content
  margin 2rem auto
  iframe
    width 100%
    height 80vh
</style>
