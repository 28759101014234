<template>
  <div>
    <div class="basic-form">
      <div v-for="item in strategies" :key="item.name" class="row">
        <div class="title">
          {{ item.name }}
        </div>
        <div class="content">
          {{ item.value }}
        </div>
      </div>
    </div>
    <div class="text-minor" style="margin-top: 1.5rem;">
      資料時間：{{ fund.date | date }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fund: Object,
  },
  computed: {
    strategies() {
      return this.fund.metadata.strategies
    }
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/components/fund/noteform.styl'
</style>
