<!--
## 使用範例

import UiTabs from 'modules/ui/tabs'
export default {
  components: {
    UiTabs,
  }
}

<UiTabs :tabs="['全部基金', '銷售中', '停售']">
  <div slot="全部基金" class="">1</div>
  <div slot="銷售中" class="">2</div>
  <div slot="停售" class="">3</div>
</UiTabs>

## Design Patterns

# Remote Patten
 > 使用分離的 navigation 控制 template 切換

<UiTabs :tabs="mainTabs" :tab.sync="current.tab" class="modern"></UiTabs>
<UiTabs :tabs="mainTabs" :tab.sync="current.tab" :navigation="false">
  <template #foo>
    Hello World
  </template>
</UiTabs>

-->

<script>
import Core from './core'

import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar'

export default {
  mixins: [Core],
  props: {
    size: {
      // large, medium
      type: String,
      default: 'medium'
    },
    theme: {
      // modern or classic
      type: String,
      default: 'modern'
    }
  },
  computed: {
    mergedClasses() {
      const sizeClass = this.size
      const customClass = {
        flatten: this.flatten,
        [sizeClass]: true,
        modern: this.theme === 'modern',
      }
      return customClass
    },
  },
  mounted() {
    this._perfectScroll = this.$refs.nav && new PerfectScrollbar(this.$refs.nav)
    window.addEventListener('resize', () => this.updateLayout())
    this.updateLayout()
  },
  methods: {
    updateLayout() {
      this._perfectScroll && this._perfectScroll.update()
    }
  }
}
</script>

<template>
  <div class="animated-tabs" :class="mergedClasses">
    <div class="animated-tabs__tabs">
      <div v-if="navigation && !flatten" ref="nav" class="animated-tabs__nav tab-nav">
        <a v-for="(tabName, index) in $_navTabs" :key="index" style="cursor:pointer"
           :class="{active: currentIndex == index }" :data-ga-value="index" @click="go(index+1)"
        >
          <slot v-if="$slots[`tab-${index+1}`]" :name="`tab-${index+1}`" v-bind="$_slotAttrs" />
          <slot v-else-if="$slots[`tab-${tabName}`]" :name="`tab-${tabName}`" v-bind="$_slotAttrs" />
          <slot v-else v-bind="$_slotAttrs">{{ tabName }}</slot>
        </a>
        <a v-if="$slots['tab-btn']" class="tab-buttons" :class="{active: currentIndex == propTabs.length }">
          <slot name="tab-btn" />
        </a>
      </div>
      <div v-if="$slots['tabs-after']" class="animated-tabs__tools">
        <slot name="tabs-after" v-bind="$_slotAttrs" />
      </div>
    </div>
    <div class="animated-tabs__header">
      <slot name="header" v-bind="$_slotAttrs">
        <div v-if="navigation && !flatten" class="separator" />
      </slot>
    </div>
    <div class="tab-contents">
      <template v-for="(tabName,tabIndex) in propTabs">
        <div v-if="flatten" :key="`header-${tabIndex}`" class="tab-header" />
        <div v-if="flatten" :key="`separator-${tabIndex}`" class="separator" />
        <transition
          :key="`transition-${tabIndex}`"
          :enter-active-class="'ui transition animating fade ' + (direction>0? 'left': 'right') + ' in'"
          :leave-active-class="'ui transition animating fade ' + (direction<0? 'left': 'right') + ' out'"
          v-on="$_bindTransitionEvents({ index: tabIndex, tabName })"
        >
          <keep-alive :key="tabIndex+1" :include="'CachedTab'">
            <component
              :is="(cache==false)||omit.split(/\s*,\s*/).includes(tabName) ? 'Tab' : 'CachedTab'"
              v-if="(flatten||step==tabIndex+1)&&(!(onlyTabs&&onlyTabs.length)||onlyTabs.indexOf(tabIndex)>=0)" class="tab-content" :name="tabIndex+1"
            >
              <slot v-if="$scopedSlots[tabIndex+1]" :name="tabIndex+1" v-bind="$_slotAttrs" />
              <slot v-else-if="$scopedSlots[tabName]" :name="tabName" v-bind="$_slotAttrs" />
              <slot v-else v-bind="$_slotAttrs" />
            </component>
          </keep-alive>
        </transition>
      </template>
      <div v-if="$scopedSlots.footer" class="animated-tabs__footer">
        <slot name="footer" v-bind="$_slotAttrs" />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped src="./tabs.less"></style>
<style lang="stylus" scoped src="./theme-modern.styl"></style>
