<template>
  <div>
    <div class="page-layout container-top">
      <CountdownPanel />
      <Marquee class="widget-box" />

      <Announcement class="widget-box">
        <template #header="{ entry }">
          <h2 v-if="entry.length > 0" class="basic__topic announcement mb-1">
            重要公告
          </h2>
        </template>
      </Announcement>

      <h2 class="basic__topic">
        基金中心
      </h2>
      <FundInfo class="widget-box" />
    </div>

    <div class="container-middle">
      <div class="page-layout">
        <div class="topic-wrap">
          <h2 class="basic__topic">
            銷售工具
          </h2>
          <router-link :to="{ name: 'Saleskit'}" class="btn-more">
            更多>
          </router-link>
        </div>
        <Saleskit />
      </div>
    </div>

    <div class="page-layout container-bottom">
      <div class="topic-wrap">
        <h2 class="basic__topic perspectives">
          經理人觀點
        </h2>
        <router-link :to="{ name: 'Perspectives'}" class="btn-more">
          更多>
        </router-link>
      </div>
      <Perspectives class="widget-box" />

      <div class="topic-wrap">
        <h2 class="basic__topic">
          夥伴學習課程
        </h2>
        <router-link :to="{ name: 'Training'}" class="btn-more">
          更多>
        </router-link>
      </div>
      <Training class="widget-box" />
    </div>
  </div>
</template>

<script>
import CountdownPanel from 'modules/widgets/CountdownPanel'
import Marquee from 'modules/widgets/Marquee'
import Announcement from 'modules/widgets/Announcement'
import FundInfo from 'modules/widgets/FundInfo'
import Saleskit from 'modules/widgets/Saleskit'
import Perspectives from 'modules/widgets/Perspectives'
import Training from 'modules/widgets/Training'

export default {
  components: {
    CountdownPanel,
    Marquee,
    Announcement,
    FundInfo,
    Saleskit,
    Perspectives,
    Training,
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.page-layout
  width 100%
  +over(xl)
    max-width 1076px
    margin 0 auto
.container-top
  padding 5rem 0 0 0
  +under(xl)
    padding 5rem 3rem 0 3rem
  +under(md)
    padding 2rem 1rem 0
.container-middle
  background-color #fff
  padding 4rem 3rem 50px
.container-bottom
  padding 7rem 0 13rem 0
  +under(xl)
    padding 7rem 3rem 13rem 3rem
  +under(md)
    padding 3rem 1rem 13rem 1rem
.widget-box
  margin 2rem auto 4rem
  +under(md)
    margin 2rem auto
.topic-wrap
  display flex
  align-items center
  justify-content space-between
  .btn-more
    color $gray2
    font-size 1.44rem
    font-weight bold
    +under(md)
      font-size 1.2rem

+over(md)
  .basic__topic
    position relative
    z-index 1
    &.announcement:before, &.perspectives:before
      content ''
      position absolute
      background-repeat no-repeat
      background-position center center
      background-size contain
    &.announcement
      &:before
        right 21px
        bottom 0
        width 114px
        height 73px
        background-image url('~images/animation/img-1.0.7.svg')
    &.perspectives
      &:before
        width 200px
        height 180px
        left 180px
        bottom -55px
        background-image url('~images/animation/img-0.0.1.svg')
        z-index -1

.mb-1
  margin-bottom 1rem
</style>
