import { render, staticRenderFns } from "./index.vue?vue&type=template&id=d454fb78&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./tabs.less?vue&type=style&index=0&id=d454fb78&lang=less&scoped=true&"
import style1 from "./theme-modern.styl?vue&type=style&index=1&id=d454fb78&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d454fb78",
  null
  
)

export default component.exports