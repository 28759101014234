export default {
  performance: [
    { id: 1, title: '近 1 月', name: 'perf_1m', key: '1', default: true },
    { id: 2, title: '近 3 月', name: 'perf_3m', key: '3', default: true },
    { id: 3, title: '近 6 月', name: 'perf_6m', key: '6', default: true },
    { id: 4, title: '近 1 年', name: 'perf_1y', key: '12', default: true },
    { id: 5, title: '近 3 年', name: 'perf_3y', key: '36', default: true },
    { id: 6, title: '近 5 年', name: 'perf_5y', key: '60' },
    { id: 7, title: '今年以來', name: 'perf_ytd', key: 'ytd', default: true },
    { id: 8, title: '成立至今', name: 'perf_stl', key: 'stl' },
  ],
  volatility: [
    { id: 1, title: '近 1 月', name: 'vol_1m_ann', key: '1', default: true },
    { id: 2, title: '近 3 月', name: 'vol_3m_ann', key: '3', default: true },
    { id: 3, title: '近 6 月', name: 'vol_6m_ann', key: '6', default: true },
    { id: 4, title: '近 1 年', name: 'vol_1y_ann', key: '12', default: true },
    { id: 5, title: '近 3 年', name: 'vol_3y_ann', key: '36', default: true },
    { id: 6, title: '近 5 年', name: 'vol_5y_ann', key: '60' },
    { id: 7, title: '今年以來', name: 'vol_ytd_ann', key: 'ytd', default: true },
    { id: 8, title: '成立至今', name: 'vol_stl_ann', key: 'stl' },
  ],
  sharpe: [
    { id: 1, title: '近 1 月', name: 'sharp_1m', key: '1', default: true },
    { id: 2, title: '近 3 月', name: 'sharp_3m', key: '3', default: true },
    { id: 3, title: '近 6 月', name: 'sharp_6m', key: '6', default: true },
    { id: 4, title: '近 1 年', name: 'sharp_1y', key: '12', default: true },
    { id: 5, title: '近 3 年', name: 'sharp_3y', key: '36', default: true },
    { id: 6, title: '近 5 年', name: 'sharp_5y', key: '60' },
    { id: 7, title: '今年以來', name: 'sharp_ytd', key: 'ytd', default: true },
    { id: 8, title: '成立至今', name: 'sharp_stl', key: 'stl' },
  ],
}
