<template>
  <div>
    <div class="photo-cont" @click="$emit('showVideo')">
      <div class="photo" :style="{'background-image': `url(${card['cover_medium_url']})`}" />
    </div>
    <div class="text-cont">
      <div class="sub-title">
        <div v-if="card.category" class="ui button round small minor" @click="$emit('clickTag')">
          {{ card.category.name }}
        </div>
        <div class="date font-bold">
          {{ card.publish_at | date }}
        </div>
      </div>
      <div class="main">
        <div class="descript" @click="$emit('showVideo')">
          {{ card.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    card: Object,
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'

.photo-cont
  position relative
  display block
  width 100%
  height 184px
  cursor pointer
  &:before, &:after
    opacity 0
    transition .3s
  &:before
    content ''
    position absolute
    width 100%
    height 100%
    top 0
    left 0
    background-color rgba(0,0,0, .3)
  &:after
    content ''
    position absolute
    left 50%
    top 50%
    transform translate(-50%, -50%)
    width 49px
    height 49px
    background-image url('~images/buttons/btn-play-video.png')
    background-repeat no-repeat
    background-position center center
    background-size contain
  &:hover:before, &:hover:after
    opacity 1
  &:hover
    box-shadow 0px 1px 15px rgba(170, 170, 170, 0.3)
.photo
  height 100%
  background-repeat no-repeat
  background-position center center
  background-size cover
  transition .5s
.text-cont
  padding 1rem 1.5rem
.sub-title
  display flex
  justify-content space-between
  margin 1rem 0 .75rem
  color $gray2
.main
  color #505050
  cursor pointer
  &:hover
    font-weight bold
  .descript
    display -webkit-box
    -webkit-line-clamp 2
    -webkit-box-orient vertical
    text-overflow ellipsis
    overflow hidden
</style>
