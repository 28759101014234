<template>
  <div>
    <div class="fund-tags">
      <div v-for="tag in fund.tags" :key="tag" class="ui button minor micro round custom-tag" v-text="tag" />
    </div>
    <div class="fund-name">
      <h3>{{ fund.name }}</h3>
      <div class="eng">
        Bloomberg Code: {{ fund.blg_code }}
      </div>
    </div>
    <div style="margin-bottom: 1.5rem;">
      <UiScroll>
        <div class="basic-info">
          <div class="info-block">
            <div class="title">
              單位淨值{{ fund.price_ccy }} <span style="margin-left: .5rem;">({{ fund.price_date }})</span>
            </div>
            <div class="content flex align-center">
              <div class="price">
                {{ fund.price_est || fund.price | currency(4) }}
              </div>
              <div v-if="fund.price_est" style="font-size:12px; color:#aaa; margin-left:.75rem;">
                (預估淨值)
              </div>
              <div class="price-change text-minor">
                <div class="flex space-between">
                  <span class="d-title flex space-between"><span>漲</span><span>跌</span></span>
                  <span class="indicator">
                    <i v-if="fund.price_1m_chg > 0" class="icon-up-dir color-positive" />
                    <i v-else-if="fund.price_1m_chg < 0" class="icon-down-dir color-negative" />
                  </span>
                  <span class="d-num color" :class="{'color-positive': fund.price_1m_chg > 0, 'color-negative': fund.price_1m_chg < 0}">{{ fund.price_1m_chg | round(4) }}</span>
                </div>
                <div class="flex">
                  <span class="d-title">漲跌幅</span>
                  <span class="indicator">
                    <i v-if="fund.perf_1m > 0" class="icon-up-dir color-positive" />
                    <i v-else-if="fund.perf_1m < 0" class="icon-down-dir color-negative" />
                  </span>
                  <span class="d-num color"
                        :class="{'color-positive': fund.perf_1m > 0, 'color-negative': fund.perf_1m < 0}"
                  >{{ fund.perf_1m | percentage(1) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="info-block">
            <div class="title">
              資產規模 （{{ fund.aum_date }}）
            </div>
            <div class="content flex align-center">
              <div class="price">
                {{ fundAum | decimal(1) }}
              </div>
              <span style="font-size: 12px;margin-left: .5rem;">百萬美元</span>
            </div>
          </div>
          <div class="info-block">
            <div class="title">
              成立日期
            </div>
            <div class="content">
              {{ fund.launch_date }}
            </div>
          </div>
          <div class="info-block">
            <div class="title">
              重點投資區域
            </div>
            <div class="content">
              {{ fund.metadata.strategies[0].value }}
            </div>
          </div>
        </div>
      </UiScroll>
    </div>
    <div class="actions-area">
      <div class="action-button">
        <template v-if="$deviceView.desktop">
          <a class="ui button minor button-gap" target="_blank" :class="{disabled: !fund.monthly_report}" :href="fund.monthly_report && fund.monthly_report.file_url">
            <i class="icon-file" style="margin-right: .5rem;" />
            <span>最新月報</span>
          </a>
          <a class="ui button minor button-gap" target="_blank" :class="{disabled: !fund.annually_report}" :href="fund.annually_report && fund.annually_report.file_url">
            <i class="icon-file" style="margin-right: .5rem;" />
            <span>年度財報</span>
          </a>
        </template>
        <template v-else>
          <!-- 手機版： 合併在資料下載裡，如果兩個資料都缺，直接顯示disabled btn -->
          <div v-if="!fund.monthly_report && !fund.annually_report" class="ui button minor button-gap disabled">
              <i class="icon-file" style="margin-right: .5rem;"></i>
              <span>資料下載</span>
          </div>
          <DropdownButton v-else class="button-gap" ref="dropdown">
            <template #button>
              <i class="icon-file"></i>
              <span>資料下載</span>
            </template>
            <template #menu>
              <a class="item" target="_blank" 
                :href="fund.monthly_report && fund.monthly_report.file_url" 
                :class="{ disabled: !fund.monthly_report }"
              >
                最新月報
              </a>
              <a class="item" target="_blank" 
                :href="fund.annually_report && fund.annually_report.file_url" 
                :class="{ disabled: !fund.annually_report }"
              >
                年度財報
              </a>
            </template>
          </DropdownButton>
        </template>
        <div class="ui button minor button-gap" @click="$emit('showModalPicker')">
          <i class="icon-compare" style="margin-right: .5rem;" />
          <span>績效比較</span>
        </div>
      </div>
      <ShareIcon :direction="$deviceView.desktop ? 'ani-left' : 'ani-down'" />
    </div>
  </div>
</template>

<script>
import UiScroll from 'modules/ui/scroll'
import DropdownButton from 'modules/ui/form/dropdownButton'
import ShareIcon from 'modules/components/SocialShare'

export default {
  components: {
    UiScroll,
    DropdownButton,
    ShareIcon,
  },
  props: {
    fund: Object,
  },
  computed: {
    fundAum() {
      return this.fund.aum / 1000000
    },
  },
}
</script>

<style lang="stylus" scoped>
.flex
  display flex
  &.space-between
    justify-content space-between
  &.align-center
    align-items center
.fund-tags
  display flex
  align-items center
  margin-bottom 1rem
.fund-name
  margin-bottom 2rem
  h3
    font-size 1.728rem
    line-height 31px
    margin 0
  .eng
    font-size 1.2rem
    line-height 21px
    font-weight bold
    margin-top 0.5rem
    color $gray2
.basic-info
  display flex
  align-items center
  border-top 1px solid #ededed
  border-bottom 1px solid #ededed
  padding 1rem 0
  .info-block
    position relative
    flex-shrink 0
    .title
      color $gray2
      font-size 12px
      margin-bottom .7rem
    .content
      font-size 1.2rem
    .price
      font-size 1.728rem
      line-height 31px
    .price-change
      font-size 12px
      margin-left .75rem
      min-width 106px
      .d-title
        flex 0 0 36px
      .d-num
        margin-left auto
      .indicator
        margin 0 .75rem
    // 正負
    .color-positive
      color #ED4758
    .color-negative
      color #00BF9C

    + .info-block
      margin-left 2rem
      padding-left 2rem
      &:before
        content ''
        position absolute
        left 0
        top 50%
        transform translateY(-50%)
        width 1px
        height 28px
        background #ddd
.actions-area /deep/
  display flex
  justify-content space-between
  align-items center
  font-size 0
  margin-bottom 3rem
  .action-button
    > *
      vertical-align middle
  .button-gap
    margin-right 1rem
.custom-tag
  cursor default
</style>
