<template>
  <div class="about">
    <div class="layout">
      <Slick class="ug-slider" :options="slickSetting">
        <div class="slider-box">
          <div>
            <div class="top">
              <img src="~images/logo-ug.png" alt="" class="logo">
              <div>
                <div class="company">
                  UG Investment
                </div>
                <div class="slug">
                  榮獲亞洲避險基金權威機構年度大獎
                </div>
              </div>
            </div>
            <div>
              <div class="trophy-box">
                <img src="~images/trophy-2020.png" alt="" class="pic">
                <div>
                  <div class="word chinese">
                    2020 年度最佳基金管理公司
                  </div>
                  <div class="word eng">
                    Management Firm of the Year 2020
                  </div>
                </div>
              </div>
              <div class="trophy-box">
                <img src="~images/trophy-2017.png" alt="" class="pic">
                <div>
                  <div class="word chinese">
                    2017 年度最佳基金管理公司
                  </div>
                  <div class="word eng">
                    Management Firm of the Year 2017
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slider-box">
          <div>
            <div class="top">
              <img src="~images/logo-ug.png" alt="" class="logo">
              <div>
                <div class="company">
                  UG Investment
                </div>
                <div class="slug">
                  美國SEC註冊，管理資產規模突破30億美元
                </div>
              </div>
            </div>
            <div class="timeline">
              <div class="line">
                <div class="dot" data-year="2020" />
                <div class="text">
                  管理資產突破30億美元
                </div>
              </div>
              <div class="line">
                <div class="dot" data-year="2019" />
                <div class="text">
                  管理資產突破20億美元
                </div>
              </div>
              <div class="line">
                <div class="dot" data-year="2015" />
                <div class="text">
                  管理資產突破10億美元
                </div>
              </div>
              <div class="line">
                <div class="dot" data-year="2013" />
                <div class="text">
                  美國SEC註冊
                </div>
              </div>
              <div class="line">
                <div class="dot" data-year="2003" />
                <div class="text">
                  進軍中國
                </div>
              </div>
              <div class="line">
                <div class="dot" data-year="1998" />
                <div class="text">
                  UG Investment Advisers Ltd. 成立
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slider-box">
          <div>
            <div class="top">
              <img src="~images/logo-ug.png" alt="" class="logo">
              <div>
                <div class="company">
                  UG Investment
                </div>
                <div class="slug">
                  為亞洲避險基金管理公司中少數能夠同時與4家主要經紀商（Prime Broker）往來
                </div>
              </div>
            </div>
            <div class="broker-pic">
              <img v-if="$deviceView.tablet" src="~/images/ug-brokers-m.png" alt="">
              <img v-else src="~/images/ug-brokers.png" alt="">
            </div>
          </div>
        </div>
      </Slick>
    </div>
  </div>
</template>

<script>
import Slick from 'modules/ui/slick'

export default {
  components: {
    Slick,
  },
  data() {
    return {
      slickSetting: {
        dots: true,
        infinite: false,
        slidesToShow: 1,
        arrows: true,
        // adaptiveHeight: true,
        prevArrow: '<div class="icon-button tertiary slick-prev"><i class="icon-angle-left"></i></div>',
        nextArrow: '<div class="icon-button tertiary slick-next"><i class="icon-angle-right"></i></div>',
      },
    }
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'

.about
  position relative
  width 100%
  padding 5rem 3rem 5rem
  overflow hidden
  +over(xl)
    max-width 1076px
    margin 0 auto
  +over(lg)
    &:before
      content ''
      position absolute
      width 200px
      height 180px
      top -40px
      right 64px
      background-image url('~images/animation/img-0.0.1.svg')
      background-repeat no-repeat
      background-position center center
      background-size contain
  +under(lg)
    padding 2rem 1rem 11rem 1rem
  .layout
    position relative
    width 100%
    border-radius 21px
    box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
    background-color #fff
    +over(lg)
      height 674px
    &:before
      content ''
      position absolute
      width 163px
      height 170px
      top calc(100% - 28px)
      left 0
      background-image url('~images/animation/img-0.3.2.svg')
      background-repeat no-repeat
      background-position center center
      background-size contain
      +under(lg)
        top calc(100% - 58px)
.ug-slider
  width 100%
  height 100%
  display flex
  justify-content center
  align-items center
  background-color #fff
  border-radius 21px
  /deep/ .slick-arrow
    margin 0 3rem
    flex-shrink 0
    font-size 26px
    +under(lg)
      margin 0 1rem
    &.slick-disabled
      opacity 0
      pointer-events none
  /deep/ .slick-list
    flex 1 1 auto
  /deep/ ul.slick-dots
    position absolute
    width 100%
    left 0
    bottom 28px
.slider-box
  width 100%
  padding 1rem
  +under(lg)
    padding 2rem 0 4.5rem 0
  > div
    margin auto
    max-width 628px
  .top
    font-weight bold
    color #505050
    margin-bottom 5rem
    +over(lg)
      display flex
      justify-content center
      align-items flex-start
    +under(lg)
      margin-bottom 2rem
      text-align center
    .logo
      width 98px
      flex-shrink 0
      margin-right 5rem
      +under(lg)
        width 56px
        margin 0 auto .5rem
    .company
      font-size 3.7rem
      line-height 1.26
      margin-bottom .5rem
      +under(lg)
        font-size 2.07rem
        margin-bottom .5rem
    .slug
      font-size 1.728rem
      line-height 1.28
      +under(lg)
        font-size 1rem
  .trophy-box
    +over(lg)
      display flex
      justify-content center
      align-items center
    +under(lg)
      text-align center
    + .trophy-box
        margin-top 2rem
    .pic
      width 185px
      margin-right 2rem
      +under(lg)
        width 172px
        margin 0 auto 1.5rem
    .word
      font-weight bold
    .chinese
      font-size 1.44rem
      +under(lg)
        font-size 1rem
    .eng
      font-size 1.2rem
      margin-top .5rem
      +under(lg)
        font-size 12px
  .broker-pic
    width 84%
    margin auto
    img
      width 100%
.timeline
  +over(lg)
    padding-left 168px
  +under(lg)
    max-width 360px
    margin auto
  .line
    display flex
    align-items center
    padding-left 54px
    margin-bottom 2rem
    +under(lg)
      padding-top 25px
    &:last-child .dot:after
      display none
  .dot
    position relative
    width 12px
    height 12px
    background-color #FF895A
    border 2px solid #ddd
    border-radius 50%
    margin-right 1.5rem
    +under(lg)
      margin-right 1rem
    &:before
      content attr(data-year)
      position absolute
      font-weight bold
      font-size 1.2rem
      +over(lg)
        right calc(100% + 14px)
        top -5px
      +under(lg)
        top -25px
        left -14px
        font-size 1rem
    &:after
      content ''
      position relative
      top calc(100% + 4px)
      left 2px
      width 1px
      height 30px
      display block
      border 2px dashed #ddd
  .text
    font-size 1rem
</style>
