<template>
  <UiModal ref="modal" :dataOptions="{closable: false, allowMultiple: true}" modalClass="tiny">
    <template #modal>
      <div class="header">
        績效期間選取 (最多 6 個)
      </div>
      <div class="content">
        <div v-for="option in displayOptions" :key="option.name" class="option" :class="{active: isActive(option.name) }" @click="toggleClick(option.name)">
          <UiCheckbox :ref="option.name" v-model="pickedOptions" :value="option.name" :disabled="isDisabled(option.name)">
            {{ option.title }}
          </UiCheckbox>
        </div>
      </div>
      <div class="actions">
        <div class="ui button fluid medium major" :class="{disabled : isOptionEmpty}" @click="submit">
          確認 ({{ pickedOptions.length }})
        </div>
      </div>
    </template>
  </UiModal>
</template>

<script>
import { ModalImplement } from 'modules/ui/modal'
import UiCheckbox from 'modules/ui/form/checkbox'

export default {
  components: {
    UiCheckbox,
  },
  mixins: [ModalImplement],
  props: {
    displayOptions: Array,
    pickedOptions: Array,
  },
  computed: {
    isOptionEmpty () {
      return this.pickedOptions.length === 0
    }
  },
  methods: {
    toggleClick (name) {
      const target = this.$refs[`${name}`][0]
      if (!target.disabled) target.onClick()
    },
    isActive (name) {
      return this.pickedOptions.includes(name)
    },
    isDisabled (name) {
      return this.pickedOptions.length >= 6 && !this.isActive(name)
    },
    submit () {
      this.$emit('update:pickedOptions', this.pickedOptions)
      this.close()
    }
  },
}
</script>

<style lang="stylus" scoped>
.ui.modal > .content
  padding 0
.option
  padding 0.85em 1.5em
  &.active, &:hover
    background-color #FEF9F4
  &:hover
    cursor pointer
</style>
