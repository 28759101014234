export default function getScrollParent(node) {
  if (node == null || !(node instanceof Element)) {
    return null
  }
  if (['auto', 'scroll'].includes(window.getComputedStyle(node).overflowY)) {
    if (node === document.body) {
      return window
    } else {
      return node
    }
  } else {
    return getScrollParent(node.parentNode)
  }
}