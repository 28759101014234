import Mark from 'mark.js'

export default {
  install(Vue, options) {
    Vue.directive('mark', {
      bind(el, binding) {
        const keywords = binding.value
        el.__marker = new Mark(el)
        keywords && el.__marker.mark(keywords)
      },
      update(el, binding) {
        const keywords = binding.value
        el.__marker.unmark()
        keywords && el.__marker.mark(keywords)
      },
      unbind(el) {
        el.__marker.unmark()
      }
    })
    Vue.directive('highlight', Vue.options.directives.mark)
  }
}
