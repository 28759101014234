// Modal
let errorMessages = []
let errorMessagesVisible = false
let nextError = null

function error(message, title = '系統錯誤') {
  const entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    '\'': '&#39;',
    '/': '&#x2F;',
    '`': '&#x60;',
    '=': '&#x3D;'
  }

  function escapeHtml (string) {
    return String(string).replace(/[&<>"'`=/]/g, function (s) {
      return entityMap[s]
    })
  }

  function show() {
    if (errorMessagesVisible) {
      return false
    }
    if (!nextError) {
      return false
    }
    let { message, title } = nextError
    let messageHTML = ''
    let messageDoc = ''
    let style = ''
    let content = ''

    nextError = null

    if (typeof message === 'string' && message.indexOf('<!DOCTYPE html>') === 0) {
      messageDoc = message
    } else if (message instanceof Object && message.status !== undefined && message.body !== undefined) {
      if (message.body.message) {
        title = `${message.status} ${message.statusText}`
        message = message.body.message
      } else if (message.status) {
        title = `${message.status} ${message.statusText}`
        messageHTML = message.body
      } else if (!navigator.onLine) {
        title = '無法連線'
        message = '請確認你的網路連線'
      }
    } else if (message instanceof Object) {
      message = JSON.stringify(message, null, 2)
    }

    if (messageHTML) {
      style = 'font-size: 14px; color: #666'
    } else {
      style = 'white-space: pre-line;'
    }

    if (messageDoc) {
      content = `
        <iframe srcdoc="${escapeHtml(messageDoc)}" style="width:100%; height: 400px; border:none; "/>
      `
    } else if (messageHTML || message) {
      content = `
        <div class="ui small image" style="flex-shrink:0">
          <i class="red times circle icon"></i>
        </div>
        <div class="description f12" style="${style}">
          ${messageDoc ? `<iframe srcdoc="${escapeHtml(messageDoc)}" />` : (messageHTML || escapeHtml(message))}
        </div>
      `
    }

    if (content) {
      jQuery(`
        <div class="ui modal error">
          <i class="close icon"></i>
          <div class="header">
            ${title}
          </div>
          <div class="scrolling image content">
            ${content}
          </div>
          <div class="actions">
            <div class="ui button minor deny">
              全部略過
            </div>
            <div class="ui button major approve">
              繼續
            </div>
            <!--
            <div class="ui positive right labeled icon button">
              Yep, that's me
              <i class="checkmark icon"></i>
            </div>
            -->
          </div>
        </div>
      `.trim()).modal({
        observeChanges: true,
        closable: false,
        allowMultiple: true,
        onApprove: () => {
          nextError = errorMessages.pop()
        },
        onDeny: () => {
          nextError = null
          errorMessages = []
        },
        onShow: () => {
          errorMessagesVisible = true
        },
        onHidden() {
          errorMessagesVisible = false
          show()
        },
      }).modal('show')
    }
  }

  errorMessages.push({ message, title })

  if (!errorMessagesVisible) {
    nextError = errorMessages.pop()
    show()
  }
}

if (process.env.NODE_ENV === 'development') {
  window._console_error_ = console.error
  window.error = error
  window.onerror = function(msg, url, line, col, err) {
    error('Error: ' + msg + '\nurl: ' + url + '\nline: ' + line, '真糟糕系統當掉了')
    window._console_error_('Error: ' + msg + '\nurl: ' + url + ':' + line)
  }
  console.error = function(err) {
    if (String(err).indexOf('[Vue warn]') >= 0) {
      window._console_error_.apply(this, arguments)
      return
    }
    error(Array.prototype.slice.call(arguments).join('\n'))
    window._console_error_.apply(this, arguments)
  }
}

export default error
