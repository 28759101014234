/**
  <template>
    <div class="d3-chart-core">
      <svg height="400" ref="svg" v-once width="800" />
    </div>
  </template>
*/

export default {
  data() {
    return {
      config: {
        margin: { top: 20, right: 10, bottom: 30, left: 50 },
        colors: [
          //
          '#FF895A',
          '#82CA82',
          '#76A3FC',
          '#F89FC1',
          '#91A5AE',
          '#F2D206',
          '#46B4B4',
          '#B3A6EB',
          '#E77B7B',
          '#CAA0A0',
          '#B5A981',
          '#6D8E5B',
          '#86604C',
          '#D0D0D0',
        ],
        format: {
          tick: {
            x: (val, index) => val,
            y: d3.format('.0%'),
          },
        },
        ticks: {
          y: 5,
        },
        valueOf: {
          x: (v) => v.x,
          y: (v) => v.y,
        },
      },
      context: {
        svg: null,
        graph: null,
        chart: null,
      },
      canvas: {
        width: 0,
        height: 0,
      },
      chart: {
        x: null,
        y: null,
        z: null,
        scaled: {
          x: null,
          y: null,
        },
      },
    }
  },
  computed: {
    maxY() {
      return this.rawdata && Math.max(...this.rawdata.map((d) => this.config.valueOf.y(d)))
    },
    minY() {
      return this.rawdata && Math.min(...this.rawdata.map((d) => this.config.valueOf.y(d)))
    },
    maxX() {
      return this.rawdata && Math.max(...this.rawdata.map((d) => this.config.valueOf.x(d)))
    },
    minX() {
      return this.rawdata && Math.min(...this.rawdata.map((d) => this.config.valueOf.x(d)))
    },
    styleMargin() {
      return {
        marginTop: `${this.config.margin.top}px`,
        marginRight: `${this.config.margin.right}px`,
        marginBottom: `${this.config.margin.bottom}px`,
        marginLeft: `${this.config.margin.left}px`,
        height: `${this.canvas.height}px`,
        weight: `${this.canvas.weight}px`,
      }
    }
  },
  methods: {
    prepare() {
      // 在這裡設定X, Y軸資料範圍
      // this.chart.x = d3.scaleTime()
      // this.chart.x.domain()
      // this.chart.y = d3.scaleLinear()
      // this.chart.y.domain([0, 1])
      throw new Error('prepare 方法尚未實作，尚未定義座標軸')
    },
    init() {
      this.context.svg = d3.select(this.$refs.svg)
      this.context.svg.selectAll('g').remove()
      this.context.graph = this.context.svg.append('g').attr('transform', 'translate(' + this.config.margin.left + ',' + this.config.margin.top + ')')
    },
    render() {
      if (!this.$refs.svg || !this.context?.graph) {
        return
      }

      this.setSizes()

      this.context.graph.selectAll('*').remove()
      this.drawBoundary()
      this.drawTicks()
      this.drawContext()
      this.attachEvents()
    },
    setSizes() {
      this.canvas.width = (parseFloat(window.getComputedStyle(this.context.svg.node()).width) || this.context.svg.attr('width')) - this.config.margin.left - this.config.margin.right
      this.canvas.height = (parseFloat(window.getComputedStyle(this.context.svg.node()).height) || this.context.svg.attr('height')) - this.config.margin.top - this.config.margin.bottom
      this.setScale()
    },
    setScale() {
      this.chart.scaled.x = this.chart.x.copy().rangeRound([0, this.canvas.width])
      this.chart.scaled.y = this.chart.y.copy().range([this.canvas.height, 0])
    },
    getColor(d) {
      return '#DDDDDD'
    },
    drawTicks() {
      // Horizontal Grid lines
      this.context.graph.append('g').attr('class', 'grid-horizontal').call(d3.axisLeft(this.chart.scaled.y).ticks(this.config.ticks.y).tickFormat('').tickSize(-this.canvas.width))

      // Zero
      this.context.graph.select('.grid-horizontal')
        .append('line')
        .attrs({
          'x1': 0,
          'y1': Math.round(this.chart.scaled.y(0)),
          'x2': this.canvas.width,
          'y2': Math.round(this.chart.scaled.y(0)),
          'shape-rendering': 'crispEdges',
          'stroke': '#999',
          'stroke-width': 1,
        })

      // prettier-ignore
      this.context.graph
        .append('g')
        .attr('class', 'axis-y')
        .call(
          d3.axisLeft(this.chart.scaled.y).tickFormat(this.config.format.tick.y).ticks(this.config.ticks.y)
        )
      // prettier-ignore
      this.context.graph
        .append('g')
        .attr('class', 'axis-x')
        .attr('transform', 'translate(0,' + this.canvas.height + ')')
        .call(
          d3.axisBottom(this.chart.scaled.x).tickFormat(this.config.format.tick.x).ticks(this.config.ticks.x)
        )
    },
    drawBoundary() {
      this.context.graph.append('rect')
        .attr('class', 'boundary')
        .attr('width', this.canvas.width)
        .attr('height', this.canvas.height)
        .attr('x', (d) => 0)
        .attr('y', (d) => 0)
    },
    drawContext() {
      this.context.chart = this.context.graph.append('g').attr('class', 'chart')
      // this.rawdata && this.context.chart
      //   .selectAll('entry-line')
      //   .data(this.rawdata)
      //   .enter()
      //   .append('rect')
      //   .style('fill', this.getColor)
      //   .attr('width', (d) => this.chart.scaled.x.bandwidth())
      //   .attr('height', (d) => this.canvas.height - this.chart.scaled.y(this.config.valueOf.y(d)))
      //   .attr('x', (d) => this.chart.scaled.x(this.config.valueOf.x(d)))
      //   .attr('y', (d) => this.chart.scaled.y(this.config.valueOf.y(d)))
    },
    attachEvents() {

    },
    findClosestPointByX(mouseX, points) {
      let mouseExactValue

      if (typeof this.chart.scaled.x.bandwidth !== 'undefined') {
        // For scaleBand (doesn't have invert)
        const eachBand = this.chart.scaled.x.step()
        const index = Math.min(Math.round((mouseX / eachBand)) - 1, this.chart.scaled.x.domain().length - 1)
        mouseExactValue = this.chart.scaled.x.domain()[index]
      } else {
        mouseExactValue = this.chart.scaled.x.invert(mouseX)
      }

      return points.reduce((prev, curr) =>
        (Math.abs(this.config.valueOf.x(curr) - mouseExactValue) < Math.abs(this.config.valueOf.x(prev) - mouseExactValue) ? curr : prev)
      )
    },
  },
}
