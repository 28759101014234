import Tabs from 'modules/ui/tabs'
import Pagination from 'modules/ui/pagination'
import Checkbox from 'modules/ui/form/checkbox'
import Dropdown from 'modules/ui/form/dropdown'
import Input from 'modules/ui/form/input'
import Pikaday from 'modules/ui/form/pikaday'
import Calendar from 'modules/ui/form/calendar'
import Datetime from 'modules/ui/form/datetime'
import Attachment from 'modules/ui/form/attachment'
import Field from 'modules/ui/form/field'
import TinyMCE from 'modules/components/TinyMCE.vue'

import Draggable from 'vuedraggable'
import DraggableTbody from './DraggableTbody'

import Categories from 'modules/admin/content/field/categories.vue'
import Images from 'modules/admin/content/field/images.vue'

import Loader from 'modules/ui/loader'
import EmptyPlaceholder from 'modules/ui/placeholder/empty'

export default {
  Tabs,
  Pagination,
  Checkbox,
  Dropdown,
  Input,
  Pikaday,
  Calendar,
  Datetime,
  Attachment,
  Field,
  Images,
  TinyMCE,
  Draggable,
  DraggableTbody,
  Categories,
  Loader,
  EmptyPlaceholder,
}
