<script>
import Decimal from 'decimal.js'

export default {
  model: {
    event: 'input',
  },
  props: {
    placeholder: [String, Number],
    value: [String, Number],
    incrementer: {
      type: Boolean,
      default: null
    },
    size: {
      type: String,
      default: 'medium',
    },
    min: {
      type: [String, Number],
      default() {
        if (this.type === 'percent') {
          return 0
        }
        if (this.type === 'currency') {
          return 0
        }
      },
    },
    max: {
      type: Number,
      default() {
        if (this.type === 'percent') {
          return 100
        }
      },
    },
    maxlength: {
      type: [Number, String],
      default: null,
    },
    prefix: String,
    places: Number,
    autoselect: Boolean,
    autofocus: Boolean,
    autocomplete: String,
    disabled: Boolean,
    readonly: Boolean,
    step: {
      type: Number,
      default: 1,
    },
    type: {
      // text, number, currency, percentage, email, password
      type: [String, Number],
      default: 'text',
    },
    error: String,
  },
  data: () => ({
    currentValue: null,
    internalValue: null,
    current: {
      focus: false,
      peekPassword: false,
    },
  }),
  computed: {
    // https://ithelp.ithome.com.tw/articles/10209183
    inputListeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        compositionend: this.onInput,
        focus: this.onFocus,
        blur: this.onBlur,
        change: this.onChange,
        keypress: this.keypressFilter,
      }
    },
    classes() {
      return {
        [`type--${this.type}`]: true,
        [`size--${this.size}`]: true,
        'disabled': this.disabled,
        'is-focus': this.current.focus,
        'has-error': this.error,
      }
    },
    adjustedValue() {
      return this.adjust(this.internalValue)
    },
    formatedValue() {
      return this.format(this.internalValue)
    },
    parsedNumber() {
      return +this.internalValue.trim().replace(/\,/g, '')
    },
    inputType() {
      if (this.type === 'password' && this.current.peekPassword) {
        return 'text'
      }
      if (this.isNativeType) {
        return this.type
      }

      return 'text'
    },
    isNativeType() {
      return ['email', 'password'].includes(this.type)
    },
    isPlainType() {
      return ['text', 'email', 'password'].includes(this.type)
    },
    valid() {
      if (this.isNativeType && this.internalValue) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(this.internalValue)
      }
      return true
    },
    hasIncrementer() {
      if (this.incrementer === false) {
        return false
      }
      if (['number', 'percent'].includes(this.type)) {
        return true
      }
      if (this.incrementer && ['currency'].includes(this.type)) {
        return true
      }
      return false
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.internalValue = value
        // this.currentValue = value
      },
    },
    internalValue() {
      this.$emit('input', this.adjustedValue)
    },
    currentValue() {
      this.$emit('change', this.adjustedValue)
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.input.focus()
    }
  },
  methods: {
    adjust(value) {
      if (this.isPlainType) {
        return value
      }
      // For numbers
      value = String(value)
        .trim()
        .replace(/\[,]/, '')
      if (['number', 'percent', 'currency'].includes(this.type)) {
        value = Number.parseFloat(value)
      }
      if (Number.isNaN(value)) {
        return ''
      }
      if (this.max != null && value > +this.max) {
        return +this.max
      }
      if (this.min != null && value < +this.min) {
        return +this.min
      }
      return value
    },
    format(value) {
      if (['number', 'percent'].includes(this.type)) {
        if (isNaN(+value) || value === null || value === undefined || value === '') {
          value = ''
        } else {
          value = (+value).toFixed(+this.places)
        }
      }
      if (['currency'].includes(this.type)) {
        value = (+value)
          .toFixed(+this.places)
          .toString()
          .replace(/\B(?=(\d{3})+\b)/g, ',')
      }
      return value
    },
    // 暫時停用： IE11 情況下，如果onInput先更動了internalValue，它會寫入input，導致IE誤判為沒有變動，而沒觸發onChange
    onInput(event) {
      if (event && event.inputType === 'insertCompositionText') {
        return
      }
      if (['percent'].includes(this.type) || this.places > 0) {
        // 百分比輸入框 間接更動數值，否則會變NaN
        return
      }
      if (['currency'].includes(this.type)) {
        this.internalValue = +this.$refs.input.value.trim().replace(/\,/g, '')
      } else {
        this.internalValue = this.$refs.input.value
      }
    },
    onChange() {
      if (['currency'].includes(this.type)) {
        this.internalValue = this.adjust(+this.$refs.input.value.trim().replace(/\,/g, ''))
      } else {
        this.internalValue = this.adjust(this.$refs.input.value.trim())
      }
      this.currentValue = this.internalValue
      // BUGGY! DO not proxy on Change event due to different arguments
      // this.$listeners.change && this.$listeners.change.apply(this, arguments)
    },
    onBlur() {
      this.current.focus = false
      this.currentValue = this.internalValue
      this.$listeners.blur && this.$listeners.blur.apply(this, arguments)
    },
    onFocus() {
      this.currentValue = this.internalValue
      this.current.focus = true
      this.$nextTick(() => {
        if (this.autoselect) {
          this.$refs.input.setSelectionRange(0, this.$refs.input.value.length)
          this.$refs.input.select()
        }
        this.$listeners.focus && this.$listeners.focus.apply(this, arguments)
      })
    },
    keypressFilter(event) {
      if (this.isPlainType) {
        return
      }
      if (event.key === '-') {
        if (this.$refs.input.value.indexOf('-') === -1) {
          this.$refs.input.value = `-${this.$refs.input.value}`
        }
        return event.preventDefault()
      }
      if (event.key === '+') {
        if (this.$refs.input.value.indexOf('-') === 0) {
          this.$refs.input.value = this.$refs.input.value.replace(/^-/, '')
        }
        return event.preventDefault()
      }
      if (event.key === '.') {
        if (this.$refs.input.value.indexOf('.') === -1) {
          return
        }
        return event.preventDefault()
      }
      if (event.keyCode === 13) {
        this.onChange()
        return event.preventDefault()
      }
      if (event.which < 48 || event.which > 57) {
        return event.preventDefault()
      }
      this.$listeners.keypress && this.$listeners.keypress.apply(this, arguments)
    },
    increase(value) {
      this.currentValue = this.internalValue = this.adjust(+Decimal(this.adjustedValue).add(value))
    },
    focus() {
      this.$refs.input.focus()
    },
    click(event) {
      if (event.target === this.$refs.input) {
        return
      }
      this.$refs.input.click()
    },
    blur() {
      this.$refs.input.blur()
    },
  },
}
</script>

<template>
  <span :class="classes" class="form-input" @click="click">
    <span class="form-input__wrap">
      <div class="form-input__wrap-input">
        <span v-if="prefix" class="form-input__before" v-text="prefix" />
        <input ref="input" :type="inputType" :value="formatedValue" v-bind="{placeholder, autofocus, autocomplete, readonly, maxlength}" v-on="inputListeners">
        <span v-if="type == 'password'" class="form-input__after">
          <div v-if="current.peekPassword" class="action icon-eye-open" @click="current.peekPassword = false" />
          <div v-if="!current.peekPassword" class="action icon-eye-close" @click="current.peekPassword = true" />
        </span>
        <span v-if="$slots.after" class="form-input__after">
          <slot name="after">
            <span v-if="['percent'].includes(type)">%</span>
          </slot>
        </span>
      </div>
      <div v-if="hasIncrementer" class="number-adjustment">
        <span class="decrease" @click="increase(+step)">
          <i class="icon-up-dir" />
        </span>
        <span class="increase" @click="increase(-step)">
          <i class="icon-down-dir" />
        </span>
      </div>
      <div v-if="$slots.action" class="form-input__action">
        <slot name="action" />
      </div>
    </span>
    <p v-if="error" class="form-input__error">
      <span v-text="error" />
      <slot name="error-after" />
    </p>
  </span>
</template>

<style lang="less" scoped>

.border-color(@color) {
  &,
  & *,
  & ~ *,
  & ~ * * {
    border-color: @color !important;
  }
}

.form-input {
  position: relative;
  display: inline-block;
  border-color: #dddddd;

  &.fluid {
    display: block;
    width: 100%;
  }
  &__wrap {
    display: flex;
    border-radius: 0.25em;
    background: white;
    align-items: center;
  }
  &__wrap-input {
    flex-grow: 1;
    display: flex;
    height: 100%;
    align-items: center;
    border: 1px solid;
    border-radius: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background: #fbfbfb;
    .border-color(#dddddd);
    input {
      width: 100% !important;
    }
  }
  &__before {
    user-select: none;
    text-align: center;
    color: #666;
    margin-right: 1rem;
  }
  &__after {
    user-select: none;
    margin-left: 1rem;
    text-align: center;
  }
  &__action {
    margin-left: 1em;
  }
  &.type--number input,
  &.type--percent input {
    text-align: right;
    min-width: 3.5em;
  }
  &.type--currency input {
    text-align: right;
    min-width: 80px;
  }
  &.is-focus {
    .border-color(#aaaaaa);
  }
  input {
    background: transparent;
    border: none !important;
    padding: 0 !important;
    width: 100%;
    border: none;
    border-radius: 0.25em;
    color: #666;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #aaaaaa;
    }
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .number-adjustment {
    line-height: 1;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: -1px;
    border: 1px solid;
    border-top-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
    color: #aaaaaa;
    display: flex;
    flex-direction: column;
    width: 21px;
    height: 100%;
    text-align: center;
    > * {
      border-bottom: 1px solid;
      margin-bottom: -1px;
      height: 50%;
      flex: 1;
      cursor: pointer;
    }
  }
  &.has-error, .has-error & {
    .form-input__wrap-input {
      &,
      ~ *,
      ~ * * {
        border-color: #ed4758 !important;
      }
      color: #ed4758 !important;
    }
  }
  &__error {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.8;
    margin-top: 0.5em;
    color: #ed4758 !important;
  }
}
</style>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'

.action
  cursor pointer
.form-input
  &.size--
    &small
      $fontSize = 12px
      .form-input__wrap
        height 28px
      input
        font-size $fontSize
    &medium
      $fontSize = 14px
      .form-input__wrap
        height 36px
      input
        font-size $fontSize
    &large
      $fontSize = 16.8px
      .form-input__wrap
        height 42px
      input
        font-size $fontSize
  .number-adjustment
    @media $mobile
      display none
    > *:hover
      color $major
</style>

<!--Data Entry Styles for other input-liked components -->
<style lang="stylus">
.ui.selection.dropdown
.ui.form input[type=text]
.ui.input>input
.ui.input>input:active
.ui.input>input:focus
.v-pikaday input
  font-family inherit
  background #FBFBFB
  color #666

.ui.input.focus>input
.ui.input>input
.ui.selection.dropdown
.ui.selection.active.dropdown
.ui.selection.active.dropdown:hover
.ui.selection.active.dropdown .menu
.ui.selection.active.dropdown:hover .menu
.ui.form input:not([type])
.ui.form input[type=date]
.ui.form input[type=datetime-local]
.ui.form input[type=email]
.ui.form input[type=file]
.ui.form input[type=number]
.ui.form input[type=password]
.ui.form input[type=search]
.ui.form input[type=tel]
.ui.form input[type=text]
.ui.form input[type=time]
.ui.form input[type=url]
  &
  &:focus
    border-color #DDDDDD
    background #FBFBFB

.ui.form .field.field input
  &:-webkit-autofill
  &:-webkit-autofill:focus
    -webkit-box-shadow 0 0 0 30px #FBFBFB inset !important
</style>
