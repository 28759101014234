/* Usage Example

let commit = await confirm({
  title: '警告',
  message: '無法使用此功能',
  buttonText: '朕知道了',
  buttonClass: 'success'
})

*/

const internalAlert = window.alert

async function modalAlert(args) {
  let {
    //
    message,
    title,
    size = 'medium',
    type = 'warning',
    callback,
    buttonText,
    buttonClass,
  } = args

  const titleHTML = title ? `<div class="modal-message__title">${title}</div>` : ''
  const simple = !title && String(message).split(/\n/).length < 2 ? 'modal-message--simple' : ''

  if (!message.match(/<\/(div|span|p|table)|<br/)) {
    message = message.replace(/\n+/g, '<br>')
  }

  await new Promise(function(resolve, reject) {
    const $modal = jQuery(
      `
      <div class="ui modal modal-message modal-message--alert ${simple} type-${type} component-modal size--${size}">
        ${titleHTML}
        <div class="scrolling image content">
          <div class="description">
            ${message}
          </div>
        </div>
        <div class="actions">
          <div class="ui button major fluid approve ${type} ${buttonClass}">
            ${buttonText || '確定'}
          </div>
        </div>
      </div>
    `.trim()
    )
      .modal({
        observeChanges: true,
        onHidden: () => {
          $modal.remove()
          callback && callback()
          resolve()
        },
        allowMultiple: true,
        closable: false,
      })
      .modal('show')
  })
}

window.internalAlert = internalAlert
window.alert = async function(args) {
  if (typeof args == 'object') {
    return await modalAlert(args)
  } else if (arguments.length > 1) {
    return await modalAlert({ message: arguments[0], title: arguments[1], callback: arguments[2] })
  } else {
    return await modalAlert({ message: args, callback: arguments[2] })
  }
}

export default modalAlert
