<template>
  <div>
    <template v-if="fundIds.length">
      <div v-if="$deviceView.desktop" class="strategy-table" :class="tableClasses">
        <div class="table-head">
          <div class="main-space">
            <div class="row">
              <div class="grid subject" />
              <div v-for="fundId in fundIds" :key="fundId" class="grid fund">
                <span class="color-ball" :style="{backgroundColor: fundColors[fundId]}" />
                <span>{{ raw.features[fundId] && raw.features[fundId].name }}</span>
              </div>
            </div>
          </div>
          <div v-if="fundIds.length < 2" class="empty-space" />
        </div>
        <div class="table-content">
          <div class="main-space">
            <section>
              <div v-for="topicName in firstBlock" :key="topicName" class="row">
                <div class="grid subject">
                  {{ topicName }}
                </div>
                <div v-for="fundId in fundIds" :key="fundId" class="grid fund">
                  <span>{{ getFieldValue({fundId, topicName}) }}%</span>
                  <span class="price-ccy">
                    <i v-if="getPercentChanges({ fundId, topicName }) > 0" class="icon-up-dir color-positive" />
                    <i v-else-if="getPercentChanges({ fundId, topicName }) < 0" class="icon-down-dir color-negative" />
                    <span :class="getPercentChanges({ fundId, topicName }) > 0 ? 'color-positive' : 'color-negative'">
                      {{ Math.abs(getPercentChanges({ fundId, topicName })) | round(1) }}%
                    </span>
                  </span>
                </div>
              </div>
            </section>
            <div class="content-topic">
              策略特性
            </div>
            <section>
              <div v-for="topicName in secondBlock" :key="topicName" class="row">
                <div class="grid subject">
                  {{ topicName }}
                </div>
                <div v-for="fundId in fundIds" :key="fundId" class="grid fund">
                  {{ getFeatureValue({fundId, topicName}) }}
                </div>
              </div>
            </section>
          </div>
          <div v-if="fundIds.length < 2" class="empty-space">
            <div>
              <div class="ui button major large round btn-compare" @click="$emit('showModalPicker')">
                <i class="icon-more" style="margin-right:.5rem;" />比較其他基金
              </div>
              <div class="illustration">
                <img src="~images/img-illustration-10.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="remark text-minor">
          <span class="remark-item">資料時間：{{ sourceDate }}</span>
          <span class="remark-item">* 若與月報差異0.1%為進位差異，括號數字代表與上月差異數</span>
        </div>
      </div>
      <div v-else>
        <MobileList
          v-bind="{
            titles: firstBlock,
            fundIds,
            fundColors,
            percentChanges,
            raw,
            dataType: 'strategies',
          }"
          :dataProvider="{
            getFieldValue,
            getPercentChanges,
          }"
          @remove="removeFund"
        />
        <div class="m-list-divider">
          策略特性
        </div>
        <MobileList
          v-bind="{
            titles: secondBlock,
            fundIds,
            fundColors,
            raw,
            dataType: 'features',
          }"
          :dataProvider="{
            getFeatureValue,
          }"
          @remove="removeFund"
        />
        <div class="remark text-minor">
          <span class="remark-item">資料時間：{{ sourceDate }}</span>
          <span class="remark-item">* 若與月報差異0.1%為進位差異，括號數字代表與上月差異數</span>
        </div>
        <div v-if="fundIds.length < 2" class="empty-space">
          <div>
            <div class="ui button major large round btn-compare" @click="$emit('showModalPicker')">
              <i class="icon-more" style="margin-right:.5rem;" />比較其他基金
            </div>
            <div class="illustration">
              <img src="~images/img-illustration-10.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </template>

    <div v-else class="empty-container">
      <div class="empty-space">
        <div>
          <div class="ui button major large round btn-compare" @click="$emit('showModalPicker')">
            <i class="icon-more" style="margin-right:.5rem;" />比較其他基金
          </div>
          <div class="illustration">
            <img src="~images/img-illustration-10.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from 'api'
import MobileList from 'modules/components/compare/_strategyList'

export default {
  components: {
    MobileList,
  },
  props: {
    fundIds: {
      type: Array,
      default() {
        return []
      },
    },
    fundColors: Object,
  },
  data() {
    return {
      firstBlock: ['整體槓桿', '淨風險部位', '人民幣部位'],
      raw: {
        strategies: {},
        features: {},
      }
    }
  },
  computed: {
    tableClasses() {
      const length = this.fundIds.length
      if (length === 2) {
        return ['main-col-2']
      } else {
        return []
      }
    },
    secondBlock() {
      // 下方策略特性欄位名稱
      const target = this.fundIds[0]
      if (!target || !this.raw.features[target]) return []
      return Object.keys(this.raw.features[target].feature)
    },
    sourceDate() {
      const target = this.fundIds[0]
      return target && this.raw.features[target] ? this.raw.features[target].date : ''
    },
    percentChanges() {
      const obj = {}
      this.fundIds.forEach(id => {
        if (!this.raw.strategies[id]) return false
        const old = this.raw.strategies[id][0]
        const latest = this.raw.strategies[id][1]
        const changes = {}
        this.firstBlock.forEach(title => {
          if (title === '人民幣部位') {
            changes['人民幣部位'] = latest['幣別分佈']['人民幣'] - old['幣別分佈']['人民幣']
          } else {
            changes[title] = latest['總體'][title] - old['總體'][title]
          }
        })
        obj[id] = changes
      })
      return obj
    },
  },
  watch: {
    fundIds(now, past) {
      if (now.length > past.length && past.length !== 3) this.load()
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      for (const id of this.fundIds) {
        this.$set(this.raw.strategies, id, await API.fund.strategy({ id }).then(res => res.data.strategy))
        await API.fund({ id }).then(res => {
          const feature = {}
          res.data.entry.metadata.strategies.forEach(strategy => {
            feature[strategy.name] = strategy.value
          })
          const data = {
            feature,
            name: res.data.entry.name,
            date: res.data.entry.date,
          }
          this.$set(this.raw.features, id, data)
        })
      }
    },
    getFieldValue({ fundId, topicName }) {
      if (typeof this.raw.strategies[fundId] === 'undefined') {
        return ''
      }
      if (topicName === '人民幣部位') {
        return this.raw.strategies[fundId][1]['幣別分佈']['人民幣']
      } else {
        return this.raw.strategies[fundId][1]['總體'][topicName]
      }
    },
    getFeatureValue({ fundId, topicName }) {
      if (typeof this.raw.features[fundId] === 'undefined') {
        return ''
      }
      return this.raw.features[fundId].feature[topicName]
    },
    getPercentChanges({ fundId, topicName }) {
      if (typeof this.raw.strategies[fundId] === 'undefined') {
        return ''
      }
      return this.percentChanges[fundId][topicName]
    },
    removeFund(id) {
      this.$emit('remove', id)
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.strategy-table
  padding 0 1.5rem
  .row
    display flex
  .grid
    display flex
    justify-content center
    align-items center
    min-height 56px
    padding .5rem
    &.subject
      flex 0 0 200px
    &.fund
      flex 1 1 0
  .table-head
    border-bottom 1px solid #ddd
  .table-content
    .row
      border 1px solid #ddd
      border-top none
    .grid
      +.grid
        border-left 1px solid #ddd
      &.subject
        background-color #FBFBFB
    .empty-space
      border-right 1px solid #ddd
      border-bottom 1px solid #ddd
  .table-head, .table-content
    display flex
    width 100%
  .content-topic
    display flex
    justify-content center
    align-items center
    height 40px
    background-color #FBFBFB
    border 1px solid #ddd
    border-top none
  .color-ball
    width 14px
    height 14px
    border-radius 5px
    margin-right .5rem
    background-color #FF895A
  // 表格切割
  .empty-space
    flex 1 1 0
  .main-space
    flex 1 1 0
  &.main-col-2
    .main-space
      flex 2 1 0

.remark
  margin 1.5rem 0
  .remark-item + .remark-item
    margin 0 0 0 1rem
  +under(md)
    margin 1.5rem
    .remark-item
      display block
    .remark-item + .remark-item
      margin 1rem 0 0 0

.price-ccy
  margin-left .5rem
  &:before
    content "("
  &:after
    content ")"
// 正負
.color-positive
  color #ED4758
.color-negative
  color #00BF9C

// 空狀態
.empty-space
  display flex
  justify-content center
  align-items center
  text-align center
  +under(md)
    padding 2rem 0
    border-top 1px solid #ededed
  .btn-compare
    margin 0 auto 3rem
  .illustration
    margin auto
    width 62%
    img
      width 100%
.empty-container
  padding 3rem 1rem

// 手機板表格
.m-list-divider
  display flex
  align-items center
  justify-content center
  height 40px
  background-color #FBFBFB
  border-bottom 1px solid #ededed
</style>
