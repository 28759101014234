import { render, staticRenderFns } from "./checkbox.vue?vue&type=template&id=578e8f62&scoped=true&"
import script from "./checkbox.vue?vue&type=script&lang=js&"
export * from "./checkbox.vue?vue&type=script&lang=js&"
import style0 from "./checkbox.vue?vue&type=style&index=0&id=578e8f62&lang=less&scoped=true&"
import style1 from "./checkbox.vue?vue&type=style&index=1&id=578e8f62&lang=stylus&scoped=true&"
import style2 from "./checkbox.vue?vue&type=style&index=2&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "578e8f62",
  null
  
)

export default component.exports