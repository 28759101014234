<template>
  <div>
    <div class="basic-form">
      <div class="full-title">
        基本資料
      </div>
      <div class="row">
        <div class="title">
          基金成立日
        </div>
        <div class="content">
          {{ fund.launch_date }}
        </div>
      </div>
      <div class="row">
        <div class="title">
          計價幣別
        </div>
        <div class="content">
          {{ fund.price_ccy | currencyName }}
          {{ fund.price_ccy }}
        </div>
      </div>
      <div class="row">
        <div class="title">
          保管銀行
        </div>
        <div class="content">
          {{ fund.custodian_bank }}
        </div>
      </div>
      <div class="row">
        <div class="title">
          審計師
        </div>
        <div class="content">
          {{ fund.auditor }}
        </div>
      </div>
      <div class="full-title">
        交易資訊
      </div>
      <div class="row">
        <div class="title">
          最低投資金額
        </div>
        <div class="content">
          {{ fund.initial_amount }}
        </div>
      </div>
      <div class="row">
        <div class="title">
          管理費
        </div>
        <div class="content">
          {{ fund.manage_fee }}
        </div>
      </div>
      <div class="row">
        <div class="title">
          手續費
        </div>
        <div class="content">
          {{ fund.handling_fee }}
        </div>
      </div>
      <div class="row">
        <div class="title">
          保管費
        </div>
        <div class="content">
          {{ fund.custodian_fee }}
        </div>
      </div>
      <div class="row">
        <div class="title">
          銷售費
        </div>
        <div class="content">
          {{ fund.sales_fee }}
        </div>
      </div>
      <div class="row">
        <div class="title">
          績效費
        </div>
        <div class="content">
          {{ fund.performance_fee }}
        </div>
      </div>
      <div class="row">
        <div class="title">
          交易日
        </div>
        <div class="content">
          {{ fund.trade_day }}
        </div>
      </div>
      <div class="row">
        <div class="title">
          贖回付款期間
        </div>
        <div class="content">
          {{ fund.redemption_period }}
        </div>
      </div>
    </div>
    <div class="text-minor" style="margin-top: 1.5rem;">
      <div>** 其他基金費用如行政費、審計費以及風險因素...等，請詳閱基金公開說明書。</div>
      <div>** 本報告之內容資料僅供內部教育訓練之用，UG恕不就其正確、及時與完整性承擔任何保證責任，本報告亦不得被視為對本基金或其他金融工具進行投資的建議或要約。若有未竟事宜，概以基金英文公開說明書及HSBC每月價值報告內容為準。基金價值及收益可漲可跌，本基金過去績效不代表未來表現，亦不保證基金之最低投資收益。</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fund: Object,
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/components/fund/noteform.styl'
</style>
