import { Columns, createLinkColumn } from 'modules/admin/common/column'

const sortable = true

export default [
  { name: '排序', style: 'width:60px', column: 'ordering', template: Columns.Sorter },
  {
    name: '縮圖',
    style: 'width: 100px',
    column: 'cover_tiny_url',
    template: createLinkColumn('AdminFundEdit', model => ({ id: model.code }), Columns.Thumbnail)
  },
  {
    name: '基金名稱',
    column: 'name',
    template: createLinkColumn('AdminFundEdit', model => ({ id: model.code })),
  },
  {
    name: '最新報告',
    column: (model) => model?.latest_report?.date || '無',
    template: createLinkColumn('AdminFundReports', ({ code: fundId }) => ({ fundId }), Columns.Default),
  },
  { name: '最新淨值', column: 'price_date', sortable, template: Columns.Date },
  { name: '最新資產規模', column: 'aum_date', sortable, template: Columns.Date },
  { name: '顯示', column: 'active', template: Columns.Switch },
]
