<template>
  <UiModal ref="modal" :visible.sync="visible" size="medium" :modalClass="'confirm-modal'">
    <template #modal="modal">
      <div class="content">
        <div class="msg">
          <div class="main-text">
            密碼已變更
          </div>
          <div v-if="$member">
            下次請使用新密碼登入
          </div>
          <div v-else>
            請使用新密碼登入
          </div>
        </div>
      </div>
      <div class="actions">
        <router-link :to="{name: 'Partner'}" class="ui button major full" @click="modal.close">
          繼續
        </router-link>
      </div>
    </template>
  </UiModal>
</template>

<script>
import { ModalImplement } from 'modules/ui/modal'

export default {
  mixins: [
    ModalImplement
  ],
  props: {
    visible: Boolean,
  },
}
</script>

<style lang="stylus" scoped>
.confirm-modal
  .msg
    text-align center
    .main-text
      font-size 1.44rem
      font-weight bold
      margin-bottom .5rem
</style>
