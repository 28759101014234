<template>
  <div>
    <div class="card-setting">
      <div v-for="fund in funds" :key="fund.id" class="card-wrap">
        <Card v-bind="{fund}" />
      </div>
    </div>
    <router-link :to="{name: 'Calendar'}" class="dealing-reminder">
      <div class="dealing-container">
        <div class="topic-side">
          <div class="img-hand">
            <img src="~images/img-illustration-9.png" alt="">
          </div>
          <div class="topic-title">
            交易日曆
          </div>
        </div>
        <div class="dealings">
          <div v-if="purchase" class="group">
            <div class="day-wrap">
              <div class="circle-box purchase" />
              <div class="day-title font-bold">
                認購
              </div>
            </div>
            <div class="day-wrap">
              <div class="circle-box text" :class="{past: purchase.notify < today}">
                <span>{{ formatDate(purchase.notify) }}</span>
              </div>
              <div class="day-title font-bold">
                通知截止期限
              </div>
            </div>
            <div class="day-wrap">
              <div class="circle-box text" :class="{past: purchase.final < today}">
                <span>{{ formatDate(purchase.final) }}</span>
              </div>
              <div class="day-title font-bold">
                最後到款日 & 認購交易日
              </div>
            </div>
          </div>
          <div v-if="redeem" class="group">
            <div class="day-wrap">
              <div class="circle-box redeem" />
              <div class="day-title font-bold">
                贖回
              </div>
            </div>
            <div class="day-wrap">
              <div class="circle-box text" :class="{past: redeem.notify < today}">
                <span>{{ formatDate(redeem.notify) }}</span>
              </div>
              <div class="day-title font-bold">
                通知截止期限
              </div>
            </div>
            <div class="day-wrap">
              <div class="circle-box text" :class="{past: redeem.final < today}">
                <span>{{ formatDate(redeem.final) }}</span>
              </div>
              <div class="day-title font-bold">
                贖回交易日
              </div>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import API from 'api'
import Card from 'modules/components/FundInfoCard'

export default {
  components: {
    Card,
  },
  data() {
    return {
      funds: '',
      incomingTrading: null,
    }
  },
  computed: {
    today() {
      return moment().format('YYYY-MM-DD')
    },
    purchase() {
      const obj = {}
      if (!this.incomingTrading) return null
      this.incomingTrading.tradings.forEach(trade => {
        if (trade.subject.includes('認購通知截止')) obj.notify = trade.date
        if (trade.subject.includes('最後到款日')) obj.final = trade.date
      })
      return obj
    },
    redeem() {
      const obj = {}
      if (!this.incomingTrading) return null
      this.incomingTrading.redeem.forEach(trade => {
        if (trade.subject.includes('贖回通知截止')) obj.notify = trade.date
        if (trade.subject.includes('贖回交易日')) obj.final = trade.date
      })
      return obj
    },
  },
  async mounted() {
    this.funds = await API.fund.list().then(res => res.data.entries).catch()
    this.loadTradingDate()
  },
  methods: {
    async loadTradingDate() {
      await API.calendar.incomingTrading().then(res => {
        this.incomingTrading = res.data
      })
    },
    formatDate(date) {
      return moment(date).format('MM/DD')
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.card-setting
  +over(md)
    display flex
    .card-wrap
      width calc((100% - 4rem)/3)
      margin-right 2rem
      &:nth-child(3n)
        margin-right 0
  +under(md)
    .card-wrap
      margin-bottom 1.5rem
.dealing-reminder
  display block
  background-color #F6F6F6
  border 1px solid #FBFBFB
  box-shadow 0px 1px 15px rgba(170, 170, 170, 0.3)
  border-radius 21px
  margin-top 2rem
  padding 3rem 3rem 3rem 0px
  +under(lg)
    padding 1.5rem 0
.dealing-container
  +over(lg)
    display flex
    align-items center
    justify-content space-between
  .topic-side
    display flex
    align-items center
    margin-right 1rem
    +under(lg)
      margin 0 0 1rem 0
    +under(md)
      justify-content center
      margin-bottom 1.5rem
  .topic-title
    font-size 1.44rem
    font-weight bold
  .dealings
    +over(md)
      display flex
      flex-grow 1
      flex-shrink 1
      flex-basis 0
      justify-content space-around
    +under(lg)
      padding 0 1rem
    .group
      display flex
      flex-grow 1
      flex-shrink 1
      flex-basis 0
      justify-content space-between
      max-width 315px
      +under(md)
        margin auto
        +.group
          margin-top 1.5rem
    .day-wrap
      text-align center
      max-width 86px
      position relative
      z-index 1
      &:not(:last-child)
        margin-right 10%
        .circle-box
          position relative
          &:before
            content ''
            position absolute
            width 86px
            max-width 86px
            border 1px dashed #08548E
            top 28px
            left 100%
    .day-title
      margin-top 1rem
    .circle-box
      width 56px
      height 56px
      box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
      border-radius 50%
      margin auto
      &.purchase
        background-image url('~images/img-circle-purchase.png')
      &.redeem
        background-image url('~images/img-circle-redeem.png')
      &.purchase, &.redeem
        background-size cover
        background-position center center
        background-repeat no-repeat
        background-color #f6f6f6
      &.text
        display flex
        justify-content center
        align-items center
        background-color #fff
        font-size 1.2rem
        font-weight bold
        color #08548E
        &.past
          color $gray2
        &:hover:not(.past) //尚未截止的
          background-color #2673AD
          color #fff
  .img-hand
    width 150px
    margin-right 1rem
    +under(md)
      display none
    img
      width 100%
      image-rendering -webkit-optimize-contrast
</style>
