<template>
  <div class="member-panel-layout">
    <div class="panel-wrap full-height">
      <div class="panel-illustration pen">
        <img src="~images/img-illustration-16.png" alt="">
      </div>
      <div v-if="current.step === 3" class="panel">
        <div class="panel-head">
          <div class="head-title">
            重設密碼
          </div>
          <div style="margin-top: .5rem;">
            {{ step1.email }}
          </div>
        </div>
        <div class="panel-body">
          <div class="row-wrap">
            <UiInput v-model="step3.password" autocomplete="new-password" :error="fieldErrorOf('password')" class="fluid" type="password" placeholder="設定新密碼 (至少6個字)" />
          </div>
          <div class="row-wrap">
            <UiInput v-model="step3.password_confirmation" autocomplete="new-password" :error="fieldErrorOf('password_confirmation')" class="fluid" type="password" placeholder="請再輸入一次新密碼" />
          </div>
        </div>
        <div class="panel-actions">
          <router-link :to="{name: 'Partner'}" class="ui button fluid minor">
            取消
          </router-link>
          <div class="ui button fluid major" @click="changePwd">
            更改密碼
          </div>
        </div>
      </div>
      <div v-else class="panel">
        <Loader :loading="!loadingStateReady">
          <div class="panel-head">
            <div class="head-title">
              登入
            </div>
          </div>
          <template v-if="current.step === 1">
            <div class="panel-body">
              <div class="center-block">
                <div class="emphasize-text">
                  Opps....忘記密碼了？
                </div>
              </div>
              <div class="row-wrap">
                請輸入登入使用的電子信箱，我們將協助您重設密碼
              </div>
              <div class="row-wrap">
                <UiInput v-model="step1.email" autocomplete="username" :error="fieldErrorOf('email')" type="email" class="fluid" placeholder="輸入您的電子信箱" />
              </div>
            </div>
            <div class="panel-actions">
              <div class="ui button fluid major" @click="resetPwd">
                重設密碼
              </div>
            </div>
            <div class="actions-help">
              <span>想起密碼了?</span>
              <router-link :to="{name: 'Login'}" class="blue-link" style="margin-left: 1rem;">
                立即登入
              </router-link>
            </div>
          </template>
          <Authentication v-if="current.step === 2"
                          :email="step1.email"
                          authType="password"
                          @confirm="confirmToken"
          />
        </Loader>
      </div>
      <div class="panel-illustration person">
        <img src="~images/img-illustration-15.png" alt="">
      </div>
    </div>

    <ModalSuccessChange :visible="showModal" />
  </div>
</template>

<script>
import API from 'api'
import UiInput from 'modules/ui/form/input'
import Loader from 'modules/ui/loader'
import Authentication from 'pages/member/Authentication'
import ModalSuccessChange from 'pages/member/component/ModalChangePwd'
import { createValidator } from 'modules/ui/form/validation.js'
import { MixinNotifiable } from 'modules/ui/notification.vue'

export default {
  components: {
    UiInput,
    Loader,
    Authentication,
    ModalSuccessChange,
  },
  mixins: [
    MixinNotifiable,
    createValidator([
      {
        field: 'email',
        validator: (email, vm) => vm.current.step !== 1 || email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
        message: '請填入註冊使用的電子信箱'
      },
      {
        field: 'email',
        validator: (email, vm) => email || vm.current.step !== 1,
        message: '請填入註冊使用的電子信箱'
      },
      // 以下不是 step 1 需驗證的
      {
        field: 'password',
        validator: (pwd, vm) => vm.current.step === 1 || pwd.length > 5,
        message: '新密碼過短，最少6個字'
      },
      {
        field: 'password',
        validator: (pwd, vm) => vm.current.step === 1 || pwd,
        message: '請輸入新密碼'
      },
      {
        field: 'password',
        validator: (pwd, vm) => vm.current.step === 1 || pwd === vm.step3.password_confirmation,
        message: '兩次新密碼輸入不一致'
      },
      {
        field: 'password_confirmation',
        validator: (pwd2, vm) => vm.current.step === 1 || pwd2 === vm.step3.password,
        message: '兩次新密碼輸入不一致'
      },
      {
        field: 'password_confirmation',
        validator: (pwd, vm) => vm.current.step === 1 || pwd,
        message: '請輸入新密碼'
      },
    ])
  ],
  data() {
    return {
      current: {
        step: 1,
      },
      step1: {
        email: this.$route.params.username,
      },
      step2: {
        token: '',
      },
      step3: {
        password: '',
        password_confirmation: '',
      },
      showModal: false,
    }
  },
  watch: {
    'step1': {
      deep: true,
      handler() {
        this.clearValidation()
      }
    },
    'step3': {
      deep: true,
      handler() {
        this.clearValidation()
      }
    }
  },
  mounted() {
    if (this.step1.email) {
      this.$nextTick(this.resetPwd)
    }
  },
  methods: {
    nextStep() {
      this.current.step += 1
    },
    confirmToken(token) {
      this.step2.token = token
      this.nextStep()
    },
    async resetPwd() {
      if (this.validateForm(this.step1)) {
        const loader = this.createLoadingState()
        this.wait(API.member.forget({ username: this.step1.email }), { message: '請稍後...' })
          .then(res => {
            this.nextStep()
          })
          .catch(error => {
            if (error.response.status === 403) {
              alert({
                title: '請檢查您的信箱',
                message: error.response.data.message
              })
              this.nextStep()
            } else {
              throw error
            }
          })
          .finally(() => {
            loader.finish()
          })
      }
    },
    async changePwd() {
      if (this.validateForm(this.step3)) {
        const params = {
          username: this.step1.email,
          ...this.step2,
          ...this.step3,
        }
        await API.member.setPasswd(params)
          .then(res => {
            this.showModal = true
          })
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~pages/member/memberPanel.styl'
.panel
  .center-block
    text-align center
    margin-bottom 3rem
    .emphasize-text
      font-size 1.2rem
      font-weight bold
</style>
