<template>
  <div>
    <div class="step-form">
      <div class="row-wrap">
        <DropdownSimple v-model="form.agency"
                        :error="fieldErrorOf('agency')"
                        :entries="agencyOption"
                        size="medium"
                        defaultText="選擇您的工作機構"
                        fluid
                        class="selector"
        />
      </div>
      <div class="row-wrap">
        <UiInput v-model="form.branch" autocomplete="organization-title" :error="fieldErrorOf('branch')" class="fluid" placeholder="分行或分處" />
      </div>
      <div class="row-wrap">
        <UiInput v-model="form.email" autocomplete="username" :error="fieldErrorOf('email')" type="email" class="fluid" placeholder="現任公司使用的電子信箱" />
      </div>
    </div>
    <div class="actions">
      <div class="ui button fluid major" @click="submit">
        繼續
      </div>
    </div>
    <div class="actions-help">
      <span>已經是銷售夥伴？</span>
      <router-link :to="{name: 'Login'}" class="blue-link" style="margin-left: 1rem;">
        立即登入
      </router-link>
    </div>
  </div>
</template>

<script>
import API from 'api'
import DropdownSimple from 'modules/components/DropdownSimple'
import UiInput from 'modules/ui/form/input'
import { createValidator } from 'modules/ui/form/validation.js'

export default {
  components: {
    DropdownSimple,
    UiInput,
  },
  mixins: [
    createValidator([
      {
        field: 'agency',
        message: '請選擇您的工作機構'
      },
      {
        field: 'branch',
        message: '請填寫您工作的分行或分處'
      },
      {
        field: 'email',
        validator: (email, vm) => {
          if (!vm.selectedDomain) return false
          return vm.selectedDomain.some(domain => email.match(new RegExp(`${domain}$`)))
        },
        message: '請輸入正確的電子信箱'
      },
      {
        field: 'email',
        message: '請輸入電子信箱'
      },
    ])
  ],
  data() {
    return {
      form: {
        agency: '',
        branch: '',
        email: '',
      },
      agency: [],
    }
  },
  computed: {
    agencyOption() {
      return this.agency.map(item => {
        return {
          text: item.name,
          value: item.name,
        }
      })
    },
    selectedDomain() {
      return this.agency.find(item => item.name === this.form.agency)?.email_domains
    },
  },
  watch: {
    'form': {
      deep: true,
      handler() {
        this.clearValidation()
      }
    }
  },
  async mounted() {
    this.agency = await API.firms.list().then(res => res.data.entries)
  },
  methods: {
    submit() {
      if (this.validateForm(this.form)) {
        const info = {
          firm_id: this.agency.find(item => item.name === this.form.agency).id,
          firm_job_title: this.form.branch,
          username: this.form.email,
        }
        this.$emit('submit', info)
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.row-wrap
  margin-bottom 1rem
.step-form
  margin-bottom 3rem
.actions
  display flex
  margin-bottom 1rem
// 調整 dropdown
/deep/ .ui.dropdown.medium .ui.button.fake-input, /deep/ .ui.dropdown.medium .menu>.item
  font-size 1rem !important
</style>
