<template>
  <div class="dropdown-year">
    <DropdownSimple :value="startYear" :entries="startYearOptions" class="set-size" @input="startYear = +arguments[0]" />
    <span style="margin: 0 1rem;">到</span>
    <DropdownSimple :value="endYear" :entries="endYearOptions" class="set-size" @input="endYear = +arguments[0]" />
  </div>
</template>

<script>
import DropdownSimple from 'modules/components/DropdownSimple'

export default {
  components: {
    DropdownSimple,
  },
  props: {
    maxYear: {
      type: Number,
      default: +moment().format('YYYY')
    },
    minYear: {
      type: Number,
      default: moment().format('YYYY') - 5
    },
  },
  data() {
    return {
      startYear: '',
      endYear: '',
      limit: 5, // 預設6筆(要減一)
    }
  },
  computed: {
    startYearOptions() {
      const option = []
      let year = this.minYear
      for (year; year <= this.maxYear; year++) {
        option.push({
          text: year,
          value: year,
        })
      }
      return option
    },
    endYearOptions() {
      const option = []
      let year = this.startYear
      for (year; year <= this.maxYear; year++) {
        option.push({
          text: year,
          value: year,
        })
      }
      return option
    },
    selectedRange() {
      const range = []
      let year = this.startYear
      for (year; year <= this.endYear; year++) {
        range.push(year)
      }
      return range
    }
  },
  watch: {
    startYear(start) {
      if (start > this.endYear) this.endYear = start
    },
    selectedRange(val) {
      this.$emit('change', val)
    },
  },
  mounted() {
    this.endYear = this.maxYear
    this.startYear = Math.max(this.maxYear - this.limit, this.minYear)
  },
}
</script>

<style lang="stylus" scoped>
.dropdown-year
  display flex
  align-items center
  .set-size
    width 100px
    /deep/ .ui.dropdown
      width 100%
</style>
