<script>
import Scroll from 'modules/ui/scroll'
export default {
  components: {
    Scroll,
  },
  props: {
    tabs: [Array, Object],
    value: [Number, String],
    title: String,
    valueMode: Boolean,
  },
}
</script>

<template>
  <Scroll :class="{checked: value != null}" class="tab-labels">
    <span v-if="title" class="title" v-text="title" />
    <slot name="before" />
    <label v-for="(tab, index) in tabs" :key="index" :class="{checked: value==(valueMode ? tab : index)}" :data-ga-value="tab">
      <slot :name="index" />
      <input
        :checked="value==(valueMode ? tab : index)"
        :name="'v-tab-labels-'+_uid"
        :value="index"
        type="radio"
        @change.prevent="valueMode ? $emit('input', tab) : $emit('input', index)"
      >
      <span>
        <i aria-hidden="true" class="fa fa-check" />
        <slot :name="'label-'+index">{{ tab }}</slot>
      </span>
    </label>
    <slot name="after" />
  </Scroll>
</template>

<style lang="less" scoped>
@import './responsive';

@colorOrange: #FF895B;
@colorMain: #26C6DA;
@colorGeryBackground: #EDEDED;

.tab-labels {
  align-items: center;
  text-align: left;
  user-select: none;

  display: inline-block;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  .title {
    margin-right: 0.5em;
    white-space: nowrap;
  }

  label {
    display: inline-block;
    white-space: normal;
    min-width: 5em;
    text-align: center;
    margin-bottom: 0.5em;
    cursor: pointer;
    transition: 0.3s;
    padding-right: 0.25em;
  }

  input[type='radio'] + span {
    border: none;
    padding: 0;

    &:hover {
      background: none;
    }

    .fa-check {
      display: none;
    }
  }
}

.tab-labels.fancy {
  input[type='radio'] {
    visibility: hidden;
    position: absolute;

    + span {
      display: inline-block;
      width: 100%;
      border-top-right-radius: 0.25em;
      border-top-left-radius: 0.25em;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      padding-left: 1.75em;
      padding-right: 1em;
      border-bottom: 3px solid #EDEDED;
      white-space: nowrap;
      position: relative;

      &:hover {
        background-color: #e0f7fa;
        border-bottom: 3px solid #26C6DA;
      }

      .fa-check {
        display: initial;
      }

      .fa {
        opacity: 0;
        position: relative;
        left: -1.5em;
        margin-right: -1.8em;
        font-size: 80%;
        color: #FF895B;
      }
    }

    &:checked + span {
      font-weight: bold;
      border-bottom: 3px solid #FF895B;

      .fa {
        opacity: 1;
      }
    }
  }
}
.tab-labels.basic {
  line-height: 1;

  input[type='radio'] {
    position: absolute;
    opacity: 0;
  }

  label {
    min-width: auto;
    padding: 0.5em 0.75em;
    margin: 0;
    border-radius: 0.25em;
    background: transparent;
    &:not(:last-of-type){
      margin-right: .5em;
    }
    &:hover,
    &.checked {
      background: #ededed;
    }
  }

  input[type='radio'] + span {
    border: none;
    padding: 0;
    display: inline-block;
    width: 100%;

    &:hover {
      background: none;
    }

    .fa-check {
      display: none;
    }
  }

  &.checked label {
    opacity: 0.6;
    &.checked {
      opacity: 1;
    }
  }

  &.checked label:hover {
    opacity: 1;
  }

  @media @mobile {
    line-height: 1.5;

    > * {
      min-width: auto;
      flex: 0 0 auto;
    }

    > .title {
      white-space: nowrap;
    }
  }
}
.tab-labels.simple:extend(.tab-labels.basic all) {
  &.checked label {
    &.checked {
      font-weight: bold;
      background-color: #ededed;
    }
  }
  .title {
    &:after {
      content: '：';
    }
  }
  .bg-inverted & {
    label {
      color: white;
      &.checked {
        background-color: #90a4ad;
      }
    }
  }
}
.tab-labels.buttons:extend(.tab-labels.basic all) {
  label {
    color: #aaa;
    border: 1px solid #ddd;
    border-radius: 2em;
    padding: 0.5em 0.75em;
    background-color: #fbfbfb;
    // margin-right: 0.5em;
    font-weight: bold;
    &:last-child {
      margin-right: 0;
    }
  }

  &.checked label {
    &.checked {
      color: #666;
      background-color: #ededed;
      border: 1px solid #aaa;
    }
  }

  .bg-inverted & {
    label {
      color: white;
      background-color: transparent;
      &.checked {
        color: white;
        box-shadow: none;
        background-color: #90a4ad;
        border-color: #90a4ad;
      }
    }
  }
}
</style>
