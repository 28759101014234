<!--
  entries 格式：要有 text 跟 value
    entries: [
      {
        text: '全部',
        value: 'all',
      },
      {
        text: '資產配置',
        value: 'asset',
      },
    ],
-->
<template>
  <div>
    <DropdownButton v-bind="$attrs" ref="dropdown" v-model="innerValue" :class="classes" :size="size">
      <template #input>
        <span>{{ displayText }}</span>
      </template>
      <template #menu>
        <div v-for="option in entries" :key="option.value" :data-value="option.value" class="item">
          {{ option.text }}
        </div>
      </template>
    </DropdownButton>
    <p v-if="error" class="form-input__error">
      <span v-text="error" />
      <slot name="error-after" />
    </p>
  </div>
</template>

<script>
import DropdownButton from 'modules/ui/form/dropdownButton'

export default {
  components: {
    DropdownButton,
  },
  props: {
    entries: Array,
    value: [String, Number],
    defaultText: String, // 預設顯示的文字
    size: {
      type: String,
      default: 'small',
    },
    error: String,
    fluid: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      innerValue: this.value,
    }
  },
  computed: {
    displayText() {
      const text = this.entries.find((option) => option.value === this.innerValue)?.text
      return this.value ? text : this.defaultText
    },
    classes() {
      return {
        'has-error': this.error,
        'fluid': this.fluid,
      }
    },
  },
  watch: {
    value() {
      this.innerValue = this.value
    },
    innerValue() {
      if (this.innerValue !== this.value) {
        this.$emit('input', this.innerValue)
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
// error msg
.form-input__error
  font-size 12px
  line-height 1.8
  margin-top 0.5em
  color #ed4758 !important
.ui.dropdown.has-error
  /deep/ .ui.button.fake-input
    box-shadow 0 0 0 1px #ed4758 inset !important

</style>
