<template>
  <div>
    <div class="list-head">
      <div class="ui two columns grid stackable">
        <div class="column">
          <div class="ui compact labeled icon menu">
            <router-link v-if="kindConfig.categorisable" :to="`/content/${kind}/categories/create`" class="item">
              <i class="file outline icon" />
              新增分類
            </router-link>
            <router-link v-if="kindConfig.categorisable" :to="`/content/${kind}`" class="item">
              <i class="list ul icon" />
              {{ kindName }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="list-body">
        <div class="ui divider" />
        <Loader class="loader" :loading="!loadingStateReady">
          <template v-if="categories.length > 0">
            <table class="ui celled table">
              <thead>
                <tr>
                  <th v-for="{name, style} in columns" :key="name" v-bind="{style}" v-text="name" />
                </tr>
              </thead>
              <Draggable v-model="categories" tag="tbody" handle=".sorter" @change="sort">
                <tr v-for="model in categories" :key="model.id">
                  <template v-for="{name, style, template, column, props} in columns" v-bind="{style}" v-text="name">
                    <td :key="`${model.id}-${name}`" v-bind="{style}">
                      <component :is="template" v-bind="{model, column, ...props}" @toggle="toggle" v-on="{remove}" />
                    </td>
                  </template>
                </tr>
              </Draggable>
            </table>
          </template>
          <EmptyPlaceholder v-else />
        </Loader>
      </div>
    </div>
  </div>
</template>

<script>
import Api from 'api'
import MixinShared from './shared'
import columns from './categoryColumns.js'
import ColumnSorter from 'modules/admin/common/column/sorter.vue'

export default {
  components: {
    ColumnSorter,
  },
  mixins: [
    MixinShared,
  ],
  data() {
    return {
      categories: [],
    }
  },
  computed: {
    columns: () => columns,
    entries() {
      return this.categories
    }
  },
  watch: {
    kind: {
      immediate: true,
      handler() {
        this.loadCategories()
      }
    }
  },
  methods: {
    async loadCategories() {
      const loader = this.createLoadingState()
      this.categories = await Api.content.categories({ kind: this.kind, format: 'full' }).then(res => res.data.entries)
      if (this.kindConfig.categorisable === 2) {
        this.categories = this.categories.map(({ categories }) => categories).reduce((a, b) => a.concat(b), [])
      }
      loader.finish()
    },
    async sort({ moved: { element: { id, ordering }, oldIndex, newIndex } }) {
      this.$api.content.categories.sort({
        kind: this.kind,
        id,
        old_index: oldIndex + 1,
        new_index: newIndex + 1,
      })
    },
    findById(targetId) {
      return this.entries.find(({ id }) => id === targetId)
    },
    async toggle(id, prop, val) {
      this.update(id, { [prop]: val }).then(() => {
        this.findById(id)[prop] = val
      })
    },
    async update(id, attributes) {
      await this.$api.content.categories.update({ kind: this.kind, id, attributes, format: 'full' })
    },
    async remove(entry) {
      const sure = await confirm({
        title: `刪除${this.kindConfig.shortName}`,
        message: `確定要刪除「${entry.name}」嗎？此操作不可復原`
      })
      if (sure) {
        this.wait(this.$api.content.categories.destroy({ kind: this.kind, id: entry.id })).then(() => {
          this.loadCategories()
        })
      }
    },
  }
}
</script>
