<script>
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar'

const $ = jQuery

export default {
  props: {
    height: {
      type: [Number, String],
      default: 50
    },
    scrollable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      expanded: false,
      scrollHeight: 0,
      width: 1,
      instance: null
    }
  },
  computed: {
    // prettier-ignore
    bodyStyle() {
      return this.isOverflow
        ? {
            overflow: 'hidden',
            height: (this.expanded) ? `${this.scrollHeight}px` : `${this.flexibleHeight}px`,
          }
        : {
            overflow: 'hidden',
            height: `${this.flexibleHeight}px`,
          }
    },
    flexibleHeight() {
      // 彈性高度，如果差不大就直接符合高度
      if (Math.abs(this.scrollHeight - this.height) < 30) {
        return this.scrollHeight
      } else {
        return this.height
      }
    },
    isOverflow() {
      return this.scrollHeight > this.flexibleHeight
    }
  },
  mounted() {
    window.addEventListener('resize', this.setScrollHeight)
    this.observeDOM(this.$refs.body, this.setScrollHeight)
    this.setScrollHeight()
    if (this.scrollable) this.instance = new PerfectScrollbar(this.$refs.body)
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.setScrollHeight)
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded
    },
    setScrollHeight() {
      this.scrollHeight = $(this.$refs.body).children()[0].scrollHeight
    },
    observeDOM: (function() {
      // https://stackoverflow.com/a/14570614/2252696
      const MutationObserver = window.MutationObserver || window.WebKitMutationObserver
      const eventListenerSupported = window.addEventListener
      return function(obj, callback) {
        if (MutationObserver) {
          // define a new observer
          const obs = new MutationObserver(function(mutations, observer) {
            if (mutations[0].addedNodes.length || mutations[0].removedNodes.length) callback()
          })
          // have the observer observe foo for changes in children
          obs.observe(obj, { childList: true, subtree: true })
        } else if (eventListenerSupported) {
          obj.addEventListener('DOMNodeInserted', callback, false)
          obj.addEventListener('DOMNodeRemoved', callback, false)
        }
      }
    })()
  }
}
</script>
<template>
  <div class="expandable" :class="{expanded: expanded}">
    <div ref="body" class="expandable-body" :style="bodyStyle">
      <slot />
    </div>
    <div v-if="isOverflow" class="expandable-toggle" :class="{mask: !expanded}" @click="toggle">
      <slot v-if="expanded" name="toggle-up">
        收起<i class="icon-up-dir" aria-hidden="true" style="margin-left: 0.75rem;" />
      </slot>
      <slot v-else name="toggle-down">
        展開<i class="icon-down-dir" aria-hidden="true" style="margin-left: 0.75rem;" />
      </slot>
    </div>
  </div>
</template>
<style lang="less" scoped>
.expandable {
  .expandable-body {
    height: 100%;
    will-change: height;
    position: relative;
    transition: height 0.5s;
    border-bottom: 1px solid #EDEDED;
  }
  .expandable-toggle {
    position: relative;
    color: #aaa;
    font-weight: bold;
    line-height: 36px;
    z-index: 10;
    text-align: center;
    background: #FBFBFB;
    border-bottom: 1px solid #EDEDED;
    cursor: pointer;
    &.mask:before{
      content: '';
      position: absolute;
      width: 100%;
      height: 36px;
      background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      left: 0;
      bottom: 100%;
    }
  }
  // firefox
  [class*='box-u-'] {
    max-height: 100%;
  }
}
</style>
