var api = require("!../../../../../../../shared/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../../../../../shared/node_modules/css-loader/dist/cjs.js!../../../../../../../shared/node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../../../../shared/node_modules/stylus-loader/index.js??ref--7-3!./core-extend.styl?vue&type=style&index=1&id=e14541aa&lang=stylus&scoped=true&");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};