<template>
  <DropdownButton ref="dropdown" v-model="innerValue" class="dropdown-currency" v-bind="{size}">
    <template #input>
      <i class="icon-currency-o icon" style="margin-right:0.75rem" />
      <span>{{ displayText }}</span>
    </template>
    <template #menu>
      <div v-for="option in entries" :key="option.value" :data-value="option.value" class="item">
        {{ option.text }}
      </div>
    </template>
  </DropdownButton>
</template>
<script>
import DropdownButton from 'modules/ui/form/dropdownButton'
const list = [
  { text: '台幣計價', value: 'twd' },
  { text: '美元計價', value: 'usd' },
]
export default {
  components: {
    DropdownButton,
  },
  props: {
    size: {
      // small, medium
      type: String,
      default: 'medium',
    },
    value: {
      type: String,
      default() {
        return this.localCurrency || this.initial.value
      },
    },
    localCurrency: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      innerValue: this.value,
    }
  },
  computed: {
    displayText() {
      return this.entries.find((option) => option.value === this.innerValue)?.text
    },
    entries() {
      if (this.localCurrency && !['twd', 'usd'].includes(this.localCurrency.toLowerCase())) {
        return [
          { text: '原幣計價', value: this.localCurrency.toLowerCase() },
          ...list
        ]
      } else {
        return list
      }
    },
    initial() {
      return this.entries[0]
    },
  },
  watch: {
    value() {
      this.innerValue = this.value
    },
    innerValue() {
      if (this.innerValue !== this.value) {
        this.$emit('input', this.innerValue)
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.dropdown-currency
  width 150px
</style>
