import { Columns, defineColumns, createLinkColumn } from 'modules/admin/common/column'

export default defineColumns([
  { name: '排序', width: 60, column: 'ordering', template: Columns.Sorter },
  {
    name: '分類名稱',
    column: 'name',
    template: Columns.Actions,
    props: {
      deletable: ({ visible, articles_count_total }) => visible === false || articles_count_total === 0,
      linkEdit: ({ id }) => ({ name: 'AdminContentCategoryEdit', params: { id } }),
    }
  },
  { name: '數量', column: 'articles_count_total' },
  { name: '顯示', column: 'visible', template: Columns.Switch },
])
