<template>
  <div class="todo-inspector">
    <div class="button" @click="current.opened ^= true">
      <i v-if="current.opened" class="icon-up-dir" />
      <i v-else class="icon-down-dir" />
    </div>
    <div v-if="current.opened" class="component-info">
      <div class="ui header small">
        File:
      </div>
      <p>{{ $options.__file }}</p>
      <div class="ui header small">
        Props:
      </div>
      <ul class="ui list">
        <li v-for="(val, key) in $attrs" :key="key" :title="JSON.stringify(val)">
          {{ key }}
        </li>
      </ul>
      <div class="ui header small">
        Scoped slots:
      </div>
      <ul class="ui list">
        <li v-for="(val, key) in $scopedSlots" :key="key">
          {{ key }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: {
        opened: false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.todo-inspector
  border 1px solid #ed4758
  border-radius .5rem
  background white
.component-info
  padding 1rem
.button
  display inline-block
  padding .25em .5rem
  color white
  background #ed4758
  border-radius .25rem
  cursor pointer
  user-select none
  &:before
    content "替換元件"
    display inline-block
</style>
