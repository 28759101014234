<template>
  <div class="basic__container">
    <h2 class="basic__topic">
      銷售工具
    </h2>
    <UiTabs :tabs="mainTabs" :tab.sync="current.tab" class="modern tab-wrap" />
    <router-view />
  </div>
</template>

<script>
import UiTabs from 'modules/ui/tabs/index'

export default {
  components: {
    UiTabs,
  },
  data() {
    return {
      current: {
        tab: '',
      },
      mainTabs: ['重點圖卡', '基金月報'],
      tabMap: {
        '重點圖卡': 'SaleskitCharts',
        '基金月報': 'SaleskitReports',
      }
    }
  },
  watch: {
    'current.tab'(latest, old) {
      if (old && latest !== old) this.$router.push({ name: this.tabMap[latest] })
    },
  },
  mounted() {
    this.current.tab = Object.keys(this.tabMap).find(key => this.tabMap[key] === this.$route.name)
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'

.basic__container
  padding-bottom 14rem
.basic__topic
  position relative
  &:before
    content ''
    position absolute
    right -25px
    top -8px
    width 114px
    height 73px
    background-image url('~images/animation/img-1.0.7.svg')
    background-repeat no-repeat
    background-position center center
    background-size contain
    +under(md)
      right 0
.tab-wrap
  margin 2rem 0
  +under(md)
    margin 1.5rem 0
</style>
