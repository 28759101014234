<template>
  <div class="v-perfect-scrollbar" @wheel="preventParentScroll" @mousemove="unlockWheel">
    <slot />
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar'
import MixinPerfectScrollStickyPlugin from './mixins/PerfectScrollStickyPlugin.js'

export default {
  mixins: [
    MixinPerfectScrollStickyPlugin,
  ],
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    instance: null,
    observer: null,
    wheelLocked: false,
  }),
  mounted() {
    this.instance = new PerfectScrollbar(this.$el, {
      wheelSpeed: 1,
      ...this.options,
    })
    if (typeof ResizeObserver == 'function') {
      this.observer = new ResizeObserver(() => {
        defer.call(this, 'delayedUpdate', 300, () => {
          this.instance?.update()
          this.$emit('update')
        })
      })
      this.observer.observe(this.$el)
    } else {
      window.addEventListener('resize', this.update)
    }
  },
  beforeDestroy() {
    if (this.instance) {
      window.removeEventListener('resize', this.update)
      this.instance.destroy()
    }
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    update() {
      defer.call(this, 'delayedUpdate', 300, () => {
        this.instance?.update()
      })
    },
    preventParentScroll(event) {
      // issue: https://app.asana.com/0/388448967373962/1198931234771169/f
      const height = this.$el.offsetHeight
      const scrollHeight = this.$el.scrollHeight
      const scrollTop = this.$el.scrollTop
      const delta = event.deltaY
      if ((scrollTop === 0 || scrollTop + height === scrollHeight) && !this.wheelLocked) {
        return
      }
      if (scrollTop + delta <= 0 || scrollTop + height + delta >= scrollHeight) {
        this.lockWheel()
      }
      event.preventDefault()
    },
    lockWheel() {
      this.wheelLocked = true
    },
    unlockWheel() {
      this.wheelLocked = false
    },
  },
}

function defer(timer, timeout, callback) {
  clearTimeout(this['delayer_' + timer])
  this['delayer_' + timer] = setTimeout(callback, timeout)
}
</script>

<style lang="stylus" scoped>
.v-perfect-scrollbar
  position: relative
</style>
