import Api from 'api'
import components from './components.js'
import { MixinNotifiable } from 'modules/ui/notification.vue'

export default {
  mixins: [
    MixinNotifiable
  ],
  components,
  computed: {
    $api: () => Api
  }
}
