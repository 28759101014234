<template>
  <div>
    <div ref="list" class="list">
      <div class="list-head">
        <div class="row head-row">
          <div class="grid year">
            年度
          </div>
          <Scroll ref="head" class="column-group" @ps-scroll-x.native="updateScroll">
            <div v-for="title in titles" :key="title" class="grid month" v-text="title" />
          </Scroll>
        </div>
      </div>
      <div class="list-body">
        <div v-for="year in sortYear" :key="year" class="body-row-group">
          <div class="row body-row">
            <div class="grid year">
              {{ year }}
            </div>
            <Scroll ref="info" class="column-group" @ps-scroll-x.native="updateScroll">
              <div v-for="title in titles" :key="`${year}${title}`" class="grid month" :class="gridStyle({year, title})">
                <div v-if="monthlyData[year][title]">
                  <template v-if="type === 'aum_mn'">
                    {{ monthlyData[year][title].val | round(1) }}
                  </template>
                  <template v-else>
                    {{ monthlyData[year][title].val | round(4) }}
                  </template>
                </div>
                <div v-else>
                  -
                </div>
                <div v-if="monthlyData[year][title] && monthlyData[year][title]['estimate']" class="note">
                  （預估）
                </div>
              </div>
            </Scroll>
          </div>
        </div>
      </div>
    </div>
    <div class="text-minor" style="margin: 1.5rem;">
      單位：
      <span v-if="type === 'aum_mn'">百萬</span>{{ priceCurrency | currencyName }}
    </div>
  </div>
</template>

<script>
import API from 'api'

import MixinScrollList from 'modules/components/fund/MixinScrollList'
import Scroll from 'modules/ui/scroll'

export default {
  components: {
    Scroll,
  },
  mixins: [
    MixinScrollList,
  ],
  props: {
    type: { // aum_mn / price
      type: String,
      default: function() {
        return 'aum_mn'
      }
    },
    dateStart: {
      type: String,
      default: function() {
        return '2019-01-01'
      }
    },
    dateEnd: {
      type: String,
      default: function() {
        return '2021-01-01'
      }
    },
  },
  data() {
    return {
      fundId: this.$route.params.id,
      titles: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      history: [],
      priceCurrency: '',
    }
  },
  computed: {
    selectedYears() {
      let startYear = moment(this.dateStart).format('YYYY')
      const endYear = moment(this.dateEnd).format('YYYY')
      const arry = []
      for (startYear; startYear <= endYear; startYear++) {
        arry.push(startYear)
      }
      return arry
    },
    monthlyData() {
      const obj = {}
      this.selectedYears.forEach(year => {
        const matches = this.history.filter(item => item[this.type] && moment(item.date).format('YYYY') == year)
        const monthly = {}
        matches.forEach(item => {
          const key = moment(item.date).format('MM')
          monthly[key] = {
            val: item[this.type],
            estimate: item.estimate
          }
        })
        obj[year] = monthly
      })
      return obj
    },
    sortYear() {
      return this.selectedYears.slice().sort((a, b) => a - b ? -1 : 1)
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      await API.fund.history({ id: this.fundId }).then(res => {
        this.history = res.data.history
        this.priceCurrency = res.data.price_currency
      })
    },
    gridStyle({ year, title }) {
      const date = `${year}-${title}`
      return {
        fade: moment(date) < moment(this.dateStart).startOf('month') || moment(date) > moment(this.dateEnd).endOf('month')
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~modules/components/fund/monthTable.styl'
// 表格
.list
  .grid
    &.year
      width 72px
      text-align left
    &.month
      flex-grow 1
      text-align right
    &.fade
      color #ddd
  .note
    font-size 12px
    color $gray2
</style>
