<template>
  <div class="basic__container">
    <h2 class="basic__topic">
      聯繫我們
    </h2>
    <div class="panel-layout">
      <div class="ui form wrapper">
        <h3 v-if="$deviceView.desktop" class="section-title">
          若您對網站服務有任何問題，或有合作機會，歡迎填寫表單與我們聯絡：
        </h3>
        <div v-else class="font-bold" style="margin-bottom: 1rem;">
          若您對網站服務有任何問題，或有合作機會，歡迎填寫表單與我們聯絡：
        </div>
        <div class="ui two fields">
          <Field required :error="fieldErrorOf('email')">
            <label>電子信箱</label>
            <UiInput v-model="form.email" class="fluid" placeholder="電子信箱 (會透過此信箱回覆您)" />
          </Field>
        </div>
        <div class="ui two fields">
          <Field required :error="fieldErrorOf('name')">
            <label>姓名</label>
            <UiInput v-model="form.name" class="fluid" placeholder="您的姓名" />
          </Field>
        </div>
        <div class="ui two fields">
          <Field>
            <label>聯絡電話</label>
            <UiInput v-model="form.phone" class="fluid" placeholder="連絡電話 (選填)" />
          </Field>
        </div>
      </div>
      <div class="ui divider" />
      <div class="ui form wrapper">
        <Field required :error="fieldErrorOf('request')">
          <h3 class="section-title">
            聯繫目的
          </h3>
          <div class="checkbox-wrap">
            <UiCheckbox v-model="form.request" value="通路合作洽詢" radio>
              通路合作洽詢
            </UiCheckbox>
          </div>
          <div class="checkbox-wrap">
            <UiCheckbox v-model="form.request" value="媒體公關採訪" radio>
              媒體公關採訪
            </UiCheckbox>
          </div>
          <div class="checkbox-wrap">
            <UiCheckbox v-model="form.request" value="系統問題回報" radio>
              系統問題回報
            </UiCheckbox>
          </div>
          <div class="checkbox-wrap">
            <UiCheckbox v-model="form.request" value="其他" radio>
              其他（請詳述於聯絡意見中）
            </UiCheckbox>
          </div>
        </Field>
      </div>
      <div class="ui divider" />
      <div class="ui form wrapper">
        <h3 class="section-title">
          聯絡意見
        </h3>
        <Field required :error="fieldErrorOf('message')">
          <textarea v-model="form.message" rows="10" />
        </Field>
      </div>
      <div class="ui divider" />
      <div class="action wrapper">
        <div class="checkbox-wrap">
          <VueRecaptcha
            ref="recaptcha"
            loadRecaptchaScript
            sitekey="6Lc2_80aAAAAAL5WdS76YZ0EkgjMvXI5Tx7JrP8d"
            @verify="verified"
            @expired="expired"
          />
        </div>
        <div class="ui button major" style="min-width:170px; margin: auto" :class="{disabled: !is.verified || is.sent}" @click="submit">
          送出
        </div>
      </div>
    </div>
    <div class="illustration">
      <img src="~images/img-illustration-11.png" alt="">
    </div>
  </div>
</template>

<script>
import API from 'api'
import Field from 'modules/ui/form/field'
import UiInput from 'modules/ui/form/input'
import UiCheckbox from 'modules/ui/form/checkbox'
import VueRecaptcha from 'vue-recaptcha'

import { MixinNotifiable } from 'modules/ui/notification.vue'
import { createValidator } from 'modules/ui/form/validation.js'

export default {
  components: {
    Field,
    UiInput,
    UiCheckbox,
    VueRecaptcha,
  },
  mixins: [
    MixinNotifiable,
    createValidator([
      {
        field: 'email',
        validator: email => email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
        message: '請輸入正確的電子信箱'
      },
      {
        field: 'email',
        message: '請輸聯絡您的電子信箱'
      },
      {
        field: 'name',
        message: '請輸入您的姓名'
      },
      {
        field: 'request',
        message: '請選擇您的聯繫目的'
      },
      {
        field: 'message',
        message: '請填寫您的聯絡意見'
      },
    ])
  ],
  data() {
    return {
      is: {
        verified: false,
        sent: false,
      },
      form: {
        email: '',
        name: '',
        phone: '',
        request: '',
        message: '',
        recaptcha: ''
      }
    }
  },
  mounted() {
    this.reset()
    this.bindFormValidator('form')
  },
  methods: {
    submit() {
      if (this.validateForm(this.form)) {
        this.is.sent = true
        this.wait(API.member.contact(this.form)).then(res => {
          this.reset()
          this.success('謝謝，我們已收到您的來信')
        }).catch((error) => {
          this.$refs.recaptcha.reset()
          this.is.sent = false
          throw error
        })
      }
    },
    reset() {
      this.form.email = this.$member?.email || ''
      this.form.name = this.$member?.name || ''
      this.form.phone = ''
      this.form.request = ''
      this.form.message = ''
      this.form.recaptcha = ''
      this.is.sent = false
      this.is.verified = false
      this.$refs?.recaptcha?.reset()
    },
    expired() {
      this.form.recaptcha = ''
      this.is.verified = false
    },
    verified(token) {
      this.form.recaptcha = token
      this.is.verified = true
    },
  }
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.basic__container
  padding-bottom 0px
.basic__topic
  margin-bottom 2rem
.checkbox-wrap
  margin 1rem 0
.action
  text-align center
.illustration
  margin 3rem 0
.locked
  pointer-events none
</style>
