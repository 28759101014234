import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('fund', {
      __$loadedFunds: 'all'
    }),
  },
  methods: {
    ...mapActions('fund', [
      'loadFunds',
    ]),
    getFund(id) {
      return this.__$loadedFunds.find(({ id: _id }) => _id === id)
    }
  }
}
