<template>
  <div>
    <div class="step-form">
      <div class="row-wrap">
        <UiInput v-model="form.name" autocomplete="nickname" :error="fieldErrorOf('name')" class="fluid" placeholder="中文姓名" />
      </div>
      <div class="row-wrap">
        <UiInput v-model="form.password" autocomplete="new-password" :error="fieldErrorOf('password')" class="fluid" type="password" placeholder="設定您的密碼 (至少6個字)" />
      </div>
      <div class="row-wrap">
        <UiInput v-model="form.password_confirmation" autocomplete="new-password" :error="fieldErrorOf('password_confirmation')" class="fluid" type="password" placeholder="請再輸入一次您的密碼" />
      </div>
      <div class="row-wrap">
        <UiInput v-model="form.mobile_number" autocomplete="tel" :error="fieldErrorOf('mobile_number')" class="fluid" placeholder="手機號碼" />
      </div>
      <div class="row-wrap">
        <Pikaday :options="birthdayOptions" :value="form.birthday">
          <UiInput v-model="form.birthday" :error="fieldErrorOf('birthday')" class="fluid" type="date" placeholder="生日 (年/月/日)">
            <template #after>
              <i class="icon-calendar action" />
            </template>
          </UiInput>
        </Pikaday>
      </div>
      <div class="row-wrap">
        <UiCheckbox v-model="form.gender" value="Male" radio style="margin-right: 1rem;">
          男性
        </UiCheckbox>
        <UiCheckbox v-model="form.gender" value="Female" radio>
          女性
        </UiCheckbox>
        <div v-if="fieldErrorOf('gender')" class="filed-error">
          <span v-text="fieldErrorOf('gender')" />
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="ui button fluid minor" @click="$emit('back')">
        上一步
      </div>
      <div class="ui button fluid major" @click="submit">
        繼續
      </div>
    </div>
  </div>
</template>

<script>
import UiInput from 'modules/ui/form/input'
import UiCheckbox from 'modules/ui/form/checkbox'
import Pikaday from 'modules/ui/form/pikaday.vue'
import { createValidator } from 'modules/ui/form/validation.js'
import { isValidName } from 'lib/utils/taiwaneseName.js'

export default {
  components: {
    UiInput,
    UiCheckbox,
    Pikaday,
  },
  mixins: [
    createValidator([
      {
        field: 'name',
        message: '請輸入中文姓名'
      },
      {
        field: 'name',
        validator: isValidName,
        message: '請輸入正確中文姓名'
      },
      {
        field: 'password',
        validator: pwd => pwd.length > 5,
        message: '密碼過短，最少6個字'
      },
      {
        field: 'password',
        message: '請輸入密碼'
      },
      {
        field: 'password_confirmation',
        validator: (pwd2, vm) => pwd2 === vm.form.password,
        message: '兩次密碼輸入不一致'
      },
      {
        field: 'password_confirmation',
        message: '請輸入密碼'
      },
      {
        field: 'mobile_number',
        validator: mobile => mobile.match(/^[0-9]{10}$/),
        message: '請輸入正確手機號碼'
      },
      {
        field: 'mobile_number',
        message: '請輸入手機號碼'
      },
      {
        field: 'birthday',
        message: '請輸入生日'
      },
      {
        field: 'gender',
        message: '請選擇性別'
      },
    ])
  ],
  data() {
    return {
      form: {
        name: '',
        password: '',
        password_confirmation: '',
        mobile_number: '',
        birthday: '',
        gender: null,
      },
    }
  },
  computed: {
    birthdayOptions() {
      const maxDate = moment().add(-15, 'year').startOf('year').toDate()
      return {
        defaultDate: maxDate,
        setDefaultDate: false,
        yearRange: [new Date().getFullYear() - 100, new Date().getFullYear() - 15],
        maxDate: maxDate,
      }
    },
  },
  watch: {
    'form': {
      deep: true,
      handler() {
        this.clearValidation()
      }
    }
  },
  methods: {
    submit() {
      if (this.validateForm(this.form)) {
        this.$emit('submit', this.form)
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.row-wrap
  margin-bottom 1rem
.step-form
  margin-bottom 3rem
.actions
  display flex
  margin-bottom 1rem
// error msg
.filed-error
  font-size 12px
  line-height 1.8
  margin-top 0.5em
  color #ed4758
</style>
