<template>
  <div :class="{simplified}" class="v-component-pagination">
    <template v-if="simplified">
      <div>
        <span>第</span>
        <UiDropdown v-model="currentPage" :options="dropdownOptions" class="search">
          <input v-model="currentPage" type="hidden">
          <div class="default text">
            {{ currentPage }}
          </div>
          <div class="menu">
            <a v-for="_page in allPages" :key="_page" :data-text="_page" :data-value="_page" class="item" v-text="_page" />
          </div>
        </UiDropdown>
        <span>頁</span>
        <span> / 共 {{ total }} 頁</span>
      </div>
      <a :class="{disabled: currentPage == 1}" title="上一頁" @click="setPage(currentPage-1)">
        <i class="icon-angle-left" />
      </a>
      <a :class="{disabled: currentPage == total}" title="下一頁" @click="setPage(currentPage+1)">
        <i class="icon-angle-right" />
      </a>
    </template>
    <template v-else>
      <a :class="{disabled: currentPage == 1}" @click="setPage(1)">
        <i class="icon-angle-double-left" />
      </a>
      <a :class="{disabled: currentPage == 1}" @click="setPage(currentPage-1)">
        <i class="icon-angle-left" />
        <span>上一頁</span>
      </a>
      <div>
        <span>第</span>
        <UiDropdown v-model="currentPage" :options="dropdownOptions" class="search">
          <input v-model="currentPage" type="hidden">
          <div class="default text">
            {{ currentPage }}
          </div>
          <div class="menu">
            <a v-for="_page in allPages" :key="_page" :data-text="_page" :data-value="_page" class="item" v-text="_page" />
          </div>
        </UiDropdown>
        <span>頁</span>
        <span> / 共 {{ total }} 頁</span>
      </div>
      <a :class="{disabled: currentPage == total}" @click="setPage(currentPage+1)">
        <span>下一頁</span>
        <i class="icon-angle-right" />
      </a>
      <a :class="{disabled: currentPage == total}" @click="setPage(total)">
        <i class="icon-angle-double-right" />
      </a>
    </template>
  </div>
</template>

<script>
import UiDropdown from 'modules/ui/form/dropdown.vue'
export default {
  components: {
    UiDropdown
  },
  props: {
    page: Number,
    total: Number,
    simplified: Boolean,
  },
  computed: {
    dropdownOptions() {
      return {
        message: { noResults: '無效的頁碼' },
        forceSelection: false,
        onChange: (value, text, choice) => {
          if (value) {
            $(this.$el)
              .find('.ui.dropdown')
              .dropdown('clear')
            this.setPage(value)
          }
        },
      }
    },
    allPages() {
      return Array(this.total)
        .fill()
        .map((e, i) => i + 1)
    },
    currentPage: {
      get() { return this.page },
      set(v) { this.setPage(v) },
    }
  },
  methods: {
    setPage(p) {
      this.$emit('update:page', +p)
      this.$emit('input', +p)
    },
  },
}

const MixinPagination = {
  data: () => ({
    pagination: {
      page: 1,
      page_total: 1,
      page_items: 0,
      total: 0
    },
  }),
  watch: {
    'pagination.page'(p, a) {
      this.$emit('pagechange', p)
    }
  },
  methods: {
    nextPage() {
      this.pagination.page++
    },
    prevPage() {
      this.pagination.page--
    },
    setPage(p) {
      this.pagination.page = p
    }
  }
}

export { MixinPagination }

</script>

<style lang="stylus" scoped>
@import './common'

.v-component-pagination
  color $gray2
  user-select none
  font-weight bold
  display flex
  justify-content center
  text-align center
  &.simplified
    font-size 1em
    > span > .ui.dropdown
      line-height 18px
      padding 5px
    > a
      padding 4.5px 9px
    .ui.dropdown
      padding 6px 5px
  *
    vertical-align middle
  > a
    border 1px solid #DDDDDD
    background #FBFBFB
    padding 7px 12px
    cursor pointer
    &:hover
      background-color #ededed
      border-color #ddd
      color $gray2
      position relative
      z-index 2
    &:not(:last-child)
      margin-right -1px
    &:first-child
      border-top-left-radius 5px
      border-bottom-left-radius 5px
    &:last-child
      border-top-right-radius 5px
      border-bottom-right-radius 5px
  > div
    display inline-block
    margin-left 2rem
    margin-right 2rem
    > .ui.dropdown /deep/
      border 1px solid #DDD
      background #FBFBFB
      padding 9px 5px
      cursor pointer
      line-height 1
      width 50px
      border-radius 5px
      margin 0 0.5rem
      color #666
      .menu
        min-width 100%
        text-align center
        border-top-left-radius 0
        border-top-right-radius 0
      .menu>.item:hover
        background-color #ededed
        color #666
      .text
        line-height 1.4
      &, input
        cursor pointer !important
        text-align center !important
        height 100%
  @media $onlyPC
    font-size 1.2em
    &:not(.simplified) a:nth-child(2)
      border-top-right-radius 5px
      border-bottom-right-radius 5px
    a:nth-last-child(2)
      border-top-left-radius 5px
      border-bottom-left-radius 5px
  @media $onlyMobile
    display inline-flex
    flex-wrap wrap
    justify-content center
    &.fluid
      display flex
    > *
      flex 0 0 auto
    > div
      order -1
      margin-left auto
      margin-right auto
      margin-bottom 1em
      width 100%
</style>
