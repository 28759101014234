import Vue from 'vue/dist/vue.esm'

const bus = new Vue({})

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        $bus: () => bus
      }
    })
  }
}

// 已註冊事件紀錄

// 圖表
// this.$bus.$on('chart:entry-active', (id, fromModule) => { if(fromModule == this.moduleName) { this.current.activeId = id } })
// this.$bus.$on('chart:entry-idle', (id, fromModule) => { if(fromModule == this.moduleName) { this.current.activeId = null} })
