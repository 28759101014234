import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

// 自動引入 pages/page/foo => PageFoo

const Pages = {}

const requireComponent = require.context(
  // The relative path of the components folder
  'pages',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  // new RegExp(`(${includePageScopes.join('|')})\\/.+\\.(vue)$`)
  /.+\.(vue)$/
)

requireComponent.keys().forEach(fileName => {
  if (fileName.indexOf('/partial/') > -1) {
    return
  }

  if (fileName.indexOf('.index.js') > -1) {
    return
  }

  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Strip the leading `./` and extension from the filename
      fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
    )
  )

  const component = componentConfig.default || componentConfig

  Object.assign(component, {
    name: `Page${componentName}`
  })

  // Set as component
  Pages[`${componentName}`] = component
})

for (const key in Pages) {
  if (key.match(/Index$/)) {
    const alias = key.replace(/Index$/, '')
    if (!Pages[alias]) {
      Pages[alias] = Pages[key]
      Pages[alias].name = `Page${alias}`
    }
  }
}

console.log(Pages)

export default Pages
