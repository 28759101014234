export default {
  props: {
    currency: String,
    localCurrency: String,
  },
  data() {
    return {
      // 棄用直接存取 state.currency
      current: {
        currency: undefined,
      },
    }
  },
  watch: {
    currency() {
      this.currentCurrency = this.currency || this.defaultCurrency
    }
  },
  created() {
    this.currentCurrency = this.currency || this.defaultCurrency
  },
  computed: {
    currentCurrency: {
      set(val) {
        this.current.currency = val
        this.$emit('update:currency', val)
      },
      get() {
        return this.current.currency
      },
    },
    displayCurrency() { return this.currencies[this.currentCurrency.toLowerCase()] },
    displayCurrencyCode() { return this.currentCurrency.toUpperCase() },
    displayCurrencySymbol() { return this.currencySymbols[this.currentCurrency] },
    currencyDecimals: () => ({ twd: 0, usd: 2, cny: 0 }),
    currencySymbols: () => ({ twd: 'NTD', usd: 'USD', cny: 'RMB' }),
    currencies: () => ({ twd: '台幣', usd: '美元' }),
    defaultCurrency: () => 'twd',
  }
}
