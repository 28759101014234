<script>
/* eslint-disable eqeqeq */

import stable from 'lib/utils/stable.js'

// 分組使預設排序一致
function sortByDefault(funds) {
  function getOrder(item) {
    if (String(item.id).match(/^\d{8}$/)) { // 2 Other funds
      return 2
    } else if (item.type === 'benchmark' || String(item.id).match(/^\d+$/)) { // 3 Benchmark
      return 3
    } else { // UG funds
      return 1
    }
  }
  function compareByName(itemA, itemB) {
    const nameA = itemA.name || itemA.label
    const nameB = itemB.name || itemB.label
    return String(nameB).localeCompare(nameA)
  }
  funds.sort((a, b) => getOrder(a) - getOrder(b) || compareByName(a, b))
  return funds
}

export default {
  methods: {
    getSorted(funds) {
      funds = sortByDefault(funds)
      // 排序函式，比較兩者大小
      const sorter = (a, b, sortKey, sortOrd) => {
        const v1 = this.fieldModifier(a, sortKey)
        const v2 = this.fieldModifier(b, sortKey)
        const types = ['undefined', 'null', 'boolean', 'number', 'string', 'object', 'function']
        const t1 = v1 === null ? 1 : types.indexOf(typeof v1)
        const t2 = v2 === null ? 1 : types.indexOf(typeof v2)
        if (t1 === t2) return (v1 === v2 ? 0 : v1 < v2 ? -1 : 1) * sortOrd
        return (t1 < t2 ? -1 : 1) * sortOrd
      }
      if (this.current.sortBy && this.current.sortBy.length == 2) {
        const sortKey = this.current.sortBy[0]
        const sortOrd = this.current.sortBy[1]
        switch (sortKey) {
          // stable 是"穩定排序法"的輔助演算法 (為了排序多欄位)
          // 執行順序與思考邏輯是顛倒的
          default:
            // 其它則無特殊規則
            funds = stable(funds, (a, b) => sorter(a, b, sortKey, sortOrd))
        }
      }
      return funds
    },
    fieldModifier(entry, key) {
      // 欄位取值 & 微調
      const value = this.accessValue(entry, key) // 存取深層的值
      return value
    },
    accessValue(obj, path) { // 可以存取 months_performance.3 兩層的key
      path = path.split('.')
      let res = obj
      for (let i = 0; i < path.length; i++) res = res[path[i]]
      return res
    },
  },
}
</script>
