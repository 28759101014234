<script>
import DropdownFilter from 'modules/components/DropdownFilter'
import UiLoader from 'modules/ui/loader'

export default {
  components: {
    DropdownFilter,
    UiLoader,
  },
  data() {
    return {
      current: {
        category: '',
        articles: [],
      },
      categories: null,
      accumulatedPage: 1,
    }
  },
  computed: {
    filterOptions() {
      const defaultOption = { text: '全部', value: 'all', articles_count: 0 }
      if (!this.categories) return [defaultOption]
      defaultOption.articles_count = this.categories.reduce((prev, next) => {
        return prev + Number(next.articles_count)
      }, 0)
      const options = this.categories.map(item => {
        return {
          text: item.name,
          value: item.name,
          articles_count: item.articles_count
        }
      })
      return [
        defaultOption,
        ...options,
      ]
    },
    totalArticles() {
      return this.filterOptions.find(option => option.value === this.current.category)?.articles_count
    },
    currentCategoryId: {
      get() {
        if (!this.current.category) return ''
        return this.current.category !== 'all' ? this.categories.find(e => e.name === this.current.category).id : undefined
      },
      set(id) {
        if (id) {
          this.current.category = this.categories.find(e => e.id === +id).name
        } else {
          this.current.category = 'all'
        }
      },
    },
  },
  watch: {
    'currentCategoryId'(category_id) {
      this.resetCategory()
      // 有選分類才有 category_id
      if (category_id != this.$route.params.category_id) {
        this.$router.push({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            category_id
          },
        })
      }
    },
  },
  async mounted() {
  //  [sample]
  //   const categoryId = this.$route.params.category_id
  //   await API.articles.categories({ kind: this.kind }).then(res => {
  //     this.categories = res.data.entries
  //     if (categoryId) {
  //       this.currentCategoryId = categoryId
  //     } else {
  //       this.current.category = 'all'
  //     }
  //   })
  },
  methods: {
    async loadList() {
      // 全部文章
    },
    async loadCategoryList(categoryId) {
      // 目錄下的文章
    },
    async loadMore() {
      this.accumulatedPage += 1
      if (this.current.category === 'all') {
        this.current.articles.push(...await this.loadList())
      } else {
        this.current.articles.push(...await this.loadCategoryList())
      }
    },
    async resetCategory(category) {
      if (category) this.current.category = category.name // 手動更新
      this.accumulatedPage = 1
      if (this.current.category === 'all') {
        this.current.articles = await this.loadList()
      } else {
        this.current.articles = await this.loadCategoryList()
      }
    },
  },
}
</script>
