<template>
  <div class="basic__container">
    <h2 class="basic__topic">
      重要公告
    </h2>
    <div class="bulletin-board">
      <Loader :loading="!loadingStateReady" class="form-list">
        <div class="form-head">
          <div class="form-row">
            <div class="form-col date">
              公告日期
            </div>
            <div class="form-col info">
              公告
            </div>
          </div>
        </div>
        <div v-if="entries.length > 0" class="form-body">
          <div v-for="entry in entries" :key="entry.slug" class="form-row">
            <div class="form-col date">
              {{ entry.publish_at | date }}
            </div>
            <div class="form-col info">
              <router-link :to="{name: 'BulletinContent', params: {id: entry.slug}}" class="form-link">
                {{ entry.title }}
              </router-link>
            </div>
          </div>
        </div>
      </Loader>
      <Pagination
        v-if="pagination && pagination.total_pages > 1"
        :page.sync="current.page" :total="pagination.total_pages"
      />
      <div class="illustration">
        <img src="~images/img-illustration-5.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import API from 'api'
import Loader from 'modules/ui/loader'
import Pagination from 'modules/ui/pagination'
export default {
  components: {
    Loader,
    Pagination,
  },
  data() {
    return {
      current: {
        page: 1,
      },
      entries: [],
      pagination: null,
      kind: 'bulletin',
    }
  },
  computed: {
    queryParams() {
      return {
        kind: this.kind,
        page: this.current.page,
      }
    }
  },
  watch: {
    queryParams() {
      this.load()
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      const loader = this.createLoadingState()
      const { entries, pagination } = await API.content.list(this.queryParams).then(res => res.data)
      Object.assign(this, { entries, pagination })
      loader.finish()
    }
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
@require '~pages/bulletin/formList.styl'

.bulletin-board
  position relative
  margin-top 2rem
  z-index 1
  +over(md)
    &:before
      content ''
      width 158px
      height 128px
      right -10px
      top -112px
      position absolute
      background-image url('~images/animation/img-8.0.1.svg')
      background-repeat no-repeat
      background-position center center
      background-size contain
      z-index -1
.form-list
  margin-bottom 3rem
  .form-col
    &.date
      flex 0 0 200px
      +under(md)
        flex 0 0 100px
        font-size 12px
    &.info
      flex-grow 1
.illustration
  margin 4rem 0 5rem 2rem
  width 388px
  +under(md)
    width 100%
    max-width 312px
    margin 2rem 0 2rem 1rem
  img
    width 100%
</style>
