<!--

  方便實作表單的欄位，錯誤訊息顯示等等

  定義：
  https://fomantic-ui.com/collections/form.html

  使用範例：
  <div class="ui form">
    <Field required :error="fieldErrorOf('file')">
      <label>上傳檔案</label>
      <Attachment v-model="form.file" accept=".pdf" />
    </Field>
  </div>

-->

<template>
  <div class="field" :class="{error, required}">
    <slot />
    <div v-if="error" class="ui error message">
      <p>{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
