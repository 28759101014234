<template>
  <div class="edit-images">
    <div class="ui divided items">
      <div v-for="(item, index) in internal" :key="index" class="item">
        <div class="image">
          <img :src="item.image_url">
        </div>
        <div class="content">
          <TinymceBasic v-model="item.content" @input="laterUpdate" />
          <div class="extra">
            <div class="ui right floated rule-buttons-group">
              <div class="ui small button danger" @click="remove(index)">
                <i class="icon-trash" />
                刪除
              </div>
              <div class="ui minor small button" :class="{disabled: index === 0}" @click="moveUp(index)">
                <i class="icon-up-dir" />
                上移
              </div>
              <div class="ui minor small button" :class="{disabled: index === internal.length - 1}" @click="moveDown(index)">
                <i class="icon-down-dir" />
                下移
              </div>
              <Checkbox v-model="item.is_cover" name="cover" radio circled :value="true" @input="uncheckOthers(index); laterUpdate()">
                設為預覽圖
              </Checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <Attachment ref="uploader" accept="image/*" @input="appendImage" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import TinymceBasic from 'modules/components/TinymceBasic.vue'
import Checkbox from 'modules/ui/form/checkbox'
import Attachment from 'modules/ui/form/attachment'

export default {
  components: {
    TinymceBasic,
    Checkbox,
    Attachment,
  },
  model: {
    prop: 'images'
  },
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      internal: [],
      uploader: null
    }
  },
  watch: {
    images: {
      immediate: true,
      handler() {
        this.internal = JSON.parse(JSON.stringify(this.images))
      }
    },
  },
  methods: {
    appendImage({ file_upload_base64 }) {
      this.internal.push({
        image_url: file_upload_base64,
        content: '',
        is_cover: this.internal.length === 0,
      })
      this.$refs.uploader.reset()
      this.update()
    },
    remove(index) {
      this.internal.splice(index, 1)
      this.update()
    },
    moveUp(index) {
      const tmp = this.internal.splice(index, 1)
      this.internal.splice(index - 1, 0, ...tmp)
      this.update()
    },
    moveDown(index) {
      const tmp = this.internal.splice(index, 1)
      this.internal.splice(index + 1, 0, ...tmp)
      this.update()
    },
    uncheckOthers(index) {
      this.internal.forEach((item, idx) => {
        if (idx !== index) {
          item.is_cover = false
        }
      })
    },
    update() {
      this.$emit('input', this.internal.slice())
    },
    laterUpdate() {
      this.$nextTick(this.update)
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
