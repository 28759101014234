<template>
  <div class="v-tinymce-editor">
    <textarea ref="textarea" :name="name" :value="value" />
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
/* eslint-disable-next-line */
import content_css from '!!file-loader?name=tinynce-bundle.css!extract-loader!css-loader!less-loader!modules/components/ArticleContent.less'

export default {
  props: {
    name: String,
    value: String,
  },
  data: () => ({
    instance: null,
  }),
  computed: {
    config() {
      return {
        content_css,
        target: this.$refs.textarea,
        external_plugins: {
          'powerpaste': '/vendor/tinymce-extend/plugins/powerpaste/plugin.min.js',
          'powerpaste-lang': '/vendor/tinymce-extend/plugins/powerpaste/langs/zh_TW.js',
        },
        keep_styles: false,
        force_br_newlines: false,
        force_p_newlines: true,
        forced_root_block: false,
        convert_newlines_to_brs: false,
        remove_trailing_brs: true,
        remove_linebreaks: true, // https://stackoverflow.com/a/6924616/2252696
        paste_data_images: true,
        menubar: true,
        height: 300,
        language: 'zh_TW',
        language_url: '/vendor/tinymce/langs/zh_TW.js',
        plugins: 'fullscreen code table image link textcolor colorpicker lists autolink',
        toolbar: [
          'fullscreen code',
          'formatselect styleselect',
          'fontsizeselect',
          'bold italic strikethrough forecolor backcolor',
          'table',
          'link',
          'alignleft aligncenter alignright alignjustify',
          'numlist bullist outdent indent',
          'removeformat',
        ].join(' | '),
        block_formats: 'Paragraph=p;大標=h2;小標=h3',
        fontsize_formats: '1em 1.2em 1.5em 1.7em 2.0em',
        style_formats: [
          { title: '圖表', block: 'figure' },
          { title: '圖表附註', inline: 'span', classes: 'source' },
          { title: '表格附註', block: 'div', classes: 'table-source' },
          { title: '首行縮排段落', block: 'p', classes: 'indent-paragraph' },
          { title: '附註說明錨點', inline: 'span', classes: 'remark-anchor' },
          { title: '附註說明區塊', block: 'div', classes: 'remark' },
        ],
        style_formats_autohide: true,
        setup: (editor) => {
          this.instance = editor
          editor.on('change', () => {
            this.$emit('input', editor.getContent())
            this.$emit('update:value', editor.getContent())
          })
        },
      }
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (this.instance && this.instance.selection) {
        const bookmark = this.instance.selection.getBookmark(2, true)
        this.instance.setContent(newVal)
        this.instance.selection.moveToBookmark(bookmark)
      }
    },
  },
  async mounted() {
    await tinymce.init(this.config)
  },
  beforeDestroy() {
    this.instance?.destroy()
  }
}
</script>

<style lang="stylus" scoped>
.v-tinymce-editor /deep/
  div.mce-fullscreen
    z-index 200
</style>
