import MixinAdminCommonPage from 'modules/admin/common/page'

import config from 'modules/admin/content/model/config.js'

export default {
  mixins: [
    MixinAdminCommonPage,
  ],
  computed: {
    kind() { return this.$route.params.kind },
    kindName() {
      return this.kindConfig.name
    },
    kindConfig() {
      return config[this.kind]
    },
    columns() {
      return this.kindConfig?.columns || []
    },
  },
  methods: {
  }
}
