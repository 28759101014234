
const defaultValidator = v => v

function createValidator(rules = []) {
  return {
    data() {
      return {
        __form_validation_errors: {},
        __form_validation_unwatch: null,
      }
    },
    methods: {
      bindFormValidator(form) {
        if (typeof this.$data.__form_validation_unwatch === 'function') {
          this.$data.__form_validation_unwatch()
        }
        if (typeof form !== 'string') {
          throw new Error('bindFormValidator(form): form 必須是字串')
        }
        this.$data.__form_validation_unwatch = this.$watch(form, this.clearValidation, { deep: true })
      },
      validateForm(form) {
        return rules
          .filter(({ when }) => !when || when(this))
          .reduce((valid, { field, attribute, validator, message }) => {
            if ((validator || defaultValidator)(form[field || attribute], this)) {
              return valid && true
            } else {
              if (typeof message === 'function') {
                message = message(form[field || attribute], this)
              }
              this.pushFieldError(field, message)
              this.$set(this.$data.__form_validation_errors, field, message)
              return false
            }
          }, true)
      },
      scrollToErrorField() {
        this.$nextTick(() => {
          if (typeof jQuery === 'function') {
            const scrollTop = jQuery('.field.error').offset()?.top
            scrollTop && jQuery('html, body').animate({
              scrollTop: scrollTop - 40
            }, 200)
          }
        })
      },
      clearValidation() {
        Object.keys(this.$data.__form_validation_errors).forEach(field => {
          this.$set(this.$data.__form_validation_errors, field, undefined)
        })
      },
      fieldErrorOf(field) {
        return this.$data.__form_validation_errors[field]
      },
      pushFieldError(field, message) {
        this.$set(this.$data.__form_validation_errors, field, message)
      }
    }
  }
}

function calcLength(string) {
  const isAscii = /[ -~]/ig
  const halfwidth = ((string || '').match(isAscii) || []).join('').length
  return (string || '').length - halfwidth * 0.5
}

export { createValidator, calcLength }
