<template>
  <div>
    <div ref="list" class="list">
      <ViewportCondition value="> md">
        <div class="list-head">
          <div class="row head-row">
            <div class="grid g-title" />
            <Scroll ref="head" class="column-group" @ps-scroll-x.native="updateScroll">
              <div v-for="title in titles" :key="title" class="grid g-data">
                {{ title }}
              </div>
            </Scroll>
          </div>
        </div>
        <div class="list-body">
          <!-- body-row-group要加key，卷軸才會正常 -->
          <div v-for="i in 2" :key="i" class="body-row-group">
            <template v-if="i==1">
              <div class="row body-row">
                <div class="grid g-title">
                  <span class="color-line" style="background-color:#FF895A" />
                  {{ fund.name }}
                </div>
                <Scroll ref="info" class="column-group" @ps-scroll-x.native="updateScroll">
                  <div v-for="col in tableData" :key="col.name" class="grid g-data">
                    <span>{{ col.value }}%</span>
                    <span class="price-ccy">
                      <i v-if="col.percentChange > 0" class="icon-up-dir color-positive" />
                      <i v-else-if="col.percentChange < 0" class="icon-down-dir color-negative" />
                      <span :class="col.percentChange > 0 ? 'color-positive' : 'color-negative'">{{ Math.abs(col.percentChange) | round(1) }}%</span>
                    </span>
                  </div>
                </Scroll>
              </div>
            </template>
            <template v-else>
              <div class="row body-row">
                <div class="grid g-title">
                  近期規畫
                </div>
                <Scroll ref="info" class="column-group" @ps-scroll-x.native="updateScroll">
                  <div v-for="plan in planing" :key="plan.name" class="grid g-data">
                    {{ plan.value }}
                  </div>
                </Scroll>
              </div>
            </template>
          </div>
        </div>
      </ViewportCondition>

      <ViewportCondition value="< md">
        <div class="list-head" :class="{'status-fixed': fixedHead}">
          <div class="fixed-area">
            <Scroll ref="head" @ps-scroll-x.native="updateScroll">
              <div class="row head-row">
                <div v-for="title in titles" :key="title" class="grid g-data">
                  {{ title }}
                </div>
              </div>
            </Scroll>
          </div>
        </div>
        <div class="list-body">
          <div class="body-row-group">
            <div class="row body-row">
              <div class="row-name">
                <span class="color-line" style="background-color:#FF895A" />
                {{ fund.name }}
              </div>
              <Scroll ref="info" @ps-scroll-x.native="updateScroll">
                <div class="other-info">
                  <div v-for="col in tableData" :key="col.name" class="grid g-data">
                    <span>{{ col.value }}%</span>
                    <span class="price-ccy">
                      <i v-if="col.percentChange > 0" class="icon-up-dir color-positive" />
                      <i v-else-if="col.percentChange < 0" class="icon-down-dir color-negative" />
                      <span :class="col.percentChange > 0 ? 'color-positive' : 'color-negative'">{{ Math.abs(col.percentChange) | round(1) }}%</span>
                    </span>
                  </div>
                </div>
              </Scroll>
            </div>
          </div>
          <div class="body-row-group">
            <div class="row body-row">
              <div class="row-name">
                近期規畫
              </div>
              <Scroll ref="info" @ps-scroll-x.native="updateScroll">
                <div class="other-info">
                  <div v-for="plan in planing" :key="plan.name" class="grid g-data">
                    {{ plan.value }}
                  </div>
                </div>
              </Scroll>
            </div>
          </div>
        </div>
      </ViewportCondition>
    </div>
    <div class="remark text-minor">
      <span class="remark-item">資料時間：{{ tableDate | date }}</span>
      <span class="remark-item">* 若與月報差異0.1%為進位差異，括號數字代表與上月差異數</span>
    </div>
  </div>
</template>

<script>
import MixinScrollList from 'modules/components/fund/MixinScrollList'

import ViewportCondition from 'modules/misc/ViewportCondition.vue'
import Scroll from 'modules/ui/scroll'

export default {
  components: {
    ViewportCondition,
    Scroll,
  },
  mixins: [
    MixinScrollList,
  ],
  props: {
    fund: Object,
    rawData: Array,
  },
  data() {
    return {
      titles: ['整體槓桿', '淨風險部位', '人民幣部位'],
    }
  },
  computed: {
    entries() {
      if (!this.rawData) return []
      return this.rawData.map(data => {
        return {
          'date': data.date,
          ...data['總體'],
          '人民幣部位': data['幣別分佈']['人民幣'],
        }
      })
    },
    tableData() {
      return this.titles.map(title => {
        const latest = this.entries[0]
        const old = this.entries[1]
        return {
          name: title,
          value: latest[title],
          percentChange: latest[title] - old[title],
        }
      })
    },
    tableDate() {
      return this.rawData[0].date
    },
    planing() {
      if (!this.fund) return []
      return this.titles.map(title => {
        const val = this.fund.metadata.strategies.find(data => data.name === title)?.value
        return {
          name: title,
          value: val || '-'
        }
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~modules/components/fund/scrollList.styl'
.list
  .grid
    &.g-title
      display flex
      align-items center
      flex 1 0 280px
      text-align left
    &.g-data
      flex 1 0 210px
      text-align right
      +under(md)
        flex 1 0 115px
  .body-row
    .row-name
      display flex
      align-items center
.price-ccy
  margin-left .5rem
  &:before
    content "("
  &:after
    content ")"
  +under(md)
    display block
    margin 0
// 正負
.color-positive
  color #ED4758
.color-negative
  color #00BF9C
// 備註
.remark
  margin 1.5rem
  .remark-item + .remark-item
    margin 0 0 0 1rem
  +under(md)
    .remark-item
      display block
    .remark-item + .remark-item
      margin 1rem 0 0 0
  
</style>
