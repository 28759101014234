<template>
  <div v-if="form" class="ui container">
    <form id="new_article_insight" enctype="multipart/form-data" action="/admin/article_insights" accept-charset="UTF-8" method="post" class="ui form app">
      <input name="utf8" type="hidden" value="✓">
      <input type="hidden" name="authenticity_token" value="QVCr/FoaExUrftFPajWldKdeeUwkXTR6HQFM3jW2uO3TQHQDS/vOsyMG1dW2gprAlEOvkprh4K+8dIS/UGd2mg==">

      <Field>
        <label>類型</label>
        <Checkbox v-model="form.type" radio value="月報" circled>
          月報
        </Checkbox>
        <Checkbox v-model="form.type" radio value="年報" circled>
          年報
        </Checkbox>
      </Field>

      <Field v-if="form.type === '月報'" required :error="fieldErrorOf('date')">
        <label>月份</label>
        <Calendar v-model="form.date" type="month" />
      </Field>

      <Field v-else required :error="fieldErrorOf('date')">
        <label>年份</label>
        <Calendar v-model="form.date" type="year" />
      </Field>

      <Field>
        <label>顯示</label>
        <Checkbox v-model="form.active" toggleSwitch />
      </Field>

      <Field required :error="fieldErrorOf('file')">
        <label>上傳檔案</label>
        <Attachment v-model="form.file" accept=".pdf" />
      </Field>

      <div class="ui divider" />

      <div class="rule-buttons-group">
        <div class="ui button minor" @click="abort">
          取消
        </div>
        <div class="ui button outline" @click="submit">
          儲存
        </div>
        <div class="ui button major" @click="submit({goBack: true})">
          儲存並返回
        </div>
        <div class="ui button info" @click="preview">
          即時預覽
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import MixinAdminCommonPage from 'modules/admin/common/page'
import { createValidator } from 'modules/ui/form/validation.js'

export default {
  mixins: [
    MixinAdminCommonPage,
    createValidator([{
      field: 'date',
      message: '請輸入月份/年份'
    },
    {
      field: 'file',
      message: '請上傳檔案'
    }])
  ],
  data() {
    return {
      entry: null,
      form: null
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    submitTo() {
      // 存檔或新增
      if (this.id) {
        return this.$api.fund.report.update
      } else {
        return this.$api.fund.report.create
      }
    },
  },
  watch: {
    id() {
      this.load()
    },
    form: {
      deep: true,
      handler() {
        this.clearValidation()
      }
    }
  },
  mounted() {
    this.load()
  },
  beforeDestroy() {
    this.form = null
  },
  methods: {
    abort() {
      this.backToList()
    },
    backToList() {
      // 回到列表
      this.$router.push({
        name: 'AdminFundReports',
        params: this.$route.params,
      })
    },
    submit({ goBack }) {
      if (this.validateForm(this.form)) {
        this.wait(this.submitTo({
          format: 'full',
          id: this.id,
          fund_id: this.$route.params.fundId,
          attributes: this.form,
        })).then((res) => {
          this.success('已儲存').then(() => {
            if (goBack) {
              this.backToList()
            } else if (res.data?.form?.id) {
            // 跳到新文章的頁面
              this.$router.push({
                name: 'AdminFundEdit',
                params: {
                  ...this.$route.params,
                  id: res.data.form.id,
                }
              })
            }
          })
        })
      }
    },
    preview() {},
    async load() {
      await this.loadEntry()
    },
    async loadEntry() {
      if (this.id) {
        this.form = await this.$api.fund.report({ id: this.id, format: 'full' }).then(res => res.data.entry)
      } else {
        this.form = {
          type: '月報',
          date: ''
        }
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
.remark
  margin-top 2rem
  color $gray2

.filed-error
  font-size 12px
  line-height 1.8
  margin-top 0.5em
  color #ed4758
</style>
