import { render, staticRenderFns } from "./Resend.vue?vue&type=template&id=1b0abb2e&scoped=true&"
import script from "./Resend.vue?vue&type=script&lang=js&"
export * from "./Resend.vue?vue&type=script&lang=js&"
import style0 from "./Resend.vue?vue&type=style&index=0&id=1b0abb2e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b0abb2e",
  null
  
)

export default component.exports