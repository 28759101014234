/*
  FallbackPromise
  Author: Lackneets Chang <lackneets@gmail.com>

  Usage:

  function createPromise(){
    new FallbackPromise((resolve, reject) => {
      reject(new Error('error occurred'))
    }, () => {
      console.log('uncaught promise')
    })
  }

  // Example 1:
  createPromise().catch(error => {
    console.log('已接收錯誤訊息', error)
  })

  // Example 2: "uncaught promise"
  createPromise()

*/
let counter = 1
export default class FallbackPromise extends Promise {
  constructor(callback, fallback = () => {}) {
    let state = 'Pending'
    let leaf
    let fallbackHandler = fallback
    super((defaultResolve, defaultReject) => {
      const rejectHandler = (reason) => {
        // console.log('*reject', reason, state)
        state = 'Rejected'
        setTimeout(() => {
          if (this.isLeaf && state === 'Rejected') {
            fallbackHandler(reason)
          }
        }, 100)
        defaultReject(reason)
      }
      const resolveHandler = async(result, test) => {
        // console.log('*resolve', result)
        state = 'Resolved'
        if (result instanceof Promise) {
          console.error('Avoid using async callback in promise')
        }
        defaultResolve(result)
      }
      callback(resolveHandler, rejectHandler)
    })

    leaf = this

    Object.defineProperty(this, 'state', {
      get: () => state
    })
    Object.defineProperty(this, 'isLeaf', {
      get: () => leaf === this,
    })
    Object.defineProperty(this, 'leaf', {
      get: () => leaf,
      set: (val) => {
        leaf = val
      },
    })
    Object.defineProperty(this, 'fallbackHandler', {
      get: () => fallbackHandler,
      set: (val) => {
        fallbackHandler = val
      },
    })

    this.id = `#FallBackPromise-${counter++}`
    return this
  }

  then(onResolve, onReject) {
    const promise = Promise.prototype.then.call(this, onResolve, onReject)
    this.leaf = promise
    this.leaf.fallbackHandler = this.fallbackHandler
    return promise
  }

  catch(onReject) {
    const promise = Promise.prototype.catch.call(this, onReject)
    this.leaf = promise
    this.leaf.fallbackHandler = this.fallbackHandler
    return promise
  }

  finally(onFinally) {
    return Promise.prototype.then.call(this, onFinally, onFinally)
  }
}
