<!--
  entries 格式：要有 text 跟 value
    entries: [
      {
        text: '全部',
        value: 'all',
      },
      {
        text: '資產配置',
        value: 'asset',
      },
    ],
-->
<template>
  <DropdownButton ref="dropdown" v-model="innerValue" size="small" fluid>
    <template #input>
      <slot name="icon">
        <i class="icon-filter-o icon" style="margin-right:0.75rem" />
      </slot>
      <span>{{ displayText }}</span>
    </template>
    <template #menu>
      <div v-for="option in entries" :key="option.value" :data-value="option.value" class="item">
        {{ option.text }}
      </div>
    </template>
  </DropdownButton>
</template>

<script>
import DropdownButton from 'modules/ui/form/dropdownButton'

export default {
  components: {
    DropdownButton,
  },
  props: {
    entries: Array,
    topicPrefix: String,
    value: String,
  },
  data() {
    return {
      innerValue: this.value || this.entries[0]?.value,
    }
  },
  computed: {
    displayText() {
      const text = this.entries.find((option) => option.value === this.innerValue)?.text
      return this.topicPrefix ? this.topicPrefix + text : text
    },
  },
  watch: {
    value() {
      this.innerValue = this.value
    },
    innerValue() {
      if (this.innerValue !== this.value) {
        this.$emit('input', this.innerValue)
      }
    },
  },
}
</script>
