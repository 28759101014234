<template>
  <div class="member-panel-layout">
    <div class="panel-wrap" :class="{'full-height': current.step !== 4}">
      <div class="panel-illustration pen">
        <img src="~images/img-illustration-16.png" alt="">
      </div>

      <div v-if="current.step === 4" class="panel">
        <div class="welcome">
          <div class="emphasize-text">
            歡迎成為我們的銷售夥伴！
          </div>
          <div>現在就去看看有哪些資源可以幫助銷售</div>
        </div>
        <router-link :to="{name: 'Partner'}" class="ui button fluid major">
          開始使用
        </router-link>
      </div>
      <div v-else class="panel">
        <div class="panel-head">
          <div class="head-cont">
            <div class="head-title">
              銷售夥伴註冊
            </div>
            <div class="progress-bar">
              <span class="dot active" />
              <span class="line" :class="{active: current.step > 1}" />
              <span class="dot" :class="{active: current.step > 1}" />
              <span class="line" :class="{active: current.step > 2}" />
              <span class="dot" :class="{active: current.step > 2}" />
            </div>
          </div>
        </div>
        <div v-show="current.step === 1" class="step1">
          <Step1 @submit="submitStep1" />
        </div>

        <div v-show="current.step === 2" class="step2">
          <Step2 @back="prevStep"
                @submit="submitStep2"
          />
        </div>

        <div v-if="current.step === 3" class="step3">
          <Authentication :email="info.step1 && info.step1.username"
                          authType="email"
                          @confirm="nextStep"
          />
        </div>
      </div>

      <div class="panel-illustration person">
        <img src="~images/img-illustration-15.png" alt="">
      </div>
    </div>

    <MemberTerm v-if="showTerm" @agree="agreeTerm" @disagree="hideTerm" />
  </div>
</template>

<script>
import API from 'api'
import Step1 from 'pages/member/component/_formStep1'
import Step2 from 'pages/member/component/_formStep2'
import Authentication from 'pages/member/Authentication'
import MemberTerm from 'modules/components/MemberTerms'
import { MixinNotifiable } from 'modules/ui/notification.vue'

export default {
  components: {
    Step1,
    Step2,
    Authentication,
    MemberTerm,
  },
  mixins: [
    MixinNotifiable
  ],
  data() {
    return {
      current: {
        step: 1,
      },
      info: {
        step1: {},
        step2: {},
      },
      showTerm: false,
    }
  },
  methods: {
    nextStep() {
      this.current.step += 1
    },
    prevStep() {
      this.current.step -= 1
    },
    async signup(params) {
      return await API.member.signup(params).then(res => res.data)
    },
    async submitStep1(form) {
      this.info.step1 = form
      this.wait(this.signup(form), { message: '請稍後...' })
        .then((res) => {
          this.showTerm = true
        })
    },
    async submitStep2(form) {
      this.info.step2 = form
      this.wait(this.signup(Object.assign({}, this.info.step1, this.info.step2)), { message: '請稍後...' })
        .then(({ member, message }) => {
          if (member) {
            // 登入狀態
            this.$store.commit('member/setProfile', member)
          }
          this.nextStep()
        })
    },
    agreeTerm() {
      this.nextStep()
      this.showTerm = false
    },
    hideTerm() {
      this.showTerm = false
    }
  },
}
</script>

<style lang="stylus" scoped>
@require '~pages/member/memberPanel.styl'
.panel-head
  .head-cont
    display inline-block
    margin auto
  .head-title
    margin-bottom 1rem
  .progress-bar
    display flex
    justify-content space-between
    align-items center
    .dot
      width 10px
      height 10px
      border-radius 50%
      border 2px solid #DDD
      &.active
        border-color #FF895A
    .line
      width calc((100% - 30px)/2)
      height 1px
      background-color #DDD
      &.active
        background-color #FF895A
.welcome
  text-align center
  margin-bottom 3rem
  .emphasize-text
    font-size 1.44rem
    font-weight bold
    margin-bottom .5rem

</style>
