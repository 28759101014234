<template>
  <div v-if="article" class="basic__container">
    <div class="main-article">
      <h2 class="basic__topic article-title">
        {{ article.title }}
      </h2>
      <div class="tools">
        <div class="left-side">
          <router-link :to="{name: 'Bulletin'}" class="ui button round small minor" style="margin-right: 1rem;">
            重要公告
          </router-link>
          <div class="date font-bold">
            {{ article.publish_at | date }}
          </div>
        </div>
        <div class="right-side">
          <ShareIcon :link="article.share_url" :direction="$deviceView.desktop ? 'ani-left' : 'ani-down'" />
          <div v-if="article.attachment_download_url" class="tool-icon">
            <a :href="article.attachment_download_url" class="icon download" data-tooltip-title="下載檔案" />
          </div>
        </div>
      </div>
      <ArticleContent v-html="article.content" />
    </div>
    <div v-if="otherArticle.length > 0" class="others">
      <Announcement :specifyList="otherArticle">
        <template #header>
          <div class="topic-head">
            <div class="title">
              其他重要公告
            </div>
            <router-link :to="{name: 'Bulletin'}" class="btn-more">
              更多>
            </router-link>
          </div>
        </template>
      </Announcement>
    </div>
  </div>
</template>

<script>
import API from 'api'
import ArticleContent from 'modules/components/ArticleContent.vue'
import Announcement from 'modules/widgets/Announcement'
import ShareIcon from 'modules/components/SocialShare'

export default {
  components: {
    ArticleContent,
    Announcement,
    ShareIcon,
  },
  data() {
    return {
      article: '',
      kind: 'bulletin',
      otherArticle: [],
    }
  },
  computed: {
    articleId() {
      return this.$route.params.id
    },
  },
  watch: {
    articleId() {
      this.load()
      this.scrollTop()
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      this.article = await API.content({ id: this.articleId }).then(res => res.data.entry)
      this.$setTitle(this.article.title)
      this.otherArticle = await API.content.list({ kind: this.kind })
        .then(res => res.data.entries.filter(e => e.slug !== this.articleId).splice(0, 4))
    },
    scrollTop() {
      const $ = jQuery
      $(this.$el).parent().scrollTop(0)
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.basic__container
  padding-bottom 5rem
.main-article
  position relative
  margin auto
  max-width 800px
  &:before
    content ''
    width 158px
    height 128px
    left calc(100% + 30px)
    top -40px
    position absolute
    background-image url('~images/animation/img-8.0.1.svg')
    background-repeat no-repeat
    background-position center center
    background-size contain
    image-rendering -webkit-optimize-contrast
    +under(md)
      display none
  .article-title
    margin-bottom 1rem
  .tools
    display flex
    justify-content space-between
    margin 1rem 0 2rem
    .left-side, .right-side
      display flex
      align-items center
    .date
      color $gray2
  .tool-icon
    width 36px
    margin-left .5rem
    cursor pointer
    .icon
      display block
      width 100%
      padding-bottom 100%
      background-repeat no-repeat
      background-position center center
      background-size contain
      &.download
        background-image url('~images/buttons/btn-download.png')
        &:hover
          background-image url('~images/buttons/btn-download-alt.png')
.others
  margin-top 10rem
.topic-head
  display flex
  justify-content space-between
  align-items center
  font-weight bold
  margin-bottom 1rem
  .title
    font-size 1.728rem
    line-height 31px
  .btn-more
    color $gray2
    font-size 1.2rem
</style>
