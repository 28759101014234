const siteName = '大拇哥投顧UG基金服務'

export default {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        $setTitle(title, delay = 100) {
          setTimeout(() => {
            if (title) {
              document.title = `${title} - ${siteName}`
            } else {
              document.title = siteName
            }
          }, delay)
        }
      }
    })
  }
}
