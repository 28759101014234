<template>
  <div class="basic__container">
    <h2 class="basic__topic">
      經理人觀點
    </h2>
    <UiLoader v-if="!guestMode" :loading="!loadingStateReady">
      <div class="tool-bar">
        <div class="result">
          {{ totalArticles }} 個符合的影片
        </div>
        <div class="dropdown-wrap">
          <DropdownFilter :value="current.category" :entries="filterOptions" topicPrefix="發表時間：" @input="resetCategory" />
        </div>
      </div>
      <div class="list">
        <div v-for="item in current.articles" :key="item.id" class="list-item">
          <Card :card="item"
                @showVideo="showVideo(item)"
          />
        </div>
      </div>
      <div v-if="current.articles.length < totalArticles" class="btn-wrap">
        <div class="ui button major large" @click="loadMore">
          <i class="icon-more" style="margin-right: .75rem;" />查看更多內容
        </div>
      </div>
    </UiLoader>

    <ModalVideo ref="modalVideo" :video="playVideo" @hidden="closeVideo" />
  </div>
</template>

<script>
import API from 'api'
import DropdownFilter from 'modules/components/DropdownFilter'
import UiLoader from 'modules/ui/loader'
import Card from 'modules/components/PerspectiveCard'
import ModalVideo from 'modules/components/ModalVideo'

export default {
  components: {
    DropdownFilter,
    UiLoader,
    Card,
    ModalVideo,
  },
  data() {
    return {
      current: {
        articles: [],
        category: this.$route.params.category || 'all',
      },
      kind: 'perspective',
      categories: null,
      accumulatedPage: 1,
      playVideo: null,
      routeCategory: { // 目錄的 route，關掉影片會導到這
        name: 'Perspectives'
      }
    }
  },
  computed: {
    filterOptions() {
      const defaultOption = { text: '全部', value: 'all', articles_count: 0 }
      if (!this.categories) return [defaultOption]
      defaultOption.articles_count = this.categories.reduce((prev, next) => {
        return prev + Number(next.articles_count)
      }, 0)
      const options = this.categories.map(item => {
        return {
          text: item.month,
          value: item.month,
          articles_count: item.articles_count
        }
      })
      return [
        defaultOption,
        ...options,
      ]
    },
    totalArticles() {
      return this.filterOptions.find(option => option.value === this.current.category)?.articles_count
    },
    guestMode() {
      return !this.$member
    },
  },
  watch: {
    // 切換目錄更換網址
    'current.category'(category) {
      this.$router.push({
        name: 'PerspectiveCategory',
        params: {
          category,
        }
      }).catch(() => {})
      this.setTitle()
    },
  },
  async mounted() {
    // 必須有登入才載入
    if (!this.guestMode) {
      await this.loadMore()
      this.categories = await API.content.categories({ kind: this.kind }).then(res => res.data.entries)
    }
    // 嘗試播放影片(單頁模式)
    this.tryPlayVideo()
    this.setTitle()
  },
  methods: {
    async loadList() {
      return await API.content.list({ kind: this.kind, page: this.accumulatedPage }).then(res => res.data.entries)
    },
    async loadCategoryList() {
      // 目錄下的文章
      return await API.content.categories(
        {
          kind: this.kind,
          id: this.current.category,
          page: this.accumulatedPage
        }
      ).then(res => res.data.entries)
    },
    async loadMore() {
      if (this.current.category === 'all') {
        this.current.articles.push(...await this.loadList())
      } else {
        this.current.articles.push(...await this.loadCategoryList())
      }
      this.accumulatedPage += 1
      return this.current.articles
    },
    async resetCategory(name) {
      if (name) {
        this.current.category = name // 手動更新
      }
      this.accumulatedPage = 1
      this.current.articles = []
      this.loadMore()
    },
    showVideo(video) {
      this.playVideo = video
      this.$refs.modalVideo.show()
      if (this.$route.name === 'Perspectives') {
        this.routeCategory = this.$route
      }
      this.$router.replace({
        name: 'PerspectiveShow',
        params: {
          id: video.slug
        }
      }).catch(() => {})
      this.$setTitle(`${video.subtitle} - 經理人觀點`)
    },
    closeVideo() {
      this.$setTitle(this.current.category ? `${this.current.category} 經理人觀點` : '經理人觀點')
      // 影片關閉後回到列表頁的網址
      if (this.routeCategory) {
        this.$router.replace(this.routeCategory).catch(() => {})
      }
    },
    async tryPlayVideo() {
      // 如果是單頁模式，打開影片
      if (this.$route.name === 'PerspectiveShow') {
        const video = await API.content({ kind: this.kind, id: this.$route.params.id }).then(res => res.data.entry)
        this.showVideo(video)
      }
    },
    setTitle() {
      if (this.current.category !== 'all') {
        this.$setTitle(`${this.current.category} 經理人觀點`)
      } else {
        this.$setTitle('經理人觀點')
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'

.basic__container
  position relative
  padding-bottom 14rem
  +under(md)
    padding-bottom 17rem
  &:after
    content ''
    position absolute
    width 200px
    height 180px
    background-image url('~images/animation/img-0.0.1.svg')
    background-repeat no-repeat
    background-position center center
    background-size contain
    +over(md)
      left 0
      bottom 68px
    +under(md)
      right 14px
      bottom 28px
.basic__topic
  position relative
  &:before
    content ''
    position absolute
    right -25px
    top -8px
    width 114px
    height 73px
    background-image url('~images/animation/img-1.0.7.svg')
    background-repeat no-repeat
    background-position center center
    background-size contain
    +under(md)
      right 0
.tool-bar
  margin 2rem 0
  +over(md)
    display flex
    align-items center
    justify-content space-between
  .result
    font-size 1.2rem
    font-weight bold
    +under(md)
      margin-bottom 1rem
.list
  +over(md)
    display flex
    flex-wrap wrap
  .list-item
    +over(md)
      width calc((100% - 2rem)/ 2)
      margin-bottom 5rem
      margin-right 2rem
      &:nth-child(2n)
        margin-right 0
    +under(md)
      width 100%
      margin-bottom 2rem
.btn-wrap
  text-align center
  +under(md)
    margin-top 2.5rem
</style>
