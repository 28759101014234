const $ = jQuery

// Semantic UI defaults
$.fn.dropdown.settings.transition = 'fade in'
$.fn.popup.settings.transition = 'fade in'
$.fn.search.settings.transition = 'fade in'
$.fn.dropdown.settings.duration = 200
$.fn.popup.settings.duration = 200
$.fn.modal.settings.duration = 200
$.fn.dimmer.settings.duration = 200
$.fn.search.settings.inverted = true
