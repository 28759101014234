<template>
  <div>
    <Dropdown v-model="value" class="selection" :class="{disabled}" @update:value="val => $emit(onUpdate, model, val)">
      <i class="dropdown icon" />
      <div class="default text">
        <span>{{ placeholder }}</span>
      </div>
      <div class="menu">
        <div v-for="option in options" :key="option[valueKey]" :data-value="option[valueKey]" class="item">
          {{ option[labelKey] }}
        </div>
      </div>
    </Dropdown>
  </div>
</template>

<script>
import Dropdown from 'modules/ui/form/dropdown'
export default {
  components: {
    Dropdown,
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    valueKey: {
      type: String,
      default: 'value'
    },
    placeholder: {
      type: String,
      default: '請選擇'
    },
    disabled: Boolean,
  },
  computed: {
    currentSelected() {
      return this.options.find(option => String(option[this.valueKey]) === String(this.value))
    },
  }
}
</script>
