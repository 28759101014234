<template>
  <div @click="focus">
    <div class="mail-info">
      <div class="word">
        驗證碼已寄出至您的電子信箱
      </div>
      <div>{{ email }}</div>
    </div>
    <div class="enter-code">
      <div>驗證碼有效時間5分鐘，請立即前往電子信箱收信，填入驗證碼完成註冊：</div>
      <div class="code-box">
        <UiInput
          v-for="i in 6"
          ref="input"
          :key="i" v-model="codebox[i-1]"
          class="code-input"
          autoselect
          :autofocus="i == 1"
          :maxlength="1"
          @keypress.native="onlyNumber"
          @keyup.native="event => goNextInput(event, i)"
        />
      </div>
      <div v-if="remainTime" class="text-minor" style="font-size:12px;">
        剩餘有效秒數：{{ remainTime }}秒
      </div>
      <div v-else>
        <span style="margin-right: .5rem;">沒收到驗證碼？</span>
        <a href="javascript:void(0)" class="blue-link" @click.stop="resendToken">重新發送</a>
      </div>
    </div>
    <!-- https://app.asana.com/0/1115102721840049/1200219162815320/f
    <div class="actions">
      <div class="ui button fluid major" @click="confirm">
        進行驗證
      </div>
    </div>
    -->
  </div>
</template>

<script>
import API from 'api'
import UiInput from 'modules/ui/form/input'
import { MixinNotifiable } from 'modules/ui/notification.vue'

export default {
  components: {
    UiInput,
  },
  mixins: [
    MixinNotifiable
  ],
  props: {
    email: String,
    authType: {
      type: String,
      default() {
        return 'email'
      },
    }
  },
  data() {
    return {
      remainTime: 300,
      timer: null,
      codebox: [],
    }
  },
  computed: {
    token() {
      return this.codebox.join('')
    },
  },
  watch: {
    remainTime(time) {
      if (time <= 0) clearInterval(this.timer)
    },
  },
  mounted() {
    this.countdown()
    this.focus()
  },
  methods: {
    countdown() {
      this.remainTime = 300
      this.timer = setInterval(() => {
        this.remainTime -= 1
      }, 1000)
    },
    focus() { // 自動 focus 第一個輸入框
      this.$refs.input[0].focus()
    },
    onlyNumber(event) {
      if (!event.key.match(/[0-9]/)) {
        event.preventDefault()
      }
    },
    goNextInput(event, nowIndex) {
      if (event.key.match(/[0-9]/)) {
        if (nowIndex === 6) {
          // 輸入完自動驗證
          this.confirm()
        } else {
          this.$refs.input[nowIndex].focus()
        }
      } else if (event.key === 'Backspace' && nowIndex > 1) {
        this.$refs.input[nowIndex - 2].focus()
      }
    },
    resendToken() {
      let request
      if (this.authType === 'email') {
        request = API.member.resendToken({
          username: this.email,
          confirm_for: this.authType,
        })
      } else if (this.authType === 'password') {
        request = API.member.forget({
          username: this.email,
        })
      }
      request && this.wait(request, { message: '發送中請稍後...' })
        .then((res) => {
          this.countdown()
          this.success(`${res.data.message}`).then(this.focus)
        })
    },
    confirm() {
      const params = {
        username: this.email,
        token: this.token,
        confirm_for: this.authType,
      }
      this.wait(API.member.confirm(params), { message: '驗證中請稍後...' })
        .then(res => {
          const { message, member, token } = res.data
          if (member) {
            this.$store.commit('member/signin', { member, token })
          }
          if (this.authType === 'email') {
            this.$store.commit('member/activated')
          }
          // this.success(message)
          this.$emit('confirm', this.token)
        }).catch((error) => {
          // 失敗的話自動 focus 到第一格
          this.codebox = []
          this.focus()
          throw error // 使用預設錯誤訊息
        })
    },
  },
}
</script>

<style lang="stylus" scoped>
.mail-info
  text-align center
  margin-bottom 3rem
  .word
    font-size 1.44rem
    font-weight bold
    margin-bottom 0.5rem
.enter-code
  margin-bottom 3rem
  .code-box
    display flex
    justify-content space-between
    margin .75rem 0
  .code-input
    flex 0 0 40px
.actions
  margin-bottom 1rem
</style>
