<template>
  <Checkbox v-model="value" toggleSwitch @input="val => $emit('toggle', model.id, column, val)" v-on="$listeners" />
</template>

<script>
import Checkbox from 'modules/ui/form/checkbox'
export default {
  components: {
    Checkbox
  }
}
</script>
