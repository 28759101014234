import { render, staticRenderFns } from "./ModalColumnSelection.vue?vue&type=template&id=1525f525&scoped=true&"
import script from "./ModalColumnSelection.vue?vue&type=script&lang=js&"
export * from "./ModalColumnSelection.vue?vue&type=script&lang=js&"
import style0 from "./ModalColumnSelection.vue?vue&type=style&index=0&id=1525f525&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1525f525",
  null
  
)

export default component.exports