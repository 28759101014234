<template>
  <div class="basic__container">
    <h2 class="basic__topic">
      交易日曆
    </h2>
    <div v-if="rawData" class="calendar">
      <div class="calendar-controller">
        <div v-if="showIconButton('prev')" class="icon-button third prev" @click="changeMonth(-1)">
          <i class="icon-left-open" />
        </div>
        <div class="calendar-dropdown">
          <DropdownFilter v-model="displayPeriod" :entries="periodOptions">
            <template #icon>
              <i class="icon-calendar" style="margin-right:0.75rem" />
            </template>
          </DropdownFilter>
        </div>
        <div v-if="showIconButton('next')" class="icon-button third next" @click="changeMonth(1)">
          <i class="icon-right-open" />
        </div>
      </div>
      <div class="week-head">
        <div v-for="data in weekTitle" :key="data.title" class="grid" :class="{weekend: data.weekend}" v-text="data.title" />
      </div>
      <div v-for="row in 6" :key="row" class="week-row">
        <div v-for="col in 7" :key="col"
             :data-day="getDayFrom({row, col})"
             class="grid" :class="weekDateClasses({row, col})"
        >
          <div class="day">
            {{ getDayFrom({row, col}) }}
          </div>
          <div class="content">
            <template v-if="getTradingsAt(getYmdFrom({row, col})).length > 0">
              <div v-for="trade in getTradingsAt(getYmdFrom({row, col}))" :key="trade.id" class="event-box">
                <div class="month-circle">
                  <span>{{ getTradeMonth(trade) }}月</span>
                </div>
                <div class="event-name">
                  <span class="font-bold">
                    {{ trade.trading_event.kind }}
                  </span>
                  <span style="font-size:12px;">
                    {{ trade.trading_event.subject }}
                  </span>
                </div>
                <template v-if="canSubscribe(trade)">
                  <div v-if="trade.subscribed" class="icon-button third small on" @click="unsubscribe(trade.id)">
                    <i class="icon-follow-achieve" />
                  </div>
                  <div v-else class="icon-button third small" @click="subscribe(trade.id)">
                    <i class="icon-follow" />
                  </div>
                </template>
              </div>
            </template>
            <div v-if="getHolidayAt(getYmdFrom({row, col})).length > 0" class="holiday-box">
              <div v-for="holiday in getHolidayAt(getYmdFrom({row, col}))" :key="holiday.id" class="holiday-name">
                {{ holiday.subject }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$deviceView.tablet" class="monthly-event">
      <div v-for="trade in monthlyTrading" :key="trade.date" :class="eventListClasses(trade)" class="event-item">
        <div class="date">
          {{ trade.month }}月 {{ trade.day }}日
        </div>
        <div class="content">
          <div v-for="(item, i) in trade.content" :key="i" class="event-box">
            <div class="main-data">
              <div class="month-circle">
                <span>{{ getTradeMonth(item) }}月</span>
              </div>
              <div class="event-name">
                <span class="font-bold">
                  {{ item.trading_event.kind }}
                </span>
                <span style="font-size:12px;margin-left:4px;">
                  {{ item.trading_event.subject }}
                </span>
              </div>
            </div>
            <div v-if="canSubscribe(trade)" class="action">
              <div v-if="item.subscribed" class="icon-button third on" @click="unsubscribe(item.id)">
                <i class="icon-follow-achieve" />
              </div>
              <div v-else class="icon-button third" @click="subscribe(item.id)">
                <i class="icon-follow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="illustration">
      <img src="~images/img-illustration-8.png" alt="">
    </div>
  </div>
</template>

<script>
import API from 'api'
import { MixinNotifiable } from 'modules/ui/notification.vue'
import DropdownFilter from 'modules/components/DropdownFilter'

export default {
  components: {
    DropdownFilter,
  },
  mixins: [
    MixinNotifiable,
  ],
  data() {
    return {
      weekTitle: [
        { title: '日', weekend: true, },
        { title: '一', weekend: false, },
        { title: '二', weekend: false, },
        { title: '三', weekend: false, },
        { title: '四', weekend: false, },
        { title: '五', weekend: false, },
        { title: '六', weekend: true, },
      ],
      today: {
        year: 0,
        month: 0,
        day: 0,
        weekday: 0 // 星期幾
      },
      calendar: {
        year: 0,
        month: 0,
        day: 0,
        weekday: 0 // 星期幾
      },
      rawData: null,
    }
  },
  computed: {
    calendarFirstDay() {
      // 取得當月的第一個日期(用當月一號的星期往回推)
      const monthDate = new Date(this.calendar.year, this.calendar.month, 1)
      const calendarDate = new Date(this.calendar.year, this.calendar.month, 1 - monthDate.getDay())
      return {
        year: calendarDate.getFullYear(),
        month: calendarDate.getMonth(),
        day: calendarDate.getDate(),
        weekday: calendarDate.getDay(),
      }
    },
    monthlyCalendar() {
      const data = []
      for (let i = 0; i < 42; i++) {
        const date = new Date(this.calendarFirstDay.year, this.calendarFirstDay.month, this.calendarFirstDay.day + i)
        data.push({
          year: date.getFullYear(),
          month: date.getMonth(),
          day: date.getDate(),
          weekday: date.getDay(),
          ymd: moment(date, moment.HTML5_FMT.DATE).format('YYYY-MM-DD'),
          value: date,
        })
      }
      return data
    },
    tradingDate() {
      if (!this.rawData) return null
      const sortRule = {
        '認購交易日': 1,
        '認購通知截止': 2,
        '認購最後到款日': 3,
        '贖回交易日': 4,
        '贖回通知截止': 5,
      }
      return this.rawData.filter(d => d.kind === '交易').map(item => {
        const type = `${item.trading_event.kind}${item.trading_event.subject}`
        return {
          ...item,
          order: sortRule[type],
        }
      })
    },
    rawHoliday() {
      if (!this.rawData) return null
      return this.rawData.filter(data => data.kind === '假日')
    },
    holidays() {
      // 連續假期拆成一個個獨立的日期
      const singleDay = this.rawHoliday.filter(data => !data.date_end).map(data => {
        return {
          id: data.id,
          subject: data.subject,
          date: data.date_start,
          subscribed: data.subscribed,
        }
      })
      const manyDays = this.rawHoliday.filter(data => data.date_end)
      const otherDay = []
      manyDays.forEach(holiday => {
        const days = this.getDaysArray(holiday.date_start, holiday.date_end)
        days.forEach(day => {
          otherDay.push({
            id: holiday.id,
            subject: holiday.subject,
            date: day,
            subscribed: holiday.subscribed,
          })
        })
      })
      return [
        ...singleDay,
        ...otherDay,
      ].sort((a, b) => a.date > b.date ? 1 : -1)
    },
    periodOptions() {
      const allMonth = this.tradingDate.concat(this.holidays).map(data => moment(data.date, moment.HTML5_FMT.DATE).format('YYYY-MM'))
      return allMonth.filter((month, index, arr) => arr.indexOf(month) === index)
        .map(month => {
          return {
            text: month,
            value: month,
          }
        })
    },
    displayPeriod: {
      get() {
        // 用來顯示的月份要 +1
        const str = `${this.calendar.year.toString()}-${(this.calendar.month + 1).toString()}`
        return moment(str, moment.HTML5_FMT.DATE).format('YYYY-MM')
      },
      set(val) {
        this.calendar.year = moment(val, moment.HTML5_FMT.DATE).year()
        this.calendar.month = moment(val, moment.HTML5_FMT.DATE).month()
      }
    },
    monthlyTrading() {
      // 當月月曆的交易事件
      if (!this.tradingDate) return false
      const start = this.monthlyCalendar[0].ymd
      const end = this.monthlyCalendar[this.monthlyCalendar.length - 1].ymd
      const allData = this.tradingDate.filter(trading => moment(trading.date).isBetween(start, end))
      const tradingDays = allData.map(data => data.date)
        .filter((date, index, arr) => arr.indexOf(date) === index)
        .sort((a, b) => moment(a) > moment(b) ? 1 : -1)
      return tradingDays.map(day => {
        const events = allData.filter(data => data.date === day).map(data => data).sort((a, b) => a.order - b.order)
        const subscribed = allData.filter(data => data.date === day).every(data => data.subscribed === true)
        return {
          date: day,
          month: moment(day).format('M'),
          day: moment(day).format('D'),
          content: events,
          subscribed,
        }
      })
    },
  },
  async mounted() {
    this.setToday()
    await this.loadCalendar()
  },
  methods: {
    setToday() {
      const date = new Date()
      this.today.year = this.calendar.year = date.getFullYear()
      this.today.month = this.calendar.month = date.getMonth() // 0~11
      this.today.day = this.calendar.day = date.getDate()
      this.today.weekday = this.calendar.weekday = date.getDay()
    },
    changeMonth(num) {
      // 有空再優化
      const month = this.calendar.month + num
      if (month > 11) {
        this.calendar.year += 1
        this.calendar.month = month - 12
      } else if (month < 0) {
        this.calendar.year -= 1
        this.calendar.month = month + 12
      } else {
        this.calendar.month = month
      }
    },
    weekDateClasses({ row, col }) {
      // 桌機版樣式
      const target = this.monthlyCalendar[(row - 1) * 7 + (col - 1)]
      const isToday = this.today.year === target.year && this.today.month === target.month && this.today.day === target.day
      return {
        'today': isToday,
        'other-month': this.calendar.month !== target.month,
        'past': target.value < new Date() && !isToday,
        'have-trade': this.getTradingsAt(target.ymd).length > 0,
        'weekend': target.weekday === 0 || target.weekday === 6,
      }
    },
    eventListClasses(trade) {
      // 事件清單樣式
      const today = moment().format('YYYY-MM-DD')
      return {
        'today': today === trade.date,
        'past': moment(trade.date, moment.HTML5_FMT.DATE).isBefore(today),
        'have-trade': this.getTradingsAt(trade.date).length > 0,
      }
    },
    async loadCalendar(year) {
      const params = {}
      if (year) params.year = year
      await API.calendar(params).then(res => {
        this.rawData = res.data.result
      })
    },
    getTradingsAt(ymd) {
      return this.tradingDate.filter(trading => trading.date === ymd).sort((a, b) => a.order - b.order)
    },
    getHolidayAt(ymd) {
      return this.holidays.filter(holiday => holiday.date === ymd)
    },
    getDayFrom({ row, col }) {
      return this.monthlyCalendar[(row - 1) * 7 + (col - 1)].day
    },
    getMonthFrom({ row, col }) {
      // notice: 資料的 month 範圍是 0-11
      return this.monthlyCalendar[(row - 1) * 7 + (col - 1)].month + 1
    },
    getYmdFrom({ row, col }) {
      return this.monthlyCalendar[(row - 1) * 7 + (col - 1)].ymd
    },
    getTradeMonth(trade) {
      // 交易歸屬的月份
      return moment(trade.trade_month, moment.HTML5_FMT.DATE).format('M')
    },
    getDaysArray(startDay, stopDay) {
      const dateArray = []
      let currentDay = moment(startDay, moment.HTML5_FMT.DATE)
      stopDay = moment(stopDay, moment.HTML5_FMT.DATE)
      while (currentDay <= stopDay) {
        dateArray.push(moment(currentDay, moment.HTML5_FMT.DATE).format('YYYY-MM-DD'))
        currentDay = moment(currentDay, moment.HTML5_FMT.DATE).add(1, 'days')
      }
      return dateArray
    },
    subscribe(id) {
      this.wait(API.calendar.subscribe({ event_id: id }), { message: '請稍後...'})
        .then(res => {
          const description = '系統將於3日前發送通知至您帳號信箱，您亦可隨時點擊圖示解除通知。'
          this.success(description, {title:`${res.data.message}`, timeout: 3000})
          this.loadCalendar()
        })
    },
    unsubscribe(id) {
      this.wait(API.calendar.unsubscribe({ event_id: id }), { message: '請稍後...' })
        .then(res => {
          const description = '您已解除交易日曆通知，可隨時點擊圖示設定通知。'
          this.notify(description, {title:`${res.data.message}`, timeout: 3000})
          this.loadCalendar()
        })
    },
    showIconButton(btn) {
      if (btn === 'prev') return this.displayPeriod !== this.periodOptions[0].value
      if (btn === 'next') return this.displayPeriod !== this.periodOptions[this.periodOptions.length - 1].value
    },
    canSubscribe(trade) {
      return moment().isBefore(trade.date)
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.basic__container
  padding-bottom 0px
.basic__topic
  margin-bottom 2rem
.calendar
  .week-head
    display flex
    background-color #fbfbfb
    > .grid
      flex-grow 1
      flex-shrink 1
      flex-basis 0
      text-align center
      line-height 42px
      font-weight bold
      border 1px solid #ededed
      padding 0 7px // 為了讓IE格子能對齊
      &:last-child
        border-right none
  .week-row
    display flex
    background-color #fff
    > .grid
      flex-grow 1
      flex-shrink 1
      flex-basis 0
      padding 7px
      min-height 112px
      border 1px solid #ededed
      &:last-child
        border-right none
      &.today
        border 1px solid #2673AD
        .day
          font-size 1.2rem
          font-weight bold
          color #08548E
      &.other-month // 非當月
          .day
            color $gray2
      &.past // 日期已過
        .content
          color $gray2
        .event-box
          color $gray2
      &.have-trade
        background-color #FEF9F4
        .day
          font-size 1.2rem
        &:hover
          background-color #F0F8FF
    .day
      flex-grow 0
      flex-shrink 0
      flex-basis 20px
      font-weight bold
    .content
      margin-top .5rem
      line-height 18px
    .event-box
      display flex
      align-items center
      font-weight bold
      color #08548E
      .month-circle, .event-name
        margin-right .5rem
    .event-box + .event-box, .holiday-name + .holiday-name
      margin-top .5rem
// 月份圈圈
.month-circle
  width 28px
  height 28px
  font-size 12px
  display flex
  justify-content center
  align-items center
  background-color #fff
  box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
  border-radius 50%

.calendar-controller
  position relative
  text-align center
  padding .5rem 2rem
  background-color #ededed
  box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
  .icon-button
    position absolute
    top 3px
    &.prev
      left 28px
    &.next
      right 28px
.calendar-dropdown
  width 150px
  display inline-block
  /deep/ .ui.dropdown.fluid
    min-width auto
  /deep/ .ui.dropdown.fluid .ui.button.fake-input
    border 1px solid #ddd
.monthly-event
  margin-top 1rem
  .event-item
    display flex
    align-items center
    border-bottom 1px solid #ededed
    background-color #fff
    padding .5rem 1.5rem
    &:first-child
      border-top 1px solid #ededed
    &.today
      border 1px solid #2673AD
      color #08548E
    &.have-trade
      .main-data
        color #08548E
    &.past // 日期已過
      color $gray2
      .main-data
        color $gray2
    .date
      flex-grow 0
      flex-shrink 0
      flex-basis 61px
    .content
      flex-grow 1
      flex-shrink 1
      flex-basis auto
      padding 0 0 0 1rem
    .event-box
      display flex
      justify-content space-between
    .main-data
      display flex
      align-items center
      flex-grow 1
      flex-shrink 1
      flex-basis 0
    .event-name
      margin-left .5rem
.illustration
  margin 4rem 0 5rem
  width 360px
  +under(md)
    width 100%
    max-width 214px
    margin 2rem 0
  img
    width 100%

// 桌機版限定
+over(lg)
  .calendar
    position relative
    z-index 1
    &:after
      content ''
      position absolute
      right -41px
      bottom -110px
      width 163px
      height 170px
      background-image url('~images/animation/img-0.3.2.svg')
      background-repeat no-repeat
      background-position center center
      background-size contain
      z-index -1
    .week-head, .week-row
      // 週末
      .grid.weekend
        flex-grow 0
        flex-shrink 0
        flex-basis 98px

// 縮小版
+under(lg)
  .calendar
    .week-row
      // 優化：等比例高度
      > .grid
        min-height 0
        height 0
        padding-bottom (60 / 7)%
      .content
        display none
    .week-head > .grid, .week-row > .grid
      border none

</style>
