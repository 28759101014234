<template>
  <div class="basic__container">
    <template v-if="$member">
      <UiLoader v-if="content" :loading="!loadingStateReady">
        <div class="main-content">
          <h2 class="basic__topic">
            {{ content.title }}
          </h2>
          <div class="tools">
            <div class="left-side">
              <router-link class="ui button small minor round" :to="{name:'SaleskitCharts', params:{category_id: content.category.id}}">
                {{ content.category.name }}
              </router-link>
              <div class="date">
                {{ content.publish_at | date }}
              </div>
            </div>
            <div class="right-side">
              <ShareIcon :direction="$deviceView.desktop ? 'ani-left' : 'ani-down'" />
              <div class="tool-icon" data-tooltip-title="下載所有圖卡" @click="download">
                <div class="icon download" />
              </div>
            </div>
          </div>
          <div class="fisrt-chart">
            <div class="chart-layout">
              <a class="pic" :href="firstImage.image_url"
                 data-fancybox="gallery" :data-caption="firstImage.content"
              >
                <img :src="firstImage.image_url" alt="">
              </a>
            </div>
          </div>
          <div class="chart-list">
            <div v-for="(img, i) in otherImage.thumb" :key="`thumb-${i}`" class="other-chart" :class="{shrink: haveOtherImage}">
              <div class="chart-layout hover-style">
                <a class="pic" :href="img.image_url"
                   data-fancybox="gallery" :data-caption="img.content"
                >
                  <img :src="img.image_url" alt="">
                </a>
              </div>
            </div>
            <template v-if="haveOtherImage">
              <template v-for="(img, i) in otherImage.hide">
                <a v-if="i == 0" :key="i" class="see-more" :href="img.image_url" data-fancybox="gallery" :data-caption="img.content">
                  <div>
                    +{{ otherImage.hide.length }} <br>
                    <span v-if="$deviceView.desktop">查</span>看更多
                  </div>
                </a>
                <a v-show="false" v-else :key="i" class="pic" :href="img.image_url"
                   data-fancybox="gallery" :data-caption="img.content"
                >
                  <img :src="img.image_url" alt="">
                </a>
              </template>
            </template>
          </div>
        </div>
      </UiLoader>

      <div v-if="popularChart.length > 0" class="relative-chart">
        <div class="section-head">
          <div class="section-title">
            熱門圖卡
          </div>
          <router-link class="btn-more" :to="{name: 'SaleskitCharts'}">
            更多>
          </router-link>
        </div>
        <component :is="$deviceView.mobile ? 'UiScroll' : 'div'">
          <div class="section-list">
            <div v-for="chart in popularChart" :key="chart.id" class="list-item">
              <Card :card="chart"
                    linkRoute="SaleskitChartsContent"
                    @clickTag="routeToCategory(chart.category.id)"
              />
            </div>
          </div>
        </component>
      </div>
    </template>

    <template v-else>
      <!-- 未登入 -->
      <div v-show="false" ref="guest">
        <a v-for="(img, i) in content.images" :id="`fancyguest${i+1}`" :key="i" class="fancyguest" :href="img.image_url"
           data-fancybox="guest" :data-caption="img.content"
        >
          <img :src="img.image_url" alt="">
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import API from 'api'
import UiLoader from 'modules/ui/loader'
import Card from 'modules/components/SingleFlashCard'
import UiScroll from 'modules/ui/scroll'
import ShareIcon from 'modules/components/SocialShare'

export default {
  components: {
    UiLoader,
    Card,
    UiScroll,
    ShareIcon,
  },
  data() {
    return {
      content: '',
      popularChart: [],
    }
  },
  computed: {
    firstImage() {
      return this.content.images[0]
    },
    otherImage() {
      const imgs = this.content.images.slice()
      const count = this.$deviceView.desktop ? 5 : 4
      imgs.shift()
      return {
        hide: imgs.splice(count, imgs.length),
        thumb: imgs,
      }
    },
    contentId() {
      return this.$route.params.id
    },
    haveOtherImage() {
      return this.otherImage.hide.length > 0
    },
  },
  watch: {
    contentId() {
      this.load()
      this.scrollTop()
    },
  },
  async mounted() {
    await this.load()
    const $ = jQuery
    if (this.$member) {
      $('[data-fancybox="gallery"]').fancybox({
        baseClass: 'chart-fancy-box',
      })
    } else {
      $('.fancyguest').fancybox({
        buttons: [
          'zoom',
          'slideShow',
          'thumbs',
        ],
        clickSlide: false,
        clickOutside: false,
      })
      $('#fancyguest1').trigger('click')
    }
  },
  methods: {
    async load() {
      this.content = await API.content({ id: this.contentId }).then(res => res.data.entry)
      if (this.$member) { // 登入後才讀取熱門圖卡
        const charts = await API.content.popular({ kind: 'chart', limit: 4 }).then(res => res.data.entries)
        this.popularChart = charts.filter(chart => chart.id != this.contentId).splice(0, 3)
      }
      this.$setTitle(this.content.title)
    },
    scrollTop() {
      const $ = jQuery
      $(this.$el).parent().scrollTop(0)
    },
    routeToCategory(id) {
      this.$router.push({ name: 'SaleskitCharts', params: { category_id: id } })
    },
    download() {
      window.open(this.chart.download_url)
    }
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.basic__container
  padding-bottom 285px
.main-content
  margin-bottom 10rem
  +under(md)
    margin-bottom 7rem
  .fisrt-chart
    max-width 714px
    margin auto
.tools
  display flex
  justify-content space-between
  margin 1rem 0 2rem
  .left-side, .right-side
    display flex
    align-items center
  .date
    margin-left 2rem
    color $gray2
    font-weight bold
  .tool-icon
    width 36px
    margin-left .5rem
    cursor pointer
    .icon
      width 100%
      padding-bottom 100%
      background-repeat no-repeat
      background-position center center
      background-size contain
      &.download
        background-image url('~images/buttons/btn-download.png')
        &:hover
          background-image url('~images/buttons/btn-download-alt.png')
.chart-layout
  width 100%
  background-color #fff
  box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
  border-radius 21px
  overflow hidden
  &.hover-style
    border 2px solid transparent
    &:hover
      box-shadow 0px 1px 15px rgba(170, 170, 170, 0.3)
      border-color #FF895A
  .pic
    display block
    padding-bottom 82.3%
    position relative
    +under(md)
      padding-bottom 93.3%
    > img
      width 100%
      position absolute
      left 0
      top 50%
      transform translateY(-50%)
.chart-list
  display flex
  justify-content center
  margin-top 2rem
  +under(md)
    margin-top 1rem
  .other-chart
    +over(md)
      width calc((100% - 6rem)/5)
      flex 0 0 auto
      &.shrink // 有更多圖片的情況
        width calc((100% - 6rem - 121px)/5)
    +under(md)
      width calc((100% - 3rem)/4)
      &.shrink
        width calc((100% - 3rem - 65px)/4)
  .other-chart + .other-chart
    margin-left 1.5rem
    +under(md)
      margin-left 1rem
  .see-more
    width 121px
    display flex
    justify-content center
    align-items center
    color $gray2
    font-weight bold
    border 1px solid #ddd
    background-color #FBFBFB
    box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
    border-radius 21px
    cursor pointer
    margin-left 1.5rem
    +under(md)
      width 65px
      font-size 12px
      margin-left 1rem
    &:hover
      background-color #ededed
.relative-chart
  position relative
  &:after
    content ''
    width 200px
    height 180px
    position absolute
    background-image url('~images/animation/img-0.0.1.svg')
    background-repeat no-repeat
    background-position center center
    background-size contain
    image-rendering -webkit-optimize-contrast
    +over(md)
      left 10px
      top calc(100% + 35px)
    +under(md)
      width 170px
      height 153px
      right 0
      top calc(100% + 28px)
  .section-head
    display flex
    justify-content space-between
    font-weight bold
    margin-bottom 2rem
  .section-title
    font-size 1.728rem
  .btn-more
    font-size 1.2rem
    color $gray2
  .section-list
    display flex
    +under(md)
      width 836px
    .list-item
      width calc((100% - 4rem)/ 3)
      margin-right 2rem
      &:nth-child(3n)
        margin-right 0
      +under(md)
        margin-bottom 1rem
</style>

<style lang="stylus">
// 調整 fancybox
.chart-fancy-box .fancybox-caption__body
  max-height 100px
  &::-webkit-scrollbar-track
    background-color rgba(255,255,255, .5)
    border-radius 5px
  &::-webkit-scrollbar-thumb
    background-color #ddd
</style>
