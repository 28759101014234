<script>
import TinyMCE from './TinyMCE'
export default {
  mixins: [
    TinyMCE,
  ],
  computed: {
    config() {
      return {
        target: this.$refs.textarea,
        height: 150,
        menubar: false,
        language: 'zh_TW',
        language_url: '/vendor/tinymce/langs/zh_TW.js',
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'undo redo | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        setup: (editor) => {
          this.instance = editor
          editor.on('change', () => {
            this.$emit('input', editor.getContent())
            this.$emit('update:value', editor.getContent())
          })
        },
      }
    }
  }
}
</script>
