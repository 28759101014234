import { Columns, createLinkColumn } from 'modules/admin/common/column'

const sortable = true

export default [
  {
    name: '操作',
    style: 'width: 300px',
    template: Columns.Actions,
    props: {
      deletable: ({ active }) => active === false,
      linkEdit: ({ id }) => ({ name: 'AdminFundReport', params: { id } }),
      linkPreview: ({ file_url }) => file_url,
    }
  },
  { name: '類型', column: 'type', template: Columns.Default },
  { name: '年份/月份', column: 'date', sortable, template: Columns.Default },
  {
    name: '檔案',
    column: ({ file }) => file?.file_metadata?.filename,
    template: Columns.Default
  },
  { name: '建立時間', column: 'created_at', sortable, template: Columns.Date },
  { name: '顯示', column: 'active', template: Columns.Switch },
]
