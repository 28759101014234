<template>
  <div class="member-panel-layout">
    <div class="panel-wrap full-height">
      <div class="panel-illustration pen">
        <img src="~images/img-illustration-16.png" alt="">
      </div>

      <div v-if="current.step === 1" class="panel">
        <div class="panel-head">
          <div class="head-title">
            重新發送驗證碼
          </div>
        </div>
        <div class="panel-body">
          <div class="row-wrap">
            請輸入登入使用的電子信箱，系統將重新發送驗證碼
          </div>
          <div class="row-wrap">
            <UiInput v-model="form.email" :error="fieldErrorOf('email')" type="email" class="fluid" placeholder="輸入您的電子信箱" />
            <router-link v-if="showSignup" :to="{name: 'MemberSignup'}" class="blue-link" style="font-size:12px;">
              立即註冊
            </router-link>
            <router-link v-if="showLogin" :to="{name: 'Login'}" class="blue-link" style="font-size:12px;">
              立即登入
            </router-link>
          </div>
        </div>
        <div class="panel-actions">
          <router-link :to="{name: 'Partner'}" class="ui button fluid minor">
            取消
          </router-link>
          <div class="ui button fluid major" @click="submit">
            發送驗證碼
          </div>
        </div>
      </div>

      <div v-if="current.step === 2" class="panel">
        <Authentication :email="form.email"
                        authType="email"
                        @confirm="showModal = true"
        />
      </div>
      
      <div class="panel-illustration person">
        <img src="~images/img-illustration-15.png" alt="">
      </div>
    </div>

    <ModalWelcome :visible="showModal" />
  </div>
</template>

<script>
import API from 'api'
import UiInput from 'modules/ui/form/input'
import Authentication from 'pages/member/Authentication'
import ModalWelcome from 'pages/member/component/ModalWelcome'
import { MixinNotifiable } from 'modules/ui/notification.vue'
import { createValidator } from 'modules/ui/form/validation.js'

export default {
  components: {
    UiInput,
    Authentication,
    ModalWelcome,
  },
  mixins: [
    MixinNotifiable,
    createValidator([
      {
        field: 'email',
        validator: email => email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
        message: '請輸入正確的電子信箱'
      },
      {
        field: 'email',
        message: '請輸入電子信箱'
      },
    ]),
  ],
  data() {
    return {
      current: {
        step: 1,
      },
      form: {
        email: '',
      },
      showModal: false,
      showSignup: false,
      showLogin: false,
    }
  },
  watch: {
    'form': {
      deep: true,
      handler() {
        this.clearValidation()
        this.showSignup = false
        this.showLogin = false
      }
    }
  },
  methods: {
    submit() {
      if (this.validateForm(this.form)) this.resendToken()
    },
    resendToken() {
      const params = {
        username: this.form.email,
        confirm_for: 'email',
      }
      this.wait(API.member.resendToken(params), { message: '發送中請稍後...' })
        .then((res) => {
          this.success(`${res.data.message}`)
          this.current.step = 2
        }).catch(error => {
          this.pushFieldError('email', error.response.data.message)
          if (error.response.data.message === '此電子信箱還未成為銷售夥伴') this.showSignup = true
          if (error.response.data.message === '此帳號已完成驗證，無需再次驗證') this.showLogin = true
        })
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~pages/member/memberPanel.styl'
</style>
