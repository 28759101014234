<template>
  <div class="select-category">
    <Dropdown :key="idsL1.join(',')" v-model="category1" class="selection">
      <i class="dropdown icon" />
      <div class="default text">
        請選擇一個分類
      </div>
      <div class="menu">
        <div v-for="category in categories" :key="category.id" class="item" :data-value="category.id">
          {{ category.name }}
        </div>
      </div>
    </Dropdown>
    <Dropdown v-if="level >= 2 && category1 && subcategories.length" :key="idsL2.join(',')" v-model="category2" class="selection">
      <i class="dropdown icon" />
      <div class="default text">
        請選擇一個分類
      </div>
      <div class="menu">
        <div v-for="category in subcategories" :key="category.id" class="item" :data-value="category.id">
          {{ category.name }}
        </div>
      </div>
    </Dropdown>
  </div>
</template>

<script>
import Dropdown from 'modules/ui/form/dropdown'
export default {
  components: {
    Dropdown,
  },
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    value: {
      type: Number,
      default: null,
    },
    level: { // 可以選到第幾層
      type: Number,
      default: 2
    }
  },
  computed: {
    subcategories() {
      return this.categories.find(({ id }) => id === this.category1)?.categories
    },
    idsL1() {
      return this.categories.map(({ id }) => id)
    },
    idsL2() {
      return this.categories.map(({ categories }) => categories)
        .reduce((a, b) => a.concat(b), [])
        .map(({ id }) => id)
    },
    parentId() {
      return this.categories.map(({ categories }) => categories)
        .reduce((a, b) => a.concat(b), [])
        .find(({ id }) => id === this.value)?.parent_id
    },
    category1: {
      get() {
        return this.idsL1.find((id) => id === this.value) || this.parentId
      },
      set(val) {
        if (+val !== this.value) {
          this.$emit('input', +val)
        }
      }
    },
    category2: {
      get() {
        return this.idsL2.find((id) => id === this.value)
      },
      set(val) {
        if (+val !== this.value) {
          this.$emit('input', +val)
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.select-category
  display flex
  .selection:not(:last-child)
    margin-right 1rem
</style>
