var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-list"},[_c('div',{staticClass:"list-head"},[_c('div',{staticClass:"ui two columns grid stackable"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"ui compact labeled icon menu"},[_c('router-link',{staticClass:"item",attrs:{"to":{name: 'AdminFundCreate'}}},[_c('i',{staticClass:"file outline icon"}),_vm._v("\n            新增基金\n          ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"ui divider"})]),_vm._v(" "),_c('div',{staticClass:"list-body"},[_c('Loader',{staticClass:"loader",attrs:{"loading":!_vm.loadingStateReady}},[(_vm.entries.length > 0)?[_c('table',{staticClass:"ui celled table"},[_c('thead',[_c('tr',_vm._l((_vm.columns),function(ref){
var name = ref.name;
var style = ref.style;
return _c('th',_vm._b({key:name,domProps:{"textContent":_vm._s(name)}},'th',{style: style},false))}),0)]),_vm._v(" "),_c('Draggable',{attrs:{"tag":"tbody","handle":".sorter"},on:{"change":_vm.sort},model:{value:(_vm.entries),callback:function ($$v) {_vm.entries=$$v},expression:"entries"}},_vm._l((_vm.entries),function(model){return _c('tr',{key:model.id},[_vm._l((_vm.columns),function(ref){
var name = ref.name;
var style = ref.style;
var template = ref.template;
var column = ref.column;
var props = ref.props;
return [_c('td',_vm._b({key:((model.id) + "-" + name)},'td',{style: style},false),[_c(template,_vm._g(_vm._b({tag:"component",on:{"toggle":_vm.toggle}},'component',Object.assign({}, {model: model, column: column}, props),false),{remove: _vm.remove}))],1)]})],2)}),0)],1),_vm._v(" "),(_vm.pagination)?_c('Pagination',{attrs:{"page":_vm.current.page,"total":_vm.pagination.total_pages},on:{"update:page":function($event){return _vm.$set(_vm.current, "page", $event)}}}):_vm._e()]:_c('EmptyPlaceholder')],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }