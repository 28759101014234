<template>
  <div class="page-list">
    <div class="list-head">
      <div class="ui two columns grid stackable">
        <div class="column">
          <div class="ui compact labeled icon menu">
            <router-link :to="{name: 'AdminMemberCreate'}" class="item">
              <i class="file outline icon" />
              新增會員
            </router-link>
          </div>
        </div>
      </div>

      <div class="ui divider" />
    </div>
    <div class="list-body">
      <Tabs v-if="statusTabs.length>0" :tab.sync="current.tab" :tabs="statusTabs" />
      <Loader class="loader" :loading="!loadingStateReady">
        <template v-if="entries.length > 0">
          <table class="ui celled table">
            <thead>
              <tr>
                <th v-for="{name, style} in columns" :key="name" v-bind="{style}" v-text="name" />
              </tr>
            </thead>
            <DraggableTbody v-model="entries" v-bind="{columns}" v-on="{remove, changeRole}" />
          </table>
          <Pagination
            v-if="pagination"
            :page.sync="current.page" :total="pagination.total_pages"
          />
        </template>
        <EmptyPlaceholder v-else />
      </Loader>
    </div>
  </div>
</template>

<script>

import MixinAdminCommonPage from 'modules/admin/common/page'
import columns from './columns.js'

export default {
  components: {
    // Column,
  },
  mixins: [
    MixinAdminCommonPage,
  ],
  data() {
    return {
      current: {
        page: 1,
        tab: '已開通'
      },
      entries: [],
      categories: [],
      pagination: null,
      config: {
        status: {
          'activated': '已開通',
          'pending': '待啟用',
          'deactivated': '停用',
          'admin': '管理員',
        }
      }
    }
  },
  computed: {
    columns: () => columns,
    listParams() {
      return {
        page: this.current.page,
        scope: this.currentStatusKey,
        format: 'full'
      }
    },
    statusTabs() {
      return Object.values(this.config.status)
    },
    currentStatusKey() {
      const index = this.statusTabs.indexOf(this.current.tab)
      return Object.keys(this.config.status)[index] || ''
    },
  },
  watch: {
    listParams: {
      immediate: true,
      handler() {
        this.loadList()
        this.$router.replace({
          params: {
            tab: this.current.tab,
            page: this.current.page,
          }
        }).catch(() => {})
      }
    },
    'current.tab'() {
      this.current.page = 1
    }
  },
  methods: {
    reset() {
      this.entries = []
      this.pagination = null
    },
    async loadList() {
      const loader = this.createLoadingState()
      this.reset()
      // 加上 format: full 可取得完整資料，但需登入管理員的驗證
      const { entries, pagination } = await this.$api.members.list(this.listParams).then(res => res.data)
      this.entries = entries
      this.pagination = pagination
      loader.finish()
    },
    findById(targetId) {
      return this.entries.find(({ id }) => id === targetId)
    },

    async update(id, attributes) {
      this.wait(this.$api.members.update({ id, attributes, format: 'full' })).then(res => {
        Object.assign(this.findById(id), res.data.member)
        this.success(res.data.message)
      })
    },
    async remove(entry) {
      const sure = await confirm({
        title: `刪除${this.kindConfig.shortName}`,
        message: `確定要刪除「${entry.title}」嗎？此操作不可復原`
      })
      if (sure) {
        this.wait(this.$api.members.destroy({ id: entry.id })).then(() => {
          this.loadList()
        })
      }
    },
    async sort({ moved: { element: { code, ordering }, oldIndex, newIndex } }) {
      this.$api.members.sort({ id: code, old_index: oldIndex + 1, new_index: newIndex + 1 })
    },
    async changeRole(entry, role) {
      this.update(entry.id, {
        role,
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.list-body, .loader
  min-height calc(100vh - 300px) !important
.ui.table
  border-radius 0 !important
</style>
