<template>
  <div class="insight-card">
    <router-link class="photo-cont" :to="{ name: 'InsightsArticle', params: { id: article.slug } }">
      <div class="photo" :style="{'background-image': `url(${article['cover_url']})`}" />
    </router-link>
    <div class="sub-title">
      <div v-if="article.category" class="ui button round small minor" @click="$emit('resetCategory', article.category)">
        {{ article.category.name }}
      </div>
      <div class="date font-bold">
        {{ article.publish_at | date }}
      </div>
    </div>
    <router-link class="main" :to="{ name: 'InsightsArticle', params: { id: article.slug } }">
      <div class="title">
        {{ article.title }}
      </div>
      <div class="descript">
        {{ article.summary }}
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    article: Object,
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.insight-card
  .photo-cont
    display block
    width 100%
    height 184px
    border-radius 21px
    overflow hidden
    cursor pointer
    &:hover
      .photo
        transform scale(1.1)
  .photo
    height 100%
    background-repeat no-repeat
    background-position center center
    background-size cover
    transition .5s
  .sub-title
    display flex
    justify-content space-between
    margin 1rem 0 .75rem
    color $gray2
  .main
    display block
    color #505050
    cursor pointer
    &:hover
      text-decoration underline
    .title
      font-size 1.2rem
      font-weight bold
      margin-bottom .75rem
    .descript
      display -webkit-box
      -webkit-line-clamp 3
      -webkit-box-orient vertical
      text-overflow ellipsis
      overflow hidden

</style>
