<template>
  <router-link :to="{name: 'FundShow', params: {id: fund.id}}" class="fund-card">
    <div class="card-body">
      <div class="pic">
        <img src="~images/img-document.png" alt="">
      </div>
      <div class="fund-name">
        {{ fund.name }}
      </div>
      <div class="fund-price row-gap">
        <!-- 預估淨值 -->
        <template v-if="fund.price_est">
          <span class="price">{{ fund.price_est }}</span>
          <span v-if="fund.price_est" class="text-minor" style="font-size:12px;">（預估淨值）</span>
        </template>
        <!-- 實際淨值（已確認) -->
        <template v-else>
          <span class="price">{{ fund.price }}</span>
        </template>
      </div>
      <div class="text-minor row-gap" style="font-size:12px;">
        單位淨值 {{ fund.price_ccy }} （{{ fund.price_date }}）
      </div>
      <div class="flex-box">
        <div class="arrow-item">
          <div class="title">
            漲　跌
          </div>
          <div class="content" :class="{'red': fund.price_1m_chg > 0, 'green': fund.price_1m_chg < 0}">
            <i v-if="fund.price_1m_chg > 0" class="icon-up-dir" />
            <i v-else-if="fund.price_1m_chg < 0" class="icon-down-dir" />
            <span>{{ fund.price_1m_chg | round(4) }}</span>
          </div>
        </div>
        <div class="arrow-item">
          <div class="title">
            漲跌幅
          </div>
          <div class="content" :class="{'red': fund.perf_1m > 0, 'green': fund.perf_1m < 0}">
            <i v-if="fund.perf_1m > 0" class="icon-up-dir" />
            <i v-else-if="fund.perf_1m < 0" class="icon-down-dir" />
            <span>{{ fund.perf_1m | percentage(1) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-btn">
      <span class="font-bold">完整資訊</span>
      <i class="icon-link-out" style="font-size:12px;margin-left:.75rem;" />
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    fund: Object,
  },
}
</script>

<style lang="stylus" scoped>
.row-gap
  margin-bottom .5rem
.flex-box
  display flex
.fund-card
  width 100%
  display block
  &:hover
    .card-body
      .pic
        opacity 1
    .card-btn
      background-color #FEF9F4
  &:active
    .card-body
      .pic
        opacity 1
    .card-btn
      background-color #FF895A
      color #fff

.card-body
  position relative
  padding 1.5rem
  background-color #fff
  border 1px solid #ededed
  box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
  border-radius 21px 21px 0px 0px
  .pic
    position absolute
    right 0
    bottom 0
    line-height 0
    width 56px
    opacity 0
    transition .5s
    img
      width 100%
      image-rendering -webkit-optimize-contrast
  .fund-name
    font-size 1.44rem
    font-weight bold
    margin-bottom 1.5rem
    line-height 26px
  .fund-price
    .price
      font-size 1.728rem
  .arrow-item
    display flex
    align-items center
    font-size 12px
    + .arrow-item
      margin-left 1rem
    .content
      margin-left .5rem
      &.red
        color #ED4758
      &.green
        color #00BF9C
.card-btn
  display flex
  height 36px
  justify-content center
  align-items center
  background-color #FBFBFB
  box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
  border-radius 0px 0px 21px 21px
  cursor pointer
</style>
