<!-- 首頁用的 -->
<template>
  <div v-if="displayContent" class="scroll-wrap">
    <component :is="$deviceView.mobile ? 'UiScroll' : 'div'">
      <div class="list">
        <div v-for="item in displayContent" :key="item.id" class="list-item">
          <Card :card="item"
                @showVideo="showVideo(item)"
          />
        </div>
      </div>
    </component>
    <ModalVideo ref="modalVideo" :video="playVideo" />
  </div>
</template>

<script>
import API from 'api'
import Card from 'modules/components/PerspectiveCard'
import ModalVideo from 'modules/components/ModalVideo'
import UiScroll from 'modules/ui/scroll'

export default {
  components: {
    Card,
    ModalVideo,
    UiScroll,
  },
  data() {
    return {
      current: {
        videos: '',
      },
      kind: 'perspective',
      playVideo: null,
      page: 1,
    }
  },
  computed: {
    displayContent() {
      const amount = this.$deviceView.desktop ? 2 : 3
      return this.current.videos.slice(0, amount)
    },
  },
  async mounted() {
    this.current.videos = await this.loadList()
  },
  methods: {
    async loadList() {
      return await API.content.list({ kind: this.kind, page: this.page })
        .then(res => res.data.entries)
    },
    showVideo(video) {
      this.playVideo = video
      this.$refs.modalVideo.show()
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.scroll-wrap
  position relative
  z-index 1
  .v-perfect-scrollbar
    position relative
.list
  display flex
  +under(md)
    width 836px
    margin-bottom 1rem
  .list-item
    margin-right 2rem
    +over(md)
      width calc((100% - 2rem)/ 2)
      &:nth-child(2n)
        margin-right 0
    +under(md)
      width calc((100% - 4rem)/ 3)
      &:nth-child(3n)
        margin-right 0
</style>
