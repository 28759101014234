<template>
  <div>
    <div class="chart-wrap">
      <div class="canvas-box">
        <canvas ref="canvas" />
        <div class="remark">
          %
        </div>
      </div>
      <!-- 手機版標籤 -->
      <div v-if="$deviceView.tablet" class="label-board">
        <div v-for="item in displayChange" :key="item.name" class="label-name">
          <div>{{ item.name }}</div>
        </div>
        <div class="x-axes" />
      </div>
      <!-- 數據漲幅表 -->
      <div class="data-board">
        <div class="top-space" />
        <div v-for="item in displayChange" :key="item.name" class="data-number">
          <div>
            <div class="left">
              {{ item.value }}%
            </div>
            <div class="right">
              <template v-if="item.percentChange > 0">
                <i class="icon-up-dir color-positive" />
                <span class="color-positive">{{ Math.abs(item.percentChange) | round(1) }}%</span>
              </template>
              <template v-else-if="item.percentChange < 0">
                <i class="icon-down-dir color-negative" />
                <span class="color-negative">{{ Math.abs(item.percentChange) | round(1) }}%</span>
              </template>
              <template v-else>
                -
              </template>
            </div>
          </div>
        </div>
        <div class="x-axes" />
      </div>
    </div>
    <div class="remark text-minor">
      <span class="remark-item">資料時間：{{ latestDate | date }}</span>
      <span class="remark-item">* 若與月報差異0.1%為進位差異，括號數字代表與上月差異數</span>
    </div>
  </div>
</template>

<script>
import { merge } from 'lodash'
export default {
  props: {
    rawData: Array,
  },
  data() {
    return {
      chart: null,
      color: '#FDE5E5',
    }
  },
  computed: {
    latestData() {
      return this.rawData?.[0]['策略分佈']
    },
    latestDate() {
      return this.rawData?.[0].date
    },
    chartLabels() {
      if (!this.latestData) return []
      return Object.keys(this.latestData)
    },
    chartData() {
      const vals = this.chartLabels.map(label => this.latestData[label])
      return [
        {
          data: vals,
          maxBarThickness: 28,
          backgroundColor: this.color,
          borderColor: this.color,
        },
      ]
    },
    entries() {
      if (!this.rawData) return []
      return this.rawData.map(data => {
        return {
          'date': data.date,
          ...data['策略分佈'],
        }
      })
    },
    displayChange() {
      return this.chartLabels.map(label => {
        const latest = this.entries[0]
        const old = this.entries[1]
        return {
          name: label,
          value: latest[label],
          percentChange: latest[label] - old[label],
        }
      })
    },
    chartOptions() {
      return {
        legend: false, // < 3.0
        responsive: true,
        indexAxis: 'y',
        aspectRatio: this.$deviceView.tablet ? 1 : 2,
        scales: {
          y: {
            display: !this.$deviceView.tablet,
          },
          yAxes: [{ // < 3.0
            ticks: {
              display: !this.$deviceView.tablet,
            }
          }]
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || ''
                if (context.parsed.x !== null) {
                  label += `${context.parsed.x}%`
                }
                return label
              }
            },
          },
        }
      }
    },
  },
  watch: {
    chartOptions() {
      this.updateChartConfig()
    }
  },
  async mounted() {
    this.draw()
  },
  methods: {
    draw() {
      this.chart = new Chart(this.$refs.canvas.getContext('2d'), {
        type: Chart.version ? 'bar' : 'horizontalBar',
        data: {
          labels: this.chartLabels,
          datasets: this.chartData,
        },
        options: this.chartOptions,
      })
    },
    updateChartConfig() {
      this.chart.options = this.chartOptions
      this.chart.update()
    },
  },

}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.chart-wrap
  position relative
  display flex
  +over(lg)
    justify-content center
.canvas-box
  position relative
  width 100%
  max-width 750px
  +under(lg)
    max-width calc(100% - 120px)
  .remark
    position absolute
    bottom 2px
    right -10px
    font-size 12px
    +under(lg)
      bottom -19px
      right -120px
// 數據表格
.data-board
  display flex
  flex-direction column
  flex 0 0 133px
  .top-space
    flex 0 0 10px
  .x-axes
    flex 0 0 30px
  .data-number
    flex 1 1 0
    display flex
    justify-content center
    align-items center
    > div
      width 100%
      display flex
      justify-content space-around
    .right
      margin-left .5rem
      &:before
        content "("
      &:after
        content ")"
      i
        margin 0 -3px
+under(lg)
  .data-board
    position absolute
    width 133px
    height calc(100% + 1px)
    right 0
    font-size 12px
    .top-space
      display none
    .x-axes
      border-top 1px solid #ddd
    .data-number
      padding-left 10px
  .label-board
    position absolute
    left 14px
    display flex
    flex-direction column
    height 100%
    .x-axes
      flex 0 0 30px
    .label-name
      flex 1 1 0
      display flex
      align-items center

+under(md)
  .label-board
    font-size 12px

// 正負
.color-positive
  color #ED4758
.color-negative
  color #00BF9C
// 備註
.remark
  margin 1.5rem 0
  .remark-item + .remark-item
    margin 0 0 0 1rem
  +under(md)
    .remark-item
      display block
    .remark-item + .remark-item
      margin 1rem 0 0 0
</style>
