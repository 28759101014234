<template>
  <Draggable :value="entries" tag="tbody" :handle="dragHandle" @input="entries => $emit('sort:entries', entries)" @change="$emit('sort')">
    <tr v-for="model in entries" :key="model.id">
      <template v-for="{name, style, template, column, props, ...options} in columns" v-bind="{style}" v-text="name">
        <td :key="`${model.id}-${name}`" v-bind="{style}">
          <component :is="template" v-bind="{model, column, ...props, ...options}" v-on="$listeners" />
        </td>
      </template>
    </tr>
  </Draggable>
</template>

<script>
import Draggable from 'vuedraggable'
export default {
  components: {
    Draggable,
  },
  model: {
    prop: 'entries',
    event: 'sort:entries'
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    entries: {
      type: Array,
      default: () => []
    },
    dragHandle: {
      type: String,
      default: '.sorter'
    }
  },
  emits: ['sort'],
}
</script>
