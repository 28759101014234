<template>
  <div class="basic__container">
    <PanelComparePicker
      ref="modalPicker"
      v-bind="propsForComparePicker"
      v-on="eventsForComparePicker"
    />

    <h2 class="basic__topic add-button">
      <div>基金比較</div>
      <div class="ui button major large round btn-compare" @click="showModalPicker">
        <i class="icon-more" style="margin-right:.5rem;" />比較其他基金
      </div>
    </h2>
    <UiLoader :loading="!loadingStateReady" class="panel-layout">
      <UiTabs :steps="config.mainTabs" class="modern">
        <div slot="績效表現">
          <ChartPerformance
            :options="{datepicker: true, list: false}"
            :dateStart.sync="current.dateStart"
            :dateEnd.sync="current.dateEnd"
            :periodKey.sync="current.periodKey"
            :fundIds="comparingFundIdsWithTarobo"
            class="pd-box border-bm"
            v-bind="{
              chartColors,
              benchmarkIds,
              baseDate,
              earliestDate,
            }"
            @update:list="v => internal.performanceList = v"
            @update:date="v => internal.performanceDate = v"
          />
          <div class="wrapper tool-section">
            <UiLabels v-model="current.perfTableTab" class="buttons"
                      :tabs="config.perfTableTabs" valueMode
            />
            <div v-if="fundReturnsGroup.includes(current.perfTableTab)"
                 class="ui button minor small btn-select"
                 @click="showModalSelect"
            >
              <i class="icon-time-o" style="margin-right: 0.75rem;" />績效期間選取
            </div>
          </div>
          <div v-if="current.perfTableTab === '觀察期間績效' && internal.performanceList">
            <PerformanceList :list="internal.performanceList"
                             :date="internal.performanceDate"
                             :options="{sort: true, remove: true}"
                             v-bind="{ lockedItems }"
                             @remove="remove"
            />
          </div>
          <div v-if="fundReturnsGroup.includes(current.perfTableTab)">
            <FundReturnsList
              ref="fundReturnsList"
              :tab="current.perfTableTab"
              :options="{sort: true, remove: true}"
              :fundColors="chartColors"
              :fundIds="comparingFundIdsWithTarobo"
              v-bind="{ benchmarkIds, lockedItems }"
              @remove="remove"
            />
          </div>
        </div>

        <div slot="淨值規模">
          <div v-if="fundIds.length" class="wrapper">
            <UiLabels v-model="current.historyTableTab" class="buttons"
                      :tabs="config.historyTableTabs" valueMode
            />
            <DateRangePicker
              :dateStart.sync="current.dateStart"
              :dateEnd.sync="current.dateEnd"
              v-on="{setDateRange}"
            />
          </div>
          <FundHistory :type="historyType"
                       :dateStart="current.dateStart"
                       :dateEnd="current.dateEnd"
                       :fundIds="fundIds"
                       :fundColors="chartColors"
                       @showModalPicker="showModalPicker"
          />
        </div>
        <div slot="策略組合">
          <StrategyTable v-bind="{fundIds}"
                         :fundColors="chartColors"
                         @showModalPicker="showModalPicker"
                         @remove="remove"
          />
        </div>
      </UiTabs>
    </UiLoader>

    <div class="illustration">
      <img src="~images/animation/img-3.0.1.svg" alt="">
    </div>
  </div>
</template>

<script>
import { MixinColorRotation } from 'modules/chart/colors.js'
import MixinComparableBehavior from 'modules/components/compare/_mixinComparableBehavior.js'

// UI
import UiTabs from 'modules/ui/tabs/index'
import UiLabels from 'modules/ui/labels'
import UiLoader from 'modules/ui/loader'
import DateRangePicker from 'modules/components/fund/DateRangePicker'
import FundHistory from 'modules/components/compare/FundHistory'
import StrategyTable from 'modules/components/compare/StrategyTable'

// Chart
import ChartPerformance from 'modules/chart/performance'
import PerformanceList from 'modules/components/fund/PerformanceList.vue'
import FundReturnsList from 'modules/components/fund/FundReturnsList.vue'
export default {
  components: {
    UiLoader,
    UiTabs,
    UiLabels,
    DateRangePicker,
    FundHistory,
    StrategyTable,
    ChartPerformance,
    FundReturnsList,
    PerformanceList,
  },
  mixins: [
    MixinColorRotation,
    MixinComparableBehavior,
  ],
  data() {
    return {
      config: {
        mainTabs: [
          '績效表現',
          '淨值規模',
          '策略組合',
        ],
        perfTableTabs: [
          '觀察期間績效',
          '報酬率',
          '年化波動率',
          '夏普值',
        ],
        historyTableTabs: [
          '淨值',
          '資產規模',
        ],
      },
      fundReturnsGroup: ['報酬率', '年化波動率', '夏普值'],
      raw: {
        data: null,
      },
      internal: {
        performanceList: [],
        performanceDate: null,
      },
      current: {
        mainTab: '績效表現',
        perfTableTab: '觀察期間績效',
        historyTableTab: '淨值',
        dateStart: null,
        dateEnd: null,
        periodKey: '1y',
        periodKeyRanking: '1y',
      },
    }
  },
  computed: {
    chartColors() {
      return this.generateColorMap(this.selectedIds)
    },
    fundIds() {
      return this.comparingFundIds
    },
    benchmarkIds() {
      const available = this.raw.benchmarks.map(({ id }) => id)
      return this.selectedIds.filter(id => available.includes(id))
    },
    historyType() {
      const data = {
        '淨值': 'price',
        '資產規模': 'aum_mn',
      }
      return data[this.current.historyTableTab]
    },
    baseDate() {
      return moment().add(-1, 'month').endOf('month').format(moment.HTML5_FMT.DATE)
    },
    earliestDate() {
      return moment.min(
        ...this.raw.funds
          .filter(({ id }) => this.fundIds.includes(id))
          .map(({ launch_date }) => moment(launch_date))
      )
    },
    lockedItems() {
      const ugFunds = this.internal.performanceList.filter(({ itemId }) => itemId.match((/^fund-(?!\d{8}).*$/)))
      if (this.internal.performanceList.length === 2) {
        return [
          ...this.internal.performanceList,
          ...this.internal.performanceList.map(({ id }) => id),
          ...this.comparingFundIdsWithTarobo,
        ]
      } else if (ugFunds.length === 1) {
        return [
          ...ugFunds,
          ...ugFunds.map(({ type_id }) => type_id),
        ]
      } else {
        return []
      }
    }
  },
  watch: {
    selectedIds(now, was) {
      if (JSON.stringify(now) !== JSON.stringify(was)) {
        this.routeToCompare(now)
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const loader = this.createLoadingState()
      await this.loadComparableData()
      this.loadComparisonFromRoute()
      // 預設區間為近一年，但結束點為上個月底。例如： "2020-06"-30 ~ "2021-06"-30
      // 先往後半個月(約 0.041年)<，使起始點往後一個。變成 "2020-07"-01 ~ "2021-06"-30
      this.current.dateStart = moment(this.baseDate).add(-0.95, 'year').endOf('month').format(moment.HTML5_FMT.DATE)
      this.current.dateEnd = moment(this.baseDate).endOf('month').format(moment.HTML5_FMT.DATE)
      loader.finish()
    },
    setDateRange({ dateStart, dateEnd }) {
      Object.assign(this.current, {
        dateStart,
        dateEnd,
      })
    },
    showModalPicker() {
      this.$refs.modalPicker.show()
    },
    showModalSelect() {
      this.$refs.fundReturnsList.showModalSelect()
    },
    remove(id) {
      this.selectedIds = this.selectedIds.filter(f => f != id)
    },
  }
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
// 資料的顏色定義
/deep/ .data-grid--negative, /deep/ .data-value--negative
  color #00BF9C
.basic__topic
  margin-bottom 2rem
  &.add-button
    display flex
    justify-content space-between
    align-items center
.panel-layout
  background-color #fff
  border 1px solid #ededed
  box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
  border-radius 21px
  overflow hidden
  .section-title
    font-size 1.2rem
    line-height 21px
    font-weight bold
    margin-bottom 1.5rem
.tool-section
  +over(md)
    display flex
    justify-content space-between
  +under(md)
    .btn-select
      display block
      margin-top 1rem
.illustration
  margin 35px 0 5rem -30px
  width 182px
  +under(md)
    width 100%
    max-width 116px
    margin 1rem 0 2rem
  img
    width 100%

// 間距
.wrapper
  padding 1.5rem
.pd-box
  padding 1.5rem 1.5rem 0
.border-bm
  border-bottom 1px solid #ededed
</style>
