<template>
  <div v-if="fund" class="basic__container">
    <PanelComparePicker
      ref="modalPicker"
      v-bind="propsForComparePicker"
      v-on="eventsForComparePicker"
    />

    <Header
      v-bind="{fund}"
      @showModalPicker="showModalPicker"
    />

    <Loader :loading="!loadingStateReady" class="panel-layout">
      <UiTabs :steps="config.mainTabs" class="modern">
        <div slot="績效表現">
          <ChartPerformance
            :options="{datepicker: true, list: false}"
            :baseDate="fund.price_date"
            :dateStart.sync="current.dateStart"
            :dateEnd.sync="current.dateEnd"
            :periodKey.sync="current.periodKey"
            :earliestDate="fund.launch_date"
            class="pd-box border-bm"
            v-bind="{
              chartColors,
              fundIds,
              benchmarkIds
            }"
            @update:list="v => internal.performanceList = v"
            @update:date="v => internal.performanceDate = v"
          />
          <div class="wrapper tool-section">
            <UiLabels v-model="current.perfTableTab" class="buttons"
                      :tabs="config.perfTableTabs" valueMode
            />
            <div v-if="fundReturnsGroup.includes(current.perfTableTab)"
                 class="ui button minor small btn-select"
                 @click="showModalSelect"
            >
              <i class="icon-time-o" style="margin-right: 0.75rem;" />績效期間選取
            </div>
          </div>
          <div v-if="current.perfTableTab === '觀察期間績效' && internal.performanceList">
            <PerformanceList :list="internal.performanceList"
                             :date="internal.performanceDate"
                             :options="{sort: true}"
                             :singleFund="true"
            />
          </div>
          <div v-if="fundReturnsGroup.includes(current.perfTableTab)">
            <FundReturnsList
              ref="fundReturnsList"
              :tab="current.perfTableTab"
              :options="{sort: true}"
              :singleFund="true"
              :fundIds="fundIds"
              :fundColors="chartColors"
            />
          </div>

          <div style="margin: -.75rem 1.5rem .75rem">
            <div class="ui button fluid minor" @click="showModalPicker">
              <i class="icon-compare" style="margin-right: .5rem;" />
              <span>績效比較</span>
            </div>
          </div>
          <MonthlyReturn :periodStart="nextMonthEndOf(fund.launch_date)"
                         :periodEnd="fund.aum_date"
          />
        </div>

        <div slot="淨值規模">
          <PriceAndSize
            v-bind="{fundId}"
            :baseDate="fund.price_date"
            :earliestDate="fund.launch_date"
            :dateStart.sync="current.dateStart"
            :dateEnd.sync="current.dateEnd"
            :periodKey.sync="current.periodKey"
          />
          <UiLabels v-model="current.historyTableTab" class="buttons wrapper"
                    :tabs="config.historyTableTabs" valueMode
          />
          <FundHistory :type="historyType"
                       :dateStart="current.dateStart"
                       :dateEnd="current.dateEnd"
          />
        </div>

        <div slot="策略組合">
          <template v-if="raw.strategy">
            <div class="border-bm" style="padding-top: 1.5rem;">
              <StrategyList v-bind="{fund}" :rawData="raw.strategy" />
            </div>
            <div class="wrapper border-bm">
              <div class="section-title">
                子策略分佈
              </div>
              <StrategyChart :rawData="raw.strategy" />
            </div>
            <div class="wrapper border-bm">
              <div class="section-title">
                幣別分佈
              </div>
              <DollarAllocateChart :rawData="raw.strategy" />
            </div>
            <div class="wrapper">
              <div class="section-title">
                策略特性
              </div>
              <Strategy v-bind="{fund}" />
            </div>
          </template>
        </div>

        <div slot="基金檔案">
          <div class="wrapper">
            <div class="section-title">
              得獎紀錄
            </div>
            <UiExpandable :height="$deviceView.mobile ? 284 : 264" :scrollable="false">
              <WinningRecord :rawData="raw.entry.awards" />
              <template #toggle-down>
                展開完整清單<i class="icon-down-dir" aria-hidden="true" style="margin-left: 0.75rem;" />
              </template>
              <template #toggle-up>
                顯示較少清單<i class="icon-up-dir" aria-hidden="true" style="margin-left: 0.75rem;" />
              </template>
            </UiExpandable>
          </div>
          <div class="wrapper border-bm">
            <div class="section-title">
              基金檔案
            </div>
            <FundInfo v-bind="{fund}" />
          </div>
          <div class="wrapper">
            <div class="section-title">
              投資目標及策略
            </div>
            <div class="strategy-desc">
              {{ fund.strategy_desc }}
            </div>
          </div>
        </div>
      </UiTabs>
    </Loader>

    <div class="illustration">
      <img src="~images/animation/img-2.0.1.svg" alt="">
    </div>
  </div>
</template>

<script>
import API from 'api'

import Loader from 'modules/ui/loader'
import UiTabs from 'modules/ui/tabs/index'
import UiLabels from 'modules/ui/labels'
import UiExpandable from 'modules/ui/expandable'
import Header from 'modules/components/fund/PageHeader'
import PerformanceList from 'modules/components/fund/PerformanceList.vue'
import FundReturnsList from 'modules/components/fund/FundReturnsList.vue'
import MonthlyReturn from 'modules/components/fund/MonthlyReturn'
import FundHistory from 'modules/components/fund/FundHistory'
import StrategyList from 'modules/components/fund/StrategyList'
import StrategyChart from 'modules/components/fund/StrategyChart'
import DollarAllocateChart from 'modules/components/fund/DollarAllocateChart'
import Strategy from 'modules/components/fund/Strategy'
import WinningRecord from 'modules/components/fund/WinningRecord'
import FundInfo from 'modules/components/fund/FundInfo'

import { Colors } from 'modules/chart/colors.js'
import ChartPerformance from 'modules/chart/performance'
import PriceAndSize from 'modules/chart/PriceAndSize'

import MixinComparableBehavior from 'modules/components/compare/_mixinComparableBehavior.js'

export default {
  components: {
    Loader,
    UiTabs,
    UiLabels,
    UiExpandable,
    Header,
    FundReturnsList,
    PerformanceList,
    MonthlyReturn,
    FundHistory,
    StrategyList,
    StrategyChart,
    DollarAllocateChart,
    Strategy,
    WinningRecord,
    FundInfo,
    ChartPerformance,
    PriceAndSize,
  },
  mixins: [
    MixinComparableBehavior,
  ],
  data() {
    return {
      config: {
        mainTabs: [
          //
          '績效表現',
          '淨值規模',
          '策略組合',
          '基金檔案',
        ],
        perfTableTabs: [
          '觀察期間績效',
          '報酬率',
          '年化波動率',
          '夏普值',
        ],
        historyTableTabs: [
          '淨值',
          '資產規模',
        ],
      },
      raw: {
        entry: null,
        strategy: null,
      },
      current: {
        mainTab: '績效表現',
        perfTableTab: '觀察期間績效',
        historyTableTab: '淨值',
        dateStart: null,
        dateEnd: null,
        periodKey: '1y',
        periodKeyRanking: '1y',
        compareIds: [],
      },
      internal: {
        performanceList: [],
        performanceDate: null,
      },
      fundReturnsGroup: ['報酬率', '年化波動率', '夏普值'],
    }
  },
  computed: {
    fundId() {
      return this.$route.params.id
    },
    fund() {
      return this.raw.entry
    },
    fundIds() {
      return [this.fundId]
    },
    benchmarkIds() {
      return ['shz300']
    },
    chartColors() {
      return {
        'shz300': '#95EBE8',
        [this.fundId]: Colors.Major,
      }
    },
    historyType() {
      const data = {
        '淨值': 'price',
        '資產規模': 'aum_mn',
      }
      return data[this.current.historyTableTab]
    },
  },
  async created() {
    const loader = this.createLoadingState()
    this.current.compareIds = [this.fundId, 'shz300']
    await this.load()
    await this.loadStrategy()
    // 預設區間為近一年，但結束點為上個月底。例如： "2020-06"-30 ~ "2021-06"-30
    // 先往後半個月(約 0.041年)<，使起始點往後一個。變成 "2020-07"-01 ~ "2021-06"-30
    this.current.dateStart = moment(this.fund.price_date).add(-1, 'year').endOf('month').format(moment.HTML5_FMT.DATE)
    this.current.dateEnd = moment(this.fund.price_date).endOf('month').format(moment.HTML5_FMT.DATE)
    loader.finish()
  },
  methods: {
    async load() {
      this.raw.entry = await API.fund({ id: this.fundId }).then(res => res.data.entry)
      await this.loadComparableData()
      this.$setTitle(this.fund.name)
    },
    async loadStrategy() {
      await API.fund.strategy({ id: this.fundId }).then(res => {
        // 日期由大到小
        this.raw.strategy = res.data.strategy.sort((a, b) => moment(a).isAfter(b) ? 1 : -1)
      }).catch()
    },
    showModalSelect() {
      this.$refs.fundReturnsList.showModalSelect()
    },
    nextMonthEndOf(date) {
      return moment(date).add(1, 'month').endOf('month').format('YYYY-MM-DD')
    },
    showModalPicker() {
      this.$refs.modalPicker.show()
    },
    routeToCompare(val) {
      this.current.compareIds = val
      this.$router.push({
        name: 'Compare',
        params: {
          ids: this.current.compareIds.join(',')
        }
      })
    },
  }
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
// 資料的顏色定義
/deep/ .data-grid--negative, /deep/ .data-value--negative
  color #00BF9C
.panel-layout
  background-color #fff
  border 1px solid #ededed
  box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
  border-radius 21px
  overflow hidden
  .section-title
    font-size 1.2rem
    line-height 21px
    font-weight bold
    margin-bottom 1.5rem
.strategy-desc
  font-size 1.2rem
  line-height 27px
  color #505050
.tool-section
  +over(md)
    display flex
    justify-content space-between
  +under(md)
    .btn-select
      display block
      margin-top 1rem
.illustration
  margin 35px 0 5rem 1.5rem
  width 160px
  +under(md)
    width 100%
    max-width 116px
    margin 1rem 0 2rem
  img
    width 100%

// 間距
.wrapper
  padding 1.5rem
.pd-box
  padding 1.5rem 1.5rem 0
.border-bm
  border-bottom 1px solid #ededed

</style>
