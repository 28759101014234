<!--
  透過 API 以 base64 格式上傳檔案，選取時可以預覽圖片

  <Attachment accept="image/png, image/jpg" v-model="form.attachment">

  attachment: {
    file_url: null, // 目前已在伺服器上的檔案
    file_upload_base64: null, // 即將上傳的檔案
    file_metadata: {
      filename: null,
      size: 0,
      mime_type: ''
    }
  }
-->
<template>
  <div class="file-uploader" :class="{ 'has-file': has.file }">
    <div v-if="fileSource" class="file-preview">
      <div v-show="has.file" class="icon-button medium minor" data-tooltip="取消上傳此檔案">
        <i class="icon-exit" @click="remove" />
      </div>
      <img v-if="isImage" ref="preview" :src="fileSource" class="ui centered image preview">
      <div v-else class="file-info">
        <div class="ui button minor" @click="previewInNewTab(previewSource, file.file_metadata.filename)">
          <i class="icon-file" />
          <span>查看檔案</span>
        </div>
        <div class="filename">
          {{ file.file_metadata.filename }} ({{ file.file_metadata.size | humanFileSize }})
        </div>
      </div>
    </div>
    <label style="cursor: pointer" data-position="bottom center" :data-tooltip="placeholder">
      <input ref="input" type="file" v-bind="{ name, accept }" @change="read">
    </label>
    <img ref="loader" alt="" style="display: none">
  </div>
</template>

<script>
import humanFileSize from 'lib/utils/humanFileSize.js'
export default {
  filters: {
    humanFileSize
  },
  model: {
    prop: 'file'
  },
  props: {
    name: {
      type: String,
      default: null
    },
    accept: {
      type: String,
      default: '*'
    },
    file: {
      type: Object,
      default: () => ({
        file_url: null,
        file_upload_base64: null,
        file_metadata: {
          filename: null,
          size: 0,
          mime_type: ''
        }
      })
    },
    placeholder: {
      type: String,
      default: '點擊以上傳檔案'
    }
  },
  data() {
    return {
      has: {
        file: false
      },
      store: {
        previous: null
      }
    }
  },
  computed: {
    existFileUrl() {
      return this.file?.file_url
    },
    isImage() {
      return this.file?.file_metadata?.mime_type?.match('image')
    },
    fileSource() {
      return this.file?.file_upload_base64 || this.file?.file_url
    },
    previewSource() {
      return this.file?.file_object_url || this.file?.file_url
    }
  },
  methods: {
    reset() {
      this.has.file = false
      this.store.previous = null
      this.$refs.input.value = null
    },
    remove() {
      this.has.file = false
      this.$refs.input.value = null
      this.$emit('input', this.store.previous)
      this.store.previous = null
    },
    read() {
      const file = this.$refs.input?.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = async (e) => {
          const file_object_url = await fetch(e.target.result)
            .then(res => res.blob())
            .then(blob => URL.createObjectURL(blob))

          this.has.file = true
          this.$refs.loader.src = e.target.result
          this.store.previous = this.file
          this.$emit('input', {
            file_upload_base64: e.target.result,
            file_object_url,
            file_metadata: {
              filename: file.name,
              size: file.size,
              mime_type: file.type
            }
          })
        }
        reader.readAsDataURL(file)
      }
    },
    previewInNewTab(sourceUrl, fileName) {
      const pdfWindow = window.open()
      pdfWindow.document.title = fileName
      pdfWindow.document.body.outerHTML = (`
        <style>
          body{ margin: 0 }
          iframe{ border: none }
        </style>
        <embed width="100%" height="100%" src="${sourceUrl}#toolbar=0&navpanes=0&scrollbar=0"></embed>
      `)
    }
  }
}
</script>

<style lang="stylus" scoped>
.file-uploader
  border-radius .25rem
  background #fbfbfb
  border 1px #EDEDED solid
  &:hover, &:focus-within
    border-color $gray2
  input[type='file']
    border none
    border-radius .25rem
    cursor pointer
    &:hover, &:active
      outline none

.file-info
  display flex
  flex-direction column
  align-items center
  justify-content center

.file-preview
  position relative
  padding 1rem
  border-bottom 1px solid #EDEDED
  .filename
    margin .5rem 1rem
  .icon-button
    position absolute
    right 1rem
    top 1rem
    z-index 10
  .image.preview
    max-height 250px
    max-width 100%
</style>
