<template>
  <div v-if="entry">
    <form id="new_article_insight" enctype="multipart/form-data" action="/admin/article_insights" accept-charset="UTF-8" method="post" class="ui form app">
      <input name="utf8" type="hidden" value="✓">
      <input type="hidden" name="authenticity_token" value="QVCr/FoaExUrftFPajWldKdeeUwkXTR6HQFM3jW2uO3TQHQDS/vOsyMG1dW2gprAlEOvkprh4K+8dIS/UGd2mg==">

      <Field v-if="hasField('title')" required :error="fieldErrorOf('title')">
        <label>{{ kindConfig.name }}標題</label>
        <Input v-model="entry.title" required class="fluid" :placeholder="`必填，字數上限 ${hasField('title').limit || 50}字`" />
      </Field>

      <Field v-if="hasField('slug')" :error="fieldErrorOf('slug')">
        <label>自訂網址</label>
        <div class="ui labeled input">
          <Input v-model="entry.slug" required class="fluid" prefix="https://funds.taroboadvisors.com/xxxxxx/" />
        </div>
        <p style="color: rgb(170, 170, 170);">
          (首次儲存將會同文章標題產生，建議設定簡短的英文網址)
        </p>
      </Field>

      <Field v-if="hasField('summary')" required :error="fieldErrorOf('summary')">
        <label>內容描述</label>
        <textarea v-model="entry.summary" cols="30" rows="5" placeholder="請輸入內容描述" />
      </Field>

      <div v-if="kindConfig.categorisable > 0" class="two fields">
        <Field required :error="fieldErrorOf('category_id')">
          <label>{{ kindConfig.shortName }}分類</label>
          <Categories v-model="entry.category_id" v-bind="{categories}" />
        </Field>
      </div>

      <div v-if="hasField('status')" class="two fields">
        <Field>
          <label>狀態</label>
          <Dropdown v-model="entry.status" class="selection">
            <i class="dropdown icon" />
            <div class="default text">
              請選擇
            </div>
            <div class="menu">
              <div v-for="item in [
                {name: '草稿', status: 'Draft'},
                {name: '已發表', status: 'Published'},
                {name: '隱藏', status: 'Hidden'},
              ]" :key="item.status" class="item" :data-value="item.status"
              >
                {{ item.name }}
              </div>
            </div>
          </Dropdown>
        </Field>
        <Field :error="fieldErrorOf('publish_at')">
          <label>發布曰期</label>
          <Calendar v-model="entry.publish_at" type="datetime" />
        </Field>
      </div>

      <div class="three fields">
        <Field v-if="hasField('active')">
          <label>顯示</label>
          <Checkbox v-model="entry.active" toggleSwitch />
        </Field>
        <Field v-if="hasField('promote')">
          <label>首頁顯示</label>
          <Checkbox v-model="entry.promote" toggleSwitch />
        </Field>
        <Field v-if="hasField('promote:marquee')">
          <label>首頁跑馬</label>
          <Checkbox v-model="entry.promote" toggleSwitch />
        </Field>
      </div>

      <Field v-if="hasField('cover')" required :error="fieldErrorOf('cover')">
        <label>封面縮圖</label>
        <Attachment v-model="entry.cover" accept=".png,.jpg" />
      </Field>

      <Field v-if="hasField('attachment:pdf')" required :error="fieldErrorOf('attachment:pdf')">
        <label>PDF上傳</label>
        <Attachment v-model="entry.attachment" accept=".pdf" />
      </Field>

      <Field v-if="hasField('attachment:document')" required :error="fieldErrorOf('attachment:document')">
        <label>檔案上傳</label>
        <Attachment v-model="entry.attachment" accept=".pdf, .doc, .docx, .xls, .xlsx" />
      </Field>

      <div class="ui divider" />

      <Field v-if="hasField('card:images')" required :error="fieldErrorOf('content')">
        <label>圖卡集內容</label>
        <Images v-model="entry.content" />
      </Field>

      <Field v-if="hasField('content')" :error="fieldErrorOf('content')">
        <label>文章內容，非必填 (HTML)</label>
        <TinyMCE v-if="!_inactive" v-model="entry.content" />
      </Field>

      <Field v-if="hasField('video')" required :error="fieldErrorOf('video')">
        <label>影片連結</label>
        <Input v-model="entry.video_url" required class="fluid" placeholder="請輸入影片分享連結" />
      </Field>

      <div v-if="hasField('video:subtitle')" required class="two fields">
        <Field required :error="fieldErrorOf('subtitle')">
          <label>影音標題</label>
          <Input v-model="entry.subtitle" required class="fluid" placeholder="請輸入影音小標" />
        </Field>
        <div class="field">
          <p class="remark">
            如月份、集數等，範例：【2021-Jan Vol.1/3】
          </p>
        </div>
      </div>

      <div v-if="hasField('video:live')" class="two fields">
        <Field :error="fieldErrorOf('live_subscribe_url')" required>
          <label>預約連結</label>
          <Input v-model="entry.live_subscribe_url" required class="fluid" placeholder="請輸入影片預約連結（如 表單）" />
        </Field>
        <Field :error="fieldErrorOf('live_url')" required>
          <label>直播連結</label>
          <Input v-model="entry.live_url" required class="fluid" placeholder="請輸入影片直播連結（如 Zoom）" />
        </Field>
      </div>

      <div v-if="hasField('video:live')" class="two fields">
        <Field required :error="fieldErrorOf('live_start_at')">
          <label>直播開始時間</label>
          <Calendar v-model="entry.live_start_at" type="datetime" />
        </Field>
        <Field required :error="fieldErrorOf('live_end_at')">
          <label>直播結束時間</label>
          <Calendar v-model="entry.live_end_at" type="datetime" />
        </Field>
      </div>

      <div class="ui divider" />

      <div class="rule-buttons-group">
        <div class="ui button minor" @click="abort">
          取消
        </div>
        <div class="ui button outline" @click="submit">
          儲存
        </div>
        <div class="ui button major" @click="submit({goBack: true})">
          儲存並返回
        </div>
        <div class="ui button info" @click="preview">
          即時預覽
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Api from 'api'
import MixinShared from './shared'
import MixinValidation from './validation.js'

export default {
  mixins: [
    MixinShared,
    MixinValidation,
  ],
  data() {
    return {
      entry: null,
      categories: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    submitTo() {
      // 存檔或新增
      if (this.id) {
        return Api.content.update
      } else {
        return Api.content.create
      }
    }
  },
  watch: {
    id() {
      this.load()
    }
  },
  mounted() {
    this.load()
  },
  beforeDestroy() {
    this.entry = null
  },
  methods: {
    hasField(id) {
      const fieldConfig = this.kindConfig.fields.find(field => {
        return field === id || field.id === id
      })
      return fieldConfig && ((typeof fieldConfig === 'object') ? fieldConfig : { id: fieldConfig })
    },
    abort() {
      this.backToList()
    },
    backToList() {
      // 回到列表
      this.$router.push({
        name: 'AdminContentList',
        params: this.$route.params,
      })
    },
    submit({ goBack }) {
      this.bindFormValidator('entry')
      if (this.validateForm(this.entry)) {
        this.wait(this.submitTo({
          format: 'full',
          id: this.id,
          kind: this.kind,
          attributes: this.entry,
        })).then((res) => {
          this.success('已儲存').then(() => {
            if (goBack) {
              this.backToList()
            } else if (res.data?.entry?.id) {
            // 跳到新文章的頁面
              this.$router.push({
                name: 'AdminContentEdit',
                params: {
                  ...this.$route.params,
                  id: res.data.entry.id,
                }
              })
            }
          })
        })
      } else {
        this.scrollToErrorField()
      }
    },
    preview() {},
    async load() {
      await this.loadEntry()
      await this.loadCategories()
    },
    async loadEntry() {
      if (this.id) {
        this.entry = await Api.content({ id: this.id, format: 'full' }).then(res => res.data.entry)
      } else {
        this.entry = {
          status: 'Draft',
          // publish_at: moment().add(1, 'hour').startOf('hour')
        }
      }
    },
    async loadCategories() {
      if (this.kindConfig.categorisable) {
        this.categories = await Api.content.categories({ kind: this.kind, format: 'full' }).then(res => res.data.entries)
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
.remark
  margin-top 2rem
  color $gray2
</style>
