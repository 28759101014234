import { Columns, createLinkColumn } from 'modules/admin/common/column'
import { currentMember } from 'store'

const sortable = true

export default [
  { name: '編號/電子郵件', style: 'width: 150px', column: 'email', template: Columns.Default },
  { name: '姓名', style: 'width: 100px', column: 'name', template: Columns.Default },
  { name: '工作機構', style: 'width: 150px', column: ({ firm }) => firm.name, template: Columns.Default },
  { name: '手機', style: 'width: 150px', column: 'mobile_number', template: Columns.Default },
  { name: '啟用時間', style: 'width: 150px', column: 'confirmed_at', sortable, template: Columns.Datetime },
  { name: '備註', column: 'admin_note', template: Columns.Default },
  {
    name: '角色',
    style: 'width: 150px',
    column: ({ member_groups }) => member_groups.slice(-1).pop().code,
    sortable,
    template: Columns.Select,
    props: {
      options: [
        { name: '一般會員', value: 'member' },
        { name: '管理員', value: 'admin' },
      ],
      disabled: ({ email }) => email === currentMember.email
    },
    onUpdate: 'changeRole'
  },
  { name: '操作', column: 'action', template: Columns.Actions },
]
