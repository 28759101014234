<!--
備忘擴充
增加 ESC 觸發事件

使用範例
<template>
  <UiModal ref="modal" :visible.sync="current.visible" size="medium">
    <template v-slot:modal="modal">
      <div class="header">Example</div>
      <div class="content">...</div>
      For internal control
      <div class="actions">
        <div class="ui button minor left floated">Left</div>
        <div class="ui button major" @click="modal.close">OK</div>
      </div>
    </template>
  </UiModal>
</template>

<script>
import {ModalImplement} from 'modules/ui/modal'
export default {
  mixins: [
    ModalImplement
  ]
}
</script>

For external control
<div class="ui button major" @click="$refs.modal.open">OPEN</div>

-->

<template>
  <span class="v-modal-simple" v-on="$listeners">
    <span ref="opener" @click.stop="show">
      <slot />
    </span>
    <div v-if="isPresence" ref="modal" :class="mergedClasses" :style="dataStyle" class="ui modal transition hidden component-modal">

      <div v-if="showClose && modalOptions.closable" class="close-button" @click="onClose">
        <i class="icon-exit" />
      </div>

      <!-- slot:header deprecated -->
      <div v-if="$slots.header" class="header">
        <slot name="header" />
      </div>

      <!-- better use -->
      <slot :hide="hide" name="modal" v-bind="this" />
    </div>
  </span>
</template>

<script>
const $ = jQuery // 為了讓jQ順利運作
const UiModal = {
  props: {
    modalClass: [Object, Array, String],
    dataOptions: Object,
    dataStyle: [String, Object],
    visible: null,
    disabled: Boolean,
    size: {
      // small, medium, large, xlarge, fluid
      type: String,
      default: 'medium',
      validator(value) {
        // prettier-ignore
        return [
          'small', 'medium', 'large',
          'xl', 'xlarge', 'x-large',
          'full', 'full-width', 'fluid'
        ].indexOf(value) !== -1
      },
    },
    showClose: {
      // 是否有叉叉按鈕
      type: Boolean,
      default: false
    },
    beforeClose: Function
  },
  watch: {
    visible: {
      immediate: true,
      handler(nowVisible, wasVisible) {
        if (nowVisible !== wasVisible && !this.disabled) {
          if (nowVisible) {
            this.show()
          } else {
            this.hide()
          }
        }
      },
    },
  },
  data: () => ({
    instance: null,
    closeButton: '',
    isOpened: false,
    isVisible: false,
  }),
  computed: {
    modalOptions() {
      return Object.assign(
        {},
        {
          observeChanges: true, // Prevent destroy during update
          closable: true,
          autofocus: false,
          allowMultiple: true,
          onVisible: () => {
            this.isVisible = true
            this.$emit('update:visible', true)
            this.$emit('visible')
            this.dataOptions && this.dataOptions.onVisible instanceof Function && this.dataOptions.onVisible()
          },
          onHidden: () => {
            this.isVisible = false
            this.$emit('update:visible', false)
            this.$emit('hidden')
            this.dataOptions && this.dataOptions.onHidden instanceof Function && this.dataOptions.onHidden()
          },
        },
        this.dataOptions || {}
      )
    },
    mergedClasses() {
      const sizedClass = `size--${this.size}`
      if (Array.isArray(this.modalClass)) {
        return [...this.modalClass, sizedClass]
      }
      if (typeof this.modalClass === 'object') {
        return {
          ...this.modalClass,
          [sizedClass]: true,
        }
      }
      if (typeof this.modalClass == 'string') {
        return `${this.modalClass} ${sizedClass}`
      }
      return sizedClass
    },
    isPresence() {
      // 已需要顯示，且已顯示
      return this.isOpened || this.instance
    },
  },
  beforeDestroy() {
    this.destroy()
  },
  methods: {
    async init() {
      return new Promise(resolve => {
        this.$nextTick(() => {
          this.instance = $(this.$refs.modal).modal(this.modalOptions)
          resolve(this.instance)
        })
      })
    },
    async onClose() {
      if (typeof this.beforeClose == 'function') {
        if (await this.beforeClose() === false) return false
        this.close()
        this.$emit('action') // 客製要做的設定(關 modal 後)
      } else {
        this.close()
      }
    },
    destroy() {
      // 警告：呼叫 modal 內建的 destroy 會造成 closable 衝突
      if (this.closeButton) {
        this.closeButton.off('click', () => {
          this.close()
        })
      }
      if (this.instance && this.instance.modal('is active')) {
        this.instance.modal('hide', () => {
          // this.instance.modal('destroy')
          this.instance.remove()
        })
      } else if (this.instance) {
        // this.instance.modal('destroy')
        this.instance.remove()
      }
    },
    fixDimmerOptions() {
      if (this.instance) {
        $('body').dimmer('setting', 'closable', this.modalOptions.closable)
        this.instance.dimmer('setting', 'closable', this.modalOptions.closable)
      }
    },
    hide() {
      return new Promise(resolve => {
        if (this.disabled) {
          return resolve()
        }
        this.$emit('hide')
        this.isOpened = false
        if (this.instance) {
          this.instance.modal('hide', () => {
            resolve()
          })
        }
      })
    },
    show() {
      return new Promise(async resolve => {
        this.fixDimmerOptions()
        if (this.disabled) {
          return resolve()
        }
        this.isOpened = true
        if (!this.instance) {
          await this.init()
        }
        this.$emit('show')
        if (this.instance) {
          this.instance.modal('show', () => {
            resolve()
          })
        }
      })
    },
    async close() {
      return await this.hide()
    },
    async open() {
      return await this.show()
    },
  },
}

const ModalImplement = {
  methods: {
    getModal() {
      if (this.$refs.modal) {
        return this.$refs.modal
      } else {
        Vue.config.silent || console.error('Bad ModalImplement. Please put ref="modal" in <UiModal>')
        return {
          open: () => {},
          close: () => {},
        }
      }
    },
    async show() {
      return await this.getModal().open()
    },
    async hide() {
      return await this.getModal().close()
    },
    async open() {
      return await this.getModal().open()
    },
    async close() {
      return await this.getModal().close()
    },
  },
  components: { UiModal },
}
export default UiModal
export { ModalImplement }
</script>

<style lang="stylus">
.v-modal-simple
  display inline
  text-align left
  .ui.modal
    width 480px
    max-width 100vw
</style>

<style lang="stylus">
@import '~modules/ui/common'

.ui.modal
  display flex
  flex-direction column
  color #666
  border-radius 0.15em
  border-color #DDD
  box-shadow 0.7px 1.9px 6px 0 rgba(170, 170, 170, 0.1)

  .close-button
    position absolute
    right -1.5rem
    top -1.5rem
    font-size 1rem
    line-height 1
    cursor pointer
    color #FFF
    text-shadow 0 1px black
    z-index 10
  > .header
    position relative
    padding 1.5rem 3rem 1.5rem 1.5rem !important
    font-size 1.2em
    font-weight bold
    font-family inherit
    line-height 1
    border-bottom solid 1px #f5f5f5
    color #666
  > .wrapper
    height 100%
    overflow auto
  > .content
    padding 1.5rem 1.5rem 3rem 1.5rem !important
    overflow hidden
  > .actions
    display flex
    flex-wrap wrap
    justify-content flex-end
    background none
    border none
    // 原本是 padding: .75rem 1.5rem ，因為子層的 .ui.button 會有 margin: 0.5rem ，
    // 為了讓整體留白一致，直接互減來設定 padding
    padding .25rem 1rem !important
    &:before
      content ''
      display block
      width calc(100% + 2rem)
      margin -.25rem -1rem !important
      height 0
      border-top solid 1px #f5f5f5
    > *
      margin .5rem !important
    > .left.floated
      margin-right auto !important
  > .ui.very.basic.table
    margin 0
    th
    td
      padding 1em !important

.ui.dimmer.modals
  background rgba(0, 0, 0, 0.3)
  > .ui.modal > .ui.dimmer
    background rgba(0, 0, 0, 0.3)

// Guideline Sizes
.ui.modal.component-modal
  max-height 80vh
.ui.modal.component-modal.size
  &--small
    width 280px
  &--medium
    width 400px
  &--large
    width 800px
  &--xl
  &--xlarge
  &--x-large
    width 80vw
  &--full
  &--full-width
  &--fluid
    width calc(100% - 3rem)
    max-width 1140px
    overflow auto

.ui.modal.component-modal.fullscreen
  @extend .ui.modal.component-modal.size--full
  left auto
  margin auto

// Mobile Unique size
@media $mobile
  .ui.modal.component-modal:not(.fullscreen)
    width calc(100% - 3em)

</style>
