<template>
  <div class="page-list">
    <div class="list-head">
      <div class="ui two columns grid stackable">
        <div class="column">
          <div class="ui compact labeled icon menu">
            <router-link :to="`/content/${kind}/create`" class="item">
              <i class="file outline icon" />
              新增{{ kindConfig.shortName }}
            </router-link>
            <router-link v-if="kindConfig.categorisable" :to="`/content/${kind}/categories`" class="item">
              <i class="folder outline icon" />
              分類管理
            </router-link>
          </div>
        </div>
        <!-- <div class="right aligned middle aligned column">
          <div class="ui icon input large">
            <input id="query" type="text" name="query" placeholder="尋找文章..." onchange="window.location=('/admin/article_insights' + '?query=' + this.value).replace(/(.+\?.+)\?(.+)/, '$1&amp;$2')">
            <i class="search icon" />
          </div>
        </div> -->
      </div>

      <div class="ui divider" />
    </div>
    <div class="list-body">
      <Tabs v-if="statusTabs.length>0" :tab.sync="currentTab" :tabs="statusTabs" />

      <Loader class="loader" :loading="!loadingStateReady">
        <template v-if="entries.length > 0">
          <table class="ui celled table">
            <thead>
              <tr>
                <th v-for="{name, style} in columns" :key="name" v-bind="{style}" v-text="name" />
              </tr>
            </thead>
            <Draggable v-model="entries" tag="tbody" handle=".sorter" @change="sort">
              <tr v-for="model in entries" :key="model.id">
                <template v-for="{name, style, template, column, props} in columns" v-bind="{style}" v-text="name">
                  <td :key="`${model.id}-${name}`" v-bind="{style}">
                    <component :is="template" v-bind="{model, column, ...props}" @toggle="toggle" v-on="{remove}" />
                  </td>
                </template>
              </tr>
            </Draggable>
          </table>
          <Pagination
            v-if="pagination && pagination.total_pages > 1"
            :page.sync="current.page" :total="pagination.total_pages"
          />
        </template>
        <EmptyPlaceholder v-else />
      </Loader>
    </div>
  </div>
</template>

<script>

import Api from 'api'
import MixinShared from './shared'

export default {
  mixins: [
    MixinShared,
  ],
  data() {
    return {
      current: {
        page: 1,
      },
      entries: [],
      categories: [],
      pagination: null,
    }
  },
  computed: {
    listParams() {
      if (this.kind === 'form') {
        return {
          page: this.current.page,
          kind: this.kind,
          category_id: this.currentStatusKey || '',
          format: 'full'
        }
      } else {
        return {
          page: this.current.page,
          kind: this.kind,
          status: this.currentStatusKey,
          format: 'full',
        }
      }
    },
    status() {
      if (this.kind === 'form') {
        return this.categories.reduce((dict, { name, id }) => ({ ...dict, [id]: name }), {})
      } else {
        return {
          'Published': '已發表',
          'Scheduled': '已排程',
          'Draft': '草稿',
          'Hidden': '下檔',
        }
      }
    },
    statusTabs() {
      return Object.values(this.status)
    },
    currentTab: {
      get() {
        return this.$route.params.tab || this.statusTabs[0]
      },
      set(tab) {
        if (tab !== this.currentTab) {
          this.$router.replace({
            params: {
              tab,
            }
          })
        }
      },
    },
    currentStatusKey() {
      const index = this.statusTabs.indexOf(this.currentTab)
      return Object.keys(this.status)[index] || ''
    },
  },
  watch: {
    listParams() {
      this.loadList()
    },
    kind() {
      this.reset()
      this.loadCategories()
    },
    currentTab() {
      this.reset()
    }
  },
  async created() {
    await this.loadCategories()
    await this.loadList()
  },
  methods: {
    reset() {
      this.entries = []
      this.pagination = null
    },
    async loadList() {
      const loader = this.createLoadingState()
      this.reset()
      // 加上 format: full 可取得完整資料，但需登入管理員的驗證
      const { entries, pagination } = await Api.content.list(this.listParams).then(res => res.data)
      this.entries = entries
      this.pagination = pagination
      loader.finish()
    },
    async loadCategories() {
      this.categories = await Api.content.categories({ kind: this.kind }).then(res => res.data.entries)
    },
    findById(targetId) {
      return this.entries.find(({ id }) => id === targetId)
    },
    async toggle(id, prop, val) {
      this.update(id, { [prop]: val }).then(() => {
        this.findById(id)[prop] = val
      })
    },
    async update(id, attributes) {
      await Api.content.update({ id, attributes, format: 'full' })
    },
    async remove(entry) {
      const sure = await confirm({
        title: `刪除${this.kindConfig.shortName}`,
        message: `確定要刪除「${entry.title}」嗎？此操作不可復原`
      })
      if (sure) {
        this.wait(Api.content.destroy({ id: entry.id })).then(() => {
          this.loadList()
        })
      }
    },
    async sort({ moved: { element: { id, ordering }, oldIndex, newIndex } }) {
      this.$api.content.sort({ id, old_index: oldIndex + 1, new_index: newIndex + 1 })
    }
  }
}
</script>

<style lang="stylus" scoped>
.list-body, .loader
  min-height calc(100vh - 300px) !important
.ui.table
  border-radius 0 !important
</style>
