<script>
const $ = jQuery

export default {
  props: {
    options: {
      type: Object,
      default: () => ({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        adaptiveHeight: true,
      })
    }
  },
  mounted() {
    $(this.$el).on('init', slick => {
      this.$emit('init', slick)
    })
    $(this.$el).slick(this.options)
  },
  beforeDestroy() {
    $(this.$el).slick('unslick')
  }
}
</script>
<template>
  <div>
    <slot />
  </div>
</template>

<style lang="stylus">
.slick-slide:focus
  outline none
</style>

<style lang="less" scoped>
  /deep/ ul.slick-dots{
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    li{
      display: inline-block;
      padding-left: .5em;
      padding-right: .5em;
      button{
        display: inline-block;
        width: .5em;
        height: .5em;
        padding: 0;
        border-radius: 50%;
        border: none;
        background: #dddddd;
        line-height: 0;
        overflow: hidden;
        color: transparent;
      }
      &.slick-active button{
        background: #aaaaaa;
      }
    }
  }
</style>
