<template>
  <div class="monthly-return">
    <div class="time-wrap">
      <span style="margin-right: 1rem;">基金每月報酬率（%）</span>
      <DropdownYearPeriod
        :minYear="formatYear(periodStart)"
        :maxYear="formatYear(periodEnd)"
        @change="selectedYears = arguments[0]"
      />
    </div>
    <div ref="list" class="list">
      <ViewportCondition value="> md">
        <div class="list-head" :class="{'status-fixed': fixedHead}">
          <div class="fixed-area">
            <div class="row head-row">
              <div class="grid year" />
              <Scroll ref="head" class="column-group" @ps-scroll-x.native="updateScroll">
                <div v-for="title in titles" :key="title" class="grid month" v-text="title" />
                <div class="grid month">
                  年度報酬
                </div>
              </Scroll>
            </div>
          </div>
        </div>
        <div class="list-body">
          <div v-for="year in sortYear" :key="year" class="body-row-group">
            <div class="row body-row">
              <div class="grid year">
                {{ year }}
              </div>
              <Scroll ref="info" class="column-group" @ps-scroll-x.native="updateScroll">
                <div v-for="title in titles" :key="title" class="grid month">
                  <span v-if="monthlyReturn[year][title]" :class="symbolClass(monthlyReturn[year][title])">
                    {{ monthlyReturn[year][title] | wiseRound(1) }}
                  </span>
                  <span v-else>-</span>
                </div>
                <div class="grid month">
                  <span v-if="yearReturn[year]" :class="symbolClass(yearReturn[year])">
                    {{ yearReturn[year] | wiseRound(1) }}
                  </span>
                  <span v-else>-</span>
                </div>
              </Scroll>
            </div>
          </div>
        </div>
      </ViewportCondition>

      <ViewportCondition value="< md">
        <div class="list-head" :class="{'status-fixed': fixedHead}">
          <div class="fixed-area">
            <Scroll ref="head" class="column-group" @ps-scroll-x.native="updateScroll">
              <div class="row head-row">
                <div class="grid year" />
                <div v-for="title in titles" :key="title" class="grid month" v-text="title" />
                <div class="grid month">
                  年度報酬
                </div>
              </div>
            </Scroll>
          </div>
        </div>
        <div class="list-body">
          <div v-for="year in sortYear" :key="year" class="body-row-group">
            <div class="row body-row">
              <Scroll ref="info" class="column-group" @ps-scroll-x.native="updateScroll">
                <div class="grid year">
                  {{ year }}
                </div>
                <div v-for="title in titles" :key="title" class="grid month">
                  <span v-if="monthlyReturn[year][title]" :class="symbolClass(monthlyReturn[year][title])">
                    {{ monthlyReturn[year][title] | wiseRound(1) }}
                  </span>
                  <span v-else>-</span>
                </div>
                <div class="grid month">
                  <span v-if="yearReturn[year]" :class="symbolClass(yearReturn[year])">
                    {{ yearReturn[year] | wiseRound(1) }}
                  </span>
                  <span v-else>-</span>
                </div>
              </Scroll>
            </div>
          </div>
        </div>
      </ViewportCondition>
    </div>
  </div>
</template>

<script>
import API from 'api'

import MixinScrollList from 'modules/components/fund/MixinScrollList'
import Scroll from 'modules/ui/scroll'
import DropdownYearPeriod from 'modules/components/fund/DropdownYearPeriod'
import ViewportCondition from 'modules/misc/ViewportCondition.vue'

export default {
  components: {
    Scroll,
    DropdownYearPeriod,
    ViewportCondition,
  },
  mixins: [
    MixinScrollList,
  ],
  props: {
    periodStart: String,
    periodEnd: String,
  },
  data() {
    return {
      fundId: this.$route.params.id,
      titles: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      selectedYears: [],
      history: [],
      priceCurrency: '',
    }
  },
  computed: {
    monthlyReturn() {
      const obj = {}
      this.selectedYears.forEach(year => {
        const matches = this.history.filter(item => item.mom_perf && moment(item.date).format('YYYY') == year)
        const monthly = {}
        matches.forEach(item => {
          const key = moment(item.date).format('MM')
          monthly[key] = item.mom_perf
        })
        obj[year] = monthly
      })
      return obj
    },
    yearReturn() {
      const obj = {}
      const yoy = this.history.filter(item => item.yoy_perf)
      yoy.forEach(y => {
        obj[y.date] = y.yoy_perf
      })
      return obj
    },
    sortYear() {
      return this.selectedYears.slice().sort((a, b) => a - b ? -1 : 1)
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      await API.fund.history({ id: this.fundId }).then(res => {
        this.history = res.data.history
        this.priceCurrency = res.data.price_currency
      })
    },
    formatYear(date) {
      return +moment(date).format('YYYY')
    },
    symbolClass(val) {
      // 正負號Class
      if (typeof val == 'number') {
        return `data-value--${val >= 0 ? 'positive' : 'negative'}`
      } else {
        return null
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
@import '~modules/components/fund/monthTable.styl'
.monthly-return
  margin-bottom 1.5rem
.time-wrap
  display flex
  align-items center
  padding 1.5rem
// 表格
.list
  margin-bottom 0
  .grid
    &.year
      width 72px
      text-align left
    &.month
      flex-grow 1
      text-align right
</style>
