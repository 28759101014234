<template>
  <DropdownButton ref="dropdownButton" v-model="sortKey" fluid>
    <template #input>
      <i class="icon-arrow" />
      <span class="text">{{ displayText }}</span>
    </template>
    <template #menu>
      <div
        v-for="item in sortTitles"
        :key="item.id"
        :data-text="item.name"
        :data-value="item.id"
        class="item"
      >
        {{ item.name }}
      </div>
    </template>
  </DropdownButton>
</template>

<script>
/* eslint-disable vue/require-default-prop */

import DropdownButton from 'modules/ui/form/dropdownButton'
export default {
  components: {
    DropdownButton,
  },
  props: {
    sortTitles: Array,
    sortBy: Array,
  },
  computed: {
    displayText() {
      const target = this.sortTitles.find(item => this.sortKey === item.id)
      return target?.name || '預設排序'
    },
    sortKey: {
      get() {
        return this.sortBy[0] || 'default'
      },
      set(val) {
        if (val === 'default') {
          this.$emit('update:sortBy', [])
        } else {
          this.$emit('update:sortBy', [val, -1])
        }
      }
    },
  },
}
</script>

<style lang="stylus" scoped>

</style>
