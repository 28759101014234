import API from 'api'
import PanelComparePicker from 'modules/components/compare/PanelComparePicker'

class OptionItem {
  constructor(attrs) {
    Object.assign(this, attrs)
    Object.defineProperty(this, 'id', {
      get() {
        return attrs.id || attrs.lipper_id
      }
    })
  }

  static fromArray(items) {
    return items.map(each => new OptionItem(each))
  }
}

// 將 ID 轉為正確的資料格式
function normalizeId(id) {
  if (String(id).match(/^\d+$/)) {
    return +id
  } else {
    return String(id)
  }
}

function sortByDefault(arrayOfItems) {
  const getOrder = (item) => {
    if (item.category === '熱搜基金') return -1
    return 1
  }
  const sorted = arrayOfItems.slice()
  sorted.sort((a, b) => getOrder(a) - getOrder(b))
  return sorted
}

const MixinComparableBehavior = {
  components: {
    PanelComparePicker,
  },
  data() {
    return {
      raw: {
        entry: null,
        strategy: null,
        benchmarks: [],
        funds: [],
        taroboFunds: [],
      },
      current: {
        compareIds: [],
      }
    }
  },
  methods: {
    async loadComparableData() {
      this.raw.benchmarks = await API.benchmark.list().then(res => res.data.entries).then(sortByDefault).then(items => OptionItem.fromArray(items))
      this.raw.funds = await API.fund.list().then(res => res.data.entries).then(sortByDefault).then(items => OptionItem.fromArray(items))
      this.raw.taroboFunds = await API.taroboFund.list().then(res => res.data.entries).then(sortByDefault).then(items => OptionItem.fromArray(items))
    },
    updateSelectedIds(val) {
      this.current.compareIds = val
    },
    routeToCompare(val) {
      this.current.compareIds = val
      this.$router.push({
        name: 'Compare',
        params: {
          ids: this.current.compareIds.join(',')
        }
      })
    },
    loadComparisonFromRoute() {
      this.current.compareIds = this.$route.params.ids.split(',').map(normalizeId)
    }
  },
  computed: {
    selectedIds: { // 因為 Compare.vue 使用了不同變數來儲存比較 id，只好多一個轉接
      get() {
        return this.current.compareIds
      },
      set(v) {
        this.current.compareIds = v
      }
    },
    comparingFundIds() {
      const available = [
        ...this.raw.funds.map(({ id }) => id),
      ]
      return this.selectedIds.filter(id => available.includes(id))
    },
    comparingFundIdsWithTarobo() {
      const available = [
        ...this.comparingFundIds,
        ...this.raw.taroboFunds.map(({ id }) => id)
      ]
      return this.selectedIds.filter(id => available.includes(id))
    },
    propsForComparePicker() {
      return {
        funds: this.raw.funds,
        taroboFunds: this.raw.taroboFunds,
        benchmarks: this.raw.benchmarks,
        selectedIds: this.current.compareIds,
      }
    },
    eventsForComparePicker() {
      return {
        submit: (ids) => {
          this.routeToCompare(ids)
          this.updateSelectedIds(ids)
        }
      }
    },
  }
}

export default MixinComparableBehavior
