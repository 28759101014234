import { render, staticRenderFns } from "./Signup.vue?vue&type=template&id=56ad9455&scoped=true&"
import script from "./Signup.vue?vue&type=script&lang=js&"
export * from "./Signup.vue?vue&type=script&lang=js&"
import style0 from "./Signup.vue?vue&type=style&index=0&id=56ad9455&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56ad9455",
  null
  
)

export default component.exports