<template>
  <div :class="groupClass" class="buttons-group">
    <a class="toggle-button share" @click.stop="group.active = !group.active" />
    <div class="child">
      <a class="toggle-button copy-link" target="_blank" @click.stop="copyLink" />
      <!-- <a :href="hrefShareFb" class="toggle-button facebook" target="_blank" @click.stop="closeGroup" /> -->
      <a :href="hrefShareLine" class="toggle-button line" @click.stop="closeGroup" />
    </div>
  </div>
</template>

<script>
import { MixinNotifiable } from 'modules/ui/notification'
const copy = require('clipboard-copy')

export default {
  mixins: [
    MixinNotifiable,
  ],
  props: {
    direction: {
      type: String,
      default: 'ani-left', // ani-left, ani-down
    },
    size: {
      type: String,
      default: 'medium', // medium, large
    },
    link: { // 自訂網址 (通用)，優先度 2
      type: String,
      default: () => String(window.location),
    },
    setUrl: { // 自訂網址，優先度 1
      type: Object,
      default: () => {
        return {
          link: null,
          fb: null,
          line: null,
        }
      }
    }
  },
  data() {
    return {
      group: {
        active: false
      },
    }
  },
  computed: {
    groupClass() {
      const arry = []
      arry.push(this.direction, this.size)
      if (this.group.active) arry.push('active')
      return arry
    },
    hrefShareFb() {
      const url = this.setUrl.fb || this.link
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
    },
    hrefShareLine() {
      const url = this.setUrl.line || this.link
      return `javascript: window.open('https://lineit.line.me/share/ui?url=${encodeURIComponent(url)}')`
    },
  },
  methods: {
    closeGroup () {
      this.group.active = false
    },
    copyLink () {
      const url = this.setUrl.link || this.link
      copy(url)
      this.success('已複製連結')
      this.closeGroup()
    }
  },
}
</script>

<style lang="stylus" scoped>
.buttons-group
  &.medium
    .toggle-button
      width 36px
      height 36px
  &.large
    .toggle-button
      width 48px
      height 48px

.buttons-group
  position relative
  z-index 10
  &.ani-down // 往下展
    .child
      position absolute
      left 0
      top 100%
      transform translateY(-100%)
      opacity 0
      transition .5s
      pointer-events none
      .toggle-button
        margin-top .5rem
        transition 0s
        &:not(:first-child)
          position absolute
          top 0
          left 0
    &.active
      .child
        transform translateY(0)
        opacity 1
        pointer-events auto
      .toggle-button
        &:not(:first-child)
          position static
  &.ani-left // 往左展
    .child
      position absolute
      left 0
      top 0
      transform translateX(0)
      display flex
      flex-direction row-reverse
      opacity 0
      transition .5s
      pointer-events none
      .toggle-button
        margin-right .5rem
        &:not(:first-child)
          position absolute
          top 0
          left 0
          transition .5s
    &.active
      .child
        transform translateX(-100%)
        opacity 1
        pointer-events auto
      .toggle-button
        &:not(:first-child)
          position static

.toggle-button
  display block
  margin-left auto
  background-size 100% 100%
  background-position center center
  transition .3s
  cursor pointer
  &.share
    background-image url('~images/buttons/btn-share.png')
    &:hover
      background-image url('~images/buttons/btn-share-alt.png')
  &.copy-link
    background-image url('~images/buttons/btn-copy-link.png')
    &:hover
      background-image url('~images/buttons/btn-copy-link-alt.png')
  &.facebook
    background-image url('~images/buttons/btn-facebook.png')
    &:hover
      background-image url('~images/buttons/btn-facebook-alt.png')
  &.line
    background-image url('~images/buttons/btn-line.png')
    &:hover
      background-image url('~images/buttons/btn-line-alt.png')
</style>
