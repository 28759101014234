<template>
  <div :class="classes" class="ui-checkbox">
    <div :class="{toggle: toggleSwitch, checked: isChecked, radio, circled}" class="ui checkbox">
      <input ref="input" :checked="isChecked" :type="type" class="hidden" v-bind="$attrs">
      <label @click.stop="onClick" v-on="$listeners">
        <slot />
      </label>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'input',
  },
  props: {
    disabled: Boolean,
    checked: {
      type: [String, Number, Boolean, Array],
      default: null,
    },
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    toggleSwitch: Boolean,
    radio: Boolean,
    circled: Boolean,
  },
  computed: {
    isChecked() {
      if (this.radio) {
        return this.checked === this.value
      } else if (this.checked instanceof Array) {
        return this.checked.indexOf(this.value) > -1
      } else {
        return this.checked
      }
    },
    classes() {
      return {
        'disabled': this.disabled,
        'toggle-switch': this.toggleSwitch,
        'radio': this.radio,
      }
    },
    type() {
      return this.radio ? 'radio' : 'checkbox'
    },
  },
  methods: {
    onClick() {
      const status = { check: !this.isChecked, value: this.value }
      if (this.radio) {
        this.$emit('input', this.value, status)
      } else if (this.checked instanceof Array) {
        if (this.isChecked) {
          this.$emit(
            'input',
            this.checked.filter(e => e !== this.value),
            status
          )
        } else {
          this.$emit('input', [...this.checked, this.value], status)
        }
      } else {
        this.$emit('input', !this.isChecked, status)
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import './checkbox.less';
</style>

<style lang="stylus" scoped>
.ui-checkbox
  display inline-block
  vertical-align middle
  margin-right .5em
  line-height 1
  &:last-child
    margin-bottom 0
    margin-right 0
  &.fluid
    display block
  &.disabled label:before, &.disabled label:after
    pointer-events none
  &:not(.fluid) .ui.checkbox
    float left
  &.fluid + &.fluid
    margin-top .5em
</style>

<style lang="stylus">
.ui.toggle.checkbox input
  ~ label
    padding-left 2.6em
  ~ label:before
    width 2.5em !important
    height 1.45em
  ~ label:after
    box-shadow none !important
    width 1.1em
    height 1.1em
    margin .2em
  &:checked ~ label:after
    left 1em
</style>
