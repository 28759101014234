/* Usage Example

let commit = await confirm({
  title: '移除此項目',
  message: '一旦移除後後將無法復原，您確定嗎？',
  buttonText: { approve: "立即移除"},
  buttonClass: { approve: "danger" },
  showBtnClose: true   // 顯示右上角叉叉
})

*/

window.confirm = (function() {
  const internalConfirm = window.confirm
  const modalConfirm = async function(args) {
    const {
      //
      message,
      title,
      buttonText = { approve: '確定', deny: '取消' },
      buttonClass = { approve: '', deny: '' },
      showBtnClose,
    } = args
    let answer = null
    const titleHTML = title ? `<div class="header">${title}</div>` : ''
    const buttonClose = showBtnClose ? '<i class="close icon" style="top:25px!important; right:10px!important; padding:0; color:#aaa;"></i>' : ''

    await new Promise(function(resolve, reject) {
      const $modal = jQuery(
        `
        <div class="ui modal modal-message modal-message--confirm">
          ${titleHTML}
          ${buttonClose}
          <div class="scrolling image content">
            <div class="description" style="width:100%">
              ${String(message).replace(/\n/g, '<br/>')}
            </div>
          </div>
          <div class="actions">
            <div class="ui deny button minor ${buttonClass.deny}">
              ${buttonText.deny || '取消'}
            </div>
            <div class="ui approve button major ${buttonClass.approve}">
              ${buttonText.approve || '確定'}
            </div>
          </div>
        </div>
      `.trim()
      ).modal({
        observeChanges: true,
        closable: false,
        allowMultiple: true,
        onApprove: () => {
          answer = true
        },
        onDeny: () => {
          answer = false
        },
        onHidden: () => {
          $modal.remove()
          resolve(answer)
        },
      })
      $modal.modal('show')
    })

    return answer
  }
  return function(args) {
    if (typeof args == 'object') {
      return modalConfirm(args)
    } else {
      return internalConfirm(args)
    }
  }
})()

export default window.confirm
