<template>
  <div v-if="classifyRecord" class="winning-record">
    <div v-for="record in classifyRecord" :key="record.year" class="year-section">
      <div class="year-topic">
        {{ record.year }}
      </div>
      <div class="content">
        <template v-for="host in record.awardsHosts">
          <!-- 得獎 -->
          <div v-if="host.getHonor.length > 0" :key="`${host.name}Honor`" class="section-row flex-box is-winner">
            <template v-if="$deviceView.desktop">
              <div class="symbol gold">
                <div>
                  <span>{{ host.name }}</span>
                </div>
              </div>
              <div class="descript">
                <div v-for="item in host.getHonor" :key="item.title" class="descript-item flex-box">
                  <div class="font-bold mr-1">
                    {{ item.title }}
                  </div>
                  <div class="ui button major micro noaction custom-tag">
                    Winner
                  </div>
                </div>
              </div>
              <div class="trophy">
                <img src="~images/shining-trophy.png" alt="">
              </div>
            </template>
            <div v-else>
              <div class="flex-box">
                <div class="symbol gold">
                  <div>
                    <span>{{ host.name }}</span>
                  </div>
                </div>
                <div class="trophy">
                  <img src="~images/shining-trophy.png" alt="">
                </div>
              </div>
              <div class="descript">
                <div v-for="item in host.getHonor" :key="item.title" class="descript-item flex-box">
                  <div class="font-bold mr-1">
                    {{ item.title }}
                  </div>
                  <div class="ui button major micro noaction custom-tag">
                    Winner
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 提名 -->
          <div v-if="host.onlyNominate.length > 0" :key="`${host.name}Nominate`" class="section-row flex-box">
            <template v-if="$deviceView.desktop">
              <div class="symbol basic">
                <div>
                  <span>{{ host.name }}</span>
                </div>
              </div>
              <div class="descript">
                <div v-for="(item, i) in host.onlyNominate" :key="`descript${i}`" class="descript-item flex-box">
                  <div class="mr-1">
                    {{ item.title }}
                  </div>
                  <div class="ui button minor micro noaction custom-tag">
                    提名
                  </div>
                </div>
              </div>
            </template>
            <div v-else>
              <div class="symbol basic">
                <div>
                  <span>{{ host.name }}</span>
                </div>
              </div>
              <div class="descript">
                <div v-for="(item, i) in host.onlyNominate" :key="`descript${i}`" class="descript-item flex-box">
                  <div class="mr-1">
                    {{ item.title }}
                  </div>
                  <div class="ui button minor micro noaction custom-tag">
                    提名
                  </div>
                </div>
              </div>

            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rawData: Array,
  },
  computed: {
    classifyRecord() {
      const sortData = this.rawData.slice().sort((a, b) => a > b ? -1 : 1)
      return sortData.map(item => {
        const awardsHosts = item.awards_by_hosts.map(host => {
          const getHonor = host.awards.filter(award => award.honor === '得獎')
          const onlyNominate = host.awards.filter(award => award.honor === '提名')
          return {
            name: host.name,
            getHonor,
            onlyNominate,
          }
        })
        return {
          year: item.year,
          awardsHosts,
        }
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.flex-box
  display flex
  align-items center
.mr-1
  margin-right 1rem
.ui.button.custom-tag
  min-width 56px
  flex-shrink 0

.is-winner
  order: -1

.winning-record
  +over(md)
    padding 0 2.5rem
.year-section
  padding-bottom 1.5rem
  border-bottom 1px solid #EDEDED
  margin-bottom 1.5rem
  .year-topic
    font-weight bold
    margin-bottom .5rem
    +over(md)
      font-size 1.2rem
  .content
    position relative
    margin-left 41px
    display flex
    flex-direction column
    +over(md)
      padding-left 35px
    &:before
      content ''
      position absolute
      border 2px dashed #ddd
      height 100%
      left -22px
      top 0
  .section-row + .section-row
    margin-top 2rem
    +under(md)
      margin-top 1rem
  .descript
    flex 1 1 auto
    margin-left 2rem
    +under(md)
      margin 1rem 0 0
    .descript-item + .descript-item
      margin-top .5rem
  .symbol
    position relative
    display flex
    flex 0 0 100px
    width 100px
    height 100px
    align-items center
    justify-content center
    text-align center
    border-radius 50%
    +under(md)
      font-size 12px
    &.basic
      background #FEF9F4
      border 1px solid #FF895A
      color $gray2
    &.gold
      background #D4B676
      color #505050
      &:before
        content ''
        position absolute
        display block
        width 89px
        height 89px
        border 1px solid #fff
        border-radius 50%
        top 5.5px
        left 5.5px
        +under(md)
          width 92px
          height 92px
          top 4px
          left 4px
  .trophy
    flex 0 0 84px
    margin-left 1rem
    +under(md)
      flex 0 0 50px
    img
      width 100%
</style>
