<!--
使用範例

<SortableFieldName v-model="sortBy" name="price">價格</SortableFieldName>
<SortableFieldName v-model="sortBy" name="ranking">人氣</SortableFieldName>

<script>
import SortableFieldName from 'modules/ui/table/SortableFieldName'
export default {
  components: [
    SortableFieldName
  ],
  data: () => ({
    sortBy: ['ranking', -1]
  })
}
</script>

-->

<template>
  <span class="sortable-field-name" :class="{'is-sorted': value[0] === name}" @click="switchOrder">
    <span class="name">
      <slot />
    </span>
    <i :class="iconClass" class="icon" />
  </span>
</template>

<script>
/* eslint-disable vue/require-default-prop */

export default {
  props: {
    name: String,
    value: Array,
  },
  computed: {
    ordering() {
      if (this.value[0] === this.name) {
        return this.value[1]
      }
      return ''
    },
    iconClass() {
      switch (this.ordering) {
        case 1:
          return 'icon-up-dir'
        case -1:
          return 'icon-down-dir'
        default:
          return 'icon-sort'
      }
    },
  },
  methods: {
    switchOrder() {
      // 切換順序：反序 → 正序 → 取消
      switch (this.ordering) {
        case 1:
          this.$emit('sort', this.name, null)
          this.$emit('input', [])
          break
        case -1:
          this.$emit('sort', this.name, 1)
          this.$emit('input', [this.name, 1])
          break
        default:
          this.$emit('sort', this.name, -1)
          this.$emit('input', [this.name, -1])
          break
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.sortable-field-name
  cursor pointer
  transition .3s
  display inline-flex
  height 100%
  align-items center
  justify-content center
  &:hover
    font-weight bold
    .icon
      color $gray2
  &.is-sorted, &.is-sorted .icon, &.is-sorted:hover
    color $gray1
    font-weight normal

.name
  display inline-block
  vertical-align middle
.icon
  display inline-block
  vertical-align middle
  margin 0
  color #ddd
  &.icon-sort-up, &.icon-sort-down
    color $gray1
</style>
