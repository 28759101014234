import { createValidator, calcLength } from 'modules/ui/form/validation.js'

export default createValidator([
  {
    when: ({ hasField }) => hasField('title'),
    field: 'title',
    message: '請輸入標題'
  },
  {
    when: ({ hasField }) => hasField('title'),
    validator: (title, { hasField }) => calcLength(title) <= (hasField('title').limit || 50),
    field: 'title',
    message: (title, { hasField }) => `標題不得超過 ${(hasField('title').limit || 50)} 字`
  },
  {
    when: ({ hasField }) => hasField('cover'),
    field: 'cover',
    message: '請上傳封面圖片'
  },
  {
    when: ({ hasField }) => hasField('attachment:pdf'),
    field: 'attachment',
    message: '必須上傳檔案'
  },
  {
    when: ({ hasField }) => hasField('attachment:document'),
    field: 'attachment',
    message: '必須上傳檔案'
  },
  {
    when: ({ hasField }) => hasField('summary'),
    field: 'summary',
    message: '請輸入內容描述'
  },
  {
    when: ({ hasField }) => hasField('video'),
    field: 'video_url',
    message: '請輸入影片分享連結'
  },
  {
    when: ({ hasField }) => hasField('video:subtitle'),
    field: 'subtitle',
    message: '請輸入影音小標'
  },
  {
    when: ({ hasField }) => hasField('video:subtitle'),
    validator: subtitle => calcLength(subtitle) <= 10,
    field: 'subtitle',
    message: '影音小標不得超過 10 字'
  },
  {
    when: ({ hasField }) => hasField('video:live'),
    field: 'live_subscribe_url',
    message: '請輸入影片預約連結'
  },
  {
    when: ({ hasField }) => hasField('video:live'),
    field: 'live_url',
    message: '請輸入影片直播連結'
  },
  {
    when: ({ hasField }) => hasField('video:live'),
    field: 'live_start_at',
    message: '請輸入直播開始日期'
  },
  {
    when: ({ hasField }) => hasField('video:live'),
    field: 'live_end_at',
    message: '請輸入直播結束日期'
  },
  {
    when: ({ hasField }) => hasField('card:images'),
    validator: content => content.length > 0,
    field: 'content',
    message: '請上傳圖卡'
  },
  {
    when: ({ kindConfig }) => kindConfig.categorisable > 0,
    field: 'category_id',
    message: '請選擇分類'
  },
])
