import getScrollParent from 'lib/utils/getScrollParent.js'

export default {
  props: {
    sticky: Boolean,
  },
  methods: {
    updateSticky() {
      const railX = this.$el.querySelector('.ps__rail-x')
      const rect = this.$el.getBoundingClientRect()
      const delta = window.innerHeight - rect.bottom
      if (delta < 0 && rect.top < window.innerHeight) {
        railX.style.willChange = 'transform'
        railX.style.transition = 'transform 0s'
        railX.style.transform = `translateY(${delta}px)`
      } else {
        railX.style.transform = `translateY(${0}px)`
      }
    }
  },
  mounted () {
    if (this.sticky) {
      this.__scrollableContainer = getScrollParent(this.$el)
      this.__scrollableContainer?.addEventListener('scroll', this.updateSticky)
      this.$on('update', this.updateSticky)
    }
  },
  beforeDestroy() {
    if (this.sticky) {
      this.__scrollableContainer?.removeEventListener('scroll', this.updateSticky)
    }
  }
}

