import Vue from 'vue/dist/vue.esm'

// Essential
Vue.use(require('vue-router').default)
Vue.use(require('vuex').default)

// Plugins
Vue.use(require('./plugins/filters.js').default)
Vue.use(require('./plugins/deviceView.js').default)
Vue.use(require('./plugins/tooltip.js').default)
Vue.use(require('./plugins/mark.js').default)
Vue.use(require('./plugins/loadingState.js').default)
Vue.use(require('./plugins/setTitle.js').default)
Vue.use(require('./plugins/eventBus.js').default)

export default Vue
