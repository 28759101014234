function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Draggable',{attrs:{"value":_vm.entries,"tag":"tbody","handle":_vm.dragHandle},on:{"input":function (entries) { return _vm.$emit('sort:entries', entries); },"change":function($event){return _vm.$emit('sort')}}},_vm._l((_vm.entries),function(model){return _c('tr',{key:model.id},[_vm._l((_vm.columns),function(ref){
var name = ref.name;
var style = ref.style;
var template = ref.template;
var column = ref.column;
var props = ref.props;
var rest = objectWithoutProperties( ref, ["name", "style", "template", "column", "props"] );
var options = rest;
return [_c('td',_vm._b({key:((model.id) + "-" + name)},'td',{style: style},false),[_c(template,_vm._g(_vm._b({tag:"component"},'component',Object.assign({}, {model: model, column: column}, props, options),false),_vm.$listeners))],1)]})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }