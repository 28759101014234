<template>
  <div class="basic__container">
    <h2 class="basic__topic">
      交易表單
    </h2>
    <UiTab v-if="typeTitle" :tabs="typeTitle" :tab.sync="current.typeName" class="modern tab-wrap" />
    <Loader v-if="displayList" :loading="!loadingStateReady" class="forms-board">
      <div class="form-list">
        <div class="form-head">
          <div class="form-row">
            <div class="form-col info">
              表單
            </div>
            <div class="form-col icon">
              檢視/下載檔案
            </div>
          </div>
        </div>
        <section v-for="(entry, i) in displayList" :key="i">
          <div v-if="entry.name" class="form-subhead">
            {{ entry.name }}
          </div>
          <div class="form-body">
            <div v-for="item in entry.list" :key="item.id" class="form-row">
              <div class="form-col info">
                <router-link v-if="item.has_content" :to="{name: 'FormsContent', params: {id: item.id}}" class="form-link">
                  {{ item.title }}
                </router-link>
                <a v-else :href="item.attachment_url" target="_blank" class="form-link">{{ item.title }}</a>
              </div>
              <!-- 有製作成內文的不要顯示icon -->
              <div v-if="item.attachment_download_url && !item.has_content" class="form-col icon">
                <a class="icon-button third" :href="item.attachment_download_url" target="_blank">
                  <i class="icon-file" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Loader>
    <div class="illustration">
      <img src="~images/img-illustration-7.png" alt="">
    </div>
  </div>
</template>

<script>
import API from 'api'
import UiTab from 'modules/ui/tabs/index'
import Loader from 'modules/ui/loader'

export default {
  components: {
    UiTab,
    Loader,
  },
  data() {
    return {
      current: {
        typeName: ''
      },
      kind: 'form',
      types: [],
      entries: null,
    }
  },
  computed: {
    typeTitle() {
      return this.types.map(type => type.name)
    },
    currentType() {
      return this.types.find(type => type.name === this.current.typeName)
    },
    currentTypeId: {
      get() {
        if (!this.currentType) return ''
        return this.currentType.id
      },
      set(id) {
        this.current.typeName = this.types.find(e => e.id === +id).name
      },
    },
    displayList() {
      if (!this.currentType || !this.entries) return null
      if (this.currentType.categories.length > 1) {
        return this.currentType.categories.map(category => {
          return {
            id: category.id,
            name: category.name,
            articles_count: category.articles_count,
            list: this.entries.filter(e => e.category?.id == category.id)
          }
        })
      } else {
        return [
          { list: this.entries }
        ]
      }
    },
  },
  watch: {
    'currentTypeId'(typeId) {
      this.loadTypeList()
      if (+typeId !== +this.$route.params.type_id) {
        this.$router.push({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            type_id: typeId,
          },
        })
        this.setTitle()
      }
    },
  },
  async mounted() {
    this.types = await API.content.categories({ kind: this.kind }).then(res => res.data.entries)
    this.currentTypeId = this.$route.params.type_id
    this.setTitle()
  },
  methods: {
    async loadTypeList() {
      const loader = this.createLoadingState()
      this.entries = await API.content.categories({ kind: this.kind, id: this.currentTypeId }).then(res => res.data.entries)
      loader.finish()
    },
    setTitle() {
      this.$setTitle(`${this.current.typeName} - 交易表單`)
    }
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
@require '~pages/bulletin/formList.styl'
.tab-wrap
  margin 2rem auto
  position relative
  z-index 1
  +over(md)
    &:before
      content ''
      width 182px
      height 140px
      right 25%
      bottom 0
      position absolute
      background-image url('~images/animation/img-3.0.1.svg')
      background-repeat no-repeat
      background-position center center
      background-size contain
      transform rotate(175deg)
      z-index -1
.form-list
  .form-col
    &.info
      flex-grow 1
    &.icon
      flex 0 0 100px
      text-align center
      i
        color $gray2
        font-size 15px
.illustration
  margin 4rem 0 5rem
  width 359px
  +under(md)
    width 100%
    max-width 210px
    margin 2rem 0
  img
    width 100%
    image-rendering -webkit-optimize-contrast
</style>
