<!--
  eslint-disable vue/max-attributes-per-line
  eslint-disable vue/html-self-closing
  eslint-disable vue/attributes-order

  清單模組：報酬率與波動率

-->

<template>
  <UiLoader :loading="!loadingStateReady">
    <ModalColumnSelection ref="modalSelect" :displayOptions="allPeriods" :pickedOptions.sync="pickedPeriodsName"></ModalColumnSelection>

    <div class="sort-wrap mobile-only" v-if="options.sort">
      <SortListDropdown v-bind="{sortTitles}" :sortBy.sync="current.sortBy"></SortListDropdown>
    </div>

    <div class="list" ref="list">
      <ViewportCondition value="> md">
        <div class="list-head">
          <div class="row head-row">
            <template v-if="options.sort">
              <div class="grid g-title"></div>
              <Scroll class="column-group" ref="head" @ps-scroll-x.native="updateScroll">
                <div class="grid" v-for="period in tablePeriods" :key="period.name">
                  <SortableFieldName v-model="current.sortBy" :name="period.name">
                    {{ period.title }}
                  </SortableFieldName>
                </div>
              </Scroll>
            </template>
            <template v-else>
              <div class="grid g-title"></div>
              <Scroll class="column-group" ref="head" @ps-scroll-x.native="updateScroll">
                <div class="grid" v-for="period in tablePeriods" :key="period.name">
                  {{ period.title }}
                </div>
              </Scroll>
            </template>
            <div v-if="options.remove && fundsSorted.length > 1" class="grid actions">
              刪除
            </div>
          </div>
        </div>

        <div class="list-body">
          <template v-if="singleFund">
            <!-- 基金單頁 -->
            <div class="body-row-group" v-for="(fund, index) in fundsSorted" :key="`body-row-group-${index}`">
              <div class="row body-row">
                <div class="grid g-title">
                  <span class="color-line" :style="{backgroundColor: fundColors[fund.id]}"></span>
                  <span>{{ fund.name }}</span>
                </div>
                <Scroll class="column-group" ref="info" @ps-scroll-x.native="updateScroll">
                  <div class="grid" v-for="period in tablePeriods" :key="period.name" :class="symbolClass(fund.attributes[period.name])">
                    <template v-if="isSharp">
                      {{ fund.attributes[period.name] | round(3) }}
                    </template>
                    <template v-else>
                      {{ fund.attributes[period.name] | percentage(1) }}
                    </template>
                  </div>
                </Scroll>
              </div>
            </div>
          </template>
          <template v-else>
            <!-- 基金比較 -->
            <div class="body-row-group" v-for="(fund, index) in fundsSorted" :key="`body-row-group-${index}`"
                 :style="customStyle(fund.id)"
                 @mouseenter="hoverFund(fund.id)"
                 @mouseleave="hoverFund(fund.id)"
            >
              <div class="row body-row">
                <div class="grid g-title">
                  <FundName v-if="fundItems.includes(fund.id) && !singleFund"
                            :color="fundColors[fund.id]"
                            :fundId="fund.id"
                            :fundName="fund.name"
                  ></FundName>
                  <template v-else>
                    <span class="color-line" :style="{backgroundColor: fundColors[fund.id]}" />
                    <span>{{ fund.name }}</span>
                  </template>
                </div>
                <Scroll class="column-group" ref="info" @ps-scroll-x.native="updateScroll">
                  <div class="grid" v-for="period in tablePeriods" :key="period.name" :class="symbolClass(fund.attributes[period.name])">
                    <template v-if="isSharp">
                      {{ fund.attributes[period.name] | round(3) }}
                    </template>
                    <template v-else>
                      {{ fund.attributes[period.name] | percentage(1) }}
                    </template>
                  </div>
                </Scroll>
                <div class="grid actions" v-if="options.remove && fundsSorted.length > 1">
                  <div v-if="!lockedItems.includes(fund.id)" class="icon-button third" @click="remove(fund.id)">
                    <i class="icon-delete" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </ViewportCondition>

      <ViewportCondition value="< md">
        <div class="list-head" :class="{'status-fixed': fixedHead}">
          <div class="fixed-area">
            <Scroll ref="head" @ps-scroll-x.native="updateScroll">
              <div class="row head-row">
                <div class="grid" v-for="period in tablePeriods" :key="period.name">
                  {{ period.title }}
                </div>
              </div>
            </Scroll>
          </div>
        </div>
        <div class="list-body">
          <template v-for="(fund, index) in fundsSorted">
            <div class="body-row-group" :key="`body-row-group-${index}`">
              <div class="row body-row" :key="fund.id">
                <div class="row-name">
                  <div class="sector-content">
                    <!-- 基金單頁 -->
                    <template v-if="singleFund">
                      <span class="color-line" :style="{backgroundColor: fundColors[fund.id]}"></span>
                      <span>{{ fund.name }}</span>
                    </template>
                    <!-- 績效比較 -->
                    <template v-else>
                      <FundName v-if="fund.type === 'fund'"
                                :color="fundColors[fund.id]"
                                :fundId="fund.id"
                                :fundName="fund.name"
                      ></FundName>
                      <template v-else>
                        <span class="color-line" :style="{backgroundColor: fundColors[fund.id]}" />
                        <span>{{ fund.name }}</span>
                      </template>
                    </template>
                  </div>
                  <div class="actions" v-if="options.remove && fundsSorted.length > 1">
                    <div v-if="!lockedItems.includes(fund.id)" class="icon-button third" @click="remove(fund.id)">
                      <i class="icon-delete" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
                <Scroll ref="info" @ps-scroll-x.native="updateScroll">
                  <div class="other-info">
                    <div class="grid" v-for="period in tablePeriods" :key="period.name">
                      <template v-if="isSharp">
                        {{ fund.attributes[period.name] | round(3) }}
                      </template>
                      <template v-else>
                        {{ fund.attributes[period.name] | percentage(1) }}
                      </template>
                    </div>
                  </div>
                </Scroll>
              </div>
            </div>
          </template>
        </div>
      </ViewportCondition>
    </div>
  </UiLoader>
</template>

<script>
import API from 'api'

import MixinScrollList from 'modules/components/fund/MixinScrollList'
import MixinSortList from 'modules/components/fund/MixinSortList'
import MixinInteractive from 'modules/components/fund/MixinInteractive'
import tableConfig from 'modules/components/fund/config'

import UiLoader from 'modules/ui/loader.vue'
import SortableFieldName from 'modules/ui/table/SortableFieldName'
import SortListDropdown from 'modules/components/fund/SortListDropdown.vue'
import ModalColumnSelection from 'modules/components/ModalColumnSelection'
import ViewportCondition from 'modules/misc/ViewportCondition.vue'
import FundName from 'modules/components/fund/FundName'

export default {
  components: {
    UiLoader,
    SortableFieldName,
    SortListDropdown,
    ModalColumnSelection,
    ViewportCondition,
    FundName,
  },
  mixins: [
    MixinScrollList,
    MixinSortList,
    MixinInteractive, // 圖表互動
  ],
  props: {
    singleFund: Boolean, // for 基金單頁
    benchmarkIds: Array, // for 多隻基金
    fundIds: Array,
    fundColors: {
      type: Object,
    },
    tab: String,
    currency: {
      type: String,
      default: function() {
        return 'USD'
      },
    },
    options: {
      type: Object,
      default: () => ({
        remove: false,
        sort: false,
      })
    },
    /**
     *不可刪除的項目
     */
    lockedItems: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      database: {
        benchmarks: [],
        funds: [],
      },
      current: {
        sortBy: [],
      },
      pickedPeriodsKey: [],
      // TODO 必須動態取得
      fundItems: ['GCMF', 'SOF', 'UGWAF'],
    }
  },
  computed: {
    allPeriods() {
      return tableConfig[this.tabKey]
    },
    tablePeriods() {
      return this.pickedPeriodsKey.map(key => this.allPeriods.find(e => e.key === key)).sort((a, b) => a.id > b.id ? 1 : -1)
    },
    pickedPeriodsName: {
      get() {
        // 選擇期間吃 name
        return this.pickedPeriodsKey.map(key => this.allPeriods.find(e => e.key === key).name)
      },
      set(names) {
        // list 之間統一用 key 溝通
        this.pickedPeriodsKey = names.map(name => this.allPeriods.find(e => e.name === name).key)
      }
    },
    tabKey() {
      const map = {
        報酬率: 'performance',
        年化波動率: 'volatility',
        夏普值: 'sharpe',
      }
      return map[this.tab]
    },
    isSharp() {
      return this.tab === '夏普值'
    },
    fundsSorted() {
      const allEntries = [
        ...this.database.benchmarks,
        ...this.database.funds,
      ].slice()
      return this.getSorted(allEntries)
    },
    sortTitles() {
      const options = this.tablePeriods.map(period => {
        return {
          ...period,
          name: `${period.title}，高→低`,
          id: period.name,
        }
      })
      options.unshift(
        { id: 'default', name: '預設排序' })
      return options
    },
  },
  watch: {
    fundIds() {
      this.load()
    },
  },
  async created() {
    await this.load()
    this.pickedPeriodsKey = this.allPeriods.filter(item => item.default).map((item) => item.key)
  },
  methods: {
    async load() {
      this.resetDatabase()
      await this.$_loadFundReturns()
    },
    async $_loadFundReturns () {
      const loader = this.createLoadingState()
      for (let i = 0; i < this.fundIds.length; i++) {
        const id = this.fundIds[i]
        await API.fund.fundReturns({ id })
          .then(res => {
            this.database.funds.push({
              ...res.data.entry,
              id,
              type: 'fund',
            })
          }).catch(() => {})
      }

      if (this.singleFund) {
        // 基金單頁的相依指標，預設滬森300
        const id = 'shz300'
        await this.getBenchmarkData(id)
      } else if (!this.singleFund && this.benchmarkIds) {
        // 績效比較的指標資料
        for (let i = 0; i < this.benchmarkIds.length; i++) {
          const id = this.benchmarkIds[i]
          await this.getBenchmarkData(id)
        }
      }
      loader.finish()
    },
    async getBenchmarkData(id) {
      await API.benchmark({ id })
        .then(res => {
          this.database.benchmarks.push({
            ...res.data.entry,
            type: 'benchmark',
          })
        }).catch()
    },
    remove(id) {
      this.$emit('remove', id)
    },
    resetDatabase() {
      this.$set(this.database, 'benchmarks', [])
      this.$set(this.database, 'funds', [])
    },
    fieldModifier(entry, key) {
      // mapping 方式與公版不同
      const value = this.accessValue(entry.attributes, key)
      return value
    },
    showModalSelect() {
      this.$refs.modalSelect.show()
    },
  }
}
</script>

<style lang="stylus" scoped>
@import '~modules/components/fund/scrollList.styl'
.list
  border-top 1px solid #ededed
  .grid
    width 106px
    text-align right
    &.g-title
      display flex
      align-items center
      flex 1 0 200px
      text-align left
    &.actions
      width 80px
      text-align center
      > div
        margin auto
.column-group
  @media $desktop
    .grid
      text-align right
      flex 1 0 106px

.sort-wrap
  border-top 1px solid #ededed
  padding 1rem 1.5rem
.price-ccy
  margin-left .5rem
  &:before
    content "("
  &:after
    content ")"

.preview-feature
  vertical-align top
  display inline
  white-space nowrap
  color #FF895A
  margin-left .5em

+under(md)
  .body-row
    .row-name, .sector-content
      display flex
      align-items center
      flex-grow 1
    .actions
      margin-left auto
</style>
