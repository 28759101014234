// Ref https://medium.com/i-am-mike/%E4%BD%BF%E7%94%A8axios%E6%99%82%E4%BD%A0%E7%9A%84api%E9%83%BD%E6%80%8E%E9%BA%BC%E7%AE%A1%E7%90%86-557d88365619
// https://medium.com/%E4%BC%81%E9%B5%9D%E4%B9%9F%E6%87%82%E7%A8%8B%E5%BC%8F%E8%A8%AD%E8%A8%88/vue%E5%B0%88%E6%A1%88%E4%B8%AD%E7%9A%84api%E7%AE%A1%E7%90%86%E5%8F%8A%E5%B0%81%E8%A3%9D-jwt%E8%BA%AB%E4%BB%BD%E9%A9%97%E8%AD%89%E7%AF%87-11b845e5eed5

import axios from 'axios'
import buildURL from 'axios/lib/helpers/buildURL'
import alertError from 'packs/framework/message.error.js'

const resources = {}
function createResources(resourcesHash) {
  for (const key in resourcesHash) {
    let options = {}
    if (typeof resourcesHash[key] === 'string') {
      options = {
        baseURL: resourcesHash[key]
      }
    } else {
      options = resourcesHash[key]
    }
    resources[key] = axios.create(options)

    // For Rails: request.xhr?api_request?
    resources[key].interceptors.request.use((config) => {
      config.headers.common['X-Requested-With'] = 'Axios-API'
      return config
    })

    // Handle Errors
    resources[key].interceptors.response.use((response) => response, (error) => {
      // 稍後如果沒有由 notification 顯示，那麼就在此顯示錯誤
      setTimeout(() => {
        if (!error.notificationHandled) {
          const { status, statusText } = error.response
          if (typeof error.response.data === 'string') {
            alertError(error.response.data, `${status} ${statusText}`)
          } else {
            alertError(error.response.data.message, `${status} ${statusText}`)
          }
        }
      }, 1500)
      throw error
    })
  }
}

function createActionFor(resource, method, url, optionalQueryParams = []) {
  return (params = {}) => {
    const trimmedParams = JSON.parse(JSON.stringify(params))
    const compiledUrl = url.replace(/(:[^:?/]+\??)/g, (field) => {
      const [, key, optional] = field.match(/^:(.+?)(\?)?$/)
      if (params[key] || optional) {
        delete trimmedParams[key] // queryString 不需要已經從 url path 傳進去的參數
        return params[key] || ''
      } else {
        throw new Error(`"${url}" 需要參數: "${key}". Params: `, params)
      }
    })
    // 打開新視窗
    if (method === 'open') {
      return window.open(buildURL(resource.defaults.baseURL + compiledUrl, trimmedParams))
    }
    // 在背景下載
    if (method === 'download') {
      return new Promise((resolve, reject) => {
        const container = document.createElement('iframe')
        container.style.display = 'none'
        container.onload = () => { // NOTE: onload 沒有作用
          document.body.removeChild(container)
          resolve()
        }
        container.src = buildURL(resource.defaults.baseURL + compiledUrl, trimmedParams)
        document.body.appendChild(container)
      })
    }
    if (['post', 'patch', 'put'].includes(method)) {
      return resource[method](compiledUrl, trimmedParams)
    } else {
      return resource[method](compiledUrl, { params: trimmedParams })
    }
  }
}

// 定義資源集
createResources({
  member: '/api/member/',
  members: '/api/members/', // 後台專用
  article: '/api/articles/',
  content: '/api/contents/',
  categories: '/api/categories', // 後台專用
  fund: '/api/funds',
  report: '/api/funds/reports',
  firms: '/api/firms',
  calendar: '/api/calendar',
  charts: '/api/charts',
  benchmark: '/api/benchmarks',
  taroboFund: '/api/tarobo_funds',
})

// 定義資源方法
const apis = {
  member: {
    loadProfile: createActionFor(resources.member, 'get', '/'),
    updateProfile: createActionFor(resources.member, 'patch', '/', ['avatar', 'name', 'mobile_number', 'birthday', 'gender']),
    signin: createActionFor(resources.member, 'post', '/signin', ['username', 'password', 'remember']),
    signup: createActionFor(resources.member, 'post', '/signup', ['username',
      'firm_id',
      'firm_job_title',
      'name',
      'password',
      'password_confirmation',
      'mobile_number',
      'birthday',
      'gender'
    ]),
    confirm: createActionFor(resources.member, 'post', '/confirm', ['username', 'token', 'confirm_for']),
    resendToken: createActionFor(resources.member, 'post', '/resend', ['username', 'confirm_for']),
    forget: createActionFor(resources.member, 'post', '/forget', ['username']),
    setPasswd: createActionFor(resources.member, 'post', '/passwd', ['username',
      'token',
      'former_password',
      'password',
      'password_confirmation']),
    contact: createActionFor(resources.member, 'post', '/contact', [
      'email',
      'name',
      'phone',
      'request',
      'message',
      'recaptcha',
    ]),
  },
  // 後台會員管理
  members: Object.assign(
    createActionFor(resources.members, 'get', '/:id'), {
      list: createActionFor(resources.members, 'get', '/'),
      update: createActionFor(resources.members, 'patch', '/:id'),
    }),
  // 市場觀點和關鍵圖卡
  articles: Object.assign(
    createActionFor(resources.article, 'get', '/:id'), {
      list: createActionFor(resources.article, 'get', '/:kind', ['page']),
      popular: createActionFor(resources.article, 'get', '/:kind/popular', ['limit']),
      categories: createActionFor(resources.article, 'get', '/:kind/categories/:id?', ['page']),
    }),
  content: Object.assign(
    createActionFor(resources.content, 'get', '/:id'), {
      list: createActionFor(resources.content, 'get', '/:kind', ['page', 'status']),
      popular: createActionFor(resources.content, 'get', '/:kind/popular', ['limit']),
      promoted: createActionFor(resources.content, 'get', '/:kind/promoted', ['limit']),
      categories: Object.assign(
        createActionFor(resources.content, 'get', '/:kind/categories/:id?', ['page']), { // 前台用，目錄文章
          edit: createActionFor(resources.content, 'get', '/:kind/categories/:id/edit'), // 後台用，目錄資料
          sort: createActionFor(resources.content, 'post', '/:kind/categories/:id/sort', ['page']),
          update: createActionFor(resources.content, 'patch', '/:kind/categories/:id'),
          destroy: createActionFor(resources.content, 'delete', '/:kind/categories/:id'),
          create: createActionFor(resources.content, 'post', '/:kind/categories'),
        }
      ),
      update: createActionFor(resources.content, 'patch', '/:id'),
      destroy: createActionFor(resources.content, 'delete', '/:id'),
      sort: createActionFor(resources.content, 'post', '/:id/sort', ['old_index', 'new_index']),
      create: createActionFor(resources.content, 'post', '/:kind'),
      download: createActionFor(resources.content, 'download', '/:id/download'), // 只有 chart 可以下載
      saleskits: createActionFor(resources.content, 'get', '/saleskits'),
      read: createActionFor(resources.content, 'post', '/:id/read'), // 標示為已讀
      marquee: createActionFor(resources.content, 'get', '/marquee'), // 跑馬燈
      livestream: createActionFor(resources.content, 'get', '/livestream'), // 直播
    }),
  // 基金系列
  fund: Object.assign(
    createActionFor(resources.fund, 'get', '/:id'), {
      list: createActionFor(resources.fund, 'get', '/'),
      reports: createActionFor(resources.fund, 'get', '/:id/reports'),
      update: createActionFor(resources.fund, 'patch', '/:id'),
      destroy: createActionFor(resources.fund, 'delete', '/:id'),
      history: createActionFor(resources.fund, 'get', '/:id/history', ['from', 'to']),
      strategy: createActionFor(resources.fund, 'get', '/:id/strategy'),
      fundReturns: createActionFor(resources.fund, 'get', '/:id/price', ['currency']),
      sort: createActionFor(resources.fund, 'post', '/:id/sort', ['old_index', 'new_index']),
      // 基金報告
      report: Object.assign(
        createActionFor(resources.report, 'get', '/:id'), {
          list: createActionFor(resources.fund, 'get', '/:fund_id/reports'),
          create: createActionFor(resources.fund, 'post', '/:fund_id/reports'),
          update: createActionFor(resources.report, 'patch', '/:id'),
          destroy: createActionFor(resources.report, 'delete', '/:id'),
        }
      ),
    }
  ),
  taroboFund: Object.assign(
    createActionFor(resources.taroboFund, 'get', '/:id'), {
      list: createActionFor(resources.taroboFund, 'get', '/'),
    }
  ),
  // 圖表
  chart: {
    perfomance: createActionFor(resources.charts, 'get', '/perfs', [
      'from',
      'to',
      'funds',
      'benchmarks',
    ]),
  },
  firms: {
    list: createActionFor(resources.firms, 'get', '/allow_signup'),
  },
  calendar: Object.assign(
    createActionFor(resources.calendar, 'get', '/', ['year']), {
      incomingTrading: createActionFor(resources.calendar, 'get', '/incoming_trading'),
      subscribe: createActionFor(resources.calendar, 'put', '/subscribe/:event_id'), // 也可傳 YYYY-MM-DD
      unsubscribe: createActionFor(resources.calendar, 'delete', '/subscribe/:event_id'), // 也可傳 YYYY-MM-DD
    }
  ),
  benchmark: Object.assign(
    createActionFor(resources.benchmark, 'get', '/:id'), {
      list: createActionFor(resources.benchmark, 'get', '/'),
      history: createActionFor(resources.benchmark, 'get', '/:id/history', ['from', 'to']),
    }
  ),
}

// 掛在 Vuex 當中，串接登入 token
function createMemberAuthPlugin(options) {
  return store => {
    // 套用到所有 resource
    Object.values(resources).forEach(resource => {
      resource.interceptors.request.use((config) => {
        // 如果有登入就要附上登入權杖
        if (store.state.member.auth.token) {
          config.headers.common['X-Member-Authorization'] = `Bearer ${store.state.member.auth.token}`
        }
        return config
      })
    })
  }
}

export default apis
export { createMemberAuthPlugin }
