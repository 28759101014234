<template>
  <div class="member-panel-layout">
    <div class="panel-wrap full-height">
      <div class="panel-illustration pen">
        <img src="~images/img-illustration-16.png" alt="">
      </div>
      <div v-if="current.step === 1" class="panel">
        <div class="panel-head">
          <div class="head-title">
            您尚未完成信箱驗證
          </div>
          <div style="margin-top: .5rem;">
            {{ email }}
          </div>
        </div>
        <div class="panel-body">
          只差一步就完成註冊！<br>
          您可以重新發送驗證碼完成註冊。
        </div>
        <div class="panel-actions">
          <div class="ui button fluid major" @click="resendToken">
            重新發送驗證碼
          </div>
        </div>
      </div>

      <div v-if="current.step === 2" class="panel">
        <Authentication :email="email"
                        authType="email"
                        @confirm="showModal = true"
        />
      </div>
      <div class="panel-illustration person">
        <img src="~images/img-illustration-15.png" alt="">
      </div>
    </div>

    <ModalWelcome :visible="showModal" />
  </div>
</template>

<script>
import API from 'api'
import Authentication from 'pages/member/Authentication'
import ModalWelcome from 'pages/member/component/ModalWelcome'
import { MixinNotifiable } from 'modules/ui/notification.vue'

export default {
  components: {
    Authentication,
    ModalWelcome,
  },
  mixins: [
    MixinNotifiable
  ],
  data() {
    return {
      current: {
        step: 1,
      },
      showModal: false,
    }
  },
  computed: {
    email() {
      return this.$member.email
    },
  },
  methods: {
    resendToken() {
      const params = {
        username: this.email,
        confirm_for: 'email',
      }
      this.wait(API.member.resendToken(params), { message: '發送中請稍後...' })
        .then((res) => {
          this.success(`${res.data.message}`)
          this.current.step = 2
        })
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~pages/member/memberPanel.styl'
</style>
