<template>
  <article class="v-article-content">
    <div v-html="value" />
    <slot />
  </article>
</template>

<script>

const $ = jQuery

export default {
  props: {
    value: String,
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(this.prepare)
    }, 200)
  },
  updated() {
    setTimeout(() => {
      this.$nextTick(this.prepare)
    }, 200)
  },
  methods: {
    // prettier-ignore
    prepare() {
      $(this.$el).scrollTop(0)

      $(this.$el).find('table').each(function(i, e) {
        // $(e).removeAttr('style')
        $(e).wrap('<div class="table-wrap">')
      })

      $(this.$el).find('.table-source').each(function(i, e) {
        $(e).find('span').removeAttr('style')
      })

      $(this.$el).find('p').each(function() {
        const $el = $(this)
        if ($.trim($el.html()) == '&nbsp;') {
          $el.remove()
        }
      })

      $(this.$el).find('figure').each(function(i, e) {
        $(e).find('br').remove()
        $(e).find('img + span[style]').addClass('source').removeAttr('style')
        $(e).contents().filter(function() {
          return this.nodeType == 3
        })
          .wrap('<span class="desc"/>')

        $(e).find('img').wrap($('<a>', {
          'class': 'fancy-wrap',
          'href': $(this).find('img').attr('src'),
          'data-fancybox': 'article',
          'data-caption': '<div class="desc">' + $(this).find('.desc').text() + '</div>' + '<div class="source">' + $(this).find('.source').text() + '</div>'
        }))
      })

      // 啟用 Fancybox
      $(this.$el).find('[data-fancybox="article"]').fancybox({
        buttons: [
          'slideShow',
          'zoom',
          'fullScreen',
          'close'
        ],
        thumbs: {
          autoStart: true
        }
      })

      // 移除失效圖片
      this.$el.querySelectorAll('img').forEach((img) => {
        img.onerror = function() {
          this.style.display = 'none'
        }
      })

      $(this.$el).find('.remark-anchor').each(function() {
        const tag = $(this).text()
        const $anchor = $(this)
        const $remark = $('article .remark:contains(#' + tag + ')').hide()

        if ($remark.length == 0) {
          $anchor.removeClass('remark-anchor')
          return
        }
        // Remove hashtag from remarks
        $remark.contents().filter(function() {
          return this.nodeType === Node.TEXT_NODE
        }).each(function() {
          this.textContent = this.textContent.replace('#' + tag, '')
        })

        $anchor.click(function() {
          $remark.insertAfter($anchor)
          $remark.slideToggle()
          $anchor.toggleClass('opened')
        })
      })
    },
  },
}
</script>

<style lang="less">
@import './ArticleContent.less';
</style>
