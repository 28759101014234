<template>
  <div class="panel tall">
    <div class="panel-head">
      <div class="center-block">
        <div class="head-title">
          登入
        </div>
      </div>
      <div class="emphasize-text">
        Opps....忘記密碼了？
      </div>
      <div style="margin-top: .5rem;">
        {{ username }}
      </div>
    </div>
    <div class="panel-body">
      <p>
        <slot name="message" />
      </p>
    </div>
    <div class="rule-buttons-group fluid">
      <router-link class="ui button fluid major" :to="{ name: 'ForgetPasswd', params: { username } }">
        重設密碼
      </router-link>
      <div class="ui button fluid minor" @click="$emit('retry')">
        使用其他帳號登入
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    username: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
@require '~pages/member/memberPanel.styl'

.panel
 max-width initial !important

.center-block
  text-align center
  margin-bottom 3rem

.emphasize-text
  font-size 1.2rem
  font-weight bold
</style>
