<!-- 
    圖表互動： active / hover
-->
<script>
export default {
  data() {
    return {
      activeId: null,
      hoverId: null,
    }
  },
  methods: {
    isActive(id) {
      return this.activeId && this.activeId === id
    },
    blendColors(c0, c1, p) {
      var f=parseInt(c0.slice(1),16),t=parseInt(c1.slice(1),16),R1=f>>16,G1=f>>8&0x00FF,B1=f&0x0000FF,R2=t>>16,G2=t>>8&0x00FF,B2=t&0x0000FF;
      return "#"+(0x1000000+(Math.round((R2-R1)*p)+R1)*0x10000+(Math.round((G2-G1)*p)+G1)*0x100+(Math.round((B2-B1)*p)+B1)).toString(16).slice(1);
    },
    activeColor(id) {
      if (!this.fundColors[id]) return false
      return this.blendColors('#ffffff', this.fundColors[id], 0.1)
    },
    customStyle(id) {
      if (this.isActive(id) || this.hoverId == id) {
        return {
          backgroundColor: this.activeColor(id)
        }
      } else {
        return false
      }
    },
    hoverFund(id) {
      if (event.type === 'mouseenter') this.hoverId = id
      if (event.type === 'mouseleave') this.hoverId = null
    }
  },
}
</script>