<!--
  preload 模式
    DOM會預先載入在頁面，被 dimmer 蓋住不可操作
    通常用在圖表等需要預先初始化畫布的元件
-->
<template>
  <div v-if="preload||loading" style="min-height: 90px; position: relative;">
    <slot v-if="loading" name="placeholder">
      <div class="ui inverted dimmer transition active">
        <div class="ui text loader">
          <slot name="placedoler-text">
            載入中
          </slot>
        </div>
      </div>
    </slot>
    <slot v-if="preload" v-bind="{ready: !loading, loading}" />
  </div>
  <div v-else>
    <slot v-bind="{ready: !loading, loading}" />
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    preload: {
      type: Boolean,
    }
  }
}
</script>
