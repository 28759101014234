<template>
  <div class="member-panel-layout">
    <div class="panel-wrap full-height">
      <div class="panel-illustration pen">
        <img src="~images/img-illustration-16.png" alt="">
      </div>
      <div class="panel">
        <div class="panel-head">
          <div class="head-title">
            變更資料
          </div>
        </div>
        <div class="panel-body">
          <div class="row-wrap">
            <div class="row-title">
              手機
            </div>
            <UiInput v-model="form.mobile_number" :error="fieldErrorOf('mobile_number')" class="fluid" placeholder="手機號碼" />
          </div>
          <div class="row-wrap">
            <div class="row-title">
              生日
            </div>
            <Pikaday :options="birthdayOptions" :value="form.birthday">
              <UiInput v-model="form.birthday" :error="fieldErrorOf('birthday')" class="fluid" type="date" placeholder="生日 (年/月/日)">
                <template #after>
                  <i class="icon-calendar" />
                </template>
              </UiInput>
            </Pikaday>
          </div>
          <div class="row-wrap">
            <UiCheckbox v-model="form.gender" value="Male" radio style="margin-right: 1rem;">
              男性
            </UiCheckbox>
            <UiCheckbox v-model="form.gender" value="Female" radio>
              女性
            </UiCheckbox>
            <div v-if="fieldErrorOf('gender')" class="filed-error">
              <span v-text="fieldErrorOf('gender')" />
            </div>
          </div>
        </div>
        <div class="panel-actions">
          <router-link :to="{name: 'Partner'}" class="ui button fluid minor">
            取消
          </router-link>
          <div class="ui button fluid major" @click="submit">
            確認變更
          </div>
        </div>
      </div>
      <div class="panel-illustration person">
        <img src="~images/img-illustration-15.png" alt="">
      </div>

    </div>
  </div>
</template>

<script>
import API from 'api'
import UiInput from 'modules/ui/form/input'
import UiCheckbox from 'modules/ui/form/checkbox'
import Pikaday from 'modules/ui/form/pikaday.vue'
import { MixinNotifiable } from 'modules/ui/notification.vue'
import { createValidator } from 'modules/ui/form/validation.js'
import { mapActions } from 'vuex'

export default {
  components: {
    UiInput,
    UiCheckbox,
    Pikaday,
  },
  mixins: [
    MixinNotifiable,
    createValidator([
      {
        field: 'mobile_number',
        validator: mobile => mobile.match(/^[0-9]{10}$/),
        message: '請輸入正確手機號碼'
      },
      {
        field: 'mobile_number',
        message: '請輸入手機號碼'
      },
      {
        field: 'birthday',
        message: '請輸入生日'
      },
      {
        field: 'gender',
        message: '請選擇性別'
      },
    ])
  ],
  data() {
    return {
      form: {
        mobile_number: '',
        birthday: '',
        gender: '',
      },
    }
  },
  computed: {
    birthdayOptions() {
      const maxDate = moment().add(-15, 'year').startOf('year').toDate()
      return {
        defaultDate: maxDate,
        setDefaultDate: false,
        yearRange: [new Date().getFullYear() - 100, new Date().getFullYear() - 15],
        maxDate: maxDate,
      }
    },
  },
  watch: {
    'form': {
      deep: true,
      handler() {
        this.clearValidation()
      }
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    ...mapActions('member', ['loadProfile']),
    load() {
      this.form.mobile_number = this.$member.mobile_number
      this.form.birthday = moment(this.$member.birth_date).format('YYYY-MM-DD')
      this.form.gender = this.$member.gender
    },
    submit() {
      if (this.validateForm(this.form)) {
        this.wait(API.member.updateProfile(this.form), { message: '變更中請稍後...' })
          .then((res) => {
            this.success(`${res.data.message}`)
            this.loadProfile()
          })
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~pages/member/memberPanel.styl'
// error msg
.filed-error
  font-size 12px
  line-height 1.8
  margin-top 0.5em
  color #ed4758
</style>
