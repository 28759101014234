<!--
    1. perfect scroll 左右滾動連動
    2. 表頭固定
    3. 資料值判斷正負號(加 Class)

    * 讓 PerfectScroll ref 能在同一個陣列的特殊方法
    https://forum.vuejs.org/t/how-to-use-the-same-ref-for-more-than-one-item/22481
    ex:
      <div class="list-body" :key="i" v-for="i in 1">
        <PerfectScroll ref="info" @ps-scroll-x.native="updateScroll">
        <PerfectScroll ref="info" @ps-scroll-x.native="updateScroll">
      </div>
-->
<script>
import Scroll from 'modules/ui/scroll'
import Vue from 'lib/vue/essential'
import getScrollParent from 'lib/utils/getScrollParent.js'

const $ = jQuery

export default {
  components: {
    Scroll
  },
  data() {
    return {
      container: undefined,
      containerRact: undefined,
      fixedHead: false,
      fixedAreaStyle: {},
    }
  },
  mounted() {
    if (this.$el) {
      this.container = getScrollParent(this.$parent.$el)
      if (this.container === window) {
        this.containerRact = document.body.getBoundingClientRect()
      } else {
        this.containerRact = this.container?.getBoundingClientRect()
      }
      $(this.container).on('scroll', () => this.updateWindowScroll())
    }
  },
  methods: {
    updateScroll(e) {
      const scrollX = e.target.scrollLeft
      if (this.$refs.head) {
        // head 和 info 可能獨立存在也可能是 Array
        const heads = (this.$refs.head instanceof Array) ? this.$refs.head : [this.$refs.head]
        heads.forEach(head => (head.$el.scrollLeft = scrollX))
        const infos = (this.$refs.info instanceof Array) ? this.$refs.info : [this.$refs.info]
        infos.forEach(item => (item.$el.scrollLeft = scrollX))
      }
    },
    updateWindowScroll() {
      // 滑動清單時固定表頭，離開清單時解開封印
      let mobileTable = this.$refs.list
      // $refs.list 可能因為被 UiLoader 或 UiTab 包起來，而變成不同形式
      if (mobileTable instanceof Vue) {
        mobileTable = mobileTable.$el
      } else if (mobileTable instanceof Array) {
        mobileTable = mobileTable[0]
      }
      if (!mobileTable?.getBoundingClientRect) return false

      const list = mobileTable.getBoundingClientRect()
      // 1440px 以下 header fixed，要把 header 高加上去
      const detectPosition = this.$deviceView.wider ? this.containerRact.top : (this.containerRact.top + 49)
      if (list.top < detectPosition && (list.height + list.top) > detectPosition) {
        this.fixedHead = true
        this.fixedAreaStyle = {
          position: 'fixed',
          top: `${detectPosition}px`,
          left: `${this.containerRact.left}px`,
          width: `${this.containerRact.width}px`,
        }
      } else {
        this.fixedHead = false
        this.fixedAreaStyle = {}
      }
    },
    symbolClass(val) {
      // 正負號Class
      if (typeof val == 'number') {
        return `data-value--${val >= 0 ? 'positive' : 'negative'}`
      } else {
        return null
      }
    }
  },
}

</script>
