<template>
  <div v-if="current.fund" class="page-list">
    <div class="ui dividing huge header">
      {{ current.fund.name }} > 報告管理
      <span v-if="$route.meta.title">
        > {{ $route.meta.title }}
      </span>
    </div>
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view />
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
import MixinAdminCommonPage from 'modules/admin/common/page'
export default {
  mixins: [
    MixinAdminCommonPage,
  ],
  data() {
    return {
      current: {
        fund: null
      }
    }
  },
  watch: {
    '$route.params.fundId': {
      immediate: true,
      async handler(id) {
        this.current.fund = await this.$api.fund({ id, format: 'full' }).then(res => res.data.entry)
      }
    }
  }
}
</script>
