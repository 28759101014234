const rules = [
  {
    attribute: 'cover',
    message: '請上傳封面圖片'
  },
  {
    attribute: 'code',
    message: '請輸入基金代號'
  },
  {
    attribute: 'name',
    message: '請輸入基金中文名稱'
  },
]

const validateForm = (entry) => {
  const defaultValidator = v => v
  return rules.reduce((valid, { attribute, validator, message }) => {
    if (valid) {
      if ((validator || defaultValidator)(entry[attribute])) {
        return true
      } else {
        throw message
      }
    }
    return false
  }, true)
}

export default validateForm
export { rules }
