const $ = jQuery

export default {
  data: () => ({
    $_ghost: {
      dropdownElement: null,
      placeholderElement: null,
      resizeObserver: null,
    }
  }),
  mounted() {
    this.$on('show', () => {
      this.$data.$_ghost.dropdownElement = $(this.$el).find('.ui.dropdown').addBack('.ui.dropdown')[0]
      const rect = this.$data.$_ghost.dropdownElement.getBoundingClientRect()
      this.$data.$_ghost.placeholderElement = $(this.$data.$_ghost.dropdownElement).clone(true).css({
        width: rect.width,
        height: rect.height,
        display: this.$data.$_ghost.dropdownElement.style.display,
      })[0]
      $(this.$data.$_ghost.placeholderElement).insertAfter(this.$data.$_ghost.dropdownElement)
      $(document.body).append(this.$data.$_ghost.dropdownElement)
      $(this.$data.$_ghost.dropdownElement).children(':not(.menu)').addClass('ghost-hidden').hide()
      $(this.$data.$_ghost.dropdownElement).css({
        position: 'fixed',
        zIndex: 10000,
        top: Math.round(rect.top),
        left: rect.left,
        width: rect.width,
        maxWidth: rect.width,
        minWidth: rect.width,
        height: rect.height,
        background: 'transparent', // 避免遮蓋，但會有邊框連接不完全的問題
      })

      this.$data.$_ghost.haunt = () => {
        if (this.$data.$_ghost.placeholderElement) {
          const rect = this.$data.$_ghost.placeholderElement.getBoundingClientRect()
          $(this.$data.$_ghost.dropdownElement).css({
            position: 'fixed',
            top: Math.round(rect.top),
            left: rect.left,
            width: rect.width,
            maxWidth: rect.width,
            minWidth: rect.width,
            height: rect.height,
          })
        }
      }

      if (typeof ResizeObserver == 'function') {
        // 如果支援則監聽元素的大小異動
        this.$data.$_ghost.resizeObserver = new ResizeObserver(this.$data.$_ghost.haunt)
        this.$data.$_ghost.resizeObserver.observe(this.$root.$el)
      } else {
        // 監聽整個視窗大小異動
        window.addEventListener('resize', this.delayedCheckWidth)
      }

      document.addEventListener('scroll', this.$data.$_ghost.haunt, true)
    })
    this.$on('hide', () => {
      this.$nextTick(() => {
        this.$data.$_ghost.resizeObserver?.disconnect()
        document.removeEventListener('resize', this.$data.$_ghost.haunt)
        document.removeEventListener('scroll', this.$data.$_ghost.haunt, true)
        if (this.$data.$_ghost.placeholderElement) {
          $(this.$data.$_ghost.dropdownElement).children('.ghost-hidden:not(.menu)').removeClass('ghost-hidden').show()
          $(this.$data.$_ghost.placeholderElement).replaceWith(this.$data.$_ghost.dropdownElement)
          $(this.$data.$_ghost.dropdownElement).css({
            position: '',
            zIndex: '',
            top: '',
            left: '',
            width: '',
            maxWidth: '',
            minWidth: '',
            height: '',
          })
          this.$data.$_ghost.placeholderElement = null
        }
      })
    })
  }
}
