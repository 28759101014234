<template>
  <div>
    <template v-if="fundIds.length">
      <div class="table-wrap">
        <div ref="list" class="list">
          <div class="list-head">
            <div class="row head-row">
              <div class="grid month" />
              <div v-for="title in fundTitles" :key="title.id" class="grid fund">
                <span class="color-ball" :style="{backgroundColor: fundColors[title.id]}" />
                <router-link :to="{name: 'FundShow', params: {id: title.id}}" class="fund-link" target="_blank">{{ title.name }}</router-link>
              </div>
            </div>
          </div>
          <div v-if="dateColumns" class="list-body">
            <Scroll>
              <div style="max-height:684px;">
                <div v-for="dateColumn in dateColumns" :key="dateColumn" class="body-row-group">
                  <div class="row body-row">
                    <div class="grid month">
                      {{ dateColumn }}
                    </div>
                    <div v-for="fundId in fundIds" :key="fundId" class="grid fund">
                      <div v-if="type === 'aum_mn'">
                        {{ getFieldValue(dateColumn, fundId) | round(1) }}
                      </div>
                      <div v-else>
                        {{ getFieldValue(dateColumn, fundId) | round(4) }}
                      </div>
                      <div v-if="isEstimateValue(dateColumn, fundId)" class="note">
                        （預估）
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Scroll>
          </div>
        </div>
        <div v-if="$deviceView.mobile" class="remark text-minor">
          單位：
          <span v-if="type === 'aum_mn'">百萬</span>美元
        </div>
        <div v-if="fundIds.length < 2" class="empty-space">
          <div>
            <div class="ui button major large round btn-compare" @click="$emit('showModalPicker')">
              <i class="icon-more" style="margin-right:.5rem;" />比較其他基金
            </div>
            <div class="illustration">
              <img src="~images/img-illustration-10.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div v-if="$deviceView.desktop" class="remark text-minor">
        單位：
        <span v-if="type === 'aum_mn'">百萬</span>美元
      </div>
    </template>
    <div v-else class="empty-container">
      <div class="empty-space">
        <div>
          <div class="ui button major large round btn-compare" @click="$emit('showModalPicker')">
            <i class="icon-more" style="margin-right:.5rem;" />比較其他基金
          </div>
          <div class="illustration">
            <img src="~images/img-illustration-10.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from 'api'
import Scroll from 'modules/ui/scroll'

export default {
  components: {
    Scroll,
  },
  props: {
    fundIds: {
      type: Array,
      default() {
        return []
      },
    },
    type: { // aum_mn / price
      type: String,
      default: function() {
        return 'aum_mn'
      }
    },
    dateStart: {
      type: String,
      default: function() {
        return '2020-06-01'
      }
    },
    dateEnd: {
      type: String,
      default: function() {
        return '2021-01-01'
      }
    },
    fundColors: Object,
  },
  data() {
    return {
      rawData: {},
      fundTitles: [],
    }
  },
  computed: {
    filterData() {
      if (Object.keys(this.rawData).length < this.fundIds.length) return ''
      const obj = {}
      this.fundIds.forEach(id => {
        obj[id] = this.rawData[id].history
          .filter(item => {
            return moment(item.date).isBetween(this.dateStart, this.dateEnd) || moment(item.date).isSame(this.dateStart, 'month') || moment(item.date).isSame(this.dateEnd, 'month')
          })
          .filter(item => !Object.keys(item).includes('yoy_perf')) // 濾掉不是月份的
          .sort((a, b) => moment(a) > moment(b) ? 1 : -1)
      })
      return obj
    },
    dateColumns() {
      const dateArray = []
      let currentDate = moment(this.dateEnd)
      const dateStart = moment(this.dateStart)
      while (currentDate >= dateStart) {
        dateArray.push(moment(currentDate).format('YYYY-MM'))
        currentDate = moment(currentDate).add(-1, 'month')
      }
      return dateArray
    },
  },
  watch: {
    fundIds() {
      this.load()
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      const title = []
      for (const id of this.fundIds) {
        await API.fund.history({ id }).then(res => {
          this.$set(this.rawData, id, {
            history: res.data.history,
            name: res.data.name
          })
          title.push({
            name: res.data.name,
            id,
          })
        })
      }
      this.fundTitles = title
    },
    getFieldValue(dateColumn, fundId) {
      if (!this.filterData) return ''
      const target = this.filterData[fundId].find(data => moment(data.date).format('YYYY-MM') === dateColumn)
      return target ? target[this.type] : '-'
    },
    isEstimateValue(dateColumn, fundId) {
      if (!this.filterData) return ''
      const target = this.filterData[fundId].find(data => moment(data.date).format('YYYY-MM') === dateColumn)
      return target ? target.estimate : false
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~modules/ui/common'
.table-wrap
  +over(md)
    display flex
  .empty-space
    border 1px solid #EDEDED
    flex 1 1 0
    +under(md)
      padding 2rem 0
.list
  flex 1 1 0
  .grid
    width 80px
    padding 0 .75rem
    &.month
      flex-shrink 0
    &.fund
      flex 1 1 0
      text-align right
  .row
    display flex
    align-items center
    padding 0 .75rem
  .note
    font-size 12px
    color $gray2
  .fund-link
    &:hover
      font-weight bold
.list-head
  border-top 1px solid #ededed
  border-bottom 1px solid #ededed
  &.status-fixed
    padding-bottom 40px
    .fixed-area
      position fixed
      top 0
      left 0
      right 0
      background #fff
      z-index 50
      border-bottom 1px solid #EDEDED
      padding-left .5rem
      padding-right .5rem
.head-row
  min-height 40px
.body-row
  min-height 56px
  height auto
  border-bottom 1px solid #ededed
  &.row.extra
    @media $mobile
      padding-left 1.5rem
      padding-right 1.5rem
  &:empty
    display none !important
  +over(md)
    &:hover
      background #F0F8FF
.body-row-group
  border-bottom 1px solid #ededed
  .body-row
    border-bottom none

.color-ball
  width 14px
  height 14px
  border-radius 5px
  margin-right .5rem
  background-color #FF895A
// 空狀態
.empty-space
  display flex
  justify-content center
  align-items center
  text-align center
  .btn-compare
    margin 0 auto 3rem
  .illustration
    margin auto
    width 62%
    img
      width 100%
.empty-container
  padding 3rem 1rem
.remark
  margin 1.5rem
</style>
