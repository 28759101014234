
import Vue from 'lib/vue/essential'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context(
  '.',
  true,
  /.+\.(vue)$/
)

const Columns = {}

const props = {
  id: [Number, String],
  name: String,
  column: [String, Function],
  model: {
    type: Object,
    default: () => {},
  },
  template: {
    type: [Object, Function],
  },
  onUpdate: {
    type: String,
    default: 'input',
  }
}

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(
      // Strip the leading `./` and extension from the filename
      fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
    )
  )
  const template = componentConfig.default || componentConfig

  Columns[componentName] = Vue.extend({
    functional: true,
    render (createElement, context) {
      // 自動轉換 Function 的 prop
      const passedProps = Object.entries(context.props).reduce((props, [key, value]) => {
        if (!['column'].includes(key) && value instanceof Function) {
          props[key] = value(context.props.model)
        } else {
          props[key] = value
        }
        return props
      }, {})
      return createElement(
        {
          ...template,
          name: `Column${componentName}`,
          props: {
            ...template.props,
            ...props,
          },
          computed: {
            value: {
              get() {
                if (typeof this.column === 'function') {
                  return this.column(this.model)
                } else {
                  return this.model[this.column]
                }
              },
              set(value) {
                this.$emit('input', value)
              }
            },
            ...template.computed,
          }
        },
        {
          on: context.data.on,
          props: passedProps,
        }
      )
    },
  })
})

function createLinkColumn(routerName, paramsGenerator, component = Columns.Default) {
  let template = '<router-link :to="routeTo">{{ value }}</router-link>'
  if (component) {
    template = '<router-link :to="routeTo"><DataComponent v-bind="$props"/></router-link>'
  }
  return {
    name: 'LinkColumn',
    props,
    components: {
      DataComponent: component,
    },
    computed: {
      routeTo() {
        return {
          name: routerName,
          params: paramsGenerator(this.model),
        }
      },
      value() {
        if (typeof this.column === 'function') {
          return this.column(this.model)
        } else {
          return this.model[this.column]
        }
      }
    },
    template,
  }
}

// Design Pattern: 工廠模式
function defineColumns(columns) {
  return columns.map(({ width, template, ...column }) => {
    let style = ''
    if (width) {
      style = `width: ${width}px; ${style}`
    }
    return {
      ...column,
      style,
      template: template || Columns.Default,
    }
  })
}

export { defineColumns, createLinkColumn, Columns }
export default Columns
