<template>
  <UiLoader v-if="livestream" class="countdown-panel">
    <div class="main-panel" :class="panelClasses">
      <template v-if="current.expand">
        <!-- Live 直播中 -->
        <template v-if="current.live">
          <div class="content">
            <div class="topic">
              <div>
                <div class="ui button round minor small">
                  Live 直播中
                </div>
              </div>
              <div class="topic-title">
                <h1 v-if="$deviceView.desktop">
                  {{ livestream.title }}
                </h1>
                <h4 v-else>
                  {{ livestream.title }}
                </h4>
              </div>
              <div class="descript">
                {{ livestream.summary }}
              </div>
            </div>
            <div class="button-box">
              <a :href="livestream.live_url" :class="{disabled: disabledButton}" target="_blank" class="ui button major round large fluid ">
                立即收看
              </a>
            </div>
          </div>
        </template>
        <!-- 預告 -->
        <template v-else>
          <div class="content">
            <div class="upcoming">
              <div class="text-minor font-bold">
                即將開始
              </div>
              <div :class="{'align-box': $deviceView.mobile}">
                <div class="timer">
                  <div class="unit">
                    <div class="digital">
                      {{ timer.day }}
                    </div>
                    <div class="txt">
                      天
                    </div>
                  </div>
                  <div class="unit">
                    <div class="digital">
                      {{ timer.hour }}
                    </div>
                    <div class="txt">
                      小時
                    </div>
                  </div>
                  <div class="unit">
                    <div class="digital">
                      {{ timer.min }}
                    </div>
                    <div class="txt">
                      分鐘
                    </div>
                  </div>
                </div>
                <a
                  :href="livestream.live_subscribe_url" target="_blank"
                  class="ui button major round large fluid btn-reservation"
                >
                  立即預約
                </a>
              </div>
            </div>
            <div class="topic">
              <div class="align-box">
                <div class="ui button round minor small">
                  直播預告
                </div>
                <span class="start-time">{{ livestream.live_start_at | timeformat('YYYY-MM-DD HH:mm') }}</span>
              </div>
              <div class="topic-title">
                <h1 v-if="$deviceView.desktop">
                  {{ livestream.title }}
                </h1>
                <h4 v-else>
                  {{ livestream.title }}
                </h4>
              </div>
              <div class="descript">
                {{ livestream.summary }}
              </div>
            </div>
          </div>
        </template>
        <div class="deco-pic normal">
          <img src="~images/img-illustration-12.png" alt="">
        </div>
      </template>
      <template v-else>
        <div class="content">
          <div class="topic">
            <div class="topic-title">
              <h2 v-if="$deviceView.desktop">
                {{ livestream.title }}
              </h2>
              <h4 v-else>
                {{ livestream.title }}
              </h4>
            </div>
          </div>
          <div class="button-box">
            <template v-if="current.live">
              <a :href="livestream.live_url" target="_blank" class="ui button major round large fluid">
                立即收看
              </a>
            </template>
            <template v-else>
              <a :href="livestream.live_subscribe_url" target="_blank" class="ui button major round large fluid">
                立即預約
              </a>
            </template>
          </div>
        </div>
        <div class="deco-pic small">
          <img src="~images/img-illustration-13.png" alt="">
        </div>
      </template>
    </div>
    <div class="touch-bar" @click="current.expand = !current.expand">
      <div v-if="current.expand">
        收起 <i class="icon-up-dir" style="margin-left: .5rem;" />
      </div>
      <div v-else>
        直播預告<i class="icon-down-dir" style="margin-left: .5rem;" />
      </div>
    </div>
  </UiLoader>
</template>

<script>
import API from 'api'
import UiLoader from 'modules/ui/loader'

export default {
  components: {
    UiLoader,
  },
  data() {
    return {
      entry: null,
      current: {
        time: moment(),
        expand: true, // 展開
        live: false, // 直播中 / 預告
      },
      timer: {
        day: 0,
        hour: 0,
        min: 0,
      },
      disabledButton: false,
    }
  },
  computed: {
    livestream() {
      return this.entry?.[0]
    },
    panelClasses() {
      return {
        live: this.current.live,
        upcoming: !this.current.live,
        expand: this.current.expand,
        shrink: !this.current.expand,
      }
    },
  },
  async mounted() {
    await this.init()
    if (this.livestream) this.countBackwards()
  },
  methods: {
    async init() {
      const loader = this.createLoadingState()
      this.entry = await API.content.livestream().then(res => res.data.entries).catch()
      loader.finish()
    },
    countBackwards() {
      const now = this.current.time = moment()
      const startTime = moment(this.livestream.live_start_at, ['YYYY-MM-DD h:mm LT', moment.defaultFormat], 'en')
      const endTime = moment(this.livestream.live_end_at, ['YYYY-MM-DD h:mm LT', moment.defaultFormat], 'en')
      if (startTime > now) {
        let min = this.timer.min = startTime.diff(now, 'minutes')
        let hour = this.timer.hour = startTime.diff(now, 'hours')
        this.timer.day = startTime.diff(now, 'days')
        if (min > 59) this.timer.min = min = min % 60
        if (hour > 23) this.timer.hour = hour = hour % 24
        setTimeout(this.countBackwards, 1000)
      } else if (endTime >= now && now >= startTime) {
        this.current.live = true
        setTimeout(this.countBackwards, 1000)
      } else {
        this.disabledButton = true
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.countdown-panel
  width 100%
  background-color #fff
  border-radius 21px
  box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
.align-box
  display flex
  align-items center
.main-panel
  +over(md)
    display flex
  +under(md)
    padding 1.5rem
  .content
    flex 1 1 0
    +over(md)
      display flex
  .upcoming
    +over(md)
      flex 0 0 170px
      margin-right 3rem
    +under(md)
      margin-bottom 2rem
      .btn-reservation
        margin-left 1rem
  .topic
    flex-grow 1
    +under(md)
      margin-bottom .5rem
    div + div
      margin-top 1rem
    .descript
      color #505050
    .start-time
      font-size 1.2rem
      font-weight bold
      color #08548E
    +over(md)
      .topic-title, .descript
        display -webkit-box
        -webkit-line-clamp 2
        -webkit-box-orient vertical
        text-overflow ellipsis
        overflow hidden
  .button-box
    flex 0 0 170px
    margin-left 3rem
    +under(md)
      max-width 140px
  .deco-pic
    flex-shrink 0
    &.normal
      width 220px
    &.small
      width 95px
      margin-right 2rem
    +under(md)
      &.normal
        width 114px
        margin-left auto
      &.small
        width 70px
        margin 0 0 0 1rem
    img
      width 100%
      image-rendering -webkit-optimize-contrast
  .timer
    display flex
    justify-content center
    margin 1rem 0 2rem
    .unit
      text-align right
      font-weight bold
      + .unit
        margin-left 1.5rem
        +under(md)
          margin-left .75rem
    .digital
      font-size 2.488rem
      line-height 44px
    .txt
      margin-top 4px
// 客製不同狀態
.main-panel
  &.live
    +over(md)
      .content
        align-items center
  &.expand
    +over(md)
      .content
        padding 3rem 3rem 3rem 4rem
    +under(md)
      .button-box
        margin 2rem auto
  &.shrink
    +over(md)
      .content
        padding 2rem 5rem 2rem 2rem
    +under(md)
      display flex
      padding 1.5rem 0 1.5rem 1.5rem
      .button-box
        margin 1rem auto 0

.touch-bar
  text-align center
  color $gray2
  font-weight bold
  padding .5rem 0
  background-color #FBFBFB
  border-top 1px solid #ededed
  border-radius 0 0 21px 21px
  cursor pointer
  &:hover
    background-color #EDEDED
</style>
