import Vue from 'lib/vue/essential'

Vue.Mixins = Vue.Mixins || {}

// https://github.com/BKWLD/vue-in-viewport-directive
// Vue.directive('in-viewport', require('vue-in-viewport-directive'))

const mixin = require('vue-in-viewport-mixin')
Vue.Mixins.inViewport = {
  ...mixin,
  mounted() {
    if (this.$vnode.isComment) {
      return false
    }
    mixin.mounted.call(this)
  }
}

// onVisible

Vue.Mixins.Behavior = Vue.Mixins.Behavior || {}

const MixinOnVisible = {
  mixins: [
    Vue.Mixins.inViewport,
  ],
  watch: {
    'inViewport.now'(visible) {
      visible ? this.$emit('onVisible') : this.$emit('onHidden')
      visible ? this.$emit('visible') : this.$emit('hidden')
    },
  },
  methods: {
    '$onVisible'(method) {
      if (typeof method == 'string') {
        method = this[method]
      } else if (!(method instanceof Function)) {
        throw new TypeError()
      }
      this.$off('onVisible', method)
      if (this.inViewport.now || !this.inViewportActive) {
        method()
      } else {
        this.$once('onVisible', method)
      }
    },
    '$afterVisible'(method) {
      this.$onVisible(method)
    },
  },
}
Vue.Mixins.Behavior.onVisible = MixinOnVisible

export { MixinOnVisible }
export default Vue.Mixins.inViewport
