import { Columns, createLinkColumn } from 'modules/admin/common/column'

const sortable = true

export default {
  chart: {
    name: '重點圖卡',
    shortName: '圖卡',
    categorisable: 1,
    columns:
    [
      {
        name: '縮圖',
        style: 'width: 100px',
        column: 'cover_tiny_url',
        template: createLinkColumn('AdminContentEdit', model => ({ id: model.id }), Columns.Thumbnail)
      },
      {
        name: '標題',
        column: 'title',
        style: 'max-width: 300px',
        template: Columns.Actions,
        props: {
          deletable: ({ active }) => active === false,
          linkEdit: ({ id }) => ({ name: 'AdminContentEdit', params: { id } }),
          linkPreview: ({ file_url }) => file_url,
        }
      },
      {
        name: '類別',
        style: 'width: 150px',
        column: ({ category }) => category?.name,
        template: Columns.Default
      },
      { name: '發表時間', column: 'publish_at', sortable, style: 'width: 150px', template: Columns.Datetime },
      { name: '人氣', column: 'hits_sum', sortable, style: 'width: 80px', template: Columns.Default },
      { name: '首頁跑馬', column: 'promote', style: 'width: 85px', template: Columns.Switch },
    ],
    fields: [
      'title',
      'slug',
      'images',
      'status',
      'promote:marquee',
      'card:images',
    ]
  },
  bulletin: {
    name: '重要公告',
    shortName: '公告',
    categorisable: 0,
    columns:
    [
      {
        name: '標題',
        column: 'title',
        style: 'max-width: 300px',
        template: Columns.Actions,
        props: {
          deletable: ({ active }) => active === false,
          linkEdit: ({ id }) => ({ name: 'AdminContentEdit', params: { id } }),
          linkPreview: ({ file_url }) => file_url,
        }
      },
      { name: 'PDF', column: 'attachment_url', style: 'width: 65px', template: Columns.File },
      { name: '發表時間', column: 'publish_at', sortable, style: 'width: 150px', template: Columns.Datetime },
      { name: '人氣', column: 'hits_sum', sortable, style: 'width: 80px', template: Columns.Default },
      { name: '首頁顯示', column: 'promote', style: 'width: 85px', template: Columns.Switch },
    ],
    fields: [
      'title',
      'slug',
      'attachment:pdf',
      'content',
      'status',
      'promote',
    ]
  },
  form: {
    name: '交易表單',
    shortName: '表單',
    categorisable: 2,
    columns:
    [
      { name: '排序', style: 'width:60px', column: 'ordering', template: Columns.Sorter },
      {
        name: '標題',
        column: 'title',
        style: 'max-width: 300px',
        template: Columns.Actions,
        props: {
          deletable: ({ active }) => active === false,
          linkEdit: ({ id }) => ({ name: 'AdminContentEdit', params: { id } }),
          linkPreview: ({ file_url }) => file_url,
        }
      },
      {
        name: '類別',
        style: 'width: 150px',
        column: ({ category }) => category?.name,
        template: Columns.Default
      },
      { name: '檔案', column: 'attachment_url', style: 'width: 65px', template: Columns.File },
      { name: '人氣', column: 'hits_sum', sortable, style: 'width: 80px', template: Columns.Default },
      { name: '顯示', column: 'active', style: 'width: 65px', template: Columns.Switch },
    ],
    fields: [
      'title',
      'attachment:document',
      'content',
      'active',
    ]
  },
  perspective: {
    name: '經理人觀點',
    shortName: '觀點',
    categorisable: 1,
    columns:
    [
      {
        name: '縮圖',
        style: 'width: 100px',
        column: 'cover_tiny_url',
        template: createLinkColumn('AdminContentEdit', model => ({ id: model.id }), Columns.Thumbnail)
      },
      {
        name: '標題',
        column: 'title',
        style: 'max-width: 300px',
        template: Columns.Actions,
        props: {
          deletable: ({ active }) => active === false,
          linkEdit: ({ id }) => ({ name: 'AdminContentEdit', params: { id } }),
          linkPreview: ({ file_url }) => file_url,
        }
      },
      { name: '小標', column: 'subtitle', style: 'width: 180px', template: Columns.Default },
      {
        name: '類別',
        style: 'width: 150px',
        column: ({ category }) => category?.name,
        template: Columns.Default
      },
      { name: '發表時間', column: 'publish_at', sortable, style: 'width: 150px', template: Columns.Datetime },
      { name: '人氣', column: 'hits_sum', sortable, style: 'width: 80px', template: Columns.Default },
      { name: '首頁跑馬', column: 'promote', style: 'width: 85px', template: Columns.Switch },
    ],
    fields: [
      {
        id: 'title',
        limit: 150,
      },
      'slug',
      'cover',
      'video',
      'video:subtitle',
      'status',
      'promote',
    ]
  },
  course: {
    name: '夥伴學習課程',
    shortName: '課程',
    categorisable: 1,
    columns:
    [
      {
        name: '縮圖',
        style: 'width: 100px',
        column: 'cover_tiny_url',
        template: createLinkColumn('AdminContentEdit', model => ({ id: model.id }), Columns.Thumbnail)
      },
      {
        name: '標題',
        column: 'title',
        style: 'max-width: 300px',
        template: Columns.Actions,
        props: {
          deletable: ({ active }) => active === false,
          linkEdit: ({ id }) => ({ name: 'AdminContentEdit', params: { id } }),
          linkPreview: ({ file_url }) => file_url,
        }
      },
      {
        name: '類別',
        style: 'width: 150px',
        column: ({ category }) => category?.name,
        template: Columns.Default
      },
      { name: '發表時間', column: 'publish_at', sortable, style: 'width: 150px', template: Columns.Datetime },
      { name: '人氣', column: 'hits_sum', sortable, style: 'width: 80px', template: Columns.Default },
      { name: '首頁跑馬', column: 'promote', style: 'width: 85px', template: Columns.Switch },
    ],
    fields: [
      'title',
      'slug',
      'cover',
      'video',
      'status',
      'promote',
    ]
  },
  livestream: {
    name: '線上直播',
    shortName: '直播',
    categorisable: 0,
    columns:
    [
      {
        name: '標題',
        column: 'title',
        style: 'max-width: 300px',
        template: Columns.Actions,
        props: {
          deletable: ({ active }) => active === false,
          linkEdit: ({ id }) => ({ name: 'AdminContentEdit', params: { id } }),
          linkPreview: ({ file_url }) => file_url,
        }
      },
      { name: '直播開始', column: 'live_start_at', style: 'width: 150px', template: Columns.Datetime },
      { name: '直播結束', column: 'live_end_at', style: 'width: 150px', template: Columns.Datetime },
      { name: '發表時間', column: 'publish_at', sortable, style: 'width: 150px', template: Columns.Datetime },
      { name: '人氣', column: 'hits_sum', sortable, style: 'width: 80px', template: Columns.Default },
    ],
    fields: [
      'title',
      'summary',
      'status',
      'video:live'
    ]
  },
}
