<!-- 首頁用的 -->
<template>
  <div v-if="displayContent" class="scroll-wrap">
    <component :is="$deviceView.mobile ? 'UiScroll' : 'div'">
      <div class="list">
        <div v-for="item in displayContent" :key="item.id" class="list-item">
          <Card :card="item"
                @showVideo="showVideo(item)"
                @clickTag="routeToLink(item.category.id)"
          />
        </div>
      </div>
    </component>
    <ModalVideo ref="modalVideo" :video="playVideo" />
  </div>
</template>

<script>
import API from 'api'
import Card from 'modules/components/TrainingCard'
import ModalVideo from 'modules/components/ModalVideo'
import UiScroll from 'modules/ui/scroll'

export default {
  components: {
    Card,
    ModalVideo,
    UiScroll,
  },
  data() {
    return {
      current: {
        videos: '',
      },
      kind: 'course',
      playVideo: null,
      page: 1,
    }
  },
  computed: {
    displayContent() {
      return this.current.videos.slice(0, 3)
    },
  },
  async mounted() {
    this.current.videos = await this.loadList()
  },
  methods: {
    async loadList() {
      return await API.content.list({ kind: this.kind, page: this.page })
        .then(res => res.data.entries)
    },
    showVideo(video) {
      this.playVideo = video
      this.$refs.modalVideo.show()
    },
    routeToLink(id) {
      this.$router.push({ name: 'Training', params: { category_id: id } })
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.scroll-wrap
  .v-perfect-scrollbar
    position relative
.list
  display flex
  +over(md)
    position relative
    z-index 1
    &:before
      content ''
      position absolute
      width 163px
      height 170px
      top calc(100% - 50px)
      right -10px
      background-image url('~images/animation/img-0.3.2.svg')
      background-repeat no-repeat
      background-position center center
      background-size contain
      z-index -1
  +under(md)
    width 836px
    margin-bottom 1rem
  .list-item
    width calc((100% - 4rem)/ 3)
    margin-right 2rem
    background-color #fff
    border-radius 21px
    overflow hidden
    &:nth-child(3n)
      margin-right 0
</style>
