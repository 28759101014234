<!--
System Notification

1. 快速使用範例

<script>
import {MixinNotifiable} from 'modules/ui/notification.vue'
export default {
  mixins: [
    MixinNotifiable
  ],
  methods: {
    loadExample(){
      this.wait(this.$api.portfolios.save({foo: bar}), {message: '儲存中請稍後...'})
      .then(() => this.success('儲存成功'))
      .catch(() => this.success('儲存失敗'))
    }
  }
}
</script>

1-A 情境：訊息結束後才進行後續動作，例如頁面重載

this.wait(this.api.portfolios.clone())
.then(() => {
  this.success('投組複製成功').then(this.reload)
})

2. 傳統用法

<template>
  <UiNotification ref="notification" :closable="false"></UiNotification>
</template>

<script>
import UiNotification from 'modules/ui/notification'
export default {
  components: {
    UiNotification
  }
}
</script>

For external control

this.$refs.notification &&
await this.$refs.notification.wait(this.follow, {message: '正在進行調整，請稍後'})
  .then(() => this.$refs.notification.show(`已成功設定績效追蹤`, { type: 'success' }))
  .catch(() => this.$refs.notification.show(`操作失敗`, { type: 'error' }))

this.$refs.notification && (await this.$refs.notification.show("操作成功", { type: 'success', timeout: 1000 }))

-->

<template>
  <div ref="modal" class="ui modal system-notification transition hidden">
    <div :class="classes" class="content align-center">
      <div class="ui active inline small loader" />
      <div class="set-space">
        <div v-if="current.title" class="content-title">
          {{ current.title }}
        </div>
        <div class="message">
          {{ current.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'lib/vue/essential'
import FallbackPromise from 'lib/utils/FallbackPromise'

const $ = jQuery
const UiNotification = {
  props: {
    type: {
      // Available Types: success, error, warning, info, loading
      type: [String],
      default: 'info',
    },
    timeout: {
      type: [Number, Boolean],
      default: () => 1200,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    modalOptions: Object,
  },
  data: () => ({
    visible: false,
    current: {
      title: '',
      message: '',
      type: undefined,
      timeout: undefined,
      waiting: undefined,
    },
    options: {
      message: {
        wait: '請稍後',
        success: '操作成功',
        error: '操作失敗',
      },
    },
  }),
  watch: {
    visible() {
      this.visible === true && this.instance && this.instance.modal('show')
      this.visible === false && this.instance && this.instance.modal('hide')
    },
  },
  computed: {
    classes() {
      return 'type-' + (this.current.type || this.type)
    },
    mergedOptions() {
      return Object.assign(
        {},
        {
          observeChanges: false, // Prevent destroy during update
          closable: this.closable,
          autofocus: false,
          allowMultiple: true,
          onVisible: () => {
            this.$emit('visible')
            this.modalOptions && this.modalOptions.onVisible instanceof Function && this.modalOptions.onVisible()
          },
          onHidden: () => {
            this.$emit('hidden')
            this.modalOptions && this.modalOptions.onHidden instanceof Function && this.modalOptions.onHidden()
          },
        },
        this.modalOptions || {}
      )
    },
  },
  methods: {
    wait(processOrPromise, { message } = { message: this.options.message.wait }) {
      const fallbackHandler = error => {
        // 如果外部沒有 catch 的話，就使用預設notification顯示伺服器回應的 API 簡短錯誤訊息
        if (error.isAxiosError && error.response.data?.message) {
          this.error(error.response.data.message)
        }
      }
      const promise = new FallbackPromise((resolve, reject) => {
        if (typeof processOrPromise == 'function') {
          processOrPromise = processOrPromise()
        }
        if (!(processOrPromise instanceof Promise)) {
          throw new Error('Not a valid async function or promise')
        }
        const showMessage = new Promise((resolve, reject) => {
          setTimeout(async () => {
            resolve()
          }, 800) // 至少顯示滿 800 ms
          this.show(message, { timeout: 0, type: 'loading' })
        })
        // 執行完全不的非同步程序，且等待時間已過
        Promise.allSettled([processOrPromise, showMessage]).then(async (results) => {
          await this.hide() // 先完全關閉上一個等待畫面
          if (results[0].reason) {
            reject(results[0].reason)
          } else {
            resolve(results[0].value)
          }
        })
      }, fallbackHandler)
      return promise.catch(error => {
        // 顯示伺服器回應的 API 簡短錯誤訊息
        if (error.isAxiosError && error.response.data?.message) {
          // 告訴 axio api，已接管此錯誤訊息
          // related: app/javascript/api/index.js
          error.notificationHandled = true
        }
        throw error // 繼續傳遞錯誤訊息
      })
    },
    async show(message, { title, timeout, type } = {}) {
      if (!message) {
        return
      }
      return await new Promise((resolve, reject) => {
        this.current.message = message
        this.current.type = type
        this.current.timeout = timeout

        if (this.current.timeout !== undefined) {
          timeout = this.current.timeout
        } else {
          timeout = this.timeout
        }
        if (title) {
          this.current.title = title
        } else {
          this.current.title = null
        }

        const init = () => {
          if (timeout) {
            setTimeout(async () => {
              await this.hide()
              this.$nextTick(resolve)
            }, timeout)
          } else {
            // NOTE: timeout = 0 必須手動關閉
            // 等待 hide 被呼叫
            this.$once('hidden', () => {
              this.$nextTick(resolve)
            })
          }
        }

        this.visible ? init() : this.$once('visible', init)
        this.visible = true
      })
    },
    async hide() {
      this.current.timeout = undefined
      // 延遲避免造成 margin-right 疊加
      await new Promise((resolve, reject) => {
        if (!this.visible) {
          setTimeout(resolve, 100)
        } else {
          this.visible = false
          this.$once('hidden', () => {
            setTimeout(resolve, 100)
          })
        }
      })
      return this
    },
    async success(message = this.options.message.success, { title, timeout, type } = { type: 'success' }) {
      return await this.show(message, { title, timeout, type })
    },
    async error(message = this.options.message.error, { title, timeout, type } = { type: 'error' }) {
      const messageText = await parseXhrResponse(message)
      if (messageText && messageText.length > 100) {
        return await error(message)
      }
      return await this.show(messageText, { title, timeout, type })
    },
    async notify(message, { title, timeout, type } = { type: 'info' }) {
      return await this.show(message, { title, timeout, type })
    },
    async warn(message, { title, timeout, type } = { type: 'warning' }) {
      return await this.show(message, { title, timeout, type })
    },
    destroy() {
      if (this.instance) {
        this.instance.modal('destroy')
        this.instance.remove()
      }
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      this.instance = $(this.$refs.modal).modal(this.mergedOptions)
      this.visible === true ? this.show() : this.hide()
    })
  },
  beforeDestroy() {
    if (this.instance && this.instance.modal('is active')) {
      this.instance.modal('hide', () => this.destroy)
    } else {
      this.destroy()
    }
  },
}

// let $globalNotification

const MixinNotifiable = {
  mounted() {
    // https://css-tricks.com/creating-vue-js-component-instances-programmatically/
    if (this.constructor.$globalNotification) {
      this.$notification = this.constructor.$globalNotification
    } else {
      const EmbedNotification = Vue.extend(UiNotification)
      this.$notification = new EmbedNotification()
      this.constructor.$globalNotification = this.$notification
      this.$notification.$mount()
      this.$notification.$el.classList.add('global-system-notification')
      document.body.appendChild(this.$notification.$el)
    }
  },
  methods: {
    wait() {
      return this.$notification && this.$notification.wait.apply(this, arguments)
    },
    success() {
      return this.$notification && this.$notification.success.apply(this, arguments)
    },
    error() {
      return this.$notification && this.$notification.error.apply(this, arguments)
    },
    notify() {
      return this.$notification && this.$notification.notify.apply(this, arguments)
    },
    warn() {
      return this.$notification && this.$notification.warn.apply(this, arguments)
    },
  },
}

async function parseXhrResponse(res) {
  if (res instanceof Object && res.status !== undefined && res.body !== undefined) {
    if (res.body.message) {
      return res.body.message
    } else if (navigator.onLine && res.status == 0) {
      return null // Aborted
    } else if (!navigator.onLine && res.status == 0) {
      return '請確認你的網路連線'
    } else if (res.status && Vue.config.silent) {
      return `${res.status} ${res.statusText}`
    } else {
      await error(res)
      return null
    }
  } else {
    return res
  }
}

export default UiNotification
export { MixinNotifiable }
</script>

<style lang="stylus">
.ui.modal.system-notification
  width 400px
  max-width 100%
  > .content
    padding-top 2.5rem !important
    padding-bottom 2.5rem !important
    padding-left 1.5rem
    padding-right 1.5rem
    font-size 1.2rem
    display flex
    height 100%
    justify-content center
    align-items start
    > *
      vertical-align middle
    > i.icon
      color #00c09d
      font-size 1.6em
    .ui.loader
      display none
    &:before
      content ''
      display none
      width 26px
      height 26px
      background-repeat no-repeat
      background-size contain
      margin-right .75em
    &.type-success
      &:before
        display inline-block
        background-image url('~modules/ui/message/icon-success.svg')
    &.type-error
      &:before
        display inline-block
        background-image url('~modules/ui/message/icon-error.svg')
    &.type-warning
      &:before
        display inline-block
        background-image url('~modules/ui/message/icon-warning.svg')
    &.type-info
      &:before
        display inline-block
        background-image url('~modules/ui/message/icon-info.svg')
    &.type-loading
      .ui.loader
        display inline-block
        margin-right 1em
  .set-space
    max-width calc(100% - 40px)
  .content-title
    font-size 1.44rem
    line-height 1.28
    font-weight bold
    margin-bottom 1rem
</style>
