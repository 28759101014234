<template>
  <div :class="interactionClasses" v-on="interactionEvents">
    <slot />
  </div>
</template>

<script>
export default {
  data: () => ({
    current: {
      focused: false,
      pressed: false,
      dragging: false,
      moving: false,
    }
  }),
  computed: {
    interactionEvents() {
      return {
        mousedown: () => {
          this.current.pressed = true
        },
        touchstart: () => {
          this.current.pressed = true
        },
        mouseup: () => {
          this.current.pressed = false
        },
        touchend: () => {
          this.current.pressed = false
          this.current.dragging = false
        },
        mouseenter: () => {
          this.current.focused = true
        },
        mouseleave: () => {
          this.current.dragging = this.current.pressed
          this.current.focused = false
        },
        mousemove: () => {
          this.current.dragging = this.current.pressed
          this.current.moving = true
          clearTimeout(this._movestoppper)
          this._movestoppper = setTimeout(() => {
            this.current.moving = false
          }, 500)
        },
        touchmove: () => {
          this.current.dragging = this.current.pressed
        }
      }
    },
    interactionClasses() {
      return {
        'pressed': this.current.pressed,
        'dragging': this.current.dragging,
        'focused': this.current.focused,
        'unfocus': !this.current.focused,
        'moving': this.current.moving,
        'steady': !this.current.moving,
      }
    }
  }
}
</script>
