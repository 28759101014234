<template>
  <div v-if="entry">
    <form id="new_article_insight" enctype="multipart/form-data" action="/admin/article_insights" accept-charset="UTF-8" method="post" class="ui form app">
      <input name="utf8" type="hidden" value="✓">
      <input type="hidden" name="authenticity_token" value="QVCr/FoaExUrftFPajWldKdeeUwkXTR6HQFM3jW2uO3TQHQDS/vOsyMG1dW2gprAlEOvkprh4K+8dIS/UGd2mg==">

      <Field required :error="fieldErrorOf('name')">
        <label>{{ kindConfig.name }}分類名稱</label>
        <Input v-model="entry.name" required class="fluid" :placeholder="`必填，字數上限 10 字`" />
      </Field>

      <Field>
        <label>顯示</label>
        <Checkbox v-model="entry.visible" toggleSwitch />
      </Field>

      <div v-if="kindConfig.categorisable === 2" class="two fields">
        <Field required :error="fieldErrorOf('parent_id')">
          <label>所屬主題</label>
          <Categories v-model="entry.parent_id" v-bind="{categories}" :level="1" />
        </Field>
      </div>

      <div class="ui divider" />

      <div class="rule-buttons-group">
        <div class="ui button minor" @click="abort">
          取消
        </div>
        <div class="ui button outline" @click="submit">
          儲存
        </div>
        <div class="ui button major" @click="submit({goBack: true})">
          儲存並返回
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Api from 'api'
import MixinShared from './shared'
import { createValidator, calcLength } from 'modules/ui/form/validation.js'

export default {
  mixins: [
    MixinShared,
    createValidator([
      {
        field: 'name',
        message: '請輸入名稱'
      },
      {
        validator: (name) => calcLength(name) <= 10,
        field: 'name',
        message: '名稱不得超過 10 字'
      },
      {
        when: ({ kindConfig }) => kindConfig.categorisable === 2,
        field: 'parent_id',
        message: '請選擇主題'
      },
    ])
  ],
  data() {
    return {
      entry: null,
      categories: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    submitTo() {
      // 存檔或新增
      if (this.id) {
        return Api.content.categories.update
      } else {
        return Api.content.categories.create
      }
    }
  },
  watch: {
    id() {
      this.load()
    }
  },
  mounted() {
    this.load()
  },
  beforeDestroy() {
    this.entry = null
  },
  methods: {
    hasField(id) {
      const fieldConfig = this.kindConfig.fields.find(field => {
        return field === id || field.id === id
      })
      return fieldConfig && ((typeof fieldConfig === 'object') ? fieldConfig : { id: fieldConfig })
    },
    abort() {
      this.backToList()
    },
    backToList() {
      // 回到列表
      this.$router.push({
        name: 'AdminContentCategories',
        params: this.$route.params,
      })
    },
    submit({ goBack }) {
      this.bindFormValidator('entry')
      if (this.validateForm(this.entry)) {
        this.wait(this.submitTo({
          format: 'full',
          id: this.id,
          kind: this.kind,
          attributes: this.entry,
        })).then((res) => {
          this.success('已儲存').then(() => {
            if (goBack) {
              this.backToList()
            } else if (res.data?.entry?.id) {
            // 跳到新文章的頁面
              this.$router.push({
                name: 'AdminContentCategoryEdit',
                params: {
                  ...this.$route.params,
                  id: res.data.entry.id,
                }
              })
            }
          })
        })
      } else {
        this.scrollToErrorField()
      }
    },
    preview() {},
    async load() {
      await this.loadEntry()
      await this.loadCategories()
    },
    async loadEntry() {
      if (this.id) {
        this.entry = await Api.content.categories.edit({
          kind: this.kind,
          id: this.id,
          format: 'full'
        }).then(res => res.data.entry)
      } else {
        this.entry = {
          visible: true,
        }
      }
    },
    async loadCategories() {
      if (this.kindConfig.categorisable === 2) {
        this.categories = await Api.content.categories({ kind: this.kind, format: 'full' }).then(res => res.data.entries)
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
.remark
  margin-top 2rem
  color $gray2
</style>
