<template>
  <div v-if="rawData">
    <ChartLegend v-if="$deviceView.desktop">
      <div v-for="entry in legendSet" :key="entry.label" class="legend-item">
        <span class="color-ball" :style="{backgroundColor: entry.color}" />
        <span>{{ entry.label }}</span>
      </div>
    </ChartLegend>
    <div v-else class="mobile-legend">
      <div v-for="entry in legendSet" :key="entry.label" class="legend-row">
        <span class="color-ball" :style="{backgroundColor: entry.color}" />
        <span class="dollar-name">{{ entry.label }}</span>
        <span>{{ entry.value }}%</span>
        <span class="price-ccy">
          <i v-if="entry.changes > 0" class="icon-up-dir color-positive" />
          <i v-else-if="entry.changes < 0" class="icon-down-dir color-negative" />
          <span :class="entry.changes > 0 ? 'color-positive' : 'color-negative'">
            {{ Math.abs(entry.changes) | round(2) }}%
          </span>
        </span>
      </div>
    </div>
    <div class="chart-wrap">
      <div class="meta-labels">
        <div v-for="(item, i) in metaLabels" :key="i" class="label" :style="{left: `${item.x}px`, top: `${item.y}px`}">
          <span>{{ item.value }}%</span>
          <span v-if="$deviceView.desktop" class="price-ccy">
            <i v-if="legendSet[i].changes > 0" class="icon-up-dir color-positive" />
            <i v-else-if="legendSet[i].changes < 0" class="icon-down-dir color-negative" />
            <span :class="legendSet[i].changes > 0 ? 'color-positive' : 'color-negative'">
              {{ Math.abs(legendSet[i].changes) | round(2) }}%
            </span>
          </span>
        </div>
      </div>
      <canvas ref="canvas" />
      <div class="unit">
        %
      </div>
    </div>
    <div class="remark text-minor">
      <span class="remark-item">資料時間：{{ latestDate | date }}</span>
      <span class="remark-item">* 若與月報差異0.1%為進位差異，括號數字代表與上月差異數</span>
    </div>
  </div>
</template>

<script>
import { ChartColors } from 'modules/chart/colors'
import ChartLegend from 'modules/chart/components/Legend.vue'

export default {
  components: {
    ChartLegend,
  },
  props: {
    rawData: Array,
  },
  data() {
    return {
      chart: null,
      colors: ChartColors,
      metaLabels: [],
    }
  },
  computed: {
    latestData() {
      return this.rawData?.[0]['幣別分佈']
    },
    previousData() {
      return this.rawData?.[1]['幣別分佈']
    },
    latestDate() {
      return this.rawData?.[0].date
    },
    chartLabels() {
      if (!this.latestData) return []
      return Object.keys(this.latestData)
    },
    barColor() {
      const count = this.chartLabels.length
      return this.colors.slice().splice(0, count)
    },
    legendSet() {
      // 幣別相關資訊
      return this.chartLabels.map((label, index) => {
        return {
          label,
          color: this.barColor[index],
          value: this.latestData[label],
          changes: this.latestData[label] - this.previousData[label],
        }
      })
    },
    chartData() {
      const vals = this.chartLabels.map(label => this.latestData[label])
      return [
        {
          data: vals,
          barPercentage: 0.3,
          backgroundColor: this.barColor,
          borderColor: this.barColor,
        },
      ]
    },
    ticksBuffer() {
      const max = Math.max(...this.chartData[0].data.map(parseFloat))
      const min = Math.min(...this.chartData[0].data.map(parseFloat))
      const range = Math.abs(max - min)
      const buffer = range * 0.15
      return {
        suggestedMax: max + buffer,
        suggestedMin: min - buffer,
      }
    },
    chartOptions() {
      return {
        legend: false, // < 3.0
        aspectRatio: this.$deviceView.mobile ? 1 : 2,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        animation: {
          onComplete: () => {
            this.updateLabels()
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                ...this.ticksBuffer
              }
            }
          ]
        }
      }
    },
  },
  watch: {
    chartOptions() {
      this.updateChartConfig()
    }
  },
  async mounted() {
    this.draw()
  },
  methods: {
    draw() {
      this.chart = new Chart(this.$refs.canvas.getContext('2d'), {
        type: 'bar',
        data: {
          labels: this.chartLabels,
          datasets: this.chartData,
        },
        options: this.chartOptions,
      })
    },
    updateLabels() {
      const { data } = this.chart.getDatasetMeta(0)
      this.metaLabels = data.map(({ x, y, ...item }, index) => {
        const value = this.chartData[0].data[index]
        if (Chart.version === undefined) { // < 3.0
          x = item._model.x
          y = item._model.y
        }
        if (value > 0) y = y - 40
        return { x, y, value }
      })
    },
    updateChartConfig() {
      this.chart.options = this.chartOptions
      this.chart.update()
    },
  },

}
</script>

<style lang="stylus" scoped>
@import '~modules/ui/common'
.chart-wrap
  position relative
  .unit
    position absolute
    left 12px
    bottom 0
  .meta-labels
    position absolute
    .label
      position absolute
      color #505050
      white-space nowrap
      transform translateX(-50%)
      text-align center
      +under(md)
        font-size 12px
      > span
        display block
.mobile-legend
  margin-bottom 1rem
  .legend-row
    display flex
    align-items center
    + .legend-row
      margin-top 1rem
  .dollar-name
    flex 0 0 56px
.price-ccy
  font-size 12px
  margin-left .2rem
  &:before
    content "("
  &:after
    content ")"
// 正負
.color-positive
  color #ED4758
.color-negative
  color #00BF9C
// 備註
.remark
  margin 1.5rem 0
  .remark-item + .remark-item
    margin 0 0 0 1rem
  +under(md)
    .remark-item
      display block
    .remark-item + .remark-item
      margin 1rem 0 0 0
canvas
  position relative
  z-index 10
</style>
