
<template>
  <div class="interactive-line-chart">
    <DateRangePicker
      v-if="mergedOptions.datepicker"
      v-bind="{
        ...current,
        localCurrency,
        periodKey,
        currency: null,
        baseDate,
        earliestDate,
      }"
      v-on="{ setDateRange, ...$listeners }"
    />

    <div v-else class="alone-currency-box">
      <div class="currency-box">
        <DropdownCurrency v-model="currentCurrency" v-bind="{ localCurrency }" />
      </div>
    </div>

    <Loader :loading="!loadingStateReady" preload>
      <ChartLegend>
        <div
          v-for="entry in legendSet" :key="entry.id"
          class="legend-item"
          :class="{active: isActive(entry.id)}"
          :style="{backgroundColor: hexa(entry.color, 20)}"
          @mouseenter="$bus.$emit('chart:entry-active', entry.id, moduleName)" @mouseleave="$bus.$emit('chart:entry-idle', entry.id, moduleName)"
        >
          <span class="color-ball" :style="{backgroundColor: entry.color}" />
          <span>{{ entry.label }}</span>
        </div>
      </ChartLegend>

      <div ref="chart" class="chart-performance" :class="{initialized: loadingStateReady, empty: isEmpty, insufficient: isInsufficient}">
        <InteractionWrapper class="chart-wrap">
          <div ref="slider" class="slider">
            <div v-if="closestCursorPoint" class="cusror-points">
              <span v-for="point in sortedCursorPoints" :key="point.id" :style="getPointStyle(point)" />
            </div>
            <div class="cursor-scanline" :style="cursorStyle">
              <div v-if="closestCursorPoint" ref="cursorPopup" class="cursor-popup" :class="{'flipped': cursorPopupFlipped}">
                <div class="h5">
                  {{ closestCursorPoint.date }}
                </div>
                <transition-group name="flip-list" tag="div">
                  <div v-for="point in sortedCursorPoints" :key="point.id" class="item" :class="{ invalid: point.value === null }">
                    <span class="color-ball" :style="{backgroundColor: point.color}" />
                    <span class="cursor-label">{{ point.label }}</span>
                    <span class="cursor-value">
                      <template v-if="point.value === null">尚未成立</template>
                      <span v-else :class="symbolClass(point.value)">{{ point.value | percentage(1) }}</span>
                    </span>
                    <template v-if="point.amount && point.diff >= 0">
                      <span class="amount-currency">{{ displayCurrency }}市值</span>
                      <span class="cursor-value">{{ point.amount | currency(currencyDecimals) }}</span>
                    </template>
                  </div>
                </transition-group>
              </div>
            </div>
            <div ref="range" class="range">
              <div class="handle handle--start" />
              <div class="handle handle--end" />
              <div class="date from" />
              <div class="date to" />
            </div>
          </div>
          <svg v-once ref="svg" width="800" height="400" />
        </InteractionWrapper>
      </div>
    </Loader>

    <div v-if="mergedOptions.list && dataset.length > 0" class="list">
      <table>
        <thead class="noselect">
          <td>TAROBO 評分</td>
          <td>選取範圍報酬率</td>
          <td>期間平均年報酬率</td>
          <td>期間年化波動率</td>
          <td>期間夏普值</td>
          <td />
        </thead>
        <tbody>
          <tr v-for="(set, index) in dataset" :key="index" class="list__row" :class="{active: isActive(set.id)}" :style="{'border-left-color': set.color, backgroundColor: hexa(set.color, 10)}"
              @mouseenter="$bus.$emit('chart:entry-active', set.id, moduleName)" @mouseleave="$bus.$emit('chart:entry-idle', set.id, moduleName)"
          >
            <td class="name">
              <template v-if="set.type === 'fund'">
                <FundNameSimple :value="set.fund" :color="colors[set.id]" squared />
              </template>
              <div v-else class="title-name">
                <span v-if="['bench', 'index', 'sector'].includes(set.type)" class="square-box base" :style="{backgroundColor: set.color}">
                  <span>
                    <i class="icon-benchmark" />
                  </span>
                </span>
                <span v-else-if="['port', 'portfolio', 'preview'].includes(set.type)" class="square-box portfolio" :style="{backgroundColor: set.color}">
                  <span>
                    <i class="icon-portfolio" />
                  </span>
                </span>
                <span>{{ set.label }}</span>
              </div>
            </td>
            <td class="value" label="選取範圍報酬率">
              <span class="color" :class="{negative: set.value<0}">
                {{ set.value>=0?'': '-' }} {{ set.value | abs | percentage(1) }}
              </span>
            </td>
            <td label="期間平均年報酬率">
              <span class="color" :class="{negative: set.perf<0}">
                {{ set.perf | percentage(1) }}
              </span>
            </td>
            <td label="期間年化波動率">
              <span class="color" :class="{negative: set.vol<0}">
                {{ set.vol | percentage(1) }}
              </span>
            </td>
            <td label="期間年化夏普值">
              <span class="color" :class="{negative: set.sharp<0}">
                {{ set.sharp | round(3) }}
              </span>
            </td>
            <td class="actions">
              <a class="action remove" @click="remove(set.id)"><i v-if="isRemovable(set.id)" aria-hidden="true" class="icon-delete" /></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
/* eslint-disable eqeqeq */

import stable from 'lib/utils/stable.js'
import { ChartPerformance, ChartPerformanceData } from './lib.js'

import MixinColors, { MixinColorRotation } from 'modules/chart/colors'
import ChartLegend from 'modules/chart/components/Legend.vue'

import MixinCurrency from 'modules/components/fund/MixinCurrency'
import MixinFundsLoader from 'modules/components/fund/MixinFundsLoader'
import DateRangePicker from 'modules/components/fund/DateRangePicker'
import DropdownCurrency from 'modules/components/fund/DropdownCurrency'
import FundNameSimple from 'modules/components/fund/FundNameSimple'

import Loader from 'modules/ui/loader'
import { MixinOnVisible } from 'modules/ui/mixins/in-viewport'
import InteractionWrapper from 'modules/misc/InteractionWrapper.vue'

const $ = jQuery

export default {
  components: {
    DateRangePicker,
    DropdownCurrency,
    FundNameSimple,
    InteractionWrapper,
    ChartLegend,
    Loader,
  },
  filters: {
    max: function(value) {
      return Math.max.apply(this, value)
    },
    min: function(value) {
      return Math.min.apply(this, value)
    },
    abs: function(value) {
      return Math.abs(value)
    }
  },
  mixins: [
    MixinColors,
    MixinColorRotation,
    MixinCurrency,
    MixinOnVisible,
    MixinFundsLoader,
  ],
  props: {
    moduleName: {
      type: String,
      default: 'defaultModule'
    },
    fundDefault: Number,
    fundIds: {
      type: Array,
      default: () => []
    },
    baseDate: {
      type: [String, Object, Date],
      default: () => moment(),
      validator: value => {
        return (typeof value !== 'object' || moment.isMoment(value))
      }
    },
    earliestDate: {
      type: [String, Object, Date],
      default: () => '2000-01-01',
      validator: value => {
        return (typeof value !== 'object' || moment.isMoment(value))
      }
    },
    benchmarkIds: {
      type: Array,
      default: () => []
    },
    chartColors: {
      type: Object,
      default: () => {}
    },
    hiddenIds: {
      type: Array,
      default: () => []
    },
    locked: {
      type: [Array, Boolean],
      default: () => []
    },
    periods: {
      type: Number,
      default: 1
    },
    options: {
      type: Object,
      default() {
        return this.defaultOptions
      }
    },
    localCurrency: String,
    dateStart: {
      type: [String],
      default() {
        return moment(this.baseDate).add(-1, 'year').format(moment.HTML5_FMT.DATE)
      }
    },
    dateEnd: {
      type: [String],
      default() {
        return moment(this.baseDate).format(moment.HTML5_FMT.DATE)
      }
    },
    /** 設定預設被選取的範圍 */
    dateAnchor: {
      type: Object
      /**
       * @param dateStart
       * @param dateEnd
       */
    },
    periodKey: String,
    excludeTaroboIndex: Boolean,
    comparables: {
      /** @type {Array<Number>} */
      type: Array,
      default: () => [],
    },
  },
  data: function() {
    return {
      defaultOptions: {
        datepicker: true,
        list: true,
      },
      state: {
        hidden: [],
      },
      data: [],
      // dataShadow: undefined,
      chart: null,
      dateFormat: moment.HTML5_FMT.DATE,
      current: {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        activeId: null,
      },
      // shared
      funds: [],
      sectors: [],
      selectedIds: [],
      selectPortfolios: [],
    }
  },
  computed: {
    mergedOptions() {
      return {
        ...this.defaultOptions,
        ...this.options,
      }
    },
    // 不可移除的項目，繼承的元件可複寫
    internalLockedIds: () => [],

    allDates() {
      return ((this.chart && this.chart.dataset.getAllDates()) || [])
    },
    modifiedDateStart() {
      // return this.current.dateStart
      // https://app.asana.com/0/1138143550358897/1151688292706889/f
      // 使起始點為月初，而不是月底
      return moment(this.current.dateStart, moment.HTML5_FMT.DATE).startOf('month').format(moment.HTML5_FMT.DATE)
    },
    colors: function() {
      if (this.chartColors) {
        // 使用外部指定顏色
        return Object.fromEntries(this.data.map(line => {
          const numberId = String(line.id).replace(/^(fund|index|bench|portfolio)-/, '')
          const color = this.chartColors[line.id] || this.chartColors[numberId]
          if (color) {
            this.assignColor(numberId, color)
          }
          return [line.id, color || this.getColor(numberId)]
        }))
      } else {
        // 自動分配
        return this.generateColorMap(this.data.map(line => line.id))
      }
    },
    dataset: function() {
      // 圖裡面必須要有線條
      if (!(this.chart && this.chart.dataset.dataset.length > 0)) {
        return []
      }
      // 讓 anchorOffset 的變動來觸動更新
      const arr = this.chart.anchorOffset.join(',') && this.data.map(s => {
        const id = String(s.id)
        let type = 'line'
        if (this.fundIds.includes(s.id)) {
          type = 'fund'
        }
        if (this.benchmarkIds.includes(s.id)) {
          type = 'benchmark'
        }
        const point = this.chart.getFinalValues().filter(v => v.id == s.id)[0]
        return point && Object.assign(point, {
          itemId: `${type}-${id}`,
          type,
          type_id: id,
          fund: type == 'fund' ? this.getFund(id) : undefined,
          perf: s.ann && s.ann.perf,
          sharp: s.ann && s.ann.sharp,
          vol: s.ann && s.ann.vol,
          hidden: this.state.hidden.includes(s.id)
        })
      }).filter(hasPoint => hasPoint)
      // 全球理財指數排第一，其次依照選取期間報酬率由大到小排序
      // NOTE: 20201126 取消依照選取期間報酬率排序
      // https://coderwall.com/p/ebqhca/javascript-sort-by-two-fields
      const isIndex = (node) => ['index', 'bench-50', '50', 50].includes(node.id)
      const isBenchmark = (node) => !!String(node.id).match(/^(?:sector|bench-)/)
      return stable(arr, (a, b) => isIndex(b) - isIndex(a) || isBenchmark(b) - isBenchmark(a))
    },
    legendSet() {
      return this.dataset.filter(line => !this.isIdHidden(line.id))
    },
    isEmpty: function() {
      return this.loadingStateReady && (this.dataset.length == 0 || !this.data.every(s => (s.data || s.performance).length > 1))
    },
    isInsufficient() {
      // 是投資組合 & 只有一個點
      return false
    },
    cursorStyle() {
      if (this.closestCursorPoint) {
        return `transform: translateX(${Math.round(this.closestCursorPoint.x)}px)`
      }
      return ''
    },
    closestCursorPoint() {
      return this.chart && this.chart.cursorPoints.slice().filter(p => p.diff >= 0).sort((a, b) => Math.abs(a.diff - b.diff))[0]
    },
    cursorPopupFlipped() {
      if (this.chart && this.closestCursorPoint && this.$refs.cursorPopup) {
        if (this.closestCursorPoint.x > this.$refs.cursorPopup.clientWidth + 100) {
          return this.closestCursorPoint.x + this.$refs.cursorPopup.clientWidth > this.chart.width / 2
        }
      }
      return null
    },
    sortedCursorPoints() {
      const fieldModifier = (entry, key) => entry[key]
      const sorter = (a, b, sortKey, sortOrd) => {
        const v1 = fieldModifier(a, sortKey)
        const v2 = fieldModifier(b, sortKey)
        const types = ['undefined', 'null', 'boolean', 'number', 'string', 'object', 'function']
        const t1 = v1 === null ? 1 : types.indexOf(typeof v1)
        const t2 = v2 === null ? 1 : types.indexOf(typeof v2)
        if (t1 === t2) return (v1 === v2 ? 0 : v1 < v2 ? -1 : 1) * sortOrd
        return (t1 < t2 ? -1 : 1) * sortOrd
      }
      const points = this.chart?.cursorPoints.filter(point => !this.isIdHidden(point.id))
      return stable(points, (a, b) => sorter(a, b, 'value', -1))
    }
  },
  watch: {
    fundIds: {
      immediate: true,
      async handler() {
        const loader = this.createLoadingState()
        // await this.loadFunds(this.fundIds)
        loader.finish()
        this.lazyload()
      },
    },
    'current.activeId'(id) {
      if (id) {
        this.$refs.chart.querySelectorAll('.lines path').forEach(line => {
          line.style.opacity = '0.2'
          line.style.strokeWidth = '1px'
        })
        this.$refs.chart.querySelectorAll('.balances path').forEach(line => {
          line.style.opacity = '0'
        })
        this.$refs.chart.querySelectorAll(`.lines path[id="${id}"], .balances path[id="${id}"]`).forEach(line => {
          line.style.opacity = ''
          line.style.strokeWidth = ''
        })
      } else {
        this.$refs.chart.querySelectorAll('.lines path, .balances path').forEach(line => {
          line.style.opacity = ''
          line.style.strokeWidth = ''
        })
      }
    },
    dataset(val) {
      this.defer('update:list', 150, () => { // 緩衝時間
        this.$emit('update:list', this.dataset.map(set => ({
          ...set,
          value: set.value, // 這是動態資料 (getter)，轉靜態
          date: set.date, // 這是動態資料 (getter)，轉靜態
          backgroundColor: this.blendRGBColors('#FFFFFF', set.color, 0.1),
        })))
      })
    },
    'chart.anchorValues'(vals) {
      // 傳日期資訊給外層的表格
      this.defer('update:anchorDates', 150, () => { // 緩衝時間
        if (vals[0][0]) {
          this.$emit('update:date', {
            observePeriod: [this.current.dateStart, this.current.dateEnd],
            selectedRange: [vals[0][0].date, vals[1][0].date]
          })
        }
      })
    },
    dateAnchor() {
      if (this.chart && this.dateAnchor) {
        this.setDateAnchor(this.dateAnchor)
      }
    },
    hiddenIds () { // 更改隱藏線必須重畫
      if (this.chart) {
        this.chart.options.hiddenIds = this.hiddenIds
        this.chart.render()
      }
    },
    dateStart(value) {
      this.current.dateStart = value
    },
    dateEnd(value) {
      this.current.dateEnd = value
    },
  },
  created: function() {
    this.lazyload()
    this.$bus.$on('chart:entry-active', (id, fromModule) => { if (fromModule == this.moduleName) { this.current.activeId = id } })
    this.$bus.$on('chart:entry-idle', (id, fromModule) => { if (fromModule == this.moduleName) { this.current.activeId = null } })
  },
  mounted: function() {
    this.chart = new ChartPerformance(this.$refs.chart, new ChartPerformanceData(), {
      format: d3.format('.0%'),
      formatValue: d3.format('.01%'),
      hiddenIds: this.hiddenIds,
    })

    // $(this.$refs.events).dropdown()
  },
  beforeDestroy() {
    this.chart && this.chart.destroy()
    this.chart = null
  },
  methods: {
    defer(timer, timeout, callback) {
      clearTimeout(this['delayer_' + timer])
      this['delayer_' + timer] = setTimeout(callback, timeout)
    },
    setDateRange({ dateStart = this.datePicker.dateStart, dateEnd = this.datePicker.dateEnd }) {
      const wasFromDate = moment(this.current.dateStart).isValid() ? moment(this.current.dateStart).format(moment.HTML5_FMT.DATE) : null
      const wasToDate = moment(this.current.dateEnd).isValid() ? moment(this.current.dateEnd).format(moment.HTML5_FMT.DATE) : null

      const curFromDate = moment(dateStart).isValid() ? moment(dateStart).format(moment.HTML5_FMT.DATE) : null
      const curToDate = moment(dateEnd).isValid() ? moment(dateEnd).format(moment.HTML5_FMT.DATE) : moment().format(moment.HTML5_FMT.DATE)

      this.$set(this.current, 'dateStart', curFromDate)
      this.$set(this.current, 'dateEnd', curToDate)

      if (curFromDate != null && wasFromDate != null && curFromDate != wasFromDate) {
        return this.lazyload()
      } else if (curToDate && curToDate != wasToDate) {
        return this.lazyload()
      }
    },
    isIdHidden(id) {
      return this.hiddenIds.find(e => String(id).match(new RegExp(`-?${e}$`)))
    },
    setDate({ from, to }) {
      console.warn('[Deprecation] Please use setDateRange({dateStart, dateEnd}) instead')
      this.setDateRange({
        dateStart: from,
        dateEnd: to,
      })
    },
    /**
     * 設定選取的範圍(日期起迄)
     */
    setDateAnchor({ dateStart, dateEnd }) {
      if (typeof this.chart?.setAnchorStart == 'function' && dateStart) {
        const anchorStart = this.chart.dataset.x(new Date(dateStart))
        if (anchorStart >= 0 && anchorStart <= 1) {
          this.chart.setAnchorStart(anchorStart)
        } else {
          this.chart.setAnchorStart(0)
        }
      }
      if (typeof this.chart?.setAnchorEnd == 'function' && dateEnd) {
        const anchorEnd = this.chart.dataset.x(new Date(dateEnd))
        if (anchorEnd >= 0 && anchorEnd <= 1) {
          this.chart.setAnchorEnd(anchorEnd)
        } else {
          this.chart.setAnchorEnd(1)
        }
      }
      this.chart?.render()
    },
    resetDates() {
      if (this.data.length > 0) {
        // const min = (this.chart.dataset.getAllDates() || []).slice(0, 1).pop() || moment().subtract(1, 'year').format(moment.HTML5_FMT.DATE)
        // const max = (this.chart.dataset.getAllDates() || []).slice(-1).pop() || new Date()
        this.chart.dataset.domainDates(moment(this.modifiedDateStart, moment.HTML5_FMT.DATE), moment(this.current.dateEnd, moment.HTML5_FMT.DATE))
        this.chart.setAnchor(0)
        if (this.dateAnchor) {
          this.setDateAnchor(this.dateAnchor)
        }
      }
    },
    load() {},
    lazyload() {
      this.defer('lazyload', 300, () => {
        this.$onVisible('load')
      })
    },
    toggleHidden(id) {
      if (this.state.hidden.includes(id)) {
        this.state.hidden = this.state.hidden.filter(_id => _id != id)
      } else {
        this.state.hidden.push(id)
      }
    },
    isRemovable(id) {
      if (this.internalLockedIds.indexOf(id) != -1) {
        return
      }
      if (id == 'index') { return false }
      if (id == 'sector') { return false }
      if (id == 'preview') { return false }
      if (typeof this.locked == 'boolean') { return !this.locked }
      return this.locked.indexOf(id) == -1
    },
    remove: function(id) {
      this.data = this.data.filter(d => d.id != id)
      this.unassignColor(id)
      this.$emit('remove', id)
      this.$emit('update:fundIds', this.fundIds.filter(_id => _id != id))
    },
    add: function(id) {
      id = +id
      if (id && this.fundIds.indexOf(id) == -1) {
        this.$emit('add', id)
      }
    },
    getPointStyle(cursorPoint) {
      const y = cursorPoint.y
      let x = cursorPoint.x
      if (cursorPoint.diff < 0) {
        x = this.closestCursorPoint.x
      }
      return {
        transform: `translate(${x}px,${y}px)`,
        backgroundColor: cursorPoint.color,
        boxShadow: `0 0 0px 2px white, 0 0 0px 6px ${this.hexa(cursorPoint.color, 20)}`
      }
    },
    isActive(lineId) {
      return this.current.activeId == lineId
    },
    symbolClass(val) {
      // 正負號Class
      if (typeof val == 'number') {
        return `data-value--${val >= 0 ? 'positive' : 'negative'}`
      } else {
        return null
      }
    }
  },
}
</script>

<style lang="less">
@import './core.less';
@import '~modules/ui/responsive';

.chart-performance{
  margin-top: 2em;
  margin-bottom: 2em;
  padding-right: 1rem;
  overflow: hidden;
}
</style>

<style lang="stylus" scoped>
@import '~modules/ui/common'

.alone-currency-box
  display flex
  .currency-box
    margin-left auto

.daterange
  display flex
  align-items center
  flex-wrap wrap
  max-width initial

  input
    color #666
    font-weight bold

  > *
    display inline-block
    margin-top .5em
    margin-bottom .5em
    // margin-left .5em
    // margin-right .5em
  &__text
    flex-shrink 0 !important
    flex-grow 0 !important
    min-width 3em
    text-align center
    @media $mobile
      width 5%
  &__group
    flex-shrink 0
    flex-grow 1
    display inline-flex
    align-items center
    flex-wrap wrap
    white-space nowrap
    max-width initial
    margin-right 1rem
    @media $mobile
      > *
        flex-grow 1
  .is-title
    margin-right .5em
    @media $mobile
      text-align left
      width 100%
    &:after
      content '：'

</style>

<style lang="stylus" scoped src="./core-extend.styl"></style>
