<!--

  下拉選單按鈕

  import DropdownButton from 'modules/ui/form/dropdownButton'

  必須填入兩個 slot
   - 按鈕內容 #text, #button, #input, 無
      text 無按鈕樣式
      button 按鈕樣式的內容
      input 下拉選單的樣式 (右邊有倒三角形)
      無 則顯示 ... 的方形按鈕

   - 展開的選單內容 #menu
      必須為 <div class="item"></div> 的清單

  <DropdownButton ref="dropdown" size="small" fluid>
    <template #input>
      <i class="icon-currency-o icon" style="margin-right:0.75rem"></i>
      <span>{{ displayText }}</span>
    </template>
    <template #menu>
      <div :data-value="option.value" :key="option.value" class="item" v-for="option in entries">{{ option.text }}</div>
    </template>
  </DropdownButton>

-->

<template>
  <UiDropdown ref="dropdown" class="ui dropdown" :class="dropdownClasses" :options="defaultOptions" v-bind="$attrs" v-on="$listeners">
    <input type="hidden">
    <template v-if="$slots.text">
      <slot name="text" />
    </template>
    <template v-else-if="$slots.button">
      <div class="ui button minor" :class="{ fluid }">
        <slot name="button" />
      </div>
    </template>
    <template v-else-if="$slots.input">
      <div class="ui button default fake-input" :class="{ fluid }">
        <slot name="input" />
        <i class="icon-down-dir" />
      </div>
    </template>
    <template v-else>
      <div class="ui button minor" :class="{ fluid }">
        ···
      </div>
    </template>
    <div class="menu">
      <slot name="menu" />
    </div>
  </UiDropdown>
</template>

<script>
import UiDropdown from 'modules/ui/form/dropdown'

export default {
  components: {
    UiDropdown,
  },
  props: {
    size: {
      // small, medium
      type: String,
      default: 'small',
    },
    fluid: Boolean,
  },
  computed: {
    instance() {
      return this.$refs.dropdown.instance
    },
    dropdownClasses() {
      return {
        [this.size]: true,
        fluid: this.fluid,
        scrolling: this.$slots.input,
      }
    },
    defaultOptions() {
      return {
        action: 'hide', // 點選後直接關閉選單
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
@import '~modules/ui/common'

.ui.dropdown
  &.fluid
    width 100%
    min-width 300px
  @media $mobile
    min-width auto
  .ui.button.fake-input
    color #666
    display flex
    align-items center
    margin 0
    line-height 1
    font-weight bold
  &.small
    .ui.button.fake-input
      font-size 1rem !important
      height 28px
    /deep/ .menu>.item
      font-size 1rem
  &.medium
    .ui.button.fake-input
      font-size 1.2rem !important
      height 36px
    /deep/ .menu>.item
      font-size 1.2rem
  i.icon-down-dir
    font-size 12px
    line-height 1
    vertical-align middle
    margin-left auto
  /deep/ .text
    overflow hidden
    text-overflow ellipsis
    text-align left
  /deep/ i:first-child
    margin-right .75rem
  /deep/ > .menu
    width 100%
    border-radius 5px
    padding 1rem 0
    margin-top .5rem
    > .item
      padding .75rem 1.5rem !important
      color $gray2
      font-weight bold
      &:hover
        background-color #EDEDED
        color $gray2
      > .description
        float none
        margin 4px 0 0 0
        color $gray2
        font-size 12px
        line-height 15px
      > .title
        font-size 14px
        line-height 18px
    .selected.item
      background-color #EDEDED
      color #666
      .title
        color #666
      .description
        color $gray2
</style>
