<template>
  <DropdownButton v-bind="{size, fluid}" ref="dropdownButton" :text.sync="text" :value="periodKey" @input="$emit('input', arguments[0])">
    <template #input>
      <i class="icon-calendar" />
      <span>觀察期間：</span>
      <span class="text">{{ text }}</span>
    </template>
    <template #menu>
      <div v-for="period in periods" :key="period.id" :data-text="period.label" :data-value="period.id" class="item" @click="setPeriod(period)">
        <div class="title">
          {{ period.label }}
        </div>
        <div v-if="baseDate" class="description">
          {{ period.getDateStart(baseDate) | timeformat('YYYY-MM') }} ~ {{ baseDate | timeformat('YYYY-MM') }}
        </div>
      </div>
    </template>
  </DropdownButton>
</template>
<script>
/* eslint-disable vue/require-default-prop */

import DropdownButton from 'modules/ui/form/dropdownButton'
export default {
  components: {
    DropdownButton,
  },
  model: {
    prop: 'periodKey',
  },
  props: {
    periodKey: [String],
    size: {
      // small, medium
      type: String,
      default: 'small',
    },
    fluid: Boolean,
    baseDate: {
      type: [String, Object, Date],
      default: moment().format('YYYY-MM-DD'),
      validator: value => {
        return (typeof value !== 'object' || moment.isMoment(value))
      }
    },
    earliestDate: {
      type: [String, Object, Date],
      default: () => '2000-01-01',
      validator: value => {
        return (typeof value !== 'object' || moment.isMoment(value))
      }
    },
  },
  data() {
    return {
      periods: [
        { label: '近 3 月', id: '3m', getDateStart: (dateEnd) => moment(dateEnd).subtract(3, 'month').startOf('month').format('YYYY-MM-DD') },
        { label: '近 6 月', id: '6m', getDateStart: (dateEnd) => moment(dateEnd).subtract(6, 'month').startOf('month').format('YYYY-MM-DD') },
        { label: '今年以來', id: 'ytd', getDateStart: (dateEnd) => moment(dateEnd).startOf('year').subtract(1, 'month').startOf('month').format('YYYY-MM-DD') },
        { label: '近 1 年', id: '1y', getDateStart: (dateEnd) => moment(dateEnd).subtract(1, 'year').startOf('month').format('YYYY-MM-DD') },
        { label: '近 3 年', id: '3y', getDateStart: (dateEnd) => moment(dateEnd).subtract(3, 'year').startOf('month').format('YYYY-MM-DD') },
        { label: '近 5 年', id: '5y', getDateStart: (dateEnd) => moment(dateEnd).subtract(5, 'year').startOf('month').format('YYYY-MM-DD') },
        { label: '成立以來', id: 'all', getDateStart: (dateEnd) => moment(this.earliestDate).startOf('month').format('YYYY-MM-DD') },
      ],
      text: '選擇觀察期間',
    }
  },
  watch: {
    periodKey: {
      immediate: true,
      handler() {
        this.text = this.periods.find(({ id }) => id === this.periodKey)?.label || '選擇觀察期間'
      }
    }
  },
  methods: {
    reset() {
      this.$refs.dropdownButton.instance.dropdown('restore defaults')
      this.text = '選擇觀察期間'
    },
    setPeriod(period) {
      const dateStart = period.getDateStart(this.baseDate)
      const dateEnd = this.baseDate
      this.$emit('setDateRange', {
        from: dateStart,
        to: dateEnd,
        dateStart,
        dateEnd,
        key: period.id,
        periodKey: period.id,
      })
    },
  },
}
</script>
