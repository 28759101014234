/* Usage Example

let commit = await prompt({
  title: '複製投組',
  message: '你要複製投組嗎?',
  defaultText: '新名稱',
  placeholder: '請輸入新投組名稱',
  buttonText: { approve: "複製"},
  buttonClass: { approve: "success" },
  showBtnClose: true   // 顯示右上角叉叉
})

*/

window.prompt = (function() {
  const internalPrompt = window.prompt
  const modalPrompt = async function(args) {
    let {
      //
      message,
      title,
      placeholder,
      defaultText,
      buttonText = { approve: '確定', deny: '取消' },
      buttonClass = { approve: '', deny: '' },
      showBtnClose,
    } = args
    let answer = null
    const titleHTML = title ? `<div class="header">${title}</div>` : ''
    const buttonClose = showBtnClose ? '<i class="close icon" style="top:25px!important; right:10px!important; padding:0; color:#aaa;"></i>' : ''

    if (typeof placeholder !== 'string') { placeholder = '' }
    if (typeof defaultText !== 'string') { defaultText = '' }

    await new Promise(function(resolve, reject) {
      const $modal = jQuery(
        `
        <div class="ui modal modal-message modal-message--prompt">
          ${titleHTML}
          ${buttonClose}
          <div class="scrolling content">
            <div class="description" style="width:100%">
              ${String(message).replace(/\n/g, '<br/>')}
              <div class="ui input fluid" style="margin-top: .5em">
                <input type="text" class="ui input" placeholder=${JSON.stringify(placeholder)} value=${JSON.stringify(defaultText)}>
              </div>
            </div>
          </div>
          <div class="actions">
            <div class="ui deny button minor ${buttonClass.deny}">
              ${buttonText.deny || '取消'}
            </div>
            <div class="ui approve button major ${buttonClass.approve}">
              ${buttonText.approve || '確定'}
            </div>
          </div>
        </div>
      `.trim()
      ).modal({
        observeChanges: true,
        closable: false,
        allowMultiple: true,
        onApprove: () => {
          answer = $modal.find('input').val()
        },
        onDeny: () => {
          answer = null
        },
        onHidden: () => {
          $modal.remove()
          resolve(answer)
        },
      })
      $modal.modal('show')
      $modal.find('input').on('keyup', ({ key }) => {
        if (key === 'Enter') {
          $modal.find('.ui.approve').click()
        }
        if (key === 'Escape') {
          $modal.find('.ui.deny').click()
        }
      })
    })

    return answer
  }
  return function(args) {
    if (typeof args == 'object') {
      return modalPrompt(args)
    } else {
      return internalPrompt(args)
    }
  }
})()

export default window.prompt
