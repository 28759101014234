<template>
  <UiLoader :loading="!loadingStateReady">
    <div class="tool-bar">
      <div class="result">
        {{ totalArticles }} 組符合的圖卡
      </div>
      <div class="dropdown-wrap">
        <DropdownFilter v-model="current.category" :entries="filterOptions" topicPrefix="圖卡分類：" />
      </div>
    </div>
    <div v-if="current.articles.length > 0" class="list">
      <div v-for="article in current.articles" :key="article.id" class="list-item">
        <Card :card="article"
              linkRoute="SaleskitChartsContent"
              @clickTag="resetCategory(article.category.name)"
        />
      </div>
    </div>
    <div v-if="current.articles.length < totalArticles" class="btn-wrap">
      <div class="ui button major large" @click="loadMore">
        <i class="icon-more" style="margin-right: .75rem;" />查看更多內容
      </div>
    </div>
  </UiLoader>
</template>

<script>
import API from 'api'
import MixinArticlesList from 'pages/articles/MixinArticlesList'
import Card from 'modules/components/SingleFlashCard'

export default {
  components: {
    Card,
  },
  mixins: [
    MixinArticlesList,
  ],
  async mounted() {
    const categoryId = this.$route.params.category_id
    await API.content.categories({ kind: 'chart' }).then(res => {
      this.categories = res.data.entries
      if (categoryId) {
        this.currentCategoryId = categoryId
      } else {
        this.current.category = 'all'
      }
    })
  },
  methods: {
    async loadList() {
      // 全部文章
      return await API.content.list({ kind: 'chart', page: this.accumulatedPage }).then(res => res.data.entries)
    },
    async loadCategoryList(categoryId) {
      // 目錄下的文章
      return await API.content.categories(
        {
          kind: 'chart',
          id: categoryId || this.currentCategoryId,
          page: this.accumulatedPage
        }
      ).then(res => res.data.entries)
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'

.tool-bar
  margin 2rem 0
  +over(md)
    display flex
    align-items center
    justify-content space-between
  .result
    font-size 1.2rem
    font-weight bold
    +under(md)
      margin-bottom 1rem
.list
  position relative
  &:after
    content ''
    width 200px
    height 180px
    position absolute
    background-image url('~images/animation/img-0.0.1.svg')
    background-repeat no-repeat
    background-position center center
    background-size contain
    +over(md)
      left 10px
      top 100%
    +under(md)
      width 170px
      height 153px
      right 0
      top calc(100% + 88px)
  +over(md)
    display flex
    flex-wrap wrap
  .list-item
    +over(md)
      width calc((100% - 4rem)/ 3)
      margin-bottom 5rem
      margin-right 2rem
      &:nth-child(3n)
        margin-right 0
    +under(md)
      width 100%
      margin-bottom 2rem
.btn-wrap
  text-align center
  +under(md)
    margin-top 2.5rem
</style>
