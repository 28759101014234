<template>
  <div class="login">
    <div v-if="is.locked" class="login-board">
      <AccountLocked :username="form.username" @retry="is.locked = false">
        <template #message>
          {{ message }}
        </template>
      </AccountLocked>
    </div>
    <div v-else class="login-board login-board--padded board-layout">
      <div class="avatar-pic">
        <img src="~images/avatar-blue.svg" alt="">
      </div>
      <div>
        <div class="row-wrap">
          <UiInput v-model="form.username" :error="fieldErrorOf('username')" type="email" class="fluid" placeholder="輸入您的電子信箱" />
        </div>
        <div class="row-wrap">
          <UiInput v-model="form.password" :error="fieldErrorOf('password')" type="password" class="fluid" placeholder="輸入您的密碼" />
        </div>
        <div class="row-wrap flex-between">
          <div>
            <UiCheckbox v-model="form.remember" circled>
              讓我保持登入
            </UiCheckbox>
          </div>
          <router-link :to="{name: 'ForgetPasswd'}" class="link-style link">
            忘記密碼
          </router-link>
        </div>
        <div class="btn-login">
          <div class="ui button major large full" @click="submit">
            登入
          </div>
        </div>
        <div class="row-wrap">
          <span style="margin-right: .5rem;">還沒收到驗證碼?</span>
          <router-link :to="{name: 'MemberResend'}" class="link-style link">
            重新發送
          </router-link>
        </div>
      </div>
    </div>
    <div class="signup">
      <h2 class="basic__topic">
        還沒有帳號?
      </h2>
      <div class="sub-title">
        銷售夥伴資源
      </div>
      <div class="signup-board board-layout">
        <component :is="$deviceView.mobile ? 'UiScroll' : 'div'" class="resource-wrap">
          <div class="resource-group">
            <div class="resource">
              <div class="pic">
                <img src="~images/img-resource1.png" alt="">
              </div>
              <div class="content">
                <div class="title">
                  銷售工具
                </div>
                <div class="descript">
                  協助與客戶溝通投資趨勢
                </div>
              </div>
            </div>
            <div class="resource">
              <div class="pic">
                <img src="~images/img-resource2.png" alt="">
              </div>
              <div class="content">
                <div class="title">
                  經理人觀點
                </div>
                <div class="descript">
                  掌握第一手投資觀點與研究報告重點
                </div>
              </div>
            </div>
            <div class="resource">
              <div class="pic">
                <img src="~images/img-resource3.png" alt="">
              </div>
              <div class="content">
                <div class="title">
                  基金資訊
                </div>
                <div class="descript">
                  基金資訊線上即時更新
                </div>
              </div>
            </div>
            <div class="resource">
              <div class="pic">
                <img src="~images/img-resource4.png" alt="">
              </div>
              <div class="content">
                <div class="title">
                  夥伴學習課程
                </div>
                <div class="descript">
                  更了解UG及操作方式
                </div>
              </div>
            </div>
          </div>
        </component>
        <div class="btn-signup">
          <router-link :to="{name: 'MemberSignup'}" class="ui button large outline full">
            銷售夥伴註冊
          </router-link>
        </div>
        <div class="note">
          本專區內容僅供銷售機構参考之用，非供一般大眾檢閱，<br>欲了解更多請洽您的理財顧問
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiInput from 'modules/ui/form/input'
import UiCheckbox from 'modules/ui/form/checkbox'
import UiScroll from 'modules/ui/scroll'

import { mapActions } from 'vuex'
import { createValidator } from 'modules/ui/form/validation.js'
import { MixinNotifiable } from 'modules/ui/notification.vue'

import AccountLocked from './component/AccountLocked'

import AccountLockedError from 'exceptions/member/AccountLockedError'
import WrongPasswordError from 'exceptions/member/WrongPasswordError'

export default {
  components: {
    UiInput,
    UiCheckbox,
    UiScroll,
    AccountLocked,
  },
  mixins: [
    MixinNotifiable,
    createValidator([
      {
        field: 'username',
        validator: email => email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
        message: '請輸入正確的電子信箱'
      },
      {
        field: 'password',
        message: '請輸入密碼'
      }
    ])
  ],
  data() {
    return {
      form: {
        username: '',
        password: '',
        remember: false,
      },
      is: {
        locked: false,
      },
      message: '',
    }
  },
  watch: {
    'form': {
      deep: true,
      handler() {
        this.clearValidation()
      }
    }
  },
  methods: {
    ...mapActions('member', ['signin']),
    async submit() {
      if (this.validateForm(this.form)) {
        this.wait(this.signin(this.form), { message: '請稍後...' })
          .then(profile => {
            this.success('登入成功')
            const path = this.$route.query.redirect || '/partner'
            if (profile?.member) {
              this.$router.replace({ path }).catch(() => {})
            }
          })
          .catch(error => {
            if (error instanceof AccountLockedError) {
              this.message = error.message
              this.is.locked = true
            } else {
              this.pushFieldError('password', error.message)
            }
          })
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
@require '~pages/member/memberPanel.styl'

.login
  position relative
  width 100%
  +over(xl)
    max-width 1076px
    margin auto
  +over(lg)
    display flex
    padding 5rem 3rem
  +between(md, lg)
    padding 5rem 3rem
  +under(md)
    padding 2rem 1rem 166px 1rem
.flex-between
  display flex
  justify-content space-between
.board-layout
  box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
  border-radius 21px
  background-color #fff
.login-board
  position relative
  &--padded
    padding 3rem
  +over(lg)
    width 384px
    flex-shrink 0
  +between(md, lg)
    &:before
      content ''
      position absolute
      width 200px
      height 180px
      top -110px
      right 4.6%
      background-image url('~images/animation/img-0.0.1.svg')
      background-repeat no-repeat
      background-position center center
      background-size contain
  .avatar-pic
    width 112px
    margin 0 auto 5rem
    img
      width 100%
  .row-wrap
    margin-bottom 1rem
  .btn-login
    margin 4rem auto 1rem
  .link-style
    color $ugBlue2
    display inline-block
    text-decoration underline
.signup
  position relative
  display flex
  flex-direction column
  width 100%
  +over(lg)
    flex-grow 1
    margin-left 3rem
  +under(lg)
    margin-top 3rem
  &:before
    content ''
    position absolute
    background-image url('~images/animation/img-0.0.1.svg')
    background-repeat no-repeat
    background-position center center
    background-size contain
    +over(lg)
      width 200px
      height 180px
      top -110px
      right 102px
    +between(md, lg)
      display none
    +under(md)
      width 120px
      height 108px
      top calc(100% + 10px)
      right 0

  .sub-title
    font-size 1.44rem
    font-weight bold
    margin 1rem 0 2rem
.signup-board
  position relative
  flex-grow 1
  padding 2rem
  &:before
    content ''
    position absolute
    right -11px
    top -65px
    width 175px
    height 96px
    background-image url('~images/woman-write.png')
    background-repeat no-repeat
    background-size cover
    background-position center center
    +under(md)
      right 0
  .resource-wrap
    +under(md)
      position relative
  .resource-group
    display flex
    +over(md)
      flex-wrap wrap
    +under(md)
      width 1062px
  .resource
    display flex
    align-items center
    border 1px solid #ededed
    border-radius 21px
    padding 3rem 1rem
    +over(md)
      width calc((100% - 2rem)/2)
      &:nth-child(2n)
        margin-left 2rem
      &:nth-child(n+3)
        margin-top 1rem
    +under(md)
      + .resource
        margin-left 1rem
    .pic
      width 60px
      margin-right 1rem
      img
        width 100%
    .title
      font-size 1.44rem
      font-weight bold
      margin-bottom .5rem
    .descript
      font-size 1.2rem
  .btn-signup
    width 300px
    margin 2rem auto 1.5rem
  .note
    text-align center

.emphasize-text
  font-size 1.44rem
  font-weight bold
  margin-bottom .5rem

</style>
