import Api from 'api'

export default {
  namespaced: true,
  state: {
    loadingIds: [],
    loadedIds: [],
    loadedEntries: [],
  },
  actions: {
    loadFunds({ state, commit, subscribe }, ids) {
      return new Promise((resolve, reject) => {
        const idsToLoad = ids
          .filter(d => !state.loadingIds.includes(d))
          .filter(d => !state.loadedIds.includes(d))

        // 避免未載入完中斷卡了不完整的資料
        window.addEventListener('beforeunload', () => {
          commit('aborted')
        })

        if (idsToLoad.length > 0) {
          commit('load', idsToLoad)
          const loaders = idsToLoad.map(id => Api.fund({ id })
            .then(res => res.data.entry)
            .catch(error => {
              commit('failed', id, error)
            }))
          Promise.all(loaders).then(funds => {
            commit('loaded', funds.filter(hasFund => hasFund))
            resolve()
          })
        } else if (state.loadingIds.length) {
          // 等待之前的載入完成
          const timer = setInterval(() => {
            if (state.loadingIds.length === 0) {
              resolve()
              clearInterval(timer)
            }
          }, 100)
        } else {
          // 不需要載入
          resolve()
        }
      })
    },
    getFund({ commit, state }, id) {
      return state.loadedEntries.find(({ id: _id }) => _id === id)
    },
    async fetchFund({ commit, state }, ids) {

    }
  },
  mutations: {
    load(state, ids) {
      state.loadingIds = [
        ...state.loadingIds,
        ...ids,
      ]
    },
    aborted(state) {
      state.loadingIds = []
    },
    loaded(state, funds) {
      state.loadedEntries = [
        ...state.loadedEntries,
        ...funds,
      ]
      state.loadedIds = [
        ...state.loadedEntries.map(({ id }) => id)
      ]
      state.loadingIds = state.loadingIds.filter(d => !state.loadedIds.includes(d))
    },
    failed(state, id, error) {
      console.error(`Cannot load fund with id: ${id}`, error)
      state.loadingIds = state.loadingIds.filter(d => d !== id)
    }
  },
  getters: {
    all: state => state.loadedEntries
  },
}
