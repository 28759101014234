import isMobile from 'isMobile.min'

const $ = jQuery

function createTooltipConfig(info, el) {
  if (info) {
    const title = $(el).data('help-title') || info.title
    const titleHTML = title ? `<p class="f13 bold">${title}</p>` : ''
    return {
      html: `
        <div class="tooltip-content">
          ${titleHTML}
          <p>${info.content}</p>
        </div>
      `,
      // exclusive: true,
      preserve: false,
      on: isMobile.any ? 'click' : 'hover',
      movePopup: false,
      position: 'bottom left',
      lastResort: 'top left',
      tooltipInfo: info,
      className: {
        popup: 'ui popup inactivate'
      },
      ...(info.options || {})
    }
  }
}

function initTooltip(e) {
  // 手機版不顯示浮動 tooltip
  if (isMobile.any) { return false }
  const $target = $(e.currentTarget)
  if (!$target.popup('exists')) {
    const config = $target.data('tooltipConfig')
    let popup
    if (config) {
      $target.popup({
        ...config,
        onShow() {
          popup = $target.popup('get popup')
          if (config.tooltipInfo.style) {
            popup.css(config.tooltipInfo.style)
          }
          if (config.tooltipInfo.className) {
            popup.addClass(config.tooltipInfo.className)
          }
          if (typeof config.onShow == 'function') {
            config.onShow.apply(this, arguments)
          }
        }
      })
      $target.popup('show')
    }
  }
}

(function initTitleTooltip() {
  $(document).on('mouseup mouseenter', '.no-touch [data-tooltip-title]:not([data-tooltip-title=""])', e => {
    const info = {
      content: $(e.currentTarget).attr('data-tooltip-title')
    }
    $(e.currentTarget).addClass('v-has-tooltip-content')
    $(e.currentTarget).data('tooltipInfo', info)
    $(e.currentTarget).data('tooltipConfig', createTooltipConfig(info, e.currentTarget))
    initTooltip(e)
  })
})()

;(function initTitleTooltip() {
  $(document).on('mouseup mouseenter', '.no-touch [title]:not([title=""])', e => {
    const info = {
      content: $(e.currentTarget).attr('title')
    }
    $(e.currentTarget).addClass('v-has-tooltip-content')
    $(e.currentTarget).data('tooltipInfo', info)
    $(e.currentTarget).data('tooltipConfig', createTooltipConfig(info, e.currentTarget))
    initTooltip(e)
  })
})()

;(function initDataTooltip() {
  $(document).on('mouseup mouseenter', '.no-touch [data-tooltip-content]', e => {
    const info = {
      title: $(e.currentTarget).attr('data-tooltip-title'),
      content: $(e.currentTarget).attr('data-tooltip-content')
    }
    $(e.currentTarget).addClass('v-has-tooltip-content')
    $(e.currentTarget).data('tooltipInfo', info)
    $(e.currentTarget).data('tooltipConfig', createTooltipConfig(info, e.currentTarget))
    initTooltip(e)
  })
})()

/*
    支援 Vue 啟動
      v-tooltip:info="資訊主題"
  */

export default {
  install(Vue, options) {
    Vue.directive('tooltip', {
      bind(el, binding, vnode) {
        if (binding.arg == 'info') {
          const info = libInfo[binding.value || binding.expression]
          if (info) {
            $(el).addClass('v-has-tooltip-info')
            $(el).data('tooltipInfo', info)
            $(el).data('tooltipConfig', createTooltipConfig(info, el))
            if (!isMobile.any) {
              $(el).one('mouseenter', initTooltip)
            }
          } else if ($(el).is('[class*=icon-]')) {
            vnode.elm = document.createComment(' ')
            vnode.text = ' '
            vnode.isComment = true
            vnode.context = undefined
            vnode.tag = undefined
            vnode.data.directives = undefined
          }
        }
      },
      update(el, binding) {
        if (binding.arg == 'info') {
          // 手機版不顯示浮動 tooltip
          if (!isMobile.any) {
            $(el).one('mouseenter', initTooltip)
          }
        }
      },
      unbind(el) {
        $(el).off('mouseenter', initTooltip)
        $(el).popup('hide')
        $(el).popup('remove popup')
        $(el).data('tooltipInfo', null)
        $(el).removeClass('v-has-tooltip-info')
      }
    })
    // Vue.directive('tooltip', Vue.options.directives.tooltip)
  }
}
