<template>
  <div class="announcement">
    <div class="list">
      <div v-for="entry in specifyList" :key="entry.id" class="list-item">
        <div v-if="entry.category.name" class="category">
          {{ entry.category.name }}
        </div>
        <div class="title">
          <router-link v-if="entry.has_content" :to="{name: 'FormsContent', params: {id: entry.id}}">
            {{ entry.title }}
          </router-link>
          <a v-else :href="entry.attachment_url" target="_blank">{{ entry.title }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    specifyList: Array,
  },
  data() {
    return {
      kind: 'forms',
    }
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.list
  .list-item
    position relative
    display flex
    justify-content space-between
    border-bottom 1px solid #ededed
    padding 9px 0 9px 18px
    &:before
      content ''
      position absolute
      left 0
      top 50%
      transform translateY(-50%)
      width 4px
      height 28px
      background-color $ugBlue2
      border-radius 5px
  .title
    flex-grow 1
    display -webkit-box
    -webkit-box-orient vertical
    text-overflow ellipsis
    -webkit-line-clamp 1
    overflow hidden
    +under(md)
      -webkit-line-clamp 2
    a:hover
      font-weight bold
  .category
    flex-shrink 0
    width 100px
    color $gray2
    font-weight bold
    margin-right 1rem
</style>
