<template>
  <UiModal ref="modal" size="large" showClose modalClass="modalvideo" :dataOptions="modalOptions" :visible.sync="is.visible" v-on="$listeners" @visible="markRead">
    <template #modal="modal">
      <div class="video">
        <iframe v-if="is.visible" ref="iframe" :key="videoUrl" :src="videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
      </div>
      <div class="video-content">
        <Scroll>
          <div style="max-height: 82px;">
            <div class="info">
              <div v-if="video.subtitle" class="sub-title">
                {{ video.subtitle }}
              </div>
              <div class="date">
                {{ video.publish_at | date }}
              </div>
            </div>
            <div class="descript">
              {{ video.title }}
            </div>
          </div>
        </Scroll>
      </div>
    </template>
  </UiModal>
</template>

<script>
import API from 'api'
import { ModalImplement } from 'modules/ui/modal'
import Scroll from 'modules/ui/scroll'

export default {
  components: {
    Scroll,
  },
  mixins: [
    ModalImplement
  ],
  props: {
    video: Object,
  },
  data() {
    return {
      is: {
        visible: false,
      }
    }
  },
  computed: {
    videoUrl() {
      const id = this.video.video_url.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/)[1]
      return `https://www.youtube.com/embed/${id}?rel=0&playsinline=1&autoplay=1&enablejsapi=1&origin=${location.origin}`
    },
    modalOptions() {
      // 訪客不能關掉影片
      return {
        closable: !!this.$member
      }
    },
  },
  methods: {
    markRead() {
      if (this.video?.id) {
        API.content.read({ id: this.video.id })
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.video
  position relative
  width 100%
  padding-bottom 56%
  iframe
    position absolute
    top 0
    left 0
    width 100%
    height 100%
.video-content
  padding 1.5rem 2rem
  line-height 18px
  .info
    display flex
    justify-content space-between
    color $gray2
    margin-bottom .75rem
  .descript
    color #505050
</style>
