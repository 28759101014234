<!--
  模組已停用
  issue: https://app.asana.com/0/1199580963712713/1200433467285900/f

  市場觀點和關鍵圖卡共用內頁
-->
<template>
  <div v-if="article" class="article-container">
    <h2 class="basic__topic article-title">
      {{ article.title }}
    </h2>
    <div class="article-meta">
      <div class="left-side">
        <div class="ui button round small minor" style="margin-right: 1rem;" @click="routeToCategory(article.category)">
          {{ article.category.name }}
        </div>
        <div class="date font-bold">
          {{ article.publish_at | date }}
        </div>
      </div>
      <div class="right-side">
        <ShareIcon :direction="$deviceView.desktop ? 'ani-left' : 'ani-down'" />
      </div>
    </div>
    <div v-if="article.category.sub_kind !== 'flashcard'" class="article-cover">
      <img :src="article.cover_medium_url" alt="">
    </div>
    <ArticleContent v-html="article.content" />

    <div v-if="relativeArticle" class="relative-article">
      <div class="section-head">
        <div class="section-title">
          {{ pageName }}
        </div>
        <router-link class="btn-more" :to="{name: pageType === 'insights' ? 'Insights' : 'FlashCard'}">
          更多>
        </router-link>
      </div>
      <component :is="$deviceView.mobile ? 'UiScroll' : 'div'">
        <div class="section-list">
          <div v-for="item in relativeArticle" :key="item.id" class="list-item">
            <FlashCard v-if="pageType === 'flashcard'"
                       :card="item"
                       linkRoute="FlashCardArticle"
                       @clickTag="routeToCategory(item.category)"
            />
            <InsightCard v-else
                         :article="item"
                         @resetCategory="routeToCategory"
            />
          </div>
        </div>
      </component>
    </div>
  </div>
</template>

<script>
import API from 'api'
import ArticleContent from 'modules/components/ArticleContent.vue'
import ShareIcon from 'modules/components/SocialShare'
import UiScroll from 'modules/ui/scroll'
import InsightCard from 'modules/components/SingleInsightsCard.vue'
import FlashCard from 'modules/components/SingleFlashCard'

export default {
  components: {
    ArticleContent,
    ShareIcon,
    UiScroll,
    InsightCard,
    FlashCard,
  },
  data() {
    return {
      article: '',
      relativeArticle: null,
    }
  },
  computed: {
    articleId() {
      return this.$route.params.id
    },
    pageType() {
      const kind = this.article.category?.sub_kind
      return kind === 'flashcard' ? 'flashcard' : 'insights'
    },
    pageName() {
      const name = {
        insights: '市場觀點',
        flashcard: '關鍵圖卡',
      }
      return name[this.pageType]
    },
  },
  watch: {
    articleId() {
      this.reloadPage()
    }
  },
  mounted() {
    this.reloadPage()
  },
  methods: {
    async reloadPage() {
      await this.loadContent()
      this.loadRelativeArticle()
    },
    async loadContent() {
      await API.articles({ id: this.articleId }).then(res => {
        this.article = res.data.entry
      })
    },
    async loadRelativeArticle() {
      await API.articles.popular({ kind: this.pageType, limit: 4 }).then(res => {
        this.relativeArticle = res.data.entries.filter(entry => entry.slug !== this.articleId).slice(0, 3)
      })
    },
    routeToCategory(category) {
      const url = `/${this.pageType}/category/${category.id}`
      this.$router.push({ path: url })
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'

.article-container
  padding 5rem 3rem 10rem 3rem
  +over(xl)
    max-width 1076px
    margin 0 auto
  +under(md)
    padding 2rem 1rem 10rem 1rem
.article-title
  margin-bottom 1rem
.article-meta
  display flex
  align-items center
  justify-content space-between
  margin-bottom 1.5rem
  .left-side, .right-side
    display flex
    align-items center
  .date
    color $gray2
.article-cover img
  width 100%

.relative-article
  position relative
  margin-top 10rem
  .section-head
    display flex
    justify-content space-between
    font-weight bold
    margin-bottom 2rem
  .section-title
    font-size 1.728rem
  .btn-more
    font-size 1.2rem
    color $gray2
  .section-list
    display flex
    +under(md)
      width 836px
    .list-item
      width calc((100% - 4rem)/ 3)
      margin-right 2rem
      &:nth-child(3n)
        margin-right 0
      +under(md)
        margin-bottom 1rem
</style>
